import { ConstantPool } from '@angular/compiler'
import { Component, HostListener, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core'
@Component({
    selector: 'input-search',
    // inputs: ['isInvalid'],
    templateUrl: './inputSearch.component.html',
    styleUrls: ['./inputSearch.component.css'],
    providers: []
})

export class InputSearchComponent implements OnInit {

    @Input()
    isInvalid: Boolean
    @Input()
    data: Array<Object>
    @Input()
    format: Array<string>
    @Input()
    filters: Array<string>

    modalOptions = {
        size: 'lg',
        scrollable: true,
        animation: true,
    }
    flagOptions = false
    widthSelect
    opcionSeleccionada = ''
    opciones = ['1', '2', '3', '4', '5', '6']
    temp = []

    constructor() { }

    ngOnInit() {
        // console.log(this.data)
        // console.log('Hola')
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!(event.target.id == 'search' || event.target.id == 'option')) {
            this.flagOptions = true
            this.toggleOptions()
        }
    }

    toggleOptions() {
        let options = document.getElementById('options')
        if (!this.flagOptions) {
            this.temp = [...this.data]
            options.classList.remove('hide')
            options.style.width = (document.getElementById('search').offsetWidth).toString() + 'px'
            this.flagOptions = true
        } else {
            options.classList.add('hide')
            this.flagOptions = false
        }
        console.log(this.temp.length)
    }

    selectOp(option) {
        this.opcionSeleccionada = option
        this.toggleOptions()
        // this.readObject(this.modalOptions)
    }

    readObject(object) {
        for (var key in object) {
            console.log(key)
            var value = object[key]
        }
    }

    filterOptions(event) {
        const val = event.target.value.toLowerCase()
        const temp = this.temp.filter(function (d) {
            let dataFilterer
            for (let key in this.filters) {
                let params = this.filters[key]
                // console.log(d.params)
                // if ((d[this.filters[key]].toLowerCase().indexOf(val) !== -1 || !val) == false) {
                //     return
                // } else {
                //     dataFilterer = d[this.filters[key]].toLowerCase().indexOf(val) !== -1 || !val
                // }
            }
            // if ((d.codigo.toLowerCase().indexOf(val) !== -1 || !val) == false) {
            //     if ((d.prestatario.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
            //         if ((d.prestamista.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
            //             if ((d.estado.toLowerCase().indexOf(val) !== -1 || !val) == false) {
            //                 if ((d.prestatario.rut.toLowerCase().indexOf(val) !== -1 || !val) == false) {

            //                 } else {
            //                     dataFilterer = d.prestatario.rut.toLowerCase().indexOf(val) !== -1 || !val
            //                 }
            //             } else {
            //                 dataFilterer = d.estado.toLowerCase().indexOf(val) !== -1 || !val
            //             }
            //         } else {
            //             dataFilterer = d.prestamista.nombre.toLowerCase().indexOf(val) !== -1 || !val
            //         }
            //     } else {
            //         dataFilterer = d.prestatario.nombre.toLowerCase().indexOf(val) !== -1 || !val
            //     }
            // } else {
            //     dataFilterer = d.codigo.toLowerCase().indexOf(val) !== -1 || !val
            // }
            return dataFilterer;
        })
        this.data = temp;
    }
}
<!-- begin breadcrumb -->
<ol class="breadcrumb pull-right">
    <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
    <li class="breadcrumb-item">Declaraciones</li>
    <li class="breadcrumb-item active">Subir declaración</li>
</ol>
<!-- end breadcrumb -->

<!--################################################COntenido############################################################-->


<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin row -->


<div class="row ">

    <div class="col-md-12">
        <div class="panel panel-iatuch">
            <!-- begin panel-heading -->
            <div class="panel-heading ui-sortable-handle">
                <div class="panel-heading-btn">

                </div>
                <h4 class="panel-title"><i class="{{icono}}"></i> {{titulo}}</h4>
            </div>
            <!-- end panel-heading -->
            <!-- begin panel-body -->
            <div class="panel-body ">
                <div class="row">
                    <div class="col-md-6">
                        <form #declaracionForm="ngForm" (ngSubmit)="onSubmit(declaracionForm)">
                            <div class="col-md-12">
                                <label>Archivo: {{nombre_archivo}}</label>
                            </div>
                            <button type="button" class="btn btn-sm btn-info m-r-3" placement="top" ngbTooltip="Adjuntar Archivo..." (click) ="adjuntar()">
                                <i class="fa fa-plus"></i>
                                <span> Añadir Archivo...</span>
                            </button>
                            <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                                <i class="fa fa-plus"></i>
                                <span> Añadir Archivo...</span>
                                <input #archivo_declaracion type="file" id="btn_archivo_declaracion" name="archivo[]" multiple
                                    (change)="fileChageEvent($event)" required>
                            </span>
                            <!--<span class="text-danger" *ngIf="!archivo.valid">Debe adjuntar Archivo</span>-->



                            <button type="submit" class="btn btn-sm btn-green start m-r-3" placement="top" ngbTooltip="Subir" [disabled]="!nombre_archivo">
                                <i class="fa fa-upload"></i></button>
                            <a href="./assets/plantillas/plantilla_declaraciones.xlsx" class="btn btn-sm btn-primary"
                            placement="top" ngbTooltip="Descargar Plantilla">
                                <i class="fa fa-download"></i></a>

                            <div *ngIf="cargando == true" class="col-md-6"><br>
                                <label class="text-info"><i class="fas fa-cog fa-pulse"></i> Cargando
                                    Resultados...</label>
                            </div>
                        </form>
                    </div>
                </div>
                <br>
                <div class="alert {{plazo.color}} fade show col-md-12" *ngIf="plazo">
                    <span class="close" data-dismiss="alert">×</span>
                    <strong><i class="fas {{plazo.icon}} fa-fw"></i> Atención!</strong>
                    {{plazo.texto}} {{(plazo.fecha | date:'dd/MM/yyyy HH:mm')+" Hrs"}}

                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div *ngIf="declaraciones" class="panel panel-iatuch ">
            <!-- begin panel-heading -->
            <div class="panel-heading ui-sortable-handle">
                <div class="panel-heading-btn">

                </div>
                <h4 class="panel-title"><i class="fas fa-lg fa-fw fa-list-ol"></i> Resultados</h4>
            </div>
            <!-- end panel-heading -->
            <!-- begin panel-body -->
            <div class="panel-body ">
                <div class="row">
                    <div class="table-responsive">
                        <div *ngIf="mensaje">
                            <div class="alert {{mensaje.color}} fade show m-b-10">

                                <i class="fa {{mensaje.icon}}"></i> {{mensaje.texto}} <br>
                                <div *ngIf="lista_errores">
                                    <ul *ngFor="let le of lista_errores">
                                        <li style="padding: 0;">{{le}}</li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                        <table id="tabla_decl" class="table table-striped table-condensed row-border hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Rut</th>
                                    <th>Razon Social</th>
                                    <th>Tipo Ingr/Egr</th>
                                    <th>Monto</th>
                                    <th class="text-center">Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dec of declaraciones" [ngClass]="{'danger':dec.estado == 'false'}">
                                    <td>{{dec.fila+1}}</td>
                                    <td>{{dec.rut}}</td>
                                    <td>{{dec.nombre}}</td>
                                    <td>{{dec.tipo_ing_egr}}</td>
                                    <td>${{dec.monto | number:0}}</td>
                                    <td align="center">
                                        <i *ngIf="dec.estado == 'true'"
                                            class="text-success fas fa-lg fa-fw m-r-10 fa-check-circle"></i>
                                        <i *ngIf="dec.estado == 'false'" placement="top" ngbTooltip="{{dec.mensaje_validacion}}"
                                            
                                            class="text-danger fas fa-lg fa-fw m-r-10 fa-exclamation-triangle"></i>

                                    </td>
                                </tr>

                            </tbody>
                            <tfoot>
                                <tr class="info">
                                    <td colspan="4">Total</td>
                                    <td colspan="2">${{total_declaraciones | number:0}}</td>
                                </tr>
                            </tfoot>
                        </table>


                        <div *ngIf="estado_declaracion == true" class="col-md-12">

                            <button (click)="guardar_declaraciones()" class="btn btn-primary"><i
                                    class="far fa-lg fa-fw m-r-5 fa-envelope"></i>Enviar</button>
                        </div>

                        <!--<div class="col-md-12 center">(click) = "guardar_declaraciones()"
										<div class="btn-group" *ngFor="let idx of paginas; index as i;">
											<button class="btn btn-white btn-circle">{{i + 1}}</button> 
										</div>
									</div>-->

                    </div>
                </div>


            </div>
        </div>
    </div>

</div>
<!-- end row -->

import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
// import * as XLSX from 'ts-xlsx'
import { Observable, Subject, Subscription } from "rxjs"
import { Declaracion } from '../../../models/declaracion';
import { Exceldeclaracion } from '../../../models/exceldeclaracion';
import { UploadService } from '../../../services/upload.service';
import { DeclaracionService } from '../../../services/declaracion.service';
import { Global } from '../../../services/global';
import { EntidadService } from '../../../services/entidad.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
//import { forEach } from '@angular/router/src/utils/collection';
import Swal from 'sweetalert2'

declare var jQuery: any
declare var $: any;
declare var button: any
//import swal, { SweetAlertOptions } from 'sweetalert2';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../../services/user.service';
import { SharingService } from '../../../services/sharing.service';
/*declare var TableManageDefault: any;
declare var datatable: any;*/
/*declare var TableManageDefault: any;*/
//var $ = require('jquery');
//var dt = require('datatables.net');

//import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-subirexcel',
  templateUrl: './subirexcel.component.html',
  styleUrls: ['./subirexcel.component.css',

    //'../../../../node_modules/Datatables.net-dt/css/jquery.dataTables.css'
  ],
  providers: [UploadService, DeclaracionService]
})



export class SubirexcelComponent implements OnInit {
  //Datatables-----------------------------------

  //-------------------------------------------
  public identity;
  public token;
  public entidad_sel;

  public titulo = " Subir declaración jurada "
  public descripcion = "";
  public icono = "fas fa-upload fa-fw";
  public filesToUpload: Array<File>;

  public nombre_archivo = '';

  public declaraciones: any;
  public total_declaraciones: number;
  public dec_extenciones: any;
  public dec_bien_raiz: any;
  public pagina_tabla = new Array();
  public paginas = new Array();

  public declaracion: Declaracion;
  public subirDeclaracionExcel;
  public status: string;
  public id_declaracion: string;
  public estado_declaracion: boolean;
  public cargando: boolean;
  public mensaje;
  public plazo;
  public lista_errores = [];

  public minified;
  @ViewChild('archivo_declaracion', { static: false }) archivo_declaracion: ElementRef;
  public entidadSelSubscription: Subscription;
  rootNode: any;

  constructor(
    private _declaracionService: DeclaracionService,
    private _uploadService: UploadService,
    private _userService: UserService,
    private _entidadService: EntidadService,
    private _router: Router,
    private _sharingService: SharingService,
    private _route: ActivatedRoute,
    rootNode: ElementRef
  ) {

    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.declaracion = new Declaracion('', '', 0, '', '', '');
    this.rootNode = rootNode;
    this.cargando = false;
    this.entidad_sel = this._entidadService.getEntidad();

  }

  ngOnInit() {
    this._sharingService.sharingIdentity.subscribe(data => {
      this.identity = data
    })  

    this.entidadSelSubscription = this._sharingService.sharingEntity.subscribe(
      (entidad) => {
        this.limpiar_carga()
      }
    )
  }

  limpiar_carga() {
    this.entidad_sel = this._entidadService.getEntidad();
    this.declaraciones = null;
    this.plazo = null;

  }

  adjuntar() {
    document.getElementById('btn_archivo_declaracion').click();
  }



  fileChageEvent(fileInput: any) {

    this.filesToUpload = <Array<File>>fileInput.target.files;
    var xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    if (this.filesToUpload.length == 1 && this.filesToUpload[0].type == xlsx) {
      this.nombre_archivo = fileInput.target.files[0].name;
    } else {

      Swal.fire({
        title: 'Error!',
        text: 'Debe Ingresar un Archivo Valido (XLSX)',
        icon: 'warning',
        showCancelButton: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-lg btn-warning ml-3',
          cancelButton: 'btn btn-lg btn-danger',

        },
        confirmButtonText: 'OK'
      }).then(() => {

      })
    }

  }



  cargaDeclaraciones(id_declaracion) {
    this.lista_errores = [];
    this._declaracionService.getDeclaraciones(this.id_declaracion).subscribe(
      response => {

        if (response.mensaje.lista_errores) {
          this.lista_errores = response.mensaje.lista_errores
        }

        this.declaraciones = response.declaraciones;


        this.plazo = response.plazo;
        this.total_declaraciones = 0;

        for (let i = 0; i < this.declaraciones.length; i++) {
          const element = this.declaraciones[i];
          this.total_declaraciones = this.total_declaraciones + element.monto;

        }



        this.dec_extenciones = response.extenciones;
        this.dec_bien_raiz = response.bien_raiz;
        this.estado_declaracion = response.estado;
        this.mensaje = response.mensaje.icon != '' ? response.mensaje : null;

        this.cargando = false;
        this.nombre_archivo = "";

      },
      error => {
        Swal.fire({
          title: 'Error!',
          text: "Ocurrio un error al cargar registros, intente mas tarde!",
          icon: 'error',
          showCancelButton: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-lg btn-danger ml-3',
            cancelButton: 'btn btn-lg btn-danger',

          },
          confirmButtonText: 'Ok'
        })

      }
    );
  }



  onSubmit(form) {

    this.entidad_sel = this._entidadService.getEntidad();
    var fecha = new Date();
    var year = fecha.getFullYear();
    this.declaracion.usuario = this.identity.mail;
    this.declaracion.entidad = this.entidad_sel._id ? this.entidad_sel._id :
      (this.entidad_sel.id_ ? this.entidad_sel.id_ : this.entidad_sel.id);
    this.declaracion.year = year;
    // Guardar datos básicos
    this.cargando = true;
    this._declaracionService.subirDeclaracionExcel(this.declaracion).subscribe(
      response => {
        if (response.declaracion) {
          this.id_declaracion = response.declaracion._id;

          // Subir la imagen
          if (this.filesToUpload) {
            this._uploadService.makeFileRequest(Global.url + "upload-file/" + this.id_declaracion, [], this.filesToUpload, 'archivo', this.token)
              .then((result: any) => {


                this.subirDeclaracionExcel = result.declaracion;
                //this.datatable();
                this.status = 'success';
                form.reset();
                this.cargaDeclaraciones(this.id_declaracion);

              });
          } else {
            this.subirDeclaracionExcel = response.declaracion;
            this.status = 'success';
            form.reset();
          }

        } else {
          this.status = 'failed';
        }
        this.archivo_declaracion.nativeElement.value = null;
        form.reset();

      },
      error => {
        this.cargando = false;
        Swal.fire({
          title: 'Error!',
          text: "Ocurrio un error al cargar registros, intente mas tarde!",
          icon: 'error',
          showCancelButton: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-lg btn-danger ml-3',
            cancelButton: 'btn btn-lg btn-danger',

          },
          confirmButtonText: 'Ok'
        })

      }
    );

  }


  guardar_declaraciones() {

    Swal.fire({
      text: "Cargando...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })


    /*this.declaraciones;
      this.dec_extenciones;
      this.dec_bien_raiz;*/
    this._declaracionService.guardarDeclaraciones(JSON.stringify(this.declaraciones), JSON.stringify(this.dec_extenciones), JSON.stringify(this.dec_bien_raiz), this.id_declaracion).subscribe(
      response => {
        this.estado_declaracion = false;
        if (response.estado == false) {

          this.estado_declaracion = true
          Swal.close()
          this.declaraciones = response.datos
          Swal.fire({
            title: 'Error!',
            text: "Su Declaración Contiene Errores por favor volver a revisar",
            icon: 'error',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-danger ml-3',
              cancelButton: 'btn btn-lg btn-danger'
            },
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
          })
        } else {



          Swal.fire({
            title: 'Exito!',
            text: "Se han guardado las declaraciones",
            icon: 'success',
            showCancelButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-success ml-3',
              cancelButton: 'btn btn-lg btn-danger'
            },
            confirmButtonText: 'Ver',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
          }).then((result) => {
            if (result.value) {
              this._router.navigate(['/verdeclaracion']);
            }
          })
        }

      },

      error => {
        console.error(<any>error);

        Swal.fire({
          title: 'Error!',
          text: "No se pudo guardar declaraciones, intente mas tarde",
          icon: 'error',
          showCancelButton: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-lg btn-danger ml-3',
            cancelButton: 'btn btn-lg btn-danger',

          },
          confirmButtonText: 'Ok'
        })

      }

    );


  }


}

<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
	<li class="breadcrumb-item">Inicio / DJ1945 / <a href="javascript:;">Registros Duplicados</a></li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin panel -->
<panel variant="iatuch" title="{{ titulo }}">
	<div class="row">
		<div class="col-md-12">
			<button class="btn btn-purple" (click) ="autocorrection()"><i placement="top" ngbTooltip="Corrección Automática"
					class="fa fa-sync"></i> &nbsp; Autocorrección</button>
		</div>

		<div class="col-md-12">
			<table *ngIf="dj_duplicate_records" class="table table-striped 
	      table-sm">
				<thead>
					<tr>
						<th>#</th>
						<th>Rut</th>
						<th>Tipo Ing/Egr</th>
						<th>Tipo</th>
						<th>Periodo</th>
						<th>Cantidad</th>
						<th><i class="fa fa-cogs"></i></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor=" let r of dj_duplicate_records; let inicio = index 
		  ">
						<td>{{ inicio + 1 }}</td>
						<td>{{ r.rut }}</td>
						<td>{{ r.tipo_ing_egr}}</td>
						<td>{{ r.grupo_entidad }}</td>
						<td>{{ r.periodo }}</td>
						<td>{{ r.count }}</td>
						<td><button class="btn btn-success btn-xs" (click)="open(modal_opciones, r)"><i class="fa 
								  fa-eye"></i></button></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

</panel>
<ng-template #modal_opciones let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title text-info"><i class="fa fa-check-circle"></i>
			Seleccione Opción</h4>

		<button type="button" class="close btn btn-white" (click)="d('Cross 
		  click')">×</button>
	</div>
	<div class="modal-body">
		<div class="card">
			<div class="card-body">
				<table class="table table-striped table-sm">
					<thead>
						<tr>
							<th>Rut</th>
							<th>Origen Ing</th>
							<th>Destino Egr</th>
							<th>Tipo Relación</th>
							<th> <i class="fa fa-cogs"></i> </th>

						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let od of opcion_duplicado; let inicio=index">
							<td>{{ od.record.rut }}</td>
							<td>{{ od.origen_ing}}</td>
							<td>{{ od.destino_egr }}</td>
							<td>{{ od.tipo_relacion }}</td>
							<td> <button class="btn btn-success btn-xs" (click)="agruparRegistro(od)"><i class="fa 
						    fa-check-circle"></i></button>
							</td>
						</tr>
					</tbody>

				</table>

			</div>


		</div>

	</div>
	<div class="modal-footer">
		<button id="cerrar_modal" class="btn btn-danger" (click)="d('Cross 
		click')">Cerrar</button>
	</div>
</ng-template>

import { Component, OnInit, ElementRef } from '@angular/core';
import { EntidadService } from '../../../services/entidad.service';
import { UploadService } from '../../../services/upload.service';
import { Global } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { F29Service } from '../../../services/declaracion_f29.service';
import { Documentacion_f29 } from '../../../models/documentacion_f29';
import { RevisionPeriodo } from '../../../models/revision_periodo';
import { Borrador_f29 } from '../../../models/f29_borrador'
import { detectAnyAdblocker } from 'just-detect-adblock'
import {Subscription} from "rxjs"


import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'


declare var jQuery: any;
declare var $: any;
declare var button: any;
import Swal from 'sweetalert2';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThousandsPipe } from '../../../pipes/thousands.pipe'
import { COLOR_BLACK } from '../../../config/globals';
import { LocalService } from '../../../services/localService';
import { SharingService } from '../../../services/sharing.service';

@Component({
  selector: 'app-declarar-impuestos',
  templateUrl: './declarar-impuestos.component.html',
  styleUrls: ['./declarar-impuestos.component.css'],
  providers: [EntidadService, UploadService, F29Service, UserService, ThousandsPipe]
})
export class DeclararImpuestosComponent implements OnInit {

  public categoria_borrador = "Débitos y Ventas"
  public url = Global.url;
  public titulo = " Documentación ";
  public descripcion = " Declaraciones (F-29) ";
  public icono = "fas fa-folder-open fa-fw";
  public filesToUpload;
  public meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

  public active = 1
  public tipo_archivo;
  public token;
  public entidad_sel;
  public archivo_subir;
  public archivos = [];

  public nombre_boton = "SECCIÓN: DÉBITOS Y VENTAS"

  public enviar_disabled = true;
  public guardar_disabled = false;

  public alerta = false;
  public mensaje_alerta;
  public periodo_abierto;
  public periodo;
  public fecha_vencimiento;
  public mes_periodo;
  public registro_periodo = false;

  public nombre_archivo;
  public extencion_archivo;
  public extencion_valida_archivo;
  public adjuntar_disabled = false;

  public etapa_periodo;
  public ultBitacora;
  public estado_periodo;
  public bitacora = [];
  public etapa_revision;
  public estados_revision;
  public privilegio = { revision_periodo: false }
  public fecha_actual = new Date();
  public vencimiento_sig_periodo = new Date()

  public revision_periodo;
  public closeResult: string;
  public btn_cerrar_periodo = false;
  public estadisticas = {
    porcentaje_aTiempo: "",
    dias_entrega: "",
    promedio_rechazos: "",
    activo: 0
  }
  public entidad_periodo;
  public minified;
  public borrador_f29
  public f29_grupos = []
  public tipo_borrador = "entidad"
  public cargando_pdf = false
  public entidadSelSubscription: Subscription;
  constructor(

    private _entidadService: EntidadService,
    private _uploadService: UploadService,
    private _userService: UserService,
    private _f29Service: F29Service,
    private modalService: NgbModal,
    private _sharingService: SharingService,
    private _localService: LocalService
  ) {
    this.minified = false;
    this.trae_privilegios("revision_periodo");
    this.ultBitacora = { fecha: Date, estado: "", etapa: "", observaciones: "" }
    this.tipo_archivo = new Documentacion_f29("", JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, '');
    this.borrador_f29 = new Borrador_f29("", JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON)
    this.getDeclaracionF29('');
    this.getEstadistica();
    this.trae_periodo();
    this.token = this._userService.getToken();
    this.entidad_sel = this._entidadService.getEntidad()
    this.revision_periodo = new RevisionPeriodo('', '', '', '', '');

  }

  ngOnInit() {
    this.entidadSelSubscription = this._sharingService.sharingEntity.subscribe(
      (entidad) => {
        this.trae_periodo()
      }
    )
  }

  // Si el campo tiene un 0 lo cambia por un 0
  cambiarCero(e) {
    if (e.target.value == 0) {
      e.target.value = 0
    }
  }

  // Valida cada caracter y verifica si es un numero
  restrictNumeric(e) {
    if (e.target.value[0] == 0) {
      e.target.value = ""
    }
    if (e.charCode < 48 || e.charCode > 57) {
      return false;
    }

    return e
  }

  // Valida cada caracter y verifica si es un numero incluyendo numeros negativos
  restrictNumericNegative(e) {
    if (e.target.value[0] == 0) {
      e.target.value = ""
    }
    if (e.charCode == 45) {
      return e
    }
    if (e.charCode < 48 || e.charCode > 57) {
      return false;
    }

    return e
  }

  carga() {
    setTimeout('', 1000);
    this.trae_periodo();
  }

  open(content, modal) {
    let size = ""
    switch (modal) {
      case 'entidad':
        this.getDeclaracionF29('entidad')
        size = "xl"
        this.tipo_borrador = "entidad"
        break
      case 'CORP':
        this.getDeclaracionF29('CORP')
        size = "xl"
        this.tipo_borrador = "corporacion"
        break
      case 'IASD':
        this.getDeclaracionF29('IASD')
        size = "xl"
        this.tipo_borrador = "iasd"
        break
      case 'estadistica':
        this.getEstadistica()
        size = "md"
        break
      case 'bitacora':
        size = 'lg'
        break
      case 'cerrar':
        size = 'lg'
        break
    }

    this.modalService.open(content, { windowClass: "modal-holder", size: size }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  abrir(archivo) {
    document.getElementById(archivo).click()
  }

  trae_periodo() {
    Swal.fire({
      text: "Cargando...",
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })

    this.entidad_sel = this._entidadService.getEntidad()

    this._f29Service.traePeriodo().subscribe(
      response => {
        Swal.close();
        this.registro_periodo = false;
        this.estadisticas.activo = 0

        if (response) {
          this.estadisticas.activo = 0

          if (response.registros == false) {

            this.alerta = true;
            this.periodo_abierto = false;
            this.mensaje_alerta = response.message;
            //this.fecha_vencimiento = this.periodo.fecha_vencimiento;
          } else {
            this.registro_periodo = true;
            this.guardar_disabled = false;
            this.alerta = false;
            this.periodo_abierto = true;
            this.periodo = response;
            this.f29_grupos = response.f29_grupos
            this.entidad_periodo = this.periodo.entidad.codigo;
            this.fecha_vencimiento = this.periodo.fecha_vencimiento;
            this.mes_periodo = this.periodo.periodo;
            this.tipo_archivo = response.documentacion;
            this.etapa_periodo = this.periodo.etapa.nombre;
            this.estado_periodo = this.periodo.estado.nombre;
            this.bitacora = response.bitacora

            if (this.bitacora.length > 0) {
              let y = this.bitacora.length
              this.ultBitacora.fecha = this.bitacora[y - 1].fecha
              this.ultBitacora.etapa = this.bitacora[y - 1].etapa
              this.ultBitacora.estado = this.bitacora[y - 1].estado
              this.ultBitacora.observaciones = this.bitacora[y - 1].observaciones
            } else {
              this.ultBitacora.estado = "RECOPILANDO"
            }
            this.trae_opciones_periodo();

            this.verifica_campos_obligatorios();
            this.verificar_vencimiento();
            this.verifica_fechaEnvio();
            this.revision_periodo = new RevisionPeriodo('', '', '', '', '');

            /*if(this.periodo.revision.estado._id == '5d7a4b8a4938a3d5df0ae2af'){
              this.adjuntar_disabled = false;
              this.guardar_disabled = false;
              this.enviar_disabled = false;
            }*/



            if (this.periodo.revision.estado._id == '5d7a4b8a4938a3d5df0ae2ad') {
              this.btn_cerrar_periodo = true;
              this.guardar_disabled = true
              this.enviar_disabled = true
            }


            this._f29Service.getPermisoDocumentacion(this.ultBitacora.estado).subscribe(
              response => {
                if (response.message) {
                  this.periodo_abierto = false;
                  Swal.fire({
                    title: "Error!",
                    text: response.message,
                    icon: 'warning'
                  });
                }
              }
            )

          }

        } else {
          this.guardar_disabled = false;
          this.tipo_archivo.borradorF29 = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.reporteAPSImpuestoUnicoPDF = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.libroMayorImpuestoUnico = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.libroMayorBoletaDeHonorarios = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.capturaLibroCompraFacturacioncl = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.capturaLibroVentasFacturacioncl = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.depositoOTransferenciaBancariaPDF = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.libroMayorIVACreditoFiscalPDF = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.libroMayorIVADebitoFiscalPDF = { archivo: "", cargando: false, check_file: false, ruta: { path: "" } };
          this.tipo_archivo.otros = { archivo: "", cargando: false, check_file: false, ruta: { path: "" }, descripcion: "" };
          this.borrador_f29 = { debito_venta: { ventas_serv_prestados: { info_ingreso: { linea_1: { descripcion: "", cantidad_doc: { cantidad: 0 }, monto_neto: { cantidad: 0 } }, linea_2: JSON, linea_3: JSON, linea_4: JSON, linea_5: JSON, linea_6: JSON }, genera_debito: JSON }, linea_18: JSON, linea_19: JSON, linea_20: JSON, linea_21: JSON, linea_22: JSON } }
          this.tipo_archivo.descripcion = "";
          this.alerta = true;
          this.enviar_disabled = true;
          this.periodo_abierto = false;
          this.mensaje_alerta = "No hay periodos Abiertos, comuniquese con un Administrador";
          this.verifica_campos_obligatorios();
          this.verificar_vencimiento();

          //this.verifica_fechaEnvio();
        }



      },
      error => {
        Swal.fire({
          title: "Error!",
          text: "Ocurrio un error al consultar registros, intente mas tarde!",
          icon: 'error'
        });
      }

    );

  }

  getDeclaracionF29(tipo) {
    Swal.fire({
      text: "Cargando...",
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })

    if (this.periodo_abierto == true || this.periodo_abierto == null) {
      this._f29Service.getDeclaracionF29(tipo, this.mes_periodo).subscribe(
        response => {
          if (response) {
            Swal.close()
            this.borrador_f29._id = response._id
            this.borrador_f29.periodo = response.periodo
            this.borrador_f29.entidad = response.entidad
            this.borrador_f29.debito_venta = {
              ventas_serv_prestados: {
                info_ingreso: {
                  linea_1: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.cantidad_doc.cantidad }, monto_neto: { cantidad: (response.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.monto_neto.cantidad) } },
                  linea_2: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.cantidad_doc.cantidad }, monto_neto: { cantidad: response.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.monto_neto.cantidad } },
                  linea_3: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.cantidad_doc.cantidad }, monto_neto: { cantidad: response.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.monto_neto.cantidad } },
                  linea_4: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.cantidad_doc.cantidad }, monto_neto: { cantidad: response.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.monto_neto.cantidad } },
                  linea_5: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.cantidad_doc.cantidad }, monto_neto: { cantidad: response.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.monto_neto.cantidad } },
                  linea_6: { monto_neto: { cantidad: response.debito_venta.ventas_serv_prestados.info_ingreso.linea_6.monto_neto.cantidad } }
                },
                genera_debito: {
                  linea_7: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_7.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_7.debitos.cantidad } },
                  linea_8: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_8.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_8.debitos.cantidad } },
                  linea_9: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_9.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_9.debitos.cantidad } },
                  linea_10: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_10.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_10.debitos.cantidad } },
                  linea_11: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_11.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_11.debitos.cantidad } },
                  linea_12: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_12.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_12.debitos.cantidad } },
                  linea_13: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_13.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_13.debitos.cantidad } },
                  linea_14: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_14.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_14.debitos.cantidad } },
                  linea_15: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_15.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_15.debitos.cantidad } },
                  linea_16: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_16.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_16.debitos.cantidad } },
                  linea_17: { cantidad_doc: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_17.cantidad_doc.cantidad }, debitos: { cantidad: response.debito_venta.ventas_serv_prestados.genera_debito.linea_17.debitos.cantidad } },
                }
              },
              linea_18: { debitos: response.debito_venta.linea_18.debitos },
              linea_19: { debitos: response.debito_venta.linea_19.debitos },
              linea_20: { debitos: response.debito_venta.linea_20.debitos },
              linea_21: { debitos: response.debito_venta.linea_21.debitos, m3: { cantidad: response.debito_venta.linea_21.m3.cantidad }, base: { cantidad: response.debito_venta.linea_21.base.cantidad }, variable: { cantidad: response.debito_venta.linea_21.variable.cantidad } },
              linea_22: { debitos: response.debito_venta.linea_22.debitos }
            }
            this.borrador_f29.credito_compra = {
              compras_serv_utilizados: {
                linea_23: { con_derecho_credito: { cantidad: response.credito_compra.compras_serv_utilizados.linea_23.con_derecho_credito.cantidad }, sin_derecho_credito: { cantidad: response.credito_compra.compras_serv_utilizados.linea_23.sin_derecho_credito.cantidad } },
                sin_derecho_credito_fiscal: {
                  linea_24: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.cantidad_doc.cantidad }, monto_neto: { cantidad: response.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.monto_neto.cantidad } },
                  linea_25: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.cantidad_doc.cantidad }, monto_neto: { cantidad: response.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.monto_neto.cantidad } },
                  linea_26: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.cantidad_doc.cantidad }, monto_neto: { cantidad: response.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.monto_neto.cantidad } },
                },
                con_derecho_credito_fiscal: {
                  internas: {
                    linea_27: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.cantidad_doc.cantidad }, credito_recuperacion_reintegro: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.credito_recuperacion_reintegro.cantidad } },
                    linea_28: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.cantidad_doc.cantidad }, credito_recuperacion_reintegro: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.credito_recuperacion_reintegro.cantidad } },
                    linea_29: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.cantidad_doc.cantidad }, credito_recuperacion_reintegro: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.credito_recuperacion_reintegro.cantidad } },
                    linea_30: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.cantidad_doc.cantidad }, credito_recuperacion_reintegro: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.credito_recuperacion_reintegro.cantidad } },
                    linea_31: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.cantidad_doc.cantidad }, credito_recuperacion_reintegro: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.credito_recuperacion_reintegro.cantidad } },
                    linea_32: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.cantidad_doc.cantidad }, credito_recuperacion_reintegro: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.credito_recuperacion_reintegro.cantidad } }
                  },
                  importaciones: {
                    linea_33: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.cantidad_doc.cantidad }, credito_recuperacion_reintegro: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.credito_recuperacion_reintegro.cantidad } },
                    linea_34: { cantidad_doc: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.cantidad_doc.cantidad }, credito_recuperacion_reintegro: { cantidad: response.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.credito_recuperacion_reintegro.cantidad } }
                  }
                }
              },
              linea_35: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_35.credito_recuperacion_reintegro.cantidad } },
              linea_36: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_36.credito_recuperacion_reintegro.cantidad } },
              linea_37: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_37.credito_recuperacion_reintegro.cantidad } },
              linea_38: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_38.credito_recuperacion_reintegro.cantidad } },
              linea_39: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_39.credito_recuperacion_reintegro.cantidad } },
              linea_40: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_40.credito_recuperacion_reintegro.cantidad } },
              linea_41: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_41.credito_recuperacion_reintegro.cantidad } },
              ley_20765: {
                linea_42: {
                  credito_recuperacion_reintegro: { cantidad: response.credito_compra.ley_20765.linea_42.credito_recuperacion_reintegro.cantidad },
                  m3_comprados_con_derecho_credito: { cantidad: response.credito_compra.ley_20765.linea_42.m3_comprados_con_derecho_credito.cantidad },
                  componente_impuesto: {
                    base: { cantidad: response.credito_compra.ley_20765.linea_42.componente_impuesto.base.cantidad },
                    variable: { cantidad: response.credito_compra.ley_20765.linea_42.componente_impuesto.variable.cantidad }
                  }
                },

                linea_43: {
                  credito_recuperacion_reintegro: { cantidad: response.credito_compra.ley_20765.linea_43.credito_recuperacion_reintegro.cantidad },
                  m3_comprados_con_derecho_credito: { cantidad: response.credito_compra.ley_20765.linea_43.m3_comprados_con_derecho_credito.cantidad },
                  componente_impuesto: {
                    base: { cantidad: response.credito_compra.ley_20765.linea_43.componente_impuesto.base.cantidad },
                    variable: { cantidad: response.credito_compra.ley_20765.linea_43.componente_impuesto.variable.cantidad }
                  }
                },
              },
              linea_44: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_44.credito_recuperacion_reintegro.cantidad } },
              linea_45: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_45.credito_recuperacion_reintegro.cantidad } },
              linea_46: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_46.credito_recuperacion_reintegro.cantidad } },
              linea_47: { credito_recuperacion_reintegro: { cantidad: response.credito_compra.linea_47.credito_recuperacion_reintegro.cantidad } }
            }
            this.borrador_f29.linea_48 = { cantidad: response.linea_48.cantidad, impuesto_determinado: { cantidad: response.linea_48.impuesto_determinado.cantidad } }
            this.borrador_f29.linea_49 = { impuesto_determinado: { cantidad: response.linea_49.impuesto_determinado.cantidad } }
            this.borrador_f29.linea_50 = { impuesto_determinado: { cantidad: response.linea_50.impuesto_determinado.cantidad } }
            this.borrador_f29.impuesto_renta = {
              retenciones: {
                linea_51: { impuesto_determinado: { cantidad: response.impuesto_renta.retenciones.linea_51.impuesto_determinado.cantidad } },
                linea_52: {
                  donacion_articulo_8_ley_18985: { cantidad: response.impuesto_renta.retenciones.linea_52.donacion_articulo_8_ley_18985.cantidad },
                  impuesto_determinado: { cantidad: response.impuesto_renta.retenciones.linea_52.impuesto_determinado.cantidad },
                  donacion_ley_20444_2010: { cantidad: response.impuesto_renta.retenciones.linea_52.donacion_ley_20444_2010.cantidad }
                },
                linea_53: { impuesto_determinado: { cantidad: response.impuesto_renta.retenciones.linea_53.impuesto_determinado.cantidad } },
                linea_54: { impuesto_determinado: { cantidad: response.impuesto_renta.retenciones.linea_54.impuesto_determinado.cantidad } },
                linea_55: { impuesto_determinado: { cantidad: response.impuesto_renta.retenciones.linea_55.impuesto_determinado.cantidad } },
                linea_56: { impuesto_determinado: { cantidad: response.impuesto_renta.retenciones.linea_56.impuesto_determinado.cantidad } },
                linea_57: { impuesto_determinado: { cantidad: response.impuesto_renta.retenciones.linea_57.impuesto_determinado.cantidad } },
                linea_58: { impuesto_determinado: { cantidad: response.impuesto_renta.retenciones.linea_58.impuesto_determinado.cantidad } },
                linea_63: { impuesto_determinado: { cantidad: response.impuesto_renta.retenciones.linea_63 ? response.impuesto_renta.retenciones.linea_63.impuesto_determinado.cantidad : 0 } },
                linea_64: { impuesto_determinado: { cantidad: response.impuesto_renta.retenciones.linea_64 ? response.impuesto_renta.retenciones.linea_64.impuesto_determinado.cantidad : 0 } },
              },
              ppm: {
                linea_59: {
                  acogido_suspension_ppm: response.impuesto_renta.ppm.linea_59.acogido_suspension_ppm,
                  monto_perdida_art90: { cantidad: response.impuesto_renta.ppm.linea_59.monto_perdida_art90.cantidad },
                  base_imponible: { cantidad: response.impuesto_renta.ppm.linea_59.base_imponible.cantidad },
                  tasa: { cantidad: response.impuesto_renta.ppm.linea_59.tasa.cantidad },
                  credito_tope_suspension_ppm: { cantidad: response.impuesto_renta.ppm.linea_59.credito_tope_suspension_ppm.cantidad },
                  ppm_neto_determinado: { cantidad: response.impuesto_renta.ppm.linea_59.ppm_neto_determinado.cantidad }
                },
                linea_60: {
                  monto_perdida_art90: { cantidad: response.impuesto_renta.ppm.linea_60.monto_perdida_art90.cantidad },
                  base_imponible: { cantidad: response.impuesto_renta.ppm.linea_60.base_imponible.cantidad },
                  tasa: { cantidad: response.impuesto_renta.ppm.linea_60.tasa.cantidad },
                  credito_tope_suspension_ppm: { cantidad: response.impuesto_renta.ppm.linea_60.credito_tope_suspension_ppm.cantidad },
                  ppm_neto_determinado: { cantidad: response.impuesto_renta.ppm.linea_60.ppm_neto_determinado.cantidad }
                },
                linea_61: {
                  monto_perdida_art90: { cantidad: response.impuesto_renta.ppm.linea_61.monto_perdida_art90.cantidad },
                  base_imponible: { cantidad: response.impuesto_renta.ppm.linea_61.base_imponible.cantidad },
                  tasa: { cantidad: response.impuesto_renta.ppm.linea_61.tasa.cantidad },
                  credito_tope_suspension_ppm: { cantidad: response.impuesto_renta.ppm.linea_61.credito_tope_suspension_ppm.cantidad },
                  ppm_neto_determinado: { cantidad: response.impuesto_renta.ppm.linea_61.ppm_neto_determinado.cantidad }
                },
                linea_62: { ppm_neto_determinado: { cantidad: response.impuesto_renta.ppm.linea_62.ppm_neto_determinado.cantidad } },
                linea_63: {
                  credito_mes: { cantidad: response.impuesto_renta.ppm.linea_63.credito_mes.cantidad },
                  remanente_mes_anterior: { cantidad: response.impuesto_renta.ppm.linea_63.remanente_mes_anterior.cantidad },
                  remanente_periodo_siguiente: { cantidad: response.impuesto_renta.ppm.linea_63.remanente_periodo_siguiente.cantidad },
                  ppm_neto_determinado: { cantidad: response.impuesto_renta.ppm.linea_63.ppm_neto_determinado.cantidad }
                },
                linea_64: { ppm_neto_determinado: { cantidad: response.impuesto_renta.ppm.linea_64.ppm_neto_determinado.cantidad } },
                linea_65: { ppm_neto_determinado: { cantidad: response.impuesto_renta.ppm.linea_65.ppm_neto_determinado.cantidad } }
              }
            }
            this.borrador_f29.linea_66 = { ppm_neto_determinado: { cantidad: response.linea_66.ppm_neto_determinado.cantidad } }
            this.borrador_f29.tributacion_simplificada = {
              linea_67: { cantidad: response.tributacion_simplificada.linea_67.cantidad },
              linea_68: { cantidad: response.tributacion_simplificada.linea_68.cantidad },
              linea_69: { impuesto_determinado: { cantidad: response.tributacion_simplificada.linea_69.impuesto_determinado.cantidad } },
            }
            this.borrador_f29.impuesto_adicional = {
              linea_70: { impuesto_determinado: { cantidad: response.impuesto_adicional.linea_70.impuesto_determinado.cantidad } },
              linea_71: { impuesto_determinado: { cantidad: response.impuesto_adicional.linea_71.impuesto_determinado.cantidad } },
              linea_72: { cantidad: response.impuesto_adicional.linea_72.cantidad },
              linea_73: { cantidad: response.impuesto_adicional.linea_73.cantidad },
              linea_74: { cantidad: response.impuesto_adicional.linea_74.cantidad },
              linea_75: { cantidad: response.impuesto_adicional.linea_75.cantidad },
              linea_76: { cantidad: response.impuesto_adicional.linea_76.cantidad },
              linea_77: { cantidad: response.impuesto_adicional.linea_77.cantidad, impuesto_determinado: { cantidad: response.impuesto_adicional.linea_77.impuesto_determinado.cantidad } },
            }
            this.borrador_f29.cambio_sujeto = {
              parte_1: {
                linea_99: { cantidad: response.cambio_sujeto.parte_1.linea_99.cantidad },
                linea_100: { cantidad: response.cambio_sujeto.parte_1.linea_100.cantidad },
                linea_101: { cantidad: response.cambio_sujeto.parte_1.linea_101.cantidad },
                linea_102: { cantidad: response.cambio_sujeto.parte_1.linea_102.cantidad },
              },
              linea_103: { cantidad: response.cambio_sujeto.linea_103.cantidad, impuesto_determinado: { cantidad: response.cambio_sujeto.linea_103.impuesto_determinado.cantidad } },
              parte_2: {
                linea_104: { cantidad: response.cambio_sujeto.parte_2.linea_104.cantidad },
                linea_105: { cantidad: response.cambio_sujeto.parte_2.linea_105.cantidad },
                linea_106: { cantidad: response.cambio_sujeto.parte_2.linea_106.cantidad },
                linea_107: { cantidad: response.cambio_sujeto.parte_2.linea_107.cantidad },
                linea_108: { cantidad: response.cambio_sujeto.parte_2.linea_108.cantidad, impuesto_determinado: { cantidad: response.cambio_sujeto.parte_2.linea_108.impuesto_determinado.cantidad } },
              }
            }
            this.borrador_f29.creditos_especiales = {
              linea_109: { cantidad: response.creditos_especiales.linea_109.cantidad, remanente_mes_anterior: { cantidad: response.creditos_especiales.linea_109.remanente_mes_anterior.cantidad }, impuesto_determinado: { cantidad: response.creditos_especiales.linea_109.impuesto_determinado.cantidad } },
              linea_110: { cantidad: response.creditos_especiales.linea_110.cantidad, remanente_mes_anterior: { cantidad: response.creditos_especiales.linea_110.remanente_mes_anterior.cantidad }, impuesto_determinado: { cantidad: response.creditos_especiales.linea_110.impuesto_determinado.cantidad } },
              linea_111: { cantidad: response.creditos_especiales.linea_111.cantidad, remanente_mes_anterior: { cantidad: response.creditos_especiales.linea_111.remanente_mes_anterior.cantidad }, impuesto_determinado: { cantidad: response.creditos_especiales.linea_111.impuesto_determinado.cantidad } },
              linea_112: { cantidad: response.creditos_especiales.linea_112.cantidad, remanente_mes_anterior: { cantidad: response.creditos_especiales.linea_112.remanente_mes_anterior.cantidad }, impuesto_determinado: { cantidad: response.creditos_especiales.linea_112.impuesto_determinado.cantidad } },
              linea_113: { cantidad: response.creditos_especiales.linea_113.cantidad, remanente_mes_anterior: { cantidad: response.creditos_especiales.linea_113.remanente_mes_anterior.cantidad }, impuesto_determinado: { cantidad: response.creditos_especiales.linea_113.impuesto_determinado.cantidad } },
              linea_114: { cantidad: response.creditos_especiales.linea_114.cantidad, remanente_mes_anterior: { cantidad: response.creditos_especiales.linea_114.remanente_mes_anterior.cantidad }, impuesto_determinado: { cantidad: response.creditos_especiales.linea_114.impuesto_determinado.cantidad } }
            }
            this.borrador_f29.linea_115 = { impuesto_determinado: { cantidad: response.linea_115.impuesto_determinado.cantidad } }
            this.borrador_f29.remanente_creditos_especiales = {
              linea_116: { cantidad: response.remanente_creditos_especiales.linea_116.cantidad },
              linea_117: { cantidad: response.remanente_creditos_especiales.linea_117.cantidad },
              linea_118: { cantidad: response.remanente_creditos_especiales.linea_118.cantidad },
              linea_119: { cantidad: response.remanente_creditos_especiales.linea_119.cantidad },
              linea_120: { cantidad: response.remanente_creditos_especiales.linea_120.cantidad },
              linea_121: { cantidad: response.remanente_creditos_especiales.linea_121.cantidad }
            }
            this.borrador_f29.linea_122 = { cantidad: response.linea_122.cantidad }
            this.borrador_f29.linea_123 = { cantidad: response.linea_123.cantidad }
            this.borrador_f29.linea_124 = { cantidad: response.linea_124.cantidad }
            this.borrador_f29.linea_125 = { cantidad: response.linea_125.cantidad }
          }

          if (!response || response.error) {
            this.borrador_f29.debito_venta = {
              ventas_serv_prestados: {
                info_ingreso: {
                  linea_1: { cantidad_doc: { cantidad: 0 }, monto_neto: { cantidad: 0 } },
                  linea_2: { cantidad_doc: { cantidad: 0 }, monto_neto: { cantidad: 0 } },
                  linea_3: { cantidad_doc: { cantidad: 0 }, monto_neto: { cantidad: 0 } },
                  linea_4: { cantidad_doc: { cantidad: 0 }, monto_neto: { cantidad: 0 } },
                  linea_5: { cantidad_doc: { cantidad: 0 }, monto_neto: { cantidad: 0 } },
                  linea_6: { monto_neto: { cantidad: 0 } }
                },
                genera_debito: {
                  linea_7: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } },
                  linea_8: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } },
                  linea_9: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } },
                  linea_10: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } },
                  linea_11: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } },
                  linea_12: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } },
                  linea_13: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } },
                  linea_14: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } },
                  linea_15: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } },
                  linea_16: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } },
                  linea_17: { cantidad_doc: { cantidad: 0 }, debitos: { cantidad: 0 } }
                }
              },
              linea_18: { debitos: 0 },
              linea_19: { debitos: 0 },
              linea_20: { debitos: 0 },
              linea_21: { debitos: 0, m3: { cantidad: 0 }, base: { cantidad: 0 }, variable: { cantidad: 0 } },
              linea_22: { debitos: 0 }
            }
            this.borrador_f29.credito_compra = {
              compras_serv_utilizados: {
                linea_23: { con_derecho_credito: { cantidad: 0 }, sin_derecho_credito: { cantidad: 0 } },
                sin_derecho_credito_fiscal: {
                  linea_24: { cantidad_doc: { cantidad: 0 }, monto_neto: { cantidad: 0 } },
                  linea_25: { cantidad_doc: { cantidad: 0 }, monto_neto: { cantidad: 0 } },
                  linea_26: { cantidad_doc: { cantidad: 0 }, monto_neto: { cantidad: 0 } },
                },
                con_derecho_credito_fiscal: {
                  internas: {
                    linea_27: { cantidad_doc: { cantidad: 0 }, credito_recuperacion_reintegro: { cantidad: 0 } },
                    linea_28: { cantidad_doc: { cantidad: 0 }, credito_recuperacion_reintegro: { cantidad: 0 } },
                    linea_29: { cantidad_doc: { cantidad: 0 }, credito_recuperacion_reintegro: { cantidad: 0 } },
                    linea_30: { cantidad_doc: { cantidad: 0 }, credito_recuperacion_reintegro: { cantidad: 0 } },
                    linea_31: { cantidad_doc: { cantidad: 0 }, credito_recuperacion_reintegro: { cantidad: 0 } },
                    linea_32: { cantidad_doc: { cantidad: 0 }, credito_recuperacion_reintegro: { cantidad: 0 } }
                  },
                  importaciones: {
                    linea_33: { cantidad_doc: { cantidad: 0 }, credito_recuperacion_reintegro: { cantidad: 0 } },
                    linea_34: { cantidad_doc: { cantidad: 0 }, credito_recuperacion_reintegro: { cantidad: 0 } }
                  }
                }
              },
              linea_35: { credito_recuperacion_reintegro: { cantidad: 0 } },
              linea_36: { credito_recuperacion_reintegro: { cantidad: 0 } },
              linea_37: { credito_recuperacion_reintegro: { cantidad: 0 } },
              linea_38: { credito_recuperacion_reintegro: { cantidad: 0 } },
              linea_39: { credito_recuperacion_reintegro: { cantidad: 0 } },
              linea_40: { credito_recuperacion_reintegro: { cantidad: 0 } },
              linea_41: { credito_recuperacion_reintegro: { cantidad: 0 } },
              ley_20765: {
                linea_42: {
                  credito_recuperacion_reintegro: { cantidad: 0 },
                  m3_comprados_con_derecho_credito: { cantidad: 0 },
                  componente_impuesto: {
                    base: { cantidad: 0 },
                    variable: { cantidad: 0 }
                  }
                },

                linea_43: {
                  credito_recuperacion_reintegro: { cantidad: 0 },
                  m3_comprados_con_derecho_credito: { cantidad: 0 },
                  componente_impuesto: {
                    base: { cantidad: 0 },
                    variable: { cantidad: 0 }
                  }
                },
              },
              linea_44: { credito_recuperacion_reintegro: { cantidad: 0 } },
              linea_45: { credito_recuperacion_reintegro: { cantidad: 0 } },
              linea_46: { credito_recuperacion_reintegro: { cantidad: 0 } },
              linea_47: { credito_recuperacion_reintegro: { cantidad: 0 } }
            }
            this.borrador_f29.linea_48 = { cantidad: 0, impuesto_determinado: { cantidad: 0 } }
            this.borrador_f29.linea_49 = { impuesto_determinado: { cantidad: 0 } }
            this.borrador_f29.linea_50 = { impuesto_determinado: { cantidad: 0 } }
            this.borrador_f29.impuesto_renta = {
              retenciones: {
                linea_51: { impuesto_determinado: { cantidad: 0 } },
                linea_52: {
                  donacion_articulo_8_ley_18985: { cantidad: 0 },
                  impuesto_determinado: { cantidad: 0 },
                  donacion_ley_20444_2010: { cantidad: 0 }
                },
                linea_53: { impuesto_determinado: { cantidad: 0 } },
                linea_54: { impuesto_determinado: { cantidad: 0 } },
                linea_55: { impuesto_determinado: { cantidad: 0 } },
                linea_56: { impuesto_determinado: { cantidad: 0 } },
                linea_57: { impuesto_determinado: { cantidad: 0 } },
                linea_58: { impuesto_determinado: { cantidad: 0 } },
                linea_63: { impuesto_determinado: { cantidad: 0 } },
                linea_64: { impuesto_determinado: { cantidad: 0 } },
              },
              ppm: {
                linea_59: {
                  acogido_suspension_ppm: 0,
                  monto_perdida_art90: { cantidad: 0 },
                  base_imponible: { cantidad: 0 },
                  tasa: { cantidad: 0 },
                  credito_tope_suspension_ppm: { cantidad: 0 },
                  ppm_neto_determinado: { cantidad: 0 }
                },
                linea_60: {
                  monto_perdida_art90: { cantidad: 0 },
                  base_imponible: { cantidad: 0 },
                  tasa: { cantidad: 0 },
                  credito_tope_suspension_ppm: { cantidad: 0 },
                  ppm_neto_determinado: { cantidad: 0 }
                },
                linea_61: {
                  monto_perdida_art90: { cantidad: 0 },
                  base_imponible: { cantidad: 0 },
                  tasa: { cantidad: 0 },
                  credito_tope_suspension_ppm: { cantidad: 0 },
                  ppm_neto_determinado: { cantidad: 0 }
                },
                linea_62: { ppm_neto_determinado: { cantidad: 0 } },
                linea_63: {
                  credito_mes: { cantidad: 0 },
                  remanente_mes_anterior: { cantidad: 0 },
                  remanente_periodo_siguiente: { cantidad: 0 },
                  ppm_neto_determinado: { cantidad: 0 }
                },
                linea_64: { ppm_neto_determinado: { cantidad: 0 } },
                linea_65: { ppm_neto_determinado: { cantidad: 0 } }
              }
            }
            this.borrador_f29.linea_66 = { ppm_neto_determinado: { cantidad: 0 } }
            this.borrador_f29.tributacion_simplificada = {
              linea_67: { cantidad: 0 },
              linea_68: { cantidad: 0 },
              linea_69: { impuesto_determinado: { cantidad: 0 } },
            }
            this.borrador_f29.impuesto_adicional = {
              linea_70: { impuesto_determinado: { cantidad: 0 } },
              linea_71: { impuesto_determinado: { cantidad: 0 } },
              linea_72: { cantidad: 0 },
              linea_73: { cantidad: 0 },
              linea_74: { cantidad: 0 },
              linea_75: { cantidad: 0 },
              linea_76: { cantidad: 0 },
              linea_77: { cantidad: 0, impuesto_determinado: { cantidad: 0 } },
            }
            this.borrador_f29.cambio_sujeto = {
              parte_1: {
                linea_99: { cantidad: 0 },
                linea_100: { cantidad: 0 },
                linea_101: { cantidad: 0 },
                linea_102: { cantidad: 0 },
              },
              linea_103: { cantidad: 0, impuesto_determinado: { cantidad: 0 } },
              parte_2: {
                linea_104: { cantidad: 0 },
                linea_105: { cantidad: 0 },
                linea_106: { cantidad: 0 },
                linea_107: { cantidad: 0 },
                linea_108: { cantidad: 0, impuesto_determinado: { cantidad: 0 } },
              }
            }
            this.borrador_f29.creditos_especiales = {
              linea_109: { cantidad: 0, remanente_mes_anterior: { cantidad: 0 }, impuesto_determinado: { cantidad: 0 } },
              linea_110: { cantidad: 0, remanente_mes_anterior: { cantidad: 0 }, impuesto_determinado: { cantidad: 0 } },
              linea_111: { cantidad: 0, remanente_mes_anterior: { cantidad: 0 }, impuesto_determinado: { cantidad: 0 } },
              linea_112: { cantidad: 0, remanente_mes_anterior: { cantidad: 0 }, impuesto_determinado: { cantidad: 0 } },
              linea_113: { cantidad: 0, remanente_mes_anterior: { cantidad: 0 }, impuesto_determinado: { cantidad: 0 } },
              linea_114: { cantidad: 0, remanente_mes_anterior: { cantidad: 0 }, impuesto_determinado: { cantidad: 0 } }
            }
            this.borrador_f29.linea_115 = { impuesto_determinado: { cantidad: 0 } }
            this.borrador_f29.remanente_creditos_especiales = {
              linea_116: { cantidad: 0 },
              linea_117: { cantidad: 0 },
              linea_118: { cantidad: 0 },
              linea_119: { cantidad: 0 },
              linea_120: { cantidad: 0 },
              linea_121: { cantidad: 0 }
            }
            this.borrador_f29.linea_122 = { cantidad: 0 }
            this.borrador_f29.linea_123 = { cantidad: 0 }
            this.borrador_f29.linea_124 = { cantidad: 0 }
            this.borrador_f29.linea_125 = { cantidad: 0 }
          }
        }
      )
    }
  }

  // Hace los calculos momentaneos antes de mandarlos al back
  guardarDeclaracionF29() {

    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.monto_neto.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.monto_neto.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.monto_neto.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.monto_neto.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.monto_neto.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.monto_neto.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.monto_neto.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.monto_neto.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.monto_neto.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.monto_neto.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_6.monto_neto.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_6.monto_neto.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_7.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_7.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_7.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_7.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_8.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_8.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_8.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_8.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_9.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_9.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_9.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_9.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_10.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_10.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_10.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_10.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_11.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_11.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_11.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_11.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_12.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_12.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_12.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_12.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_13.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_13.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_13.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_13.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_14.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_14.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_14.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_14.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_15.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_15.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_15.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_15.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_16.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_16.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_16.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_16.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_17.cantidad_doc.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_17.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_17.debitos.cantidad = parseInt((this.borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_17.debitos.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.debito_venta.linea_18.debitos = parseInt((this.borrador_f29.debito_venta.linea_18.debitos).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.linea_19.debitos = parseInt((this.borrador_f29.debito_venta.linea_19.debitos).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.linea_20.debitos = parseInt((this.borrador_f29.debito_venta.linea_20.debitos).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.linea_21.debitos = parseInt((this.borrador_f29.debito_venta.linea_21.debitos).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.linea_21.m3.cantidad = parseInt((this.borrador_f29.debito_venta.linea_21.m3.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.linea_21.base.cantidad = parseInt((this.borrador_f29.debito_venta.linea_21.base.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.linea_21.variable.cantidad = parseInt((this.borrador_f29.debito_venta.linea_21.variable.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.debito_venta.linea_22.debitos = parseInt((this.borrador_f29.debito_venta.linea_22.debitos).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.credito_compra.compras_serv_utilizados.linea_23.con_derecho_credito.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.linea_23.con_derecho_credito.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.linea_23.sin_derecho_credito.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.linea_23.sin_derecho_credito.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.monto_neto.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.monto_neto.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.monto_neto.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.monto_neto.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.monto_neto.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.monto_neto.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.cantidad_doc.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.cantidad_doc.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.credito_compra.linea_35.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_35.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.linea_36.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_36.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.linea_37.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_37.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.linea_38.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_38.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.linea_39.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_39.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.linea_40.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_40.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.linea_41.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_41.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.credito_compra.ley_20765.linea_42.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.ley_20765.linea_42.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.ley_20765.linea_42.m3_comprados_con_derecho_credito.cantidad = parseInt((this.borrador_f29.credito_compra.ley_20765.linea_42.m3_comprados_con_derecho_credito.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.ley_20765.linea_42.componente_impuesto.base.cantidad = parseInt((this.borrador_f29.credito_compra.ley_20765.linea_42.componente_impuesto.base.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.ley_20765.linea_42.componente_impuesto.variable.cantidad = parseInt((this.borrador_f29.credito_compra.ley_20765.linea_42.componente_impuesto.variable.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.ley_20765.linea_43.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.ley_20765.linea_43.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.ley_20765.linea_43.m3_comprados_con_derecho_credito.cantidad = parseInt((this.borrador_f29.credito_compra.ley_20765.linea_43.m3_comprados_con_derecho_credito.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.ley_20765.linea_43.componente_impuesto.base.cantidad = parseInt((this.borrador_f29.credito_compra.ley_20765.linea_43.componente_impuesto.base.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.ley_20765.linea_43.componente_impuesto.variable.cantidad = parseInt((this.borrador_f29.credito_compra.ley_20765.linea_43.componente_impuesto.variable.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.credito_compra.linea_44.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_44.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.linea_45.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_45.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.linea_46.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_46.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.credito_compra.linea_47.credito_recuperacion_reintegro.cantidad = parseInt((this.borrador_f29.credito_compra.linea_47.credito_recuperacion_reintegro.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.linea_48.cantidad = parseInt((this.borrador_f29.linea_48.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.linea_48.impuesto_determinado.cantidad = parseInt((this.borrador_f29.linea_48.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.linea_49.impuesto_determinado.cantidad = parseInt((this.borrador_f29.linea_49.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.linea_50.impuesto_determinado.cantidad = parseInt((this.borrador_f29.linea_50.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.impuesto_renta.retenciones.linea_51.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_51.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_52.donacion_articulo_8_ley_18985.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_52.donacion_articulo_8_ley_18985.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_52.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_52.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_52.donacion_ley_20444_2010.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_52.donacion_ley_20444_2010.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_53.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_53.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_54.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_54.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_55.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_55.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_56.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_56.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_57.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_57.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_58.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_58.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_63.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_63.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.retenciones.linea_64.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.retenciones.linea_64.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.impuesto_renta.ppm.linea_59.acogido_suspension_ppm = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_59.acogido_suspension_ppm).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_59.monto_perdida_art90.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_59.monto_perdida_art90.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_59.base_imponible.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_59.base_imponible.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_59.tasa.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_59.tasa.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_59.credito_tope_suspension_ppm.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_59.credito_tope_suspension_ppm.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_59.ppm_neto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_59.ppm_neto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_60.monto_perdida_art90.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_60.monto_perdida_art90.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_60.base_imponible.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_60.base_imponible.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_60.tasa.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_60.tasa.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_60.credito_tope_suspension_ppm.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_60.credito_tope_suspension_ppm.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_60.ppm_neto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_60.ppm_neto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_61.monto_perdida_art90.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_61.monto_perdida_art90.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_61.base_imponible.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_61.base_imponible.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_61.tasa.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_61.tasa.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_61.credito_tope_suspension_ppm.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_61.credito_tope_suspension_ppm.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_61.ppm_neto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_61.ppm_neto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_62.ppm_neto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_62.ppm_neto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_63.credito_mes.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_63.credito_mes.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_63.remanente_mes_anterior.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_63.remanente_mes_anterior.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_63.remanente_periodo_siguiente.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_63.remanente_periodo_siguiente.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_63.ppm_neto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_63.ppm_neto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_64.ppm_neto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_64.ppm_neto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_renta.ppm.linea_65.ppm_neto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_renta.ppm.linea_65.ppm_neto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.linea_66.ppm_neto_determinado.cantidad = parseInt((this.borrador_f29.linea_66.ppm_neto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.tributacion_simplificada.linea_67.cantidad = parseInt((this.borrador_f29.tributacion_simplificada.linea_67.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.tributacion_simplificada.linea_68.cantidad = parseInt((this.borrador_f29.tributacion_simplificada.linea_68.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.tributacion_simplificada.linea_69.impuesto_determinado.cantidad = parseInt((this.borrador_f29.tributacion_simplificada.linea_69.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.impuesto_adicional.linea_70.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_adicional.linea_70.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_adicional.linea_71.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_adicional.linea_71.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_adicional.linea_72.cantidad = parseInt((this.borrador_f29.impuesto_adicional.linea_72.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_adicional.linea_73.cantidad = parseInt((this.borrador_f29.impuesto_adicional.linea_73.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_adicional.linea_74.cantidad = parseInt((this.borrador_f29.impuesto_adicional.linea_74.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_adicional.linea_75.cantidad = parseInt((this.borrador_f29.impuesto_adicional.linea_75.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_adicional.linea_76.cantidad = parseInt((this.borrador_f29.impuesto_adicional.linea_76.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_adicional.linea_77.cantidad = parseInt((this.borrador_f29.impuesto_adicional.linea_77.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.impuesto_adicional.linea_77.impuesto_determinado.cantidad = parseInt((this.borrador_f29.impuesto_adicional.linea_77.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.cambio_sujeto.parte_1.linea_99.cantidad = parseInt((this.borrador_f29.cambio_sujeto.parte_1.linea_99.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.parte_1.linea_100.cantidad = parseInt((this.borrador_f29.cambio_sujeto.parte_1.linea_100.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.parte_1.linea_101.cantidad = parseInt((this.borrador_f29.cambio_sujeto.parte_1.linea_101.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.parte_1.linea_102.cantidad = parseInt((this.borrador_f29.cambio_sujeto.parte_1.linea_102.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.linea_103.cantidad = parseInt((this.borrador_f29.cambio_sujeto.linea_103.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.linea_103.impuesto_determinado.cantidad = parseInt((this.borrador_f29.cambio_sujeto.linea_103.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.parte_2.linea_104.cantidad = parseInt((this.borrador_f29.cambio_sujeto.parte_2.linea_104.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.parte_2.linea_105.cantidad = parseInt((this.borrador_f29.cambio_sujeto.parte_2.linea_105.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.parte_2.linea_106.cantidad = parseInt((this.borrador_f29.cambio_sujeto.parte_2.linea_106.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.parte_2.linea_107.cantidad = parseInt((this.borrador_f29.cambio_sujeto.parte_2.linea_107.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.parte_2.linea_108.cantidad = parseInt((this.borrador_f29.cambio_sujeto.parte_2.linea_108.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.cambio_sujeto.parte_2.linea_108.impuesto_determinado.cantidad = parseInt((this.borrador_f29.cambio_sujeto.parte_2.linea_108.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.creditos_especiales.linea_109.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_109.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_109.remanente_mes_anterior.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_109.remanente_mes_anterior.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_109.impuesto_determinado.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_109.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_110.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_110.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_110.remanente_mes_anterior.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_110.remanente_mes_anterior.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_110.impuesto_determinado.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_110.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_111.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_111.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_111.remanente_mes_anterior.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_111.remanente_mes_anterior.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_111.impuesto_determinado.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_111.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_112.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_112.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_112.remanente_mes_anterior.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_112.remanente_mes_anterior.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_112.impuesto_determinado.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_112.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_113.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_113.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_113.remanente_mes_anterior.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_113.remanente_mes_anterior.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_113.impuesto_determinado.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_113.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_114.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_114.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_114.remanente_mes_anterior.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_114.remanente_mes_anterior.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.creditos_especiales.linea_114.impuesto_determinado.cantidad = parseInt((this.borrador_f29.creditos_especiales.linea_114.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.linea_115.impuesto_determinado.cantidad = parseInt((this.borrador_f29.linea_115.impuesto_determinado.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.remanente_creditos_especiales.linea_116.cantidad = parseInt((this.borrador_f29.remanente_creditos_especiales.linea_116.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.remanente_creditos_especiales.linea_117.cantidad = parseInt((this.borrador_f29.remanente_creditos_especiales.linea_117.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.remanente_creditos_especiales.linea_118.cantidad = parseInt((this.borrador_f29.remanente_creditos_especiales.linea_118.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.remanente_creditos_especiales.linea_119.cantidad = parseInt((this.borrador_f29.remanente_creditos_especiales.linea_119.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.remanente_creditos_especiales.linea_120.cantidad = parseInt((this.borrador_f29.remanente_creditos_especiales.linea_120.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.remanente_creditos_especiales.linea_121.cantidad = parseInt((this.borrador_f29.remanente_creditos_especiales.linea_121.cantidad).toString().replace(".", "").replace(".", "")) || 0

    this.borrador_f29.linea_122.cantidad = parseInt((this.borrador_f29.linea_122.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.linea_123.cantidad = parseInt((this.borrador_f29.linea_123.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.linea_124.cantidad = parseInt((this.borrador_f29.linea_124.cantidad).toString().replace(".", "").replace(".", "")) || 0
    this.borrador_f29.linea_125.cantidad = parseInt((this.borrador_f29.linea_125.cantidad).toString().replace(".", "").replace(".", "")) || 0


    if (this.estado_periodo != 'Enviado' && this.estado_periodo != 'Aprobado' && this.alerta == false) {
      this._f29Service.guardarDeclaracionF29(this.borrador_f29).subscribe(
        response => {
          this.borrador_f29 = response
        }
      )
    }
  }

  guardar(tipo, archivo_subido) {

    Swal.fire({
      text: "Cargando...",
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })

    if (this.tipo_archivo.observaciones == "") {
      this.tipo_archivo.observaciones = "Sin Observaciones"
    }

    switch (archivo_subido) {
      case "borradorF29":
        this.tipo_archivo.observaciones = "Archivo Borrador F29 guardado"
        break;
      case "reporteAPSBoletaDeHonorariosPDF":
        this.tipo_archivo.observaciones = "Reporte APS Boleta de Honorarios (PDF) guardado"
        break;
      case "archivoPlanoAPSBoletadeHonorariosTXT":
        this.tipo_archivo.observaciones = "Archivo Plano APS Boleta de Honorarios (TXT) guardado"
        break;
      case "reporteAPSImpuestoUnicoPDF":
        this.tipo_archivo.observaciones = "Reporte APS Impuesto Único (PDF) guardado"
        break;
      case "archivoPlanoAPSImpuestoUnicoTXT":
        this.tipo_archivo.observaciones = "Archivo Plano APS Impuesto Único (TXT) guardado"
        break;
      case "libroMayorImpuestoUnico":
        this.tipo_archivo.observaciones = "Libro Mayor Impuesto Único guardado"
        break;
      case "libroMayorBoletaDeHonorarios":
        this.tipo_archivo.observaciones = "Libro Mayor Boleta de Honorarios guardado"
        break;
      case "capturaLibroCompraFacturacioncl":
        this.tipo_archivo.observaciones = "Captura Libro Compra facturacion.cl guardado"
        break;
      case "capturaLibroVentasFacturacioncl":
        this.tipo_archivo.observaciones = "Captura Libro Ventas facturacion.cl guardado"
        break;
      case "depositoOTransferenciaBancariaPDF":
        this.tipo_archivo.observaciones = "Deposito o transferencia bancaria (PDF) guardado"
        break;
      case "libroMayorIVACreditoFiscalPDF":
        this.tipo_archivo.observaciones = "Libro Mayor IVA Credito Fiscal (PDF) guardado"
        break;
      case "libroMayorIVADebitoFiscalPDF":
        this.tipo_archivo.observaciones = "Libro Mayor IVA Debito Fiscal (PDF) guardado"
        break;
      case "otros":
        this.tipo_archivo.observaciones = "Otros guardado"
        break;
    }

    let msgBitacora = {
      fecha: new Date(),
      usuario: "",
      etapa: "",
      estado: "",
      observaciones: this.tipo_archivo.observaciones
    }
    this.bitacora.push(msgBitacora)
    this.tipo_archivo.observaciones = ""

    this._f29Service.guardarDocumentacion(this.tipo_archivo, this.periodo, this.bitacora, tipo).subscribe(
      response => {

        if (tipo == 'enviar') {
          this.enviar();
        } else {
          Swal.close();
          this.tipo_archivo.borradorF29.cargando = false;
          this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF.cargando = false;
          this.tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.cargando = false;
          this.tipo_archivo.reporteAPSImpuestoUnicoPDF.cargando = false;
          this.tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.cargando = false;
          this.tipo_archivo.libroMayorImpuestoUnico.cargando = false;
          this.tipo_archivo.libroMayorBoletaDeHonorarios.cargando = false;
          this.tipo_archivo.capturaLibroCompraFacturacioncl.cargando = false;
          this.tipo_archivo.capturaLibroVentasFacturacioncl.cargando = false;
          this.tipo_archivo.depositoOTransferenciaBancariaPDF.cargando = false;
          this.tipo_archivo.libroMayorIVACreditoFiscalPDF.cargando = false;
          this.tipo_archivo.libroMayorIVADebitoFiscalPDF.cargando = false;
          this.tipo_archivo.otros.cargando = false;
          this.trae_periodo();
          Swal.fire({
            title: "Éxito!",
            text: "Se ha guardado la Documentación",
            icon: 'success'
          }).then((value) => {
            this.trae_periodo()
          })
        }
      },
      error => {
        Swal.fire({
          title: "Error!",
          text: "Ocurrio un error al guardar los registros, intente mas tarde!",
          icon: 'error'
        });
      }

    );


  }


  enviar() {

    Swal.fire({
      title: 'Advertencia!',
      text: "¿Está Seguro de Enviar documentación?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Enviar Documentación'
    }).then((result) => {
      if (result.value) {
        this.enviar_documentacion();
      }
    })

  }

  enviar_documentacion() {

    Swal.fire({
      text: "Descargando archivo, favor espere...",
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })


    this._f29Service.enviarDocumentacion(this.periodo, this.bitacora).subscribe(
      response => {


        this.guardar_disabled = true;
        this.enviar_disabled = true;
        Swal.close();
        Swal.fire({
          title: "Éxito!",
          text: "Se ha enviado la Documentación",
          icon: 'success'
        }).then((result) => {
          this.trae_periodo();


        });


      },
      error => {
        Swal.fire({
          title: "Error!",
          text: "Ocurrio un error al enviar los registros, intente mas tarde!",
          icon: 'error'
        });
      }
    );
  }


  fileChageEvent(tipo, fileInput: any) {
    var ruta;
    this.nombre_archivo = <Array<File>>fileInput.target.files[0].name;
    this.extencion_archivo = this.nombre_archivo.split('.');
    this.extencion_archivo = this.extencion_archivo[1];
    switch (tipo) {

      case "borradorF29":
        this.tipo_archivo.borradorF29.check_file = false;
        this.tipo_archivo.borradorF29.cargando = true;
        this.tipo_archivo.borradorF29.archivo = fileInput.target.files[0].name;
        this.archivos['borradorF29'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['borradorF29']
        ruta = "upload-file-impuesto/borradorF29/" + this.periodo._id;
        this.extencion_valida_archivo = 'xlsx';
        break;
      case "reporteAPSBoletaDeHonorariosPDF":
        this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF.check_file = false;
        this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF.cargando = true;
        this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF.archivo = fileInput.target.files[0].name;
        this.archivos['reporteAPSBoletaDeHonorariosPDF'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['reporteAPSBoletaDeHonorariosPDF']
        ruta = "upload-file-impuesto/reporteAPSBoletaDeHonorariosPDF/" + this.periodo._id;
        this.extencion_valida_archivo = 'pdf';
        break;
      case "archivoPlanoAPSBoletadeHonorariosTXT":
        this.tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.check_file = false;
        this.tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.cargando = true;
        this.tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.archivo = fileInput.target.files[0].name;
        this.archivos['archivoPlanoAPSBoletadeHonorariosTXT'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['archivoPlanoAPSBoletadeHonorariosTXT']
        ruta = "upload-file-impuesto/archivoPlanoAPSBoletadeHonorariosTXT/" + this.periodo._id;
        this.extencion_valida_archivo = 'txt';
        break;
      case "reporteAPSImpuestoUnicoPDF":
        this.tipo_archivo.reporteAPSImpuestoUnicoPDF.check_file = false;
        this.tipo_archivo.reporteAPSImpuestoUnicoPDF.cargando = true;
        this.tipo_archivo.reporteAPSImpuestoUnicoPDF.archivo = fileInput.target.files[0].name;
        this.archivos['reporteAPSImpuestoUnicoPDF'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['reporteAPSImpuestoUnicoPDF']
        ruta = "upload-file-impuesto/reporteAPSImpuestoUnicoPDF/" + this.periodo._id;
        this.extencion_valida_archivo = 'pdf';
        break;
      case "archivoPlanoAPSImpuestoUnicoTXT":
        this.tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.check_file = false;
        this.tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.cargando = true;
        this.tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.archivo = fileInput.target.files[0].name;
        this.archivos['archivoPlanoAPSImpuestoUnicoTXT'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['archivoPlanoAPSImpuestoUnicoTXT']
        ruta = "upload-file-impuesto/archivoPlanoAPSImpuestoUnicoTXT/" + this.periodo._id;
        this.extencion_valida_archivo = 'txt';
        break;

      case "libroMayorImpuestoUnico":
        this.tipo_archivo.libroMayorImpuestoUnico.check_file = false;
        this.tipo_archivo.libroMayorImpuestoUnico.cargando = true;
        this.tipo_archivo.libroMayorImpuestoUnico.archivo = fileInput.target.files[0].name;
        this.archivos['libroMayorImpuestoUnico'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['libroMayorImpuestoUnico']
        ruta = "upload-file-impuesto/libroMayorImpuestoUnico/" + this.periodo._id;
        this.extencion_valida_archivo = 'pdf';
        break;
      case "libroMayorBoletaDeHonorarios":
        this.tipo_archivo.libroMayorBoletaDeHonorarios.check_file = false;
        this.tipo_archivo.libroMayorBoletaDeHonorarios.cargando = true;
        this.tipo_archivo.libroMayorBoletaDeHonorarios.archivo = fileInput.target.files[0].name;
        this.archivos['libroMayorBoletaDeHonorarios'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['libroMayorBoletaDeHonorarios']
        ruta = "upload-file-impuesto/libroMayorBoletaDeHonorarios/" + this.periodo._id;
        this.extencion_valida_archivo = 'pdf';
        break;
      case "capturaLibroCompraFacturacioncl":
        this.tipo_archivo.capturaLibroCompraFacturacioncl.check_file = false;
        this.tipo_archivo.capturaLibroCompraFacturacioncl.cargando = true;
        this.tipo_archivo.capturaLibroCompraFacturacioncl.archivo = fileInput.target.files[0].name;
        this.archivos['capturaLibroCompraFacturacioncl'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['capturaLibroCompraFacturacioncl']
        ruta = "upload-file-impuesto/capturaLibroCompraFacturacioncl/" + this.periodo._id;
        this.extencion_valida_archivo = 'jpg';
        break;
      case "capturaLibroVentasFacturacioncl":
        this.tipo_archivo.capturaLibroVentasFacturacioncl.check_file = false;
        this.tipo_archivo.capturaLibroVentasFacturacioncl.cargando = true;
        this.tipo_archivo.capturaLibroVentasFacturacioncl.archivo = fileInput.target.files[0].name;
        this.archivos['capturaLibroVentasFacturacioncl'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['capturaLibroVentasFacturacioncl']
        ruta = "upload-file-impuesto/capturaLibroVentasFacturacioncl/" + this.periodo._id;
        this.extencion_valida_archivo = 'jpg';
        break;
      case "depositoOTransferenciaBancariaPDF":
        this.tipo_archivo.depositoOTransferenciaBancariaPDF.check_file = false;
        this.tipo_archivo.depositoOTransferenciaBancariaPDF.cargando = true;
        this.tipo_archivo.depositoOTransferenciaBancariaPDF.archivo = fileInput.target.files[0].name;
        this.archivos['depositoOTransferenciaBancariaPDF'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['depositoOTransferenciaBancariaPDF']
        ruta = "upload-file-impuesto/depositoOTransferenciaBancariaPDF/" + this.periodo._id;
        this.extencion_valida_archivo = 'pdf';
        break;
      case "libroMayorIVACreditoFiscalPDF":
        this.tipo_archivo.libroMayorIVACreditoFiscalPDF.check_file = false;
        this.tipo_archivo.libroMayorIVACreditoFiscalPDF.cargando = true;
        this.tipo_archivo.libroMayorIVACreditoFiscalPDF.archivo = fileInput.target.files[0].name;
        this.archivos['libroMayorIVACreditoFiscalPDF'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['libroMayorIVACreditoFiscalPDF']
        ruta = "upload-file-impuesto/libroMayorIVACreditoFiscalPDF/" + this.periodo._id;
        this.extencion_valida_archivo = 'pdf';
        break;
      case "libroMayorIVADebitoFiscalPDF":
        this.tipo_archivo.libroMayorIVADebitoFiscalPDF.check_file = false;
        this.tipo_archivo.libroMayorIVADebitoFiscalPDF.cargando = true;
        this.tipo_archivo.libroMayorIVADebitoFiscalPDF.archivo = fileInput.target.files[0].name;
        this.archivos['libroMayorIVADebitoFiscalPDF'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['libroMayorIVADebitoFiscalPDF']
        ruta = "upload-file-impuesto/libroMayorIVADebitoFiscalPDF/" + this.periodo._id;
        this.extencion_valida_archivo = 'pdf';
        break;
      case "otros":
        this.tipo_archivo.otros.check_file = false;
        this.tipo_archivo.otros.cargando = true;
        this.tipo_archivo.otros.archivo = fileInput.target.files[0].name;
        this.archivos['otros'] = <Array<File>>fileInput.target.files;
        this.archivo_subir = this.archivos['otros']
        ruta = "upload-file-impuesto/otros/" + this.periodo._id;
        this.extencion_valida_archivo = '';
        break;

      default:
        break;
    }


    if (this.extencion_valida_archivo != this.extencion_archivo.toLowerCase() && tipo != 'otros') {

      var mensaje = "Extención invalida, debe adjuntar archivo con Formato " + this.extencion_valida_archivo;


      this.tipo_archivo.borradorF29.cargando = false;
      if (tipo == "borradorF29")
        this.tipo_archivo.borradorF29.archivo = ''

      this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF.cargando = false;
      if (tipo == "reporteAPSBoletaDeHonorariosPDF")
        this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF.archivo = ''

      this.tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.cargando = false;
      if (tipo == "archivoPlanoAPSBoletadeHonorariosTXT")
        this.tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.archivo = ''

      this.tipo_archivo.reporteAPSImpuestoUnicoPDF.cargando = false;
      if (tipo == "reporteAPSImpuestoUnicoPDF")
        this.tipo_archivo.reporteAPSImpuestoUnicoPDF.archivo = ''

      this.tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.cargando = false;
      if (tipo == "archivoPlanoAPSImpuestoUnicoTXT")
        this.tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.archivo = ''

      this.tipo_archivo.libroMayorImpuestoUnico.cargando = false;
      if (tipo == "libroMayorImpuestoUnico")
        this.tipo_archivo.libroMayorImpuestoUnico.archivo = ''

      this.tipo_archivo.libroMayorBoletaDeHonorarios.cargando = false;
      if (tipo == "libroMayorBoletaDeHonorarios")
        this.tipo_archivo.libroMayorBoletaDeHonorarios.archivo = ''

      this.tipo_archivo.capturaLibroCompraFacturacioncl.cargando = false;
      if (tipo == "capturaLibroCompraFacturacioncl")
        this.tipo_archivo.capturaLibroCompraFacturacioncl.archivo = ''

      this.tipo_archivo.capturaLibroVentasFacturacioncl.cargando = false;
      if (tipo == "capturaLibroVentasFacturacioncl")
        this.tipo_archivo.capturaLibroVentasFacturacioncl.archivo = ''

      this.tipo_archivo.depositoOTransferenciaBancariaPDF.cargando = false;
      if (tipo == "depositoOTransferenciaBancariaPDF")
        this.tipo_archivo.depositoOTransferenciaBancariaPDF.archivo = ''

      this.tipo_archivo.libroMayorIVACreditoFiscalPDF.cargando = false;
      if (tipo == "libroMayorIVACreditoFiscalPDF")
        this.tipo_archivo.libroMayorIVACreditoFiscalPDF.archivo = ''

      this.tipo_archivo.libroMayorIVADebitoFiscalPDF.cargando = false;
      if (tipo == "libroMayorIVADebitoFiscalPDF")
        this.tipo_archivo.libroMayorIVADebitoFiscalPDF.archivo = ''

      this.tipo_archivo.otros.cargando = false;
      if (tipo == "otros")
        this.tipo_archivo.otros.archivo = ''


      Swal.fire({
        title: "Error!",
        text: mensaje,
        icon: 'error'
      }).then((result) => {
        this.trae_periodo()
      })


    } else {

      this._uploadService.makeFileRequest(Global.url + ruta, [], this.archivo_subir, 'archivo', this.token)
        .then((result: any) => {
          switch (tipo) {

            case "borradorF29":
              this.tipo_archivo.borradorF29.check_file = true;
              this.tipo_archivo.borradorF29.cargando = false;
              //var path = (result.path).split('\\');
              this.tipo_archivo.borradorF29.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;

            case "reporteAPSBoletaDeHonorariosPDF":
              this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF.check_file = true;
              this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF.cargando = false;
              this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;

            case "archivoPlanoAPSBoletadeHonorariosTXT":
              this.tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.check_file = true;
              this.tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.cargando = false;
              this.tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;
            case "reporteAPSImpuestoUnicoPDF":
              this.tipo_archivo.reporteAPSImpuestoUnicoPDF.check_file = true;
              this.tipo_archivo.reporteAPSImpuestoUnicoPDF.cargando = false;
              this.tipo_archivo.reporteAPSImpuestoUnicoPDF.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;
            case "archivoPlanoAPSImpuestoUnicoTXT":

              this.tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.check_file = true;
              this.tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.cargando = false;
              this.tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;
            case "libroMayorImpuestoUnico":

              this.tipo_archivo.libroMayorImpuestoUnico.check_file = true;
              this.tipo_archivo.libroMayorImpuestoUnico.cargando = false;
              this.tipo_archivo.libroMayorImpuestoUnico.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;
            case "libroMayorBoletaDeHonorarios":

              this.tipo_archivo.libroMayorBoletaDeHonorarios.check_file = true;
              this.tipo_archivo.libroMayorBoletaDeHonorarios.cargando = false;
              this.tipo_archivo.libroMayorBoletaDeHonorarios.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;
            case "capturaLibroCompraFacturacioncl":

              this.tipo_archivo.capturaLibroCompraFacturacioncl.check_file = true;
              this.tipo_archivo.capturaLibroCompraFacturacioncl.cargando = false;
              this.tipo_archivo.capturaLibroCompraFacturacioncl.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;
            case "capturaLibroVentasFacturacioncl":

              this.tipo_archivo.capturaLibroVentasFacturacioncl.check_file = true;
              this.tipo_archivo.capturaLibroVentasFacturacioncl.cargando = false;
              this.tipo_archivo.capturaLibroVentasFacturacioncl.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;
            case "depositoOTransferenciaBancariaPDF":

              this.tipo_archivo.depositoOTransferenciaBancariaPDF.check_file = true;
              this.tipo_archivo.depositoOTransferenciaBancariaPDF.cargando = false;
              this.tipo_archivo.depositoOTransferenciaBancariaPDF.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;
            case "libroMayorIVACreditoFiscalPDF":

              this.tipo_archivo.libroMayorIVACreditoFiscalPDF.check_file = true;
              this.tipo_archivo.libroMayorIVACreditoFiscalPDF.cargando = false;
              this.tipo_archivo.libroMayorIVACreditoFiscalPDF.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;
            case "libroMayorIVADebitoFiscalPDF":

              this.tipo_archivo.libroMayorIVADebitoFiscalPDF.check_file = true;
              this.tipo_archivo.libroMayorIVADebitoFiscalPDF.cargando = false;
              this.tipo_archivo.libroMayorIVADebitoFiscalPDF.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;

            case "otros":

              this.tipo_archivo.otros.check_file = true;
              this.tipo_archivo.otros.cargando = false;
              this.tipo_archivo.otros.ruta = result;
              this.guardar('guardarArchivo', tipo)
              break;

            default:
              break;
          }
          this.verifica_campos_obligatorios();

        });
    }
  }

  verifica_campos_obligatorios() {


    if (
      // this.tipo_archivo.borradorF29.check_file == true &&
      this.tipo_archivo.reporteAPSBoletaDeHonorariosPDF.check_file == true &&
      this.tipo_archivo.reporteAPSImpuestoUnicoPDF.check_file == true
      /*&&
      this.check_file.archivoPlanoAPSBoletadeHonorariosTXT  ==  true &&
      this.check_file.reporteAPSImpuestoUnicoPDF  ==  true &&
      this.check_file.archivoPlanoAPSImpuestoUnicoTXT  ==  true &&
      this.check_file.libroMayorImpuestoUnico == true &&
      this.check_file.libroMayorBoletaDeHonorarios  ==  true &&
      this.check_file.capturaLibroCompraFacturacioncl ==  true &&
      this.check_file.capturaLibroVentasFacturacioncl == true &&
      this.check_file.depositoOTransferenciaBancariaPDF == true &&
      this.check_file.libroMayorIVACreditoFiscalPDF == true &&
      this.check_file.libroMayorIVADebitoFiscalPDF == true*/
    ) {

      this.enviar_disabled = false;
    }

  }

  async trae_archivo(file) {
    Swal.fire({
      text: "Cargando...",
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const nombre_archivo = file.name
    this._f29Service.getFileSharepoint(file._id).subscribe(res => {
      let a = document.createElement("a");
      a.href = URL.createObjectURL(res);
      a.download = nombre_archivo, a.href;
      a.click();
      window.URL.revokeObjectURL(a.href);
      Swal.close()
    },(error) => {
      Swal.fire({
        title: "Error",
        text: "No se ha podido descargar el archivo, favor intente nuevamente",
        icon: 'error'
      })
    })
  }

  cambia_periodo() {

    $("#datepicker").datepicker({
      format: "mm-yyyy",
      viewMode: "months",
      minViewMode: "months"
    });
  }

  verifica_fechaEnvio() {
    if (this.periodo.envio.enviar == false) {
      this.enviar_disabled = true;
      this.guardar_disabled = true;
      this.adjuntar_disabled = true;


    }
  }

  trae_info_etapa(etapa) {

    this._f29Service.traeInfoEtapa(etapa).subscribe(
      response => {

        return JSON.stringify(response);

      },
      error => { }
    );


  }

  trae_info_etapa_revision() {

    let etapa = { _id: "5d7a43024938a3d5df0adeb5" };
    this._f29Service.traeInfoEtapa(etapa).subscribe(
      response => {

        this.etapa_revision = response;
        this.estados_revision = response.estados;

      },
      error => { }
    );

  }

  trae_opciones_periodo() {

    this.trae_info_etapa_revision();

  }

  verificar_vencimiento() {

    if (this.periodo.vencimiento.estado == true) {
      this.alerta = true;
      this.mensaje_alerta = this.periodo.vencimiento.mensaje;
      this.adjuntar_disabled = true;
      this.enviar_disabled = true;
      this.guardar_disabled = true;
    } else {
      this.alerta = false;
      this.mensaje_alerta = this.periodo.vencimiento.mensaje;
      this.adjuntar_disabled = false;
    }

  }

  trae_privilegios(code) {

    var privilegio = { code: code };

    this._f29Service.traePrivilegios(privilegio).subscribe(
      response => {

        var status = response.status;

        if (status == true) {
          switch (code) {
            case "revision_periodo":
              this.privilegio.revision_periodo = true;
              break;

            default:
              break;
          }
        }
      },
      error => { }
    );

  }

  actualizar_estado() {

    if (this.revision_periodo.estado_revision) {
      var fecha_vencimiento = this.revision_periodo.fecha ? new Date(this.revision_periodo.fecha + ' ' + this.revision_periodo.hora) : null;
      var revision = {
        id: this.periodo._id,
        estado: this.revision_periodo.estado_revision,
        observacion: this.revision_periodo.observaciones,
        fecha_vencimiento: fecha_vencimiento,
        estado_f_vencimiento: this.revision_periodo.fecha_vencimiento
      }

      this.btn_cerrar_periodo = false;

      if (this.revision_periodo.observaciones == "") {
        this.revision_periodo.observaciones = "Sin Observaciones"
      }

      let msgBitacora = {
        fecha: new Date(),
        usuario: "",
        etapa: "",
        estado: "",
        observaciones: this.revision_periodo.observaciones
      }
      this.bitacora.push(msgBitacora)
      this.revision_periodo.observaciones = ""

      this._f29Service.actualizaRevision(revision, this.bitacora).subscribe(
        response => {
          if (response) {

            Swal.fire({
              title: "Éxito!",
              text: "Se ha actualizado el estado!",
              icon: 'success'
            }).then((value) => {
              this.trae_periodo();
            })

          }
        },
        error => { }
      );

    } else {

      var mensaje = "Seleccione un estado!";
      Swal.fire({
        title: "Error!",
        text: mensaje,
        icon: 'error'
      });
    }

  }

  preCierre_periodo() {
    let fsp = this.vencimiento_sig_periodo.toLocaleString().split("T") // Se setea la fecha dd/MM
    let fecha = fsp[0].split("-").reverse()
    Swal.fire({
      title: "Confirmación",
      html: "<div class='form-group'>" +
        "<p>Fecha: " + fecha[0] + "-" + fecha[1] + "-" + fecha[2] + " Hora: " + fsp[1] + "</p>" +
        "<label>El nuevo periodo tendra esta fecha como vencimiento</label> <br>" +
        "<label> ¿Es correcta esta fecha? </label> <br>" +
        "</div>",
      showCancelButton: true,
      confirmButtonColor: 'success',
      cancelButtonColor: 'danger',
      confirmButtonText: 'Confirmar'
    }).then((result) => {
      if (result.value) {
        this.cerrar_periodo()
        this.modalService.dismissAll()
      }
    })
  }

  cerrar_periodo() {
    Swal.fire({
      text: "Cargando...",
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    var datos = { id: this.periodo._id, vencimiento: this.vencimiento_sig_periodo };
    //return;
    this._f29Service.cerrarPeriodo(datos).subscribe(
      response => {
        Swal.close()
        if (response.estado == true) {

          Swal.fire({
            title: "Éxito!",
            text: response.mensaje,
            icon: 'success'
          })

          this.trae_periodo();
          this.btn_cerrar_periodo = false;

        }
      },
      error => {
        var mensaje = "Ocurrio un Problema al Cerrar periodo, comuniquese con el administrador";
        Swal.fire({
          title: "Error!",
          text: mensaje,
          icon: 'error'
        });

      }
    );
  }

  // Funcion para advertir cambios sin guardar,  **sin implementar
  canDeactivate() {
    return true
  }

  getEstadistica() {
    this._f29Service.getEstadisticas(this.entidad_periodo).subscribe(
      response => {
        if (response != null) {
          if (response.total_registros > 0) {
            if (response.dias_entrega < 0) {
              response.dias_entrega = (response.dias_entrega * -1) + " Dias de anticipación"
            } else if (response.dias_entrega == 0) {
              response.dias_entrega = "Envia la documentación en el día de vencimiento"
            } else {
              response.dias_entrega = response.dias_entrega + " Dias Atrasado"
            }
            response.promedio_rechazos = Math.round(response.cantidad_rechazos / response.total_registros)
            this.estadisticas = response
            this.estadisticas.activo = 1
          } else {
            this.estadisticas.activo = 0;

          }
        }
      }
    )
  }


  generarPDF_consolidado() {

    detectAnyAdblocker().then((detected) => {
      if (detected) {
        Swal.fire({
          title: "¡Atención!",
          text: "Hemos detectado que hay instalado un adblock en el navegador. Por favor desactivelo, actualice la página, y vuelva a intentar generar el borrador.",
          icon: 'warning',
          showCancelButton: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-lg btn-danger ml-3',
            cancelButton: 'btn btn-lg btn-danger',

          },
          confirmButtonText: 'OK'
        })
      } else {

        const logoIASD = new Image();
        const marca_agua = new Image();
        logoIASD.src = 'assets/images/cover-sidebar-user1.jpg';
        marca_agua.src = 'assets/images/IASD6.png';
        const mes = new Date(this.mes_periodo).getMonth()
        const mes_periodo = this.meses[mes]
        const ano = new Date(this.mes_periodo).getFullYear()
        const footer = '© 2020 Iglesia Adventista del Séptimo Día - Unión Chilena';

        switch (this.tipo_borrador) {
          case "entidad":
            var titulo = 'Borrador F29';
            var descripcion = 'Borrador F29 - ' + this.entidad_sel.codigo + ' - ' + this.entidad_sel.nombre + ' - ' + ' Periodo ' + mes_periodo + " " + ano
            var x_titulo = 85
            break
          case "corporacion":
            var titulo = 'Consolidado Corporación';
            var descripcion = 'Consolidado Corporación Periodo ' + mes_periodo + " " + ano
            var x_titulo = 64
            break
          case "iasd":
            var titulo = 'Consolidado IASD';
            var descripcion = 'Consolidado IASD Periodo ' + mes_periodo + " " + ano
            var x_titulo = 76
            break
        }

        Swal.fire({
          text: "Generando borrador F29",
          allowOutsideClick: false,
          timer: 1000,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        }).then(() => {
          Swal.close()

          let doc = new jsPDF()

          doc.setFontSize(22);
          doc.text(titulo, x_titulo, 30);
          doc.setFontSize(18);
          doc.text('Periodo ' + mes_periodo + " " + ano, 108, 40, { align: 'center' });


          autoTable(doc, {
            html: '#tablas',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 7) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 22) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 24) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 50,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: {
              text: { cellWidth: 'auto' }
            },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          })

          doc.addPage()

          autoTable(doc, {
            html: '#tablas2',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0
                || data.row.index === 2
                || data.row.index === 6
                || data.row.index === 7
                || data.row.index === 14
                || data.row.index === 24) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 32) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 30,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: {
              1: { cellWidth: 80 },
              text: { cellWidth: 'auto' }
            },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          })

          doc.addPage()

          autoTable(doc, {
            html: '#tablas3',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0
                || data.row.index === 4
                || data.row.index === 16
                || data.row.index === 17
                || data.row.index === 22) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 27) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 30,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: { text: { cellWidth: 'auto' } },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          })

          doc.addPage()

          autoTable(doc, {
            html: '#tablas4',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 30,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: { text: { cellWidth: 'auto' } },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          });

          autoTable(doc, {
            html: '#tablas5',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 60,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: {
              1: { cellWidth: 80 },
              text: { cellWidth: 'auto' }
            },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          })

          autoTable(doc, {
            html: '#tablas6',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0
                || data.row.index === 6) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index > 6 && data.row.index < 11) {
                if (data.column.index > 4) {
                  data.cell.styles.fillColor = false;
                  data.cell.styles.lineWidth = 0;
                }
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 130,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: { text: { cellWidth: 'auto' } },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          });

          doc.addPage()

          autoTable(doc, {
            html: '#tablas7',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 7) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 50,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: { text: { cellWidth: 'auto' } },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          });

          autoTable(doc, {
            html: '#tablas8',
            tableWidth: 'wrap',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index > 7) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 110,
            styles: { cellWidth: 'wrap', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: {
              1: { cellWidth: 80 },
              text: { cellWidth: 'wrap' }
            },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          })
          this.active = 1
          var blob = doc.output("blob")
          window.open(URL.createObjectURL(blob));
          this.modalService.dismissAll()
        })
      }
    })
  }

  sidebar_minified() {

    if (this.minified == false) {
      //$('#page-container').addClass('page-sidebar-minified');
      this.minified = true;
    } else {
      //$('#page-container').removeClass('page-sidebar-minified');
      this.minified = false;
    }

  }

  trae_documentacion() {

    //this.entidad_sel = this._entidadService.getEntidad();
  }

}

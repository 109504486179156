import { Component, OnInit } from '@angular/core';
import { DeclaracionService } from '../../../services/declaracion.service';
import { EntidadService } from '../../../services/entidad.service';
import { UserService } from '../../../services/user.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-informe-dj',
  templateUrl: './informe-dj.component.html',
  styleUrls: ['./informe-dj.component.css'],
  providers: [UserService, EntidadService, DeclaracionService]
})
export class InformeDJComponent implements OnInit {

  icono: string = 'fas fa-chart-bar fa-fw'
  titulo: string = 'Informe'
  descripcion: string = 'Declaraciones DJ1945'
  data: Array<any>
  year: number = 2020;

  constructor(
    private _djService: DeclaracionService,

  ) {
    this.getData()
  }

  ngOnInit(): void {
  }


  getData() {
    Swal.fire({
      text: "Cargando...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    this._djService.informeDJ().subscribe(response => {
      this.data = response.result;
      Swal.close()

    }, error => {
      console.error(error)
    })

  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}

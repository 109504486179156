import { Component, OnInit, Injectable, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal, NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Parametros_pres } from '../../../../models/parametros_pres';
import { Sueldos_independientes } from '../../../../models/sueldos_independientes';
import { Diversos } from '../../../../models/diverso';
import { EntidadService } from '../../../../services/entidad.service';
import { PresupuestoService } from '../../../../services/presupuesto.service';
import { XlsxService } from '../../../../services/xslx_file.service';
import { Sueldos_dependientes } from '../../../../models/sueldos_dependientes';
import { Modificacion_sueldo_dep } from '../../../../models/modificacion-sueldo-dep';
import { Viajes } from '../../../../models/viajes';
import { Modificacion_cuentas_departamentos } from '../../../../models/departamentos_cuenta';
import { Cuentas } from '../../../../models/cuentas';
import { Prima_Infantil } from '../../../../models/prima_infantil';
import { Ingresos } from '../../../../models/ingresos';
import { Modificar_ingresos } from '../../../../models/modificar_ingresos';
import { Votar_B } from '../../../../models/votar_b';
import { Factor_Rea } from '../../../../models/factor_rea';
import { Generar_cuenta_extra } from '../../../../models/generar_cuenta_extra';
import { ConfiguracionSueldos } from '../../../../models/configuracionSueldos';
import { UserService } from '../../../../services/user.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
import { stringify } from 'querystring';
import { ThousandsPipe } from '../../../../pipes/thousands.pipe'
declare const require: any;
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import * as ExcelJS from "exceljs/dist/exceljs.min.js"
import * as FileSaver from 'file-saver'
const EXCEL_EXTENSION = '.xlsx';
declare var $: any;
// import 'animate.min.css';

// for ngb datepicker adapter
@Injectable()
export class NgbDateNativeAdapter extends NgbDateAdapter<Date> {

    fromModel(date: Date): NgbDateStruct {
        //   return
        return (date && date.getFullYear) ? { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } : null;
    }

    toModel(date: NgbDateStruct): Date {
        return date ? new Date(date.year, date.month - 1, date.day) : null;
    }
}

@Component({
    selector: 'pres_iglesia',
    // templateUrl: './pres_iglesia_antiguo.component.html',
    templateUrl: './pres_iglesia.component.html',
    // styleUrls: ['./pres_iglesia_antiguo.component.css'],
    styleUrls: ['./pres_iglesia.component.css'],
    providers: [EntidadService, PresupuestoService, UserService, NgbModal, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, ThousandsPipe, XlsxService]
})
export class PresIglesiaComponent implements OnInit {
    @Input() permiso = false
    public entidad_sel = this._entidadService.getEntidad()
    public tipo_pres = ''
    public nabTab = 'variables'
    public tipo_pestana = 'variables'
    public flag_pres = 1
    public estado
    public message = ''
    public parametros_pres: Parametros_pres
    public sueldos_dependientes: Sueldos_dependientes
    public sueldos_independientes: Sueldos_independientes
    public diversos: Diversos
    public div_modify = {}
    public modificacion_sueldo_dep: Modificacion_sueldo_dep
    public modificacion_cuentas_departamentos: Modificacion_cuentas_departamentos
    public viajes: Viajes
    public cuentas: Cuentas
    public v_b: Votar_B
    public prima_infantil: Prima_Infantil
    public ingresos: Ingresos
    public genera_cuentas_extras: Generar_cuenta_extra
    public sueldo_dep
    public sub_totales_sueldo_dep
    public sub_total_cuentas_extras_dep
    public sub_totales_sueldo_ind
    public sub_totales_viajes
    public id_sueldo
    public sueldo
    public entidad
    public valorfmr
    public bot_sp = false
    public sueldo_dependiente = []
    public sub_total_cuentas_dep
    public sueldo_independiente = []
    public sub_total_cuentas_ind
    public sub_total_cuentas_extras_ind
    public columna_cuentas_extras_ind
    public columna_cuentas_extras_dep
    public lista_diverso = []
    public mensaje
    public state
    public sl_ind
    public viaje = []
    public prim_inf = []
    public prima_inf
    public fila_411_dep = []
    public columna_411_dep = []
    public sub_totales_411_dep = []
    public sub_saldo_411_dep = []
    public saldo_411_dep = []
    public diezmo_411_dep
    public no_diezmo_411_dep
    public fila_411_ind = []
    public columna_411_ind = []
    public sub_totales_411_ind
    public sub_saldo_411_ind
    public saldo_411_ind
    public diezmo_411_ind
    public no_diezmo_411_ind
    public fila_412 = []
    public columna_412
    public sub_totales_412
    public diez_nodiez_412
    public sub_saldo_412
    public saldo_412
    public fila_414 = []
    public columna_414
    public sub_totales_414
    public diez_nodiez_414
    public sub_saldo_414
    public saldo_414
    public fila_419 = []
    public columna_419
    public sub_totales_419
    public diez_nodiez_419
    public sub_saldo_419
    public saldo_419
    public tipo_diverso = ['Dependiente', 'Independiente']
    public tipo_ingreso = ['Diezmos', 'Ofrendas', 'Donaciones Recibidas', 'Otros Operativos', 'Suvenciones Netas']
    public diezmo_ingr = []
    public count_diezmo_ingr
    public ofrendas_ingr = []
    public count_ofrendas_ingr
    public don_rec_ingr = []
    public count_don_rec_ingr
    public otros_op_ingr = []
    public count_otros_op_ingr
    public suv_net_ingr = []
    public count_suv_net_ingr
    public sub_totales_ingr
    public modifica_ingresos: Modificar_ingresos
    public carga_tabla_411
    public votar_b
    public votar_b_pd_diezmo
    public votar_b_pd_no_diezmo
    public votar_b_gastos_totales
    public votar_b_ingresos_totales_diezmo
    public votar_b_ingresos_totales_no_diezmo
    public votar_a_ingreso_311_diezmo
    public votar_a_ingreso_311_no_diezmo
    public votar_a_ingreso_312_diezmo
    public votar_a_ingreso_312_no_diezmo
    public votar_a_ingreso_313_diezmo
    public votar_a_ingreso_313_no_diezmo
    public votar_a_ingreso_318_diezmo
    public votar_a_ingreso_318_no_diezmo
    public votar_a_ingreso_319_diezmo
    public votar_a_ingreso_319_no_diezmo
    public votar_a_total_ingresos_diezmo
    public votar_a_total_ingreso_no_diezmo
    public votar_a_total_ingresos
    public ingr
    public votar_a_sueldos_diezmo
    public votar_a_sueldos_no_diezmo
    public votar_a_gastos_operativos_diezmo
    public votar_a_gastos_operativos_no_diezmo
    public votar_a_gastos_reuniones_eventos_diezmo
    public votar_a_gastos_reuniones_eventos_no_diezmo
    public votar_a_otorgamientos_diezmo
    public votar_a_otorgamientos_no_diezmo
    public votar_a_total_gastos_diezmo
    public votar_a_total_gastos_no_diezmo
    public votar_a_diferencia_diezmo
    public votar_a_diferencia_no_diezmo
    public votar_a_total_diferencia
    public votar_a_factor_411
    public votar_a_factor_412
    public votar_a_factor_414
    public votar_a_factor_419
    public votar_a_factor_diferencia
    public print_votar_b = false
    public totales_votar_b = { gastos_totales: 0, diezmo: 0, no_diezmo: 0 }
    public factor_rea = { factor_411: 0, factor_412: 0, factor_414: 0, factor_419: 0, factor_diferencia: 0 }
    public fac_rea: Factor_Rea
    public errores_importador
    public mostrar = false
    public Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    })
    closeResult: string;
    modalOptions = {
        // size: 'lg',
        // scrollable: true,
        animation: true,
    }
    temp_lista_diverso = []
    temp_prim_inf = []
    temp_sueldo_dependiente = []
    temp_sueldo_independiente = []
    temp_viaje = []
    temp_411_dep = []
    temp_411_ind = []
    temp_412 = []
    temp_414 = []
    temp_419 = []
    temp_ingDiezmo = []
    temp_ingOfrendas = []
    temp_ingDonaciones = []
    temp_ingOtrOpe = []
    temp_ingSubRec = []
    configuracionSueldos: ConfiguracionSueldos

    constructor(
        private _entidadService: EntidadService,
        private _userService: UserService,
        private _presupuestoService: PresupuestoService,
        private modalService: NgbModal,
        private _xlsxService: XlsxService
    ) {
        this.entidad_sel = this._entidadService.getEntidad()
        this.parametros_pres = new Parametros_pres('', JSON, null, null, null, null, null)
        this.sueldos_dependientes = new Sueldos_dependientes('', '', null, '', '', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, new Date(), null, JSON)
        this.sueldos_independientes = new Sueldos_independientes('', '', null, '', '', null, null, null, null, null, null, null, '', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, new Date(), null, JSON)
        this.trae_diversos()
        this.trae_viajes()
        this.traer_412()
        this.traer_414()
        this.traer_419()
        this.traer_ingresos()
        this.traer_votar_a()
        this.traerConfiguracionesSueldos()
        this.entidad = this.parametros_pres.codigo_entidad
        this.entidad_sel = this._entidadService.getEntidad()
        this.entidad = this.entidad_sel.codigo
        this.modificacion_sueldo_dep = new Modificacion_sueldo_dep('', null, '', null, '')
        this.viajes = new Viajes('', null, '', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, JSON, null)
        this.diversos = new Diversos('', null, '', 'Iglesia')
        this.modificacion_cuentas_departamentos = new Modificacion_cuentas_departamentos('', null, '', null, '', null, '')
        this.cuentas = new Cuentas('', null, null)
        this.prima_infantil = new Prima_Infantil(null, '', null)
        this.ingresos = new Ingresos('', '', null, '')
        this.modifica_ingresos = new Modificar_ingresos('', '', '', '')
        this.v_b = new Votar_B('', null, '')
        this.fac_rea = new Factor_Rea('', '', 0)
        this.genera_cuentas_extras = new Generar_cuenta_extra('', '', '')
    }

    ngDoCheck() { //esta funcion detecta cualquier cambio dentro del sistema
        var cambioEntidad = this._entidadService.getEntidad() //detecta siempre el valor de la entidad seleccionada aunq se cambie

        if (this.entidad_sel.codigo != cambioEntidad.codigo) { //Compara si la entidad seleccionada al inicio es distinta a la entidad q tiene ahora seleccionada el sistema
            this.cambio() //ejecuta funcion en caso q haya cambiado
        }


    }

    cambio() { //funcion que deberia ejecutarse cuando haya un cambio de entidad
        this.entidad_sel = this._entidadService.getEntidad() //actualizar la entidad seleccionada con la que haya en localstoge para que this.entidad_sel sea igual a cambioEntidad en DoCheck hasta que la entidad se vuelva a cambiar nuevamente y asi sucesivamente
        this.trae_datos(this.tipo_pres)
    }

    ngOnInit() {
    }


    open(content) { //abrir modal seleccionada
        this.modalService.open(content, this.modalOptions).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    //opciones por defecto para cualquier modal
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    onNavChange(changeEvent: NgbNavChangeEvent) {
        if (this.flag_pres === 1 && (changeEvent.nextId === 'cuentas' || changeEvent.nextId === 'resumenes')) {
            changeEvent.preventDefault()
        } else if (this.flag_pres === 2 && changeEvent.nextId === 'resumenes') {
            changeEvent.preventDefault()
        }
    }

    tipo_presupuesto(tipo) {
        if (this.flag_pres == 1) {
            if (tipo == 'variables' || tipo == 'diversos' || tipo == 'prima_infantil') {
                this.trae_datos(tipo)
                this.tipo_pres = tipo
                this.tipo_pestana = tipo
            } else {
                return;
            }
            this.trae_datos(tipo)
        } else if (this.flag_pres == 2) {
            if (tipo != 'resumenes') {
                if (tipo == 'cuentas') {
                    this.calcular_prim_infantil()
                    this.trae_sueldosdependientes()
                    this.trae_sueldosindependientes()
                } else {
                    this.trae_datos(tipo)
                    // this.tipo_pres = tipo
                    // this.tipo_pestana = tipo
                }
                // this.trae_datos(tipo)
                this.tipo_pres = tipo
                this.tipo_pestana = tipo
            } else {
                return;
            }
        } else if (this.flag_pres == 3) {
            if (tipo == 'cuentas') {
                this.calcular_prim_infantil()
            } else if (tipo == 'resumenes') {
                this.generar_votar_b()
            }
            this.trae_datos(tipo)
            this.tipo_pestana = tipo
            this.tipo_pres = tipo
        }
    }

    siguiente(tipo) {
        if (tipo == 'variables') {
            // this.calcular_prim_infantil()
            this.flag_pres = 2
            this.nabTab = 'cuentas'
            this.tipo_presupuesto('cuentas')
        } else if (tipo == 'cuentas') {
            this.flag_pres = 3
            this.nabTab = 'resumenes'
            this.tipo_presupuesto('resumenes')
        }

    }
    flag_presupuesto(flag) {
        this.flag_pres = flag;
    }

    update_sueldos(swal, datosActualizados) {
        // if (swal) {
        Swal.fire({
            text: "Actualizando sueldos",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })
        // }
        let update = { datosActualizados };
        this._presupuestoService.actualizar_sueldos(update).subscribe(
            response => {
                if (response.estado == true) {
                    // setTimeout(() => {
                    this.trae_datos('tab_sueldos');
                    Swal.close()
                    if (swal) {
                        this.Toast.fire({
                            icon: 'success',
                            title: 'Configuraciones actualizadas exitosamente'
                        })
                    }
                    // }, 2000);
                }

            },
            error => { console.error(error) },
        );
    }

    trae_sueldosdependientes() {

        this._presupuestoService.trae_sueldos_dependientes(this.parametros_pres).subscribe(
            response => {
                if (response.estado == true) {
                    this.sub_total_cuentas_dep = response.sub_totales_cuentas;
                    this.sueldo_dependiente = response.resultado;
                    if (response.resultado[0].entidad.codigo == 184112 || response.resultado[0].entidad.codigo == 183112) {
                        this.mostrar = true;
                    }
                    this.sub_total_cuentas_extras_dep = response.sub_totales_cuentas_extras;
                    this.sub_totales_sueldo_dep = response.sub_totales[0];
                    this.columna_cuentas_extras_dep = response.resultado[0].cuentas_extras;
                    this.temp_sueldo_dependiente = [...this.sueldo_dependiente]
                } else {
                    // this.sueldo_dependiente = '';
                    this.sub_totales_sueldo_dep = '';
                    this.sub_total_cuentas_dep = '';
                    this.columna_cuentas_extras_dep = [];
                }
            },
            error => { console.error(error) },
        );
    }

    reset_form(form) {
        form.reset();
        this.message = '';
        this.estado = true;

    }

    modal_modificar_sueldo_dep(id, valor, tipo, indice, tipo_sueldo) {
        this.modificacion_sueldo_dep = new Modificacion_sueldo_dep(id, valor, tipo, indice, tipo_sueldo);
        if (this.modificacion_sueldo_dep.valor) {
            this.modifacasueldodep();
        } else {
            this.modificacion_sueldo_dep.valor = 0;
            this.modifacasueldodep();
        }
    }

    modifacasueldodep() {
        if (this.modificacion_sueldo_dep.tipo_sueldo == 'dependiente') {
            this._presupuestoService.actualizar_sueldos_dependientes(this.modificacion_sueldo_dep).subscribe(
                response => {
                    if (response.estado == true) {
                        this.Toast.fire({
                            icon: 'success',
                            title: response.mensaje
                        })
                        this.trae_sueldosdependientes()
                    } else {
                        Swal.fire({
                            title: "¡Error!",
                            text: response.mensaje,
                            icon: 'error',
                            showCancelButton: false,
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'btn btn-lg btn-danger ml-3',
                                cancelButton: 'btn btn-lg btn-danger',

                            },
                            confirmButtonText: 'OK'
                        })
                        this.trae_sueldosdependientes()
                    }
                },
                error => { console.error(error) },
            );
        } else if (this.modificacion_sueldo_dep.tipo_sueldo == 'independiente') {
            this._presupuestoService.actualizar_sueldos_independientes(this.modificacion_sueldo_dep).subscribe(
                response => {
                    this.state = response.estado;
                    this.mensaje = response.message;
                    if (response.estado == true) {
                        this.Toast.fire({
                            icon: 'success',
                            title: response.mensaje
                        })
                        this.trae_sueldosindependientes()
                    } else {
                        Swal.fire({
                            title: "¡Error!",
                            text: response.mensaje,
                            icon: 'error',
                            showCancelButton: false,
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'btn btn-lg btn-danger ml-3',
                                cancelButton: 'btn btn-lg btn-danger',

                            },
                            confirmButtonText: 'OK'
                        })
                        this.trae_sueldosindependientes()
                    }
                },
                error => { console.error(error) },
            );

        } else if (this.modificacion_sueldo_dep.tipo_sueldo == 'viajes') {
            this._presupuestoService.actualizar_viajes(this.modificacion_sueldo_dep).subscribe(

                response => {
                    this.state = response.estado;
                    this.mensaje = response.message;
                    if (response.estado == true) {
                        this.Toast.fire({
                            icon: 'success',
                            title: response.mensaje
                        })
                        this.trae_viajes()
                    } else {
                        Swal.fire({
                            title: "¡Error!",
                            text: response.mensaje,
                            icon: 'error',
                            showCancelButton: false,
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'btn btn-lg btn-danger ml-3',
                                cancelButton: 'btn btn-lg btn-danger',

                            },
                            confirmButtonText: 'OK'
                        })
                        this.trae_viajes()
                    }
                },
                error => { console.error(error) },
            );
        }
    }

    trae_datos(tab) {
        if (tab == 'variables' || tab == 'diversos') {
            this.trae_diversos()
        } else if (tab == 'prima_infantil') {
            this.trae_prima_infantil()
        } else if (tab == 'cuentas' || tab == 'tab_sueldos') {
            this.trae_sueldosdependientes()
            this.trae_sueldosindependientes()
        } else if (tab == 'viajes') {
            this.trae_viajes()
        } else if (tab == 'sueldos') {
            this.generar_tablas_411(true, true)
        } else if (tab == 'gastos_operativos') {
            this.traer_412()
        } else if (tab == 'reuniones_eventos') {
            this.traer_414()
        } else if (tab == 'otorgamientos') {
            this.traer_419()
        } else if (tab == 'ingresos') {
            this.traer_ingresos()
            this.generar_tablas_411(false, false)
        } else if (tab == 'votar_a') {
            this.traer_votar_a()
        }
    }

    delete_sueldo(id, tipo_sueldo) {
        this.id_sueldo = { id: id };

        if (tipo_sueldo == 'dependiente') {
            this._presupuestoService.delete_sueldo_dependiente(this.id_sueldo).subscribe(
                response => {
                    this.trae_sueldosdependientes();
                },
                error => { console.error(error) },
            );
        } else if (tipo_sueldo == 'independiente') {
            this._presupuestoService.delete_sueldo_independiente(this.id_sueldo).subscribe(
                response => {
                    this.trae_sueldosindependientes();
                },
                error => { console.error(error) },
            );
        } else if (tipo_sueldo == 'viajes') {
            this._presupuestoService.delete_viajes(this.id_sueldo).subscribe(
                response => {
                    this.trae_viajes();
                },
                error => { console.error(error) },
            );
        } else if (tipo_sueldo == 'prima_infantil') {
            var borra_prima_infantil = { _id: id }

            this._presupuestoService.delete_prima_infantil(borra_prima_infantil).subscribe(
                response => {
                    if (response.estado == true) {
                        this.Toast.fire({
                            icon: 'success',
                            title: response.mensaje
                        })
                        this.trae_prima_infantil()
                    }
                },
                error => { console.error(error) },
            );
        }

    }

    trae_diversos() {
        var diver;

        this._presupuestoService.trae_diversos(diver).subscribe(
            response => {
                this.lista_diverso = response.resultado;
                this.temp_lista_diverso = [...this.lista_diverso]
            },
            error => { console.error(error) },
        );
    }

    modificar_diverso_modal(id, diverso, nombre, tipo_sueldo, modal) {
        this.div_modify['id'] = id
        this.diversos.diverso = diverso
        this.diversos.nombre = nombre
        this.diversos.tipo_diverso = tipo_sueldo
        this.open(modal)
    }

    modificar_diverso(modal) {
        this.div_modify['diverso'] = this.diversos.diverso
        this.div_modify['nombre'] = this.diversos.nombre
        this.div_modify['tipo_sueldo'] = this.diversos.tipo_diverso
        this._presupuestoService.modificar_diversos_iglesia(this.div_modify).subscribe(
            response => {
                if (response.estado == true) {
                    this.Toast.fire({
                        icon: 'success',
                        title: response.mensaje
                    })
                    this.modalService.dismissAll()
                    this.diversos = new Diversos('', null, '', 'Iglesia')
                    this.trae_diversos()
                } else {
                    this.modalService.dismissAll()
                    Swal.fire({
                        title: "¡Error!",
                        text: response.mensaje,
                        icon: 'error',
                        showCancelButton: true,
                        buttonsStyling: true,
                        customClass: {
                            confirmButton: 'btn btn-lg btn-primary ml-3',
                            cancelButton: 'btn btn-lg btn-danger',
                        },
                        confirmButtonText: 'Volver a intentar',
                        cancelButtonText: 'Cerrar'
                    }).then((result) => {
                        if (result.value) {
                            this.open(modal)
                        } else {
                            this.modalService.dismissAll()
                        }
                    })
                }

            },
            error => { console.error(error) },
        )
    }

    trae_sueldosindependientes() {

        this._presupuestoService.trae_sueldos_independientes(this.parametros_pres).subscribe(
            response => {
                if (response.estado == true) {
                    this.sub_total_cuentas_ind = response.sub_totales_cuentas;
                    this.sueldo_independiente = response.resultado;
                    this.sub_totales_sueldo_ind = response.sub_totales[0];
                    this.sub_total_cuentas_extras_ind = response.sub_totales_cuentas_extras;
                    this.columna_cuentas_extras_ind = response.resultado[0].cuentas_extras
                    this.temp_sueldo_independiente = [...this.sueldo_independiente]
                } else {
                    this.sub_totales_sueldo_ind = '';
                    this.sub_total_cuentas_ind = '';
                    this.sub_total_cuentas_extras_dep = '';
                    this.columna_cuentas_extras_ind = [];
                }
            },
            error => { console.error(error) },
        );
    }

    trae_viajes() {
        this._presupuestoService.trae_viajes(this.parametros_pres).subscribe(
            response => {
                if (response.estado == true) {
                    this.viaje = response.resultado;
                    this.sub_totales_viajes = response.sub_totales[0];
                    this.temp_viaje = [...this.viaje]
                } else {
                    this.viaje = [];
                    this.sub_totales_viajes = [];
                }

            },
            error => { console.error(error) },
        );
    }

    calcular_prim_infantil() {
        var prima;

        this._presupuestoService.calcular_prima_infantil(prima).subscribe(
            response => {
                this.prima_inf = response.prima_infantil;
                this.generar_sueldos_viajes()
            },
            error => { console.error(error) },
        );
    }

    actualizarPrimaInfantil() {
        this._presupuestoService.actualizar_prima_infantil().subscribe(
            response => {
                if (response.estado) {
                    this.trae_prima_infantil()
                }
            },
            error => { console.error(error) },
        );
    }

    agregar_prima_infantil(modal) {
        var fecha_nacimiento = JSON.parse(JSON.stringify(this.prima_infantil.fecha_nacimiento))
        fecha_nacimiento = fecha_nacimiento.split('T')
        fecha_nacimiento = fecha_nacimiento[0].split('-')
        this.prima_infantil.fecha_nacimiento = fecha_nacimiento[2] + "/" + fecha_nacimiento[1] + "/" + fecha_nacimiento[0]
        this._presupuestoService.insertar_prima_infantil(this.prima_infantil).subscribe(
            response => {

                //this.prima_inf = response.prima_infantil;
                if (response.estado == true) {
                    this.modalService.dismissAll()
                    this.Toast.fire({
                        icon: 'success',
                        title: response.mensaje
                    })
                    this.trae_prima_infantil();
                } else {
                    this.modalService.dismissAll()
                    Swal.fire({
                        title: "¡Error!",
                        text: response.mensaje,
                        icon: 'error',
                        showCancelButton: true,
                        buttonsStyling: true,
                        customClass: {
                            confirmButton: 'btn btn-lg btn-primary ml-3',
                            cancelButton: 'btn btn-lg btn-danger',

                        },
                        confirmButtonText: 'Volver a intentar',
                        cancelButtonText: 'Cerrar'
                    }).then((result) => {

                        if (result.value) {
                            this.open(modal)
                        } else {
                            this.modalService.dismissAll()
                        }
                    })
                }
            },
            error => { console.error(error) },
        )

    }

    agregar_diverso(modal) {
        this._presupuestoService.insertar_diverso(this.diversos).subscribe(
            response => {
                if (response.estado == true) {
                    this.modalService.dismissAll()
                    this.Toast.fire({
                        icon: 'success',
                        title: response.mensaje
                    })
                    this.trae_diversos();
                } else {
                    this.modalService.dismissAll()
                    Swal.fire({
                        title: "¡Error!",
                        text: response.mensaje,
                        icon: 'error',
                        showCancelButton: true,
                        buttonsStyling: true,
                        customClass: {
                            confirmButton: 'btn btn-lg btn-primary ml-3',
                            cancelButton: 'btn btn-lg btn-danger',
                        },
                        confirmButtonText: 'Volver a intentar',
                        cancelButtonText: 'Cerrar'
                    }).then((result) => {
                        if (result.value) {
                            this.open(modal)
                        } else {
                            this.modalService.dismissAll()
                        }
                    })
                }

            },
            error => { console.error(error) },
        );
    }

    modificar_estado_diverso(id, status) {
        var div = { _id: id, estado: status };
        this._presupuestoService.actualiza_diverso(div).subscribe(
            response => {
                if (response.estado == true) {
                    this.Toast.fire({
                        icon: 'success',
                        title: response.mensaje
                    })
                    this.trae_diversos();
                }
            },
            error => { console.error(error) },
        );

    }

    trae_prima_infantil() {
        var prima_inf;

        this._presupuestoService.traer_prima_infantil(prima_inf).subscribe(
            response => {
                if (response.estado == true) {
                    this.prim_inf = response.resultado;
                    this.temp_prim_inf = [...this.prim_inf];
                } else {
                    this.prim_inf = [];
                }
            },
            error => { console.error(error) },
        );
    }



    generar_sueldos_viajes() {
        Swal.fire({
            text: "Generando sueldos",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })
        var generate;
        this._presupuestoService.agregar_sueldos_viajes(generate).subscribe(
            response => {
                if (response.estado == true) {
                    //this.update_sueldos(false, 'Ambos')
                    // setTimeout(() => {
                    Swal.close()
                    // }, 3000);
                }
            },
            error => { console.error(error) },
        );
    }

    traer_412() {
        var gas_op;

        this._presupuestoService.traer_gastos_operativos(gas_op).subscribe(
            response => {
                if (response.estado == true) {
                    this.fila_412 = response.resultado;
                    this.temp_412 = [...this.fila_412]
                    this.columna_412 = response.resultado[0].departamentos;
                    this.sub_totales_412 = response.sub_totales;
                    this.diez_nodiez_412 = response.sub_diz_nodiez[0];
                    this.sub_saldo_412 = response.sub_saldo;
                    this.saldo_412 = response.saldo;
                } else if (response.estado == false) {
                    this.columna_412 = '';
                    this.sub_totales_412 = '';
                    this.diez_nodiez_412 = '';
                    this.sub_saldo_412 = '';
                    this.saldo_412 = '';
                }
            },
            error => { console.error(error); },
        );
    }

    traer_414() {
        var gas_reu_eve;

        this._presupuestoService.traer_gastos_reuniones_eventos(gas_reu_eve).subscribe(
            response => {
                if (response.estado == true) {
                    this.fila_414 = response.resultado;
                    this.temp_414 = [...this.fila_414]
                    this.columna_414 = response.resultado[0].departamentos;
                    this.sub_totales_414 = response.sub_totales;
                    this.diez_nodiez_414 = response.sub_diz_nodiez[0];
                    this.sub_saldo_414 = response.sub_saldo;
                    this.saldo_414 = response.saldo;
                } else if (response.estado == false) {
                    this.columna_414 = '';
                    this.sub_totales_414 = '';
                    this.diez_nodiez_414 = '';
                    this.sub_saldo_414 = '';
                    this.saldo_414 = '';
                }
            },
            error => { console.error(error); },
        );
    }

    traer_419() {
        var otorg;

        this._presupuestoService.traer_otorgamientos(otorg).subscribe(
            response => {
                if (response.estado == true) {
                    this.fila_419 = response.resultado;
                    this.temp_419 = [...this.fila_419]
                    this.columna_419 = response.resultado[0].departamentos;
                    this.sub_totales_419 = response.sub_totales;
                    this.diez_nodiez_419 = response.sub_diz_nodiez[0];
                    this.sub_saldo_419 = response.sub_saldo;
                    this.saldo_419 = response.saldo;
                } else if (response.estado == false) {
                    this.columna_419 = '';
                    this.sub_totales_419 = '';
                    this.diez_nodiez_419 = '';
                    this.sub_saldo_419 = '';
                    this.saldo_419 = '';
                }
            },
            error => { console.error(error); },
        );
    }

    modal_modificar_cuentas(id, valor, cuenta, indice, departamento, n_departamento, tipo, actual) {
        if (Number.isNaN(parseInt(n_departamento))) {
            n_departamento = n_departamento.value
        }
        this.modificacion_cuentas_departamentos = new Modificacion_cuentas_departamentos(id, valor, cuenta, indice, departamento, n_departamento, tipo);
        if (this.modificacion_cuentas_departamentos.valor) {
            this.modifacacuentas();
        } else {
            this.modificacion_cuentas_departamentos.valor = 0;
            this.modificacion_cuentas_departamentos.valor = 0;
            this.modifacacuentas();
        }
    }

    modal_agregar_registro_cuentas(tipo, cuenta, valor, modal) {
        this.cuentas = new Cuentas(tipo, cuenta, valor);
        this.open(modal)
    }

    agregar_registro_cuentas(modal) {
        if (this.cuentas.cuenta == 414) {
            if (this.cuentas.tipo == 'Departamento') {
                this._presupuestoService.insertar_departamento_gastos_reuniones_eventos(this.cuentas).subscribe(
                    response => {
                        if (response.estado == false) {
                            Swal.fire({
                                title: "¡Error!",
                                text: response.mensaje,
                                icon: 'warning',
                                showCancelButton: true,
                                buttonsStyling: true,
                                customClass: {
                                    confirmButton: 'btn btn-lg btn-primary ml-3',
                                    cancelButton: 'btn btn-lg btn-danger',
                                },
                                confirmButtonText: 'Volver a intentar',
                                cancelButtonText: 'Cerrar'
                            }).then((result) => {
                                if (result.value) {
                                    this.open(modal)
                                } else {
                                    this.modalService.dismissAll()
                                }
                            })
                        } else {
                            this.Toast.fire({
                                icon: 'success',
                                title: response.mensaje
                            })
                            this.modalService.dismissAll()
                            this.traer_414()
                            this.cuentas = new Cuentas('', null, null);
                        }
                    },
                    error => { console.error(error) },
                );
            } else {
                this._presupuestoService.insertar_cuenta_gastos_reuniones_eventos(this.cuentas).subscribe(
                    response => {
                        if (response.estado == false) {
                            Swal.fire({
                                title: "¡Error!",
                                text: response.mensaje,
                                icon: 'warning',
                                showCancelButton: true,
                                buttonsStyling: true,
                                customClass: {
                                    confirmButton: 'btn btn-lg btn-primary ml-3',
                                    cancelButton: 'btn btn-lg btn-danger',
                                },
                                confirmButtonText: 'Volver a intentar',
                                cancelButtonText: 'Cerrar'
                            }).then((result) => {
                                if (result.value) {
                                    this.open(modal)
                                } else {
                                    this.modalService.dismissAll()
                                }
                            })
                        } else {
                            this.Toast.fire({
                                icon: 'success',
                                title: response.mensaje
                            })
                            this.modalService.dismissAll()
                            this.traer_414()
                            this.cuentas = new Cuentas('', null, null);
                        }
                    },
                    error => { console.error(error) },
                );
            }

        } else if (this.cuentas.cuenta == 412) {
            if (this.cuentas.tipo == 'Departamento') {
                this._presupuestoService.insertar_departamento_gastos_operativos(this.cuentas).subscribe(
                    response => {
                        if (response.estado == false) {
                            Swal.fire({
                                title: "¡Error!",
                                text: response.mensaje,
                                icon: 'warning',
                                showCancelButton: true,
                                buttonsStyling: true,
                                customClass: {
                                    confirmButton: 'btn btn-lg btn-primary ml-3',
                                    cancelButton: 'btn btn-lg btn-danger',
                                },
                                confirmButtonText: 'Volver a intentar',
                                cancelButtonText: 'Cerrar'
                            }).then((result) => {
                                if (result.value) {
                                    this.open(modal)
                                } else {
                                    this.modalService.dismissAll()
                                }
                            })
                        } else {
                            this.modalService.dismissAll()
                            this.traer_412()
                            this.cuentas = new Cuentas('', null, null);
                        }
                    },
                    error => { console.error(error) },
                );
            } else {
                this._presupuestoService.insertar_cuenta_gastos_operativos(this.cuentas).subscribe(
                    response => {
                        if (response.estado == false) {
                            Swal.fire({
                                title: "¡Error!",
                                text: response.mensaje,
                                icon: 'warning',
                                showCancelButton: true,
                                buttonsStyling: true,
                                customClass: {
                                    confirmButton: 'btn btn-lg btn-primary ml-3',
                                    cancelButton: 'btn btn-lg btn-danger',
                                },
                                confirmButtonText: 'Volver a intentar',
                                cancelButtonText: 'Cerrar'
                            }).then((result) => {
                                if (result.value) {
                                    this.open(modal)
                                } else {
                                    this.modalService.dismissAll()
                                }
                            })
                        } else {
                            this.Toast.fire({
                                icon: 'success',
                                title: response.mensaje
                            })
                            this.modalService.dismissAll()
                            this.traer_412()
                            this.cuentas = new Cuentas('', null, null);
                        }
                    },
                    error => { console.error(error) },
                );
            }

        } else if (this.cuentas.cuenta == 419) {
            if (this.cuentas.tipo == 'Departamento') {
                this._presupuestoService.insertar_departamento_otorgamientos(this.cuentas).subscribe(
                    response => {
                        if (response.estado == false) {
                            Swal.fire({
                                title: "¡Error!",
                                text: response.mensaje,
                                icon: 'warning',
                                showCancelButton: true,
                                buttonsStyling: true,
                                customClass: {
                                    confirmButton: 'btn btn-lg btn-primary ml-3',
                                    cancelButton: 'btn btn-lg btn-danger',
                                },
                                confirmButtonText: 'Volver a intentar',
                                cancelButtonText: 'Cerrar'
                            }).then((result) => {
                                if (result.value) {
                                    this.open(modal)
                                } else {
                                    this.modalService.dismissAll()
                                }
                            })
                        } else {
                            this.Toast.fire({
                                icon: 'success',
                                title: response.mensaje
                            })
                            this.modalService.dismissAll()
                            this.traer_419()
                            this.cuentas = new Cuentas('', null, null);
                        }
                    },
                    error => { console.error(error) },
                );
            } else {
                this._presupuestoService.insertar_cuenta_otorgamientos(this.cuentas).subscribe(
                    response => {
                        if (response.estado == false) {
                            Swal.fire({
                                title: "¡Error!",
                                text: response.mensaje,
                                icon: 'warning',
                                showCancelButton: true,
                                buttonsStyling: true,
                                customClass: {
                                    confirmButton: 'btn btn-lg btn-primary ml-3',
                                    cancelButton: 'btn btn-lg btn-danger',
                                },
                                confirmButtonText: 'Volver a intentar',
                                cancelButtonText: 'Cerrar'
                            }).then((result) => {
                                if (result.value) {
                                    this.open(modal)
                                } else {
                                    this.modalService.dismissAll()
                                }
                            })
                        } else {
                            this.Toast.fire({
                                icon: 'success',
                                title: response.mensaje
                            })
                            this.modalService.dismissAll()
                            this.traer_419()
                            this.cuentas = new Cuentas('', null, null);
                        }
                    },
                    error => { console.error(error) },
                );
            }

        }
    }

    modifacacuentas() {
        this._presupuestoService.modificar_cuentas(this.modificacion_cuentas_departamentos).subscribe(
            response => {
                if (response.estado == true) {
                    if (this.modificacion_cuentas_departamentos.cuenta == '412') {
                        this.traer_412()
                        this.Toast.fire({
                            icon: 'success',
                            title: response.mensaje
                        })
                    } else if (this.modificacion_cuentas_departamentos.cuenta == '414') {
                        this.traer_414()
                        this.Toast.fire({
                            icon: 'success',
                            title: response.mensaje
                        })
                    } else if (this.modificacion_cuentas_departamentos.cuenta == '419') {
                        this.traer_419()
                        this.Toast.fire({
                            icon: 'success',
                            title: response.mensaje
                        })
                    }
                } else if (response.estado == false) {
                    Swal.fire({
                        title: "¡Error!",
                        text: response.mensaje,
                        icon: 'error',
                        showCancelButton: false,
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-lg btn-danger ml-3',
                            cancelButton: 'btn btn-lg btn-danger',

                        },
                        confirmButtonText: 'OK'
                    })
                }
            },
            error => { console.error(error) },
        );
    }

    insertarIngreso(modal) {
        this._presupuestoService.insertar_ingreso(this.ingresos).subscribe(
            response => {
                if (response.estado == false) {
                    this.modalService.dismissAll()
                    Swal.fire({
                        title: "¡Error!",
                        text: response.mensaje,
                        icon: 'warning',
                        showCancelButton: true,
                        buttonsStyling: true,
                        customClass: {
                            confirmButton: 'btn btn-lg btn-primary ml-3',
                            cancelButton: 'btn btn-lg btn-danger',
                        },
                        confirmButtonText: 'Volver a intentar',
                        cancelButtonText: 'Cerrar'
                    }).then((result) => {
                        if (result.value) {
                            this.open(modal)
                        } else {
                            this.modalService.dismissAll()
                        }
                    })
                } else {
                    this.Toast.fire({
                        icon: 'success',
                        title: response.mensaje
                    })
                    this.modalService.dismissAll()
                    this.traer_ingresos()
                    this.ingresos = new Ingresos('', '', null, '');
                }
            },
            error => { console.error(error) },
        );
    }

    traer_ingresos() {
        var otorg;

        this._presupuestoService.traer_ingreso(otorg).subscribe(
            response => {
                if (response.estado == true) {
                    this.diezmo_ingr = response.diezmo;
                    this.temp_ingDiezmo = [...this.diezmo_ingr]
                    this.ingr = response.ingresos
                    this.count_diezmo_ingr = response.count_diezmo;
                    this.ofrendas_ingr = response.ofrendas;
                    this.temp_ingOfrendas = [...this.ofrendas_ingr]
                    this.count_ofrendas_ingr = response.count_ofrendas;
                    this.don_rec_ingr = response.don_rec;
                    this.temp_ingDonaciones = [...this.don_rec_ingr]
                    this.count_don_rec_ingr = response.count_don_rec;
                    this.otros_op_ingr = response.otros_op;
                    this.temp_ingOtrOpe = [...this.otros_op_ingr]
                    this.count_otros_op_ingr = response.count_otros_op;
                    this.suv_net_ingr = response.suv_net;
                    this.temp_ingSubRec = [...this.suv_net_ingr]
                    this.count_suv_net_ingr = response.count_suv_net;
                    this.sub_totales_ingr = response.sub_totales[0];
                } else if (response.estado == false) {
                    this.count_diezmo_ingr = '';
                    this.count_ofrendas_ingr = '';
                    this.count_don_rec_ingr = '';
                    this.count_otros_op_ingr = '';
                    this.count_suv_net_ingr = '';
                    this.sub_totales_ingr = '';
                }
            },
            error => { console.error(error); },
        );
    }

    modificar_ingresos(id, valor, cuenta, tipo) {
        this.modifica_ingresos = new Modificar_ingresos(id, valor, cuenta, tipo);
        if (this.modifica_ingresos.valor) {
            this.modificaingresos();
        } else {
            this.modifica_ingresos.valor = '0';
            this.modifica_ingresos.valor = '0';
            this.modificaingresos();
        }

    }

    modificaingresos() {
        this._presupuestoService.modificar_ingreso(this.modifica_ingresos).subscribe(
            response => {
                if (response.estado == false) {
                    Swal.fire({
                        title: "¡Error!",
                        text: response.mensaje,
                        icon: 'error',
                        showCancelButton: false,
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-lg btn-danger ml-3',
                            cancelButton: 'btn btn-lg btn-danger',

                        },
                        confirmButtonText: 'OK'
                    })
                    this.traer_ingresos()
                } else if (response.estado == true) {
                    this.Toast.fire({
                        icon: 'success',
                        title: response.mensaje
                    })
                    this.traer_ingresos()
                }
            },
            error => { console.error(error) },
        );
    }

    traer_411() {
        var tablas;

        this._presupuestoService.traer_tabla_sueldos(tablas).subscribe(
            response => {
                if (response.estado == true) {
                    this.carga_tabla_411 = true;
                    this.fila_411_dep = response.dependientes;
                    this.temp_411_dep = [...this.fila_411_dep]
                    this.columna_411_dep = response.dependientes[0].departamentos;
                    this.sub_totales_411_dep = response.sub_dep;
                    this.diezmo_411_dep = response.total_diezmo_dep;
                    this.no_diezmo_411_dep = response.total_no_diezmo_dep;
                    this.sub_saldo_411_dep = response.saldo_total_dep;
                    this.fila_411_ind = response.independientes;
                    this.temp_411_ind = [...this.fila_411_ind]
                    this.columna_411_ind = response.independientes[0].departamentos;
                    this.sub_totales_411_ind = response.sub_ind;
                    this.diezmo_411_ind = response.total_diezmo_ind;
                    this.no_diezmo_411_ind = response.total_no_diezmo_ind;
                    this.sub_saldo_411_ind = response.saldo_total_ind;
                } else {
                    this.carga_tabla_411 = false;
                    this.diezmo_411_dep = '';
                    this.no_diezmo_411_dep = '';
                    this.sub_totales_411_ind = '';
                    this.diezmo_411_ind = '';
                    this.no_diezmo_411_ind = '';
                    this.sub_saldo_411_ind = '';
                    this.saldo_411_ind = '';
                }
            },
            error => { console.error(error); },
        );
    }

    generar_tablas_411(swal_flag, validacion_flag) {
        var validacion_departamentos = true
        for (let i = 0; i < this.sueldo_dependiente.length; i++) {
            const e = this.sueldo_dependiente[i];
            if (e.codigo_departamento == '') {
                validacion_departamentos = false
            }
        }
        for (let j = 0; j < this.sueldo_independiente.length; j++) {
            const m = this.sueldo_independiente[j];
            if (m.codigo_departamento == '') {
                validacion_departamentos = false
            }
        }
        if (validacion_departamentos == true) {
            if (swal_flag == true) {
                Swal.fire({
                    text: "Generando cuentas 411",
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    },
                })
            }
            var generar_tabla;
            this._presupuestoService.generar_tablas_sueldos_411(generar_tabla).subscribe(
                response => {
                    if (response.estado == true) {
                        this.traer_411();
                        if (swal_flag == true) {
                            Swal.close();
                            this.Toast.fire({
                                icon: 'success',
                                title: response.mensaje
                            })
                        }
                    }
                },
                error => { console.error(error) },
            );
        } else if (validacion_departamentos == false) {
            Swal.fire({
                title: "¡Atención!",
                text: "Tienes diversos sin un departamento asignado. Corrige este error antes de continuar",
                icon: 'warning',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-lg btn-danger ml-3',
                    cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'Ok'
            }).then((result) => {
                validacion_flag ? this.tipo_presupuesto('tab_sueldos') : null
            })
        }

    }

    traerConfiguracionesSueldos() {
        this._presupuestoService.traer_configuraciones_sueldos().subscribe(
            response => {
                if (response.estado) {
                    this.configuracionSueldos = new ConfiguracionSueldos(response.datos.aportPatroDep, response.datos.aportPatroInd)
                    // console.table(this.configuracionSueldos)
                }
            },
            error => { console.error(error); },
        )
    }

    modificarConfigSueldos() {
        this._presupuestoService.modificar_configuraciones_sueldos(this.configuracionSueldos).subscribe(
            response => {
                if (response.estado) {
                    this.update_sueldos(true, response.datosActualizados)
                    this.traerConfiguracionesSueldos()
                    this.modalService.dismissAll()
                }
            },
            error => { console.error(error); },
        )
    }

    generar_votar_b() {
        Swal.fire({
            text: "Generando Votar B",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })
        this._presupuestoService.generar_votar_b().subscribe(
            response => {
                if (response.estado == true) {
                    Swal.close()
                    this.traer_votar_b()
                    this.Toast.fire({
                        icon: 'success',
                        title: response.mensaje
                    })
                }
            },
            error => { console.error(error); },
        )
    }

    traer_votar_b() {
        var tablas;
        this._presupuestoService.traer_votar_b(tablas).subscribe(
            response => {
                if (response.estado == true) {

                    this.votar_b = response.votar_b;
                    this.votar_b_pd_diezmo = response.por_distribuir_diezmo;
                    this.votar_b_pd_no_diezmo = response.por_distribuir_no_diezmo;
                    this.votar_b_gastos_totales = response.gastos_totales;
                    this.votar_b_ingresos_totales_diezmo = response.ingresos_totales_diezmo;
                    this.votar_b_ingresos_totales_no_diezmo = response.ingresos_totales_no_diezmo;
                    this.totales_votar_b = response.totales[0];
                } else {

                    this.votar_b = '';
                    this.votar_b_pd_diezmo = '';
                    this.votar_b_pd_no_diezmo = '';
                    this.votar_b_gastos_totales = '';
                    this.votar_b_ingresos_totales_diezmo = '';
                    this.votar_b_ingresos_totales_no_diezmo = '';
                    this.totales_votar_b = { gastos_totales: null, diezmo: null, no_diezmo: null };
                }
            },
            error => { console.error(error); },
        );
    }

    actualizar_votar_b() {
        Swal.fire({
            text: "Actualizando Datos",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })
        var generar_tabla;
        this._presupuestoService.actualizar_votar_b(generar_tabla).subscribe(
            response => {
                Swal.close();
                if (response.estado != false) {
                    this.traer_votar_b();
                }
            },
            error => { console.error(error) },
        );
    }

    traer_votar_a() {
        var tablas;

        this._presupuestoService.traer_votar_a(tablas).subscribe(
            response => {
                if (response.estado == true) {
                    this.factor_rea = response.factor_reas;
                    this.votar_a_ingreso_311_diezmo = response.ingreso_311_diezmo;
                    this.votar_a_ingreso_311_no_diezmo = response.ingreso_311_no_diezmo;
                    this.votar_a_ingreso_312_diezmo = response.ingreso_312_diezmo;
                    this.votar_a_ingreso_312_no_diezmo = response.ingreso_312_no_diezmo;
                    this.votar_a_ingreso_313_diezmo = response.ingreso_313_diezmo;
                    this.votar_a_ingreso_313_no_diezmo = response.ingreso_313_no_diezmo;
                    this.votar_a_ingreso_318_diezmo = response.ingreso_318_diezmo;
                    this.votar_a_ingreso_318_no_diezmo = response.ingreso_318_no_diezmo;
                    this.votar_a_ingreso_319_diezmo = response.ingreso_319_diezmo;
                    this.votar_a_ingreso_319_no_diezmo = response.ingreso_319_no_diezmo;
                    this.votar_a_total_ingresos_diezmo = response.total_ingresos_diezmo;
                    this.votar_a_total_ingreso_no_diezmo = response.total_ingreso_no_diezmo;
                    this.votar_a_total_ingresos = response.total_ingresos;
                    this.votar_a_sueldos_diezmo = response.sueldos_diezmo;
                    this.votar_a_sueldos_no_diezmo = response.sueldos_no_diezmo;
                    this.votar_a_gastos_operativos_diezmo = response.gastos_operativos_diezmo;
                    this.votar_a_gastos_operativos_no_diezmo = response.gastos_operativos_no_diezmo;
                    this.votar_a_gastos_reuniones_eventos_diezmo = response.gastos_reuniones_eventos_diezmo;
                    this.votar_a_gastos_reuniones_eventos_no_diezmo = response.gastos_reuniones_eventos_no_diezmo;
                    this.votar_a_otorgamientos_diezmo = response.otorgamientos_diezmo;
                    this.votar_a_otorgamientos_no_diezmo = response.otorgamientos_no_diezmo;
                    this.votar_a_total_gastos_diezmo = response.total_gastos_diezmo;
                    this.votar_a_total_gastos_no_diezmo = response.total_gastos_no_diezmo;
                    this.votar_a_diferencia_diezmo = response.diferencia_diezmo;
                    this.votar_a_diferencia_no_diezmo = response.diferencia_no_diezmo;
                    this.votar_a_total_diferencia = response.total_diferencia;
                    this.votar_a_factor_411 = response.factor_411;
                    this.votar_a_factor_412 = response.factor_412;
                    this.votar_a_factor_414 = response.factor_414;
                    this.votar_a_factor_419 = response.factor_419;
                    this.votar_a_factor_diferencia = response.factor_diferencia;
                } else {
                    this.factor_rea = { factor_411: null, factor_412: null, factor_414: null, factor_419: null, factor_diferencia: null };
                    this.votar_a_ingreso_311_diezmo = 0;
                    this.votar_a_ingreso_311_no_diezmo = 0;
                    this.votar_a_ingreso_312_diezmo = 0;
                    this.votar_a_ingreso_312_no_diezmo = 0;
                    this.votar_a_ingreso_313_diezmo = 0;
                    this.votar_a_ingreso_313_no_diezmo = 0;
                    this.votar_a_ingreso_318_diezmo = 0;
                    this.votar_a_ingreso_318_no_diezmo = 0;
                    this.votar_a_ingreso_319_diezmo = 0;
                    this.votar_a_ingreso_319_no_diezmo = 0;
                    this.votar_a_total_ingresos_diezmo = 0;
                    this.votar_a_total_ingreso_no_diezmo = 0;
                    this.votar_a_total_ingresos = 0;
                    this.votar_a_sueldos_diezmo = 0;
                    this.votar_a_sueldos_no_diezmo = 0;
                    this.votar_a_gastos_operativos_diezmo = 0;
                    this.votar_a_gastos_operativos_no_diezmo = 0;
                    this.votar_a_gastos_reuniones_eventos_diezmo = 0;
                    this.votar_a_gastos_reuniones_eventos_no_diezmo = 0;
                    this.votar_a_otorgamientos_diezmo = 0;
                    this.votar_a_otorgamientos_no_diezmo = 0;
                    this.votar_a_total_gastos_diezmo = 0;
                    this.votar_a_total_gastos_no_diezmo = 0;
                    this.votar_a_diferencia_diezmo = 0;
                    this.votar_a_diferencia_no_diezmo = 0;
                    this.votar_a_total_diferencia = 0;
                    this.votar_a_factor_411 = 0;
                    this.votar_a_factor_412 = 0;
                    this.votar_a_factor_414 = 0;
                    this.votar_a_factor_419 = 0;
                    this.votar_a_factor_diferencia = 0;
                }
            },
            error => { console.error(error); },
        );
    }

    modal_modificar_votar_b(id, valor, tipo) {
        this.v_b = new Votar_B(id, valor, tipo);
        if (this.v_b.valor) {
            this.modificar_votar_b();
        } else {
            this.v_b.valor = 0;
            this.v_b.valor = 0;
            this.modificar_votar_b();
        }

    }

    modificar_votar_b() {
        this._presupuestoService.modificar_votar_b(this.v_b).subscribe(
            response => {
                if (response.estado == true) {
                    this.traer_votar_b();
                    this.Toast.fire({
                        icon: 'success',
                        title: response.mensaje
                    })
                }
            },
            error => { console.error(error); },
        );
    }

    generarPDF_votar_a() {
        var logoIASD = new Image();
        var marca_agua = new Image();
        logoIASD.src = 'assets/images/cover-sidebar-user1.jpg';
        marca_agua.src = 'assets/images/IASD6.png';
        marca_agua
        Swal.fire({
            text: "Generando reporte Votar A",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })
        var generar_tabla;
        this._presupuestoService.datos_pdf(generar_tabla).subscribe(
            response => {
                Swal.close();
                var año = response.year;
                var titulo = 'Presupuesto ' + año;
                var descripcion = response.entidad.nombre_campo + ' - ' + response.entidad.nombre + ' - ' + response.entidad.codigo;
                var footer = '© 2019 Iglesia Adventista del Séptimo Día - Unión Chilena';
                var pdf = new jsPDF();

                pdf.setFontSize(22);
                pdf.text(titulo, 70, 30);
                pdf.setFontSize(18);
                pdf.text('Votar A', 90, 40);
                autoTable(pdf, {
                    html: '#print_section_a',
                    tableWidth: 'auto',
                    headStyles: {
                        fillColor: [226, 231, 235],
                        textColor: [0, 0, 0]
                    },
                    footStyles: {
                        fillColor: [226, 231, 235],
                        textColor: [0, 0, 0]
                    },
                    didParseCell: function (data) {
                        if (data.row.section === 'head') {
                            data.cell.styles.halign = 'center' // Use an icon in didDrawCell instead

                        }
                        if (data.row.section === 'foot' && data.column.index === 0) {
                            data.cell.styles.halign = 'center' // Use an icon in didDrawCell instead
                        }
                        if (data.row.section === 'head' && (data.column.index > 2)) {
                            data.cell.styles.fillColor = false;
                        }
                        if (data.row.section === 'body' && (data.row.index < 5 && data.column.index > 2)) {
                            data.cell.styles.fillColor = false;
                            data.cell.styles.lineWidth = 0;
                        }
                        if (data.row.section === 'head' && data.column.index < 3) {
                            data.cell.styles.lineWidth = 0.1;
                        }
                        if (data.row.section === 'foot' && data.column.index < 4 && data.row.index === 0) {
                            data.cell.styles.lineWidth = 0.1;
                        }
                        if (data.row.section === 'foot' && data.row.index === 1) {
                            data.cell.styles.lineWidth = 0.1;
                        }
                        if (data.row.section === 'body' && (data.row.index === 5)) {
                            data.cell.styles.fillColor = [226, 231, 235];
                            data.cell.styles.textColor = [0, 0, 0];
                            data.cell.styles.fontStyle = 'bold';
                            if (data.column.index === 1 || data.column.index === 2 || data.column.index === 3) {
                                data.cell.styles.halign = 'left';
                            } else {
                                data.cell.styles.halign = 'center';
                            }
                        }
                        if (data.row.section === 'foot' && data.row.index == 0 && data.column.index > 3) {
                            data.cell.styles.fillColor = false;
                        }
                    },
                    didDrawPage: function (data) {
                        // Header
                        pdf.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
                        // Footer
                        pdf.setFontSize(6);
                        pdf.text(descripcion, 196, 280, { align: 'right' });
                        pdf.setFontSize(6);
                        pdf.text(footer, data.settings.margin.left, 280);
                        pdf.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
                    },
                    margin: { top: 30, bottom: 30 },
                    theme: 'grid',
                    startY: 50,
                    styles: { cellWidth: 'auto', fontSize: 6 },
                    columnStyles: { text: { cellWidth: 'auto' } },
                    showHead: 'firstPage',
                    showFoot: 'lastPage'
                });
                var blob = pdf.output("blob");
                window.open(URL.createObjectURL(blob));
            },
            error => { console.error(error) },
        );
    }

    generarPDF_votar_b() {
        var data = document.getElementById('print_section_b');
        var logoIASD = new Image();
        var marca_agua = new Image();
        logoIASD.src = 'assets/images/cover-sidebar-user1.jpg';
        marca_agua.src = 'assets/images/IASD6.png';
        marca_agua
        Swal.fire({
            text: "Generando reporte Votar B",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })
        var generar_tabla;
        this._presupuestoService.datos_pdf(generar_tabla).subscribe(
            response => {
                Swal.close();
                var año = response.year;
                var titulo = 'Presupuesto ' + año;
                var descripcion = response.entidad.nombre_campo + ' - ' + response.entidad.nombre + ' - ' + response.entidad.codigo;
                var footer = '© 2019 Iglesia Adventista del Séptimo Día - Unión Chilena';
                var pdf = new jsPDF();
                pdf.setFontSize(22);
                pdf.text(titulo, 70, 30);
                pdf.setFontSize(18);
                pdf.text('Votar B', 90, 40);
                autoTable(pdf, {
                    html: '#print_section_b',
                    headStyles: {
                        fillColor: [226, 231, 235],
                        textColor: [0, 0, 0]
                    },
                    footStyles: {
                        fillColor: [226, 231, 235],
                        textColor: [0, 0, 0]
                    },
                    didParseCell: function (data) {
                        if (data.row.section === 'head') {
                            data.cell.styles.halign = 'center';
                            data.cell.styles.lineWidth = 0.1;
                        }
                        if (data.row.section === 'foot' && data.column.index === 0) {
                            data.cell.styles.halign = 'center';
                        }
                        if (data.row.section === 'foot') {
                            data.cell.styles.lineWidth = 0.1;
                        }
                    },
                    didDrawPage: function (data) {
                        // Header
                        pdf.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
                        // Footer
                        pdf.setFontSize(6);
                        pdf.text(descripcion, 196, 280, { align: 'right' });
                        pdf.setFontSize(6);
                        pdf.text(footer, data.settings.margin.left, 280);
                        pdf.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
                    },
                    margin: { top: 30, bottom: 30 },
                    theme: 'grid',
                    startY: 50,
                    styles: { cellWidth: 'auto', fontSize: 6 },
                    columnStyles: { text: { cellWidth: 'auto' } },
                    showHead: 'firstPage',
                    showFoot: 'lastPage'
                });
                var blob = pdf.output("blob");
                window.open(URL.createObjectURL(blob));

            },
            error => { console.error(error) },
        );
    }

    modal_modificar_factor_rea(id, tipo, valor) {
        this.fac_rea = new Factor_Rea(id, tipo, valor);
        this.modificar_factor_rea(this.fac_rea);
    }

    modificar_factor_rea(factor_rea) {
        this._presupuestoService.modificar_rea(factor_rea).subscribe(
            response => {
                this.traer_votar_a();
            },
            error => { console.error(error) },
        );
    }

    get_nombre_cuenta_extra(cuenta) {
        this.genera_cuentas_extras = new Generar_cuenta_extra(cuenta, '', '');
        this._presupuestoService.encontrar_cuenta_extra(this.genera_cuentas_extras).subscribe(
            response => {
                if (response.estado == true) {
                    this.genera_cuentas_extras.nombre_cuenta = response.nombre_cuenta;
                } else {
                    Swal.fire({
                        title: "¡Error!",
                        text: response.mensaje,
                        icon: 'error',
                        showCancelButton: false,
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-lg btn-danger ml-3',
                            cancelButton: 'btn btn-lg btn-danger',

                        },
                        confirmButtonText: 'OK'
                    })
                }
            },
            error => { console.error(error) },
        );
    }

    agregar_cuenta_extra(tipo_sueldo, modal) {
        this.genera_cuentas_extras.tipo_sueldo = tipo_sueldo;
        this._presupuestoService.agregar_columnas_sueldos(this.genera_cuentas_extras).subscribe(
            response => {
                if (response.estado == true) {
                    this.genera_cuentas_extras.nombre_cuenta = response.nombre_cuenta;
                    if (tipo_sueldo == 'Dependiente') {
                        this.trae_sueldosdependientes()
                        this.Toast.fire({
                            icon: 'success',
                            title: response.mensaje
                        })
                        this.modalService.dismissAll()
                    } else if (tipo_sueldo == 'Independiente') {
                        this.trae_sueldosindependientes()
                        this.modalService.dismissAll()
                    }
                    this.genera_cuentas_extras = new Generar_cuenta_extra('', '', '');
                } else {
                    Swal.fire({
                        title: "¡Error!",
                        text: response.mensaje,
                        icon: 'error',
                        showCancelButton: false,
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-lg btn-danger ml-3',
                            cancelButton: 'btn btn-lg btn-danger',

                        },
                        confirmButtonText: 'OK'
                    })
                }
            },
            error => { console.error(error) },
        );
    }

    archivo_importador(modal, validar) {
        // Swal.fire({
        //     title: "¡Lo sentimos!",
        //     text: 'En estos momentos le estamos haciendo mantención al importador. Tan pronto como vuelva a estar operativo avisaremos. Disculpa las molestias.',
        //     icon: 'warning',
        //     showCancelButton: false,
        //     buttonsStyling: false,
        //     customClass: {
        //         confirmButton: 'btn btn-lg btn-primary ml-3',
        //         cancelButton: 'btn btn-lg btn-danger',

        //     },
        //     confirmButtonText: 'OK'
        // })
        // // return
        var archivo_importador;

        Swal.fire({
            text: "Generando Archivo Importador",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })

        this._presupuestoService.generar_archivo_importador(archivo_importador).subscribe(
            response => {
                this.swal_descarga_importador(modal, validar)
            },
            error => { console.error(error) },
        );
    }

    swal_descarga_importador(modal, validar) {
        var archivo_importador = {
            'diferencia_gastos': this.votar_a_total_diferencia,
            'total_sueldos': (this.votar_a_sueldos_diezmo + this.votar_a_sueldos_no_diezmo),
            'total_gastos_op': (this.votar_a_gastos_operativos_diezmo + this.votar_a_gastos_operativos_no_diezmo),
            'total_eventos': (this.votar_a_gastos_reuniones_eventos_diezmo + this.votar_a_gastos_reuniones_eventos_no_diezmo),
            'total_otorgamientos': (this.votar_a_otorgamientos_diezmo + this.votar_a_otorgamientos_no_diezmo),
            'total_ingresos': this.votar_a_total_ingresos,
            validacion: validar
        };
        var archivo;
        Swal.fire({
            text: "Revisando Archivo Importador",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })
        this._presupuestoService.revisar_archivo_importador(archivo_importador).subscribe(
            response => {
                this._presupuestoService.traer_archivo_importador(archivo).subscribe(
                    response => {
                        if (response.estado == false) {
                            this.errores_importador = response.datos;
                            Swal.close();
                            this.open(modal)
                        } else if (response.estado == true) {
                            Swal.fire({
                                title: "¡Felicidades!",
                                text: "El Importador se genero exitosamente",
                                icon: 'success',
                                showCancelButton: true,
                                buttonsStyling: true,
                                customClass: {
                                    confirmButton: 'btn btn-lg btn-primary ml-3',
                                    cancelButton: 'btn btn-lg btn-danger',
                                },
                                confirmButtonText: 'Descargar',
                                cancelButtonText: 'Cerrar'
                            }).then((result) => {
                                if (result.value) {
                                    this.trer_archivo_importador(response);
                                    Swal.close();
                                }
                            })

                        }
                    },
                    error => { console.error(error) },
                )
            },
            error => { console.error(error) },
        );
    }

    trer_archivo_importador(data) {
        let nombre_archivo = 'Importador Presupuesto 2021, ' + data.datos[0].EntityCode + ', ';
        this._presupuestoService.exportAsExcelFile(data.datos, nombre_archivo);
    }

    async exportar_tabla_excel(cuenta, presupuesto_completo) {
        try {
            let entidad = this.lista_diverso[0].entidad.codigo, year = this.lista_diverso[0].year, nombre_cuenta
            let workbook = new ExcelJS.Workbook()
            if (cuenta == '411' || presupuesto_completo == true) {
                let worksheet = workbook.addWorksheet('Cuentas 411')
                let dependientes = await this.convert_data_to_export(this.fila_411_dep, this.columna_411_dep, this.sub_totales_411_dep, this.diezmo_411_dep, this.no_diezmo_411_dep, 'Gasto')
                let independientes = await this.convert_data_to_export(this.fila_411_ind, this.columna_411_ind, this.sub_totales_411_ind, this.diezmo_411_ind, this.no_diezmo_411_ind, 'Gasto')
                if (independientes.headers > dependientes.headers) {
                    worksheet.columns = independientes.headers
                    worksheet = this._xlsxService.eliminar_cabeceras(worksheet, 0, independientes.headers.length - 1)
                } else {
                    worksheet.columns = dependientes.headers
                }
                for (let i = 0; i < worksheet.columns.length; i++) {
                    const c = worksheet.columns[i];
                    if (i <= 3) {
                        if (i == 1) {
                            c.width = 25
                        } else {
                            c.width = 15
                        }
                    } else {
                        c.width = 20
                    }
                }
                worksheet = this._xlsxService.insertar_tabla_gasto(worksheet, 1, dependientes.rows.length, dependientes.headers.length, dependientes.rows, 'Sueldos Empleados Dependientes')
                worksheet = this._xlsxService.insertar_tabla_gasto(worksheet, dependientes.rows.length + 5, dependientes.rows.length + 5 + independientes.rows.length - 1, independientes.headers.length, independientes.rows, 'Sueldos Empleados Independientes')
                nombre_cuenta = ', Cuentas 411, '
            }
            if (cuenta == '412' || presupuesto_completo == true) {
                let worksheet = workbook.addWorksheet('Cuentas 412')
                let cuenta_412 = await this.convert_data_to_export(this.fila_412, this.columna_412, this.sub_totales_412, this.diez_nodiez_412.diezmo, this.diez_nodiez_412.no_diezmo, 'Gasto')
                worksheet.columns = cuenta_412.headers
                for (let i = 0; i < worksheet.columns.length; i++) {
                    const c = worksheet.columns[i];
                    if (i <= 3) {
                        if (i == 1) {
                            c.width = 50
                        } else {
                            c.width = 15
                        }
                    } else {
                        c.width = 20
                    }
                }
                worksheet = this._xlsxService.insertar_tabla_gasto(worksheet, 1, cuenta_412.rows.length, cuenta_412.headers.length, cuenta_412.rows, 'Gastos Operativos')
                nombre_cuenta = ', Cuentas 412, '
            }
            if (cuenta == '414' || presupuesto_completo == true) {
                let worksheet = workbook.addWorksheet('Cuentas 414')
                let cuenta_414 = await this.convert_data_to_export(this.fila_414, this.columna_414, this.sub_totales_414, this.diez_nodiez_414.diezmo, this.diez_nodiez_414.no_diezmo, 'Gasto')
                worksheet.columns = cuenta_414.headers
                for (let i = 0; i < worksheet.columns.length; i++) {
                    const c = worksheet.columns[i];
                    if (i <= 3) {
                        if (i == 1) {
                            c.width = 50
                        } else {
                            c.width = 15
                        }
                    } else {
                        c.width = 20
                    }
                }
                worksheet = this._xlsxService.insertar_tabla_gasto(worksheet, 1, cuenta_414.rows.length, cuenta_414.headers.length, cuenta_414.rows, 'Gastos, Reuniones y Eventos')
                nombre_cuenta = ', Cuentas 414, '
            }
            if (cuenta == '419' || presupuesto_completo == true) {
                let worksheet = workbook.addWorksheet('Cuentas 419')
                let cuenta_419 = await this.convert_data_to_export(this.fila_419, this.columna_419, this.sub_totales_419, this.diez_nodiez_419.diezmo, this.diez_nodiez_419.no_diezmo, 'Gasto')
                worksheet.columns = cuenta_419.headers
                for (let i = 0; i < worksheet.columns.length; i++) {
                    const c = worksheet.columns[i];
                    if (i <= 3) {
                        if (i == 1) {
                            c.width = 50
                        } else {
                            c.width = 15
                        }
                    } else {
                        c.width = 20
                    }
                }
                worksheet = this._xlsxService.insertar_tabla_gasto(worksheet, 1, cuenta_419.rows.length, cuenta_419.headers.length, cuenta_419.rows, 'Otorgamientos')
                nombre_cuenta = ', Cuentas 419, '
            }
            if (cuenta == 'Ingresos' || presupuesto_completo == true) {
                let worksheet = workbook.addWorksheet('Ingresos')
                let ingresos = await this.convert_data_to_export(this.ingr, this.ingr[0], '', this.sub_totales_ingr.diezmo, this.sub_totales_ingr.no_diezmo, 'Ingresos')
                worksheet.columns = ingresos.headers
                for (let i = 0; i < worksheet.columns.length; i++) {
                    const c = worksheet.columns[i];
                    if (i === 2) {
                        c.width = 70
                    } else if (i === 1) {
                        c.width = 15
                    } else {
                        c.width = 15
                    }
                }
                worksheet.addRows(ingresos.rows)
                let comienzo_tabla = 1, fin_tabla = ingresos.rows.length, tabla_header_length = ingresos.headers.length
                let s_diezmo = comienzo_tabla + 1, f_diezmo = s_diezmo + this.count_diezmo_ingr - 1, s_ofrendas = f_diezmo + 1, f_ofrendas = s_ofrendas + this.count_ofrendas_ingr - 1,
                    s_don_rec = f_ofrendas + 1, f_don_rec = s_don_rec + this.count_don_rec_ingr - 1, s_op = f_don_rec + 1, f_op = s_op + this.count_otros_op_ingr - 1, s_suv_rec = f_op + 1, f_suv_rec = s_suv_rec + this.count_suv_net_ingr - 1
                worksheet = this._xlsxService.nombre_tabla(worksheet, comienzo_tabla - 1, tabla_header_length - 1, 'Ingresos')
                worksheet = this._xlsxService.centrar_datos(worksheet, { c: comienzo_tabla, f: comienzo_tabla }, { c: 0, f: tabla_header_length - 1 })
                worksheet = this._xlsxService.texto_en_negrita(worksheet, { c: comienzo_tabla, f: comienzo_tabla }, { c: 0, f: tabla_header_length - 1 })
                worksheet = this.count_diezmo_ingr > 0 ? this._xlsxService.juntar_celdas(worksheet, { c: 0, r: s_diezmo }, { c: 0, r: f_diezmo }) : worksheet
                worksheet = this.count_ofrendas_ingr > 0 ? this._xlsxService.juntar_celdas(worksheet, { c: 0, r: s_ofrendas }, { c: 0, r: f_ofrendas }) : worksheet
                worksheet = this.count_don_rec_ingr > 0 ? this._xlsxService.juntar_celdas(worksheet, { c: 0, r: s_don_rec }, { c: 0, r: f_don_rec }) : worksheet
                worksheet = this.count_otros_op_ingr > 0 ? this._xlsxService.juntar_celdas(worksheet, { c: 0, r: s_op }, { c: 0, r: f_op }) : worksheet
                worksheet = this.count_suv_net_ingr > 0 ? this._xlsxService.juntar_celdas(worksheet, { c: 0, r: s_suv_rec }, { c: 0, r: f_suv_rec }) : worksheet
                worksheet = this._xlsxService.ajustar_cabeceras(worksheet, { c: comienzo_tabla + 1, f: fin_tabla - 1 }, { c: 0, f: 0 })
                worksheet = this._xlsxService.bordes_gruesos(worksheet, { c: comienzo_tabla - 1, f: comienzo_tabla }, { c: 0, f: tabla_header_length - 1 })
                worksheet = this._xlsxService.formato_miles(worksheet, { c: comienzo_tabla + 1, f: fin_tabla + 1 }, { c: 3, f: tabla_header_length - 1 })
                worksheet = this._xlsxService.bordes_gruesos_derecha(worksheet, { c: comienzo_tabla + 1, f: fin_tabla - 1 }, { c: 0, f: tabla_header_length - 1 })
                worksheet = this._xlsxService.bordes_gruesos_menos_arriba(worksheet, { c: f_diezmo, f: f_diezmo }, { c: 1, f: tabla_header_length - 1 })
                worksheet = this._xlsxService.bordes_gruesos_menos_arriba(worksheet, { c: f_ofrendas, f: f_ofrendas }, { c: 1, f: tabla_header_length - 1 })
                worksheet = this._xlsxService.bordes_gruesos_menos_arriba(worksheet, { c: f_don_rec, f: f_don_rec }, { c: 1, f: tabla_header_length - 1 })
                worksheet = this._xlsxService.bordes_gruesos_menos_arriba(worksheet, { c: f_op, f: f_op }, { c: 1, f: tabla_header_length - 1 })
                worksheet = this._xlsxService.bordes_gruesos(worksheet, { c: comienzo_tabla + 1, f: fin_tabla - 1 }, { c: 0, f: 0 })
                worksheet = this._xlsxService.texto_en_negrita(worksheet, { c: comienzo_tabla + 1, f: fin_tabla - 1 }, { c: 0, f: 0 })
                worksheet = this._xlsxService.bordes_gruesos(worksheet, { c: fin_tabla, f: fin_tabla }, { c: 0, f: tabla_header_length - 1 })
                worksheet = this._xlsxService.texto_en_negrita(worksheet, { c: fin_tabla, f: fin_tabla }, { c: 0, f: tabla_header_length - 1 })
                worksheet = this._xlsxService.juntar_celdas(worksheet, { c: 0, r: fin_tabla }, { c: 2, r: fin_tabla })
                worksheet = this._xlsxService.centrar_datos(worksheet, { c: fin_tabla, f: fin_tabla }, { c: 0, f: 0 })
                nombre_cuenta = ', Ingresos, '
            }
            nombre_cuenta = presupuesto_completo == false ? nombre_cuenta : ', '
            const hoy = new Date();
            let dia = hoy.getDate();
            let mes = (hoy.getMonth() + 1).toString();
            let año = hoy.getFullYear();
            if (mes < '10') {
                mes = '0' + mes;
            }
            workbook.xlsx.writeBuffer().then((data) => {
                let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
                FileSaver.saveAs(blob, 'Presupuesto ' + year + ', Entidad ' + entidad + nombre_cuenta + dia + '-' + mes + '-' + año + EXCEL_EXTENSION)
                this.Toast.fire({
                    icon: 'success',
                    title: 'Reporte generado exitosamente'
                })
            })
        } catch (error) {
            console.error(error)
        }
    }

    convert_data_to_export(filas, cabeceras, footer, std, stnd, cuenta) {
        let data = {
            rows: filas,
            headers: cabeceras,
            footer: footer,
            std: std,
            stnd: stnd,
            cuenta: cuenta
        }
        let rows = this._presupuestoService.exportar_presupuesto(data)
        return rows

    }

    separador_miles(event: any) { // without type info
        event.target.value = event.target.value.toString()
            .replace(".", "")
            .replace(".", "")
            .replace(".", "")
            .replace(/(\d+)(\d{3})(\d{3})(\d{3})$/, "$1.$2.$3.$4")
            .replace(/(\d+)(\d{3})(\d{3})$/, "$1.$2.$3")
            .replace(/(\d+)(\d{3})$/, "$1.$2")
    }

    updateFilter(event, datos) {
        const val = event.target.value.toLowerCase()
        switch (datos) {
            case 'diverso':
                const tempDiverso = this.temp_lista_diverso.filter(function (d) {
                    let dataFilterer
                    if ((d.diverso.toString().indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.diverso.toString().indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.lista_diverso = tempDiverso;
                break;
            case 'prima':
                const tempPrima = this.temp_prim_inf.filter(function (d) {
                    let dataFilterer
                    if ((d.diverso.toString().indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre_func.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                            if ((d.nombre_hijo.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                            } else {
                                dataFilterer = d.nombre_hijo.toLowerCase().indexOf(val) !== -1 || !val
                            }
                        } else {
                            dataFilterer = d.nombre_func.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.diverso.toString().indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.prim_inf = tempPrima;
                break;
            case 'sueldosdep':
                const tempSueldoDep = this.temp_sueldo_dependiente.filter(function (d) {
                    let dataFilterer
                    if ((d.diverso.toString().indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                            if ((d.nombre_departamento.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                            } else {
                                dataFilterer = d.nombre_departamento.toLowerCase().indexOf(val) !== -1 || !val
                            }
                        } else {
                            dataFilterer = d.nombre.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.diverso.toString().indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.sueldo_dependiente = tempSueldoDep;
                break;
            case 'sueldosind':
                const tempSueldoInd = this.temp_sueldo_independiente.filter(function (d) {
                    let dataFilterer

                    if ((d.diverso.toString().indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                            if ((d.nombre_departamento.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                            } else {
                                dataFilterer = d.nombre_departamento.toLowerCase().indexOf(val) !== -1 || !val
                            }
                        } else {
                            dataFilterer = d.nombre.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.diverso.toString().indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.sueldo_independiente = tempSueldoInd;
                break;
            case 'viajes':
                const tempViajes = this.temp_viaje.filter(function (d) {
                    let dataFilterer
                    if ((d.diverso.toString().indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.diverso.toString().indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.viaje = tempViajes;
                break;
            case 'sueldosdeptot':
                const tempSuelDep = this.temp_411_dep.filter(function (d) {
                    let dataFilterer
                    if ((d.n_cuenta.toString().indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.n_cuenta.toString().indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.fila_411_dep = tempSuelDep;
                break;
            case 'sueldosindtot':
                const tempSuelInd = this.temp_411_ind.filter(function (d) {
                    let dataFilterer
                    if ((d.n_cuenta.toString().indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.n_cuenta.toString().indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.fila_411_ind = tempSuelInd;
                break;
            case 'gastosop':
                const tempGasOp = this.temp_412.filter(function (d) {
                    let dataFilterer
                    if ((d.n_cuenta.toString().indexOf(val) !== -1 || !val) == false) {
                        if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.fila_412 = tempGasOp;
                break;
            case 'eventos':
                const tempEventos = this.temp_414.filter(function (d) {
                    let dataFilterer
                    if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.fila_414 = tempEventos;
                break;
            case 'otorgamientos':
                const tempOtorg = this.temp_419.filter(function (d) {
                    let dataFilterer
                    if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.fila_419 = tempOtorg;
                break;
            case 'ingresos':
                const ingDiezmo = this.temp_ingDiezmo.filter(function (d) {
                    let dataFilterer
                    if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                const ingOfrendas = this.temp_ingOfrendas.filter(function (d) {
                    let dataFilterer
                    if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                const ingDonaciones = this.temp_ingDonaciones.filter(function (d) {
                    let dataFilterer
                    if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                const ingOtrOper = this.temp_ingOtrOpe.filter(function (d) {
                    let dataFilterer
                    if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                const ingSubRec = this.temp_ingSubRec.filter(function (d) {
                    let dataFilterer
                    if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
                        if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
                        } else {
                            dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
                        }
                    } else {
                        dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
                    }
                    return dataFilterer;
                })
                this.diezmo_ingr = ingDiezmo;
                this.ofrendas_ingr = ingOfrendas;
                this.don_rec_ingr = ingDonaciones;
                this.otros_op_ingr = ingOtrOper;
                this.suv_net_ingr = ingSubRec;
                break;
        }
    }
}


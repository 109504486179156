<ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="nabTab" (navChange)="onNavChange($event)">
    <li ngbNavItem [ngbNavItem]="'variables'">
        <a ngbNavLink (click)="tipo_presupuesto('variables')">Variables</a>
        <ng-template ngbNavContent>
            <!--Pestaña Variables-->
            <div class="btn-group">
                <button class="btn btn-white" [class.active]="tipo_pres =='diversos' || tipo_pestana == 'variables'"
                    (click)="tipo_presupuesto('diversos')">Diversos</button>
                <button class="btn btn-white" [class.active]="tipo_pres =='departamentos'"
                    (click)="tipo_presupuesto('departamentos')">Departamentos</button>
            </div>
            <!--Comienzo Diversos-->
            <div *ngIf="tipo_pres == 'diversos' || tipo_pestana == 'variables'">
                <div class="d-flex align-content-center justify-content-between mb-3 mt-2">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por diverso, nombre..."
                            (keyup)="updateFilter($event, 'diverso')" />
                    </div>
                    <div>
                        <a id='abrir_modal_diverso' class="btn btn-success text-white" (click)="open(modal_diverso)">
                            Agregar Diverso
                        </a>
                    </div>
                </div>
                <ng-container outsideBody>
                    <hr class="m-0" />
                    <div class="table-responsive text-nowrap table-data">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Diverso</th>
                                    <th>Nombre Funcionario</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="show_diversos">
                                <tr *ngFor="let d of lista_diverso; let indice = index">
                                    <td [ngClass]="{'table-danger':d.estado == 'inactive'}">{{d.diverso}}</td>
                                    <td [ngClass]="{'table-danger':d.estado == 'inactive'}">{{d.nombre}}</td>
                                    <td class="with-btn-group" [ngClass]="{'table-danger':d.estado == 'inactive'}">
                                        <div class="d-flex justify-content-start">
                                            <button type="button" *ngIf="d.estado == 'active'"
                                                (click)='modificar_diverso_modal(d._id, d.diverso, d.nombre, modal_modificar_diverso)'
                                                class="btn btn-warning btn-xs m-r-5 " placement="top"
                                                ngbTooltip="Editar"><i class="fas fa-edit"></i></button>
                                            <button type="button" *ngIf="d.estado == 'active'"
                                                (click)='modificar_estado_diverso(d._id,"inactive")'
                                                class="btn btn-danger btn-xs m-r-5" placement="top"
                                                ngbTooltip="Desactivar"><i class="fas fa-ban"></i></button>
                                            <button type="button" *ngIf="d.estado == 'inactive'"
                                                (click)='modificar_estado_diverso(d._id,"active")'
                                                class="btn btn-green btn-xs m-r-5" placement="top"
                                                ngbTooltip="Activar"><i class="fas fa-check"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="show_diversos">
                                <tr>
                                    <th colspan="3">{{lista_diverso.length}} en Total</th>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="m-l-10 mb-2" *ngIf="!show_diversos">
                            <em>No se han encontrado datos para mostrar</em>
                        </div>
                    </div>
                </ng-container>
                <!-- </div> -->
                <!-- Modal diverso -->
                <ng-template #modal_diverso let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Agregar Diverso</h5>
                        <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                            data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="panel-body panel-form">
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">N° Diverso</label>
                                <div class="col-md-8">
                                    <input id="n_div" type="text" #diverso="ngModel" name="diverso"
                                        [(ngModel)]="diversos.diverso" class="form-control" required />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nombre Funcionario</label>
                                <div class="col-md-8">
                                    <input id="nom_div" type="text" #nombre="ngModel" name="nombre"
                                        [(ngModel)]="diversos.nombre" class="form-control" required />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" (click)="close('modal_diverso')">Cerrar</button>
                        <button type="button" class="btn btn-primary"
                            (click)="agregar_diverso(modal_diverso)">Guardar</button>
                    </div>
                </ng-template>
                <!-- Fin Modal Diverso-->
                <!-- Modal diverso -->
                <ng-template #modal_modificar_diverso let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Modificar Diverso</h5>
                        <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                            data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="panel-body panel-form">
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">N° Diverso</label>
                                <div class="col-md-8">
                                    <input id="numero_div" type="text" #diverso="ngModel" name="diverso"
                                        [(ngModel)]="diversos.diverso" class="form-control" required />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nombre Funcionario</label>
                                <div class="col-md-8">
                                    <input id="nombre_div" type="text" #nombre="ngModel" name="nombre"
                                        [(ngModel)]="diversos.nombre" class="form-control" required />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            (click)="close('modal_modificar_diverso')">Cerrar</button>
                        <button type="button" class="btn btn-primary"
                            (click)="modificar_diverso(modal_modificar_diverso)">Guardar</button>
                    </div>
                </ng-template>
                <!-- Fin Modal Diverso-->
                <div class="d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary" (click)="tipo_presupuesto('departamentos')">Siguiente
                        <i class="fas fa-arrow-right fa-fw"></i></button>
                </div>
            </div>
            <!--Fin Diversos-->
            <!--Comienzo Departamentos-->
            <div class="col-md-12" *ngIf="tipo_pres == 'departamentos'">
                <div class="d-flex align-content-center justify-content-between mb-3 mt-2">
                    <div class="form-group row m-b-10">
                        <label class="col-form-label"><strong>Editar tabla</strong></label>
                        <div class="switcher">
                            <input type="checkbox" id="switcher_departamentos" data-theme="red"
                                ng-checked="switcher_departamentos_tabla" (click)="evento_switcher($event)">
                            <label for="switcher_departamentos"></label>
                        </div>
                    </div>
                    <div>
                        <a class="btn btn-success text-white" (click)="open(modal_departamentos_cursos)">
                            Agregar Colegio
                        </a>
                        <a class="btn btn-success text-white m-l-10" (click)="open(modal_subvencion)">
                            Agregar Subvención
                        </a>
                    </div>
                </div>
                <div class="table-responsive table-colegios">
                    <table *ngIf="colegios_departamentos" class="table table-bordered ">
                        <thead>
                            <tr>
                                <th style='border-left:none;border-top:none'>
                                    <!--Asignación Departamentos-->
                                </th>
                                <th *ngIf="cabeceras_departamentos.length >= 1"
                                    [class.bg-light]="switcher_departamentos_tabla == true" class="text-center"
                                    [attr.colspan]="cabeceras_departamentos.length">
                                    GRUPO DE DEPARTAMENTOS</th>
                                <th *ngIf="switcher_departamentos_tabla == true"
                                    style="width: 100px;border-right:none;border-left:none;border-bottom:none;border-top:none">
                                </th>
                            </tr>
                            <tr class="text-center table-valign-middle">
                                <th class="colegios" [class.bg-light]="switcher_departamentos_tabla == true">
                                    Nombre
                                    Departamento</th>
                                <!-- <th class="colegios">Nivel</th> -->
                                <th class="clases-departamentos"
                                    *ngFor="let cd of cabeceras_departamentos;let indice=index ">
                                    <div *ngIf="switcher_departamentos_tabla == false">
                                        {{cd}}
                                    </div>
                                    <div *ngIf="switcher_departamentos_tabla == true"
                                        class="form-row align-items-center">
                                        <div class="col-10">
                                            <!-- asdasdsad -->
                                            <input class="form-control" type="text" #subvenciones [value]="cd"
                                                (keyup.enter)='modificar_departamentos_colegios(cd._id,"subvenciones",subvenciones.value,indice)'
                                                (blur)='modificar_departamentos_colegios(cd._id,"subvenciones",subvenciones.value,indice)'>
                                        </div>
                                        <div class="col-2 ">
                                            <!-- asdasdasdsad -->
                                            <a href="javascript:;" class="btn btn-xs btn-danger" placement="top"
                                                ngbTooltip="Eliminar Grupo"
                                                (click)='eliminar_departamentos_colegios(indice,"Subvencion")'>
                                                <i class="fa fa-trash-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </th>
                                <th *ngIf="switcher_departamentos_tabla == true"
                                    style="width: 100px;border-right:none;border-left:none;border-bottom:none;border-top:none">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cd of colegios_departamentos;let indice=index">
                                <td [class.bg-light]="switcher_departamentos_tabla == false">
                                    <div *ngIf="switcher_departamentos_tabla == false">
                                        {{cd.nombre_colegio}}
                                    </div>
                                    <div *ngIf="switcher_departamentos_tabla == true">
                                        <input class="form-control form-control-sm" type="text" #nombre_colegio
                                            [value]="cd.nombre_colegio"
                                            (keyup.enter)='modificar_departamentos_colegios(cd._id,"nombre_colegio",nombre_colegio.value,"-")'
                                            (blur)='modificar_departamentos_colegios(cd._id,"nombre_colegio",nombre_colegio.value,"-")'>
                                    </div>
                                </td>
                                <!-- <td class="bg-light">{{cd.nivel}}</td> -->
                                <td *ngFor="let depto of cd.tipo_subvencion;let ind=index ">
                                    <input class="form-control form-control-sm" type="text" #departamento
                                        [value]="depto.departamento"
                                        (keyup.enter)='modificar_departamentos_colegios(cd._id,"codigo_departamento",departamento.value,ind)'
                                        (blur)='modificar_departamentos_colegios(cd._id,"codigo_departamento",departamento.value,ind)'>
                                </td>
                                <td style='border-right:none;border-left:none;border-bottom:none;border-top:none'
                                    class="m-r-10 text-center">
                                    <div *ngIf="switcher_departamentos_tabla == true" class="pull-left m-l-15">
                                        <a href="javascript:;" class="btn btn-xs btn-danger" placement="top"
                                            ngbTooltip="Eliminar Depto"
                                            (click)='eliminar_departamentos_colegios(cd._id,"Colegio")'>
                                            <i class="fa fa-trash-alt"></i>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="m-l-10 mt-2 mb-3" *ngIf="!colegios_departamentos">
                        <em>No se han encontrado datos para mostrar. Por favor agregue un nuevo colegio y
                            subvención</em>
                    </div>
                </div>
                <!-- Modal Colegios -->
                <ng-template #modal_departamentos_cursos let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Agregar Departamento</h5>
                        <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                            data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="panel-body panel-form">
                            <form class="form-horizontal form-bordered">
                                <div class="form-group row">
                                    <label class="col-md-4 col-form-label">Nombre Departamento</label>
                                    <div class="col-md-8">
                                        <input id="nombre_col" type="text" class="form-control" #nombre="ngModel"
                                            name="nombre" [(ngModel)]="colegios.nombre" required />
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nivel Colegio</label>
                                <div class="col-md-8">
                                    <input id="nivel_col" type="text" class="form-control" #nivel="ngModel"
                                        name="nivel" [(ngModel)]="colegios.nivel" required />
                                </div>
                            </div> -->
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            (click)="close(modal_departamentos_cursos)">Cerrar</button>
                        <button type="button" (click)="agregar_colegio(modal_departamentos_cursos)"
                            class="btn btn-primary">Guardar</button>
                    </div>
                </ng-template>
                <!-- Fin Modal Colegios-->
                <!-- Modal Grupo Departamento -->
                <ng-template #modal_subvencion let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Agregar Grupo Departamento</h5>
                        <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                            data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="panel-body panel-form">
                            <form class="form-horizontal form-bordered">
                                <div class="form-group row">
                                    <label class="col-md-4 col-form-label">Nombre grupo departamento</label>
                                    <div class="col-md-8">
                                        <input id="subvencion" type="text" class="form-control" #nombre value=""
                                            required />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            (click)="close(modal_subvencion)">Cerrar</button>
                        <button type="button" (click)="agregar_tipo_subvencion(modal_subvencion, nombre.value)"
                            class="btn btn-primary">Guardar</button>
                    </div>
                </ng-template>
                <!-- Fin Modal Grupo Departamento-->
                <div class="d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary" (click)="siguiente('variables')">Siguiente
                        <i class="fas fa-arrow-right fa-fw"></i></button>
                </div>
            </div>
            <!--Fin Prima Departamentos-->
            <!--Fin Pestaña Variables-->
        </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="'cuentas'">
        <a ngbNavLink (click)="tipo_presupuesto('cuentas')">Cuentas</a>
        <ng-template ngbNavContent>
            <!--Pestaña Cuentas-->
            <div class="col-md-12 mb-2">
                <div class="btn-group">
                    <button class="btn btn-white" [class.active]="tipo_pres =='ingresos' || tipo_pestana == 'cuentas'"
                        (click)="tipo_presupuesto('ingresos')">Ingresos</button>
                    <button class="btn btn-white" [class.active]="tipo_pres =='tab_sueldos'"
                        (click)="tipo_presupuesto('tab_sueldos')">Digitación Sueldos</button>
                    <button class="btn btn-white" [class.active]="tipo_pres =='sueldos' "
                        (click)="tipo_presupuesto('sueldos')">Tabulación Sueldos (411)</button>
                    <button class="btn btn-white" [class.active]="tipo_pres =='gastos_operativos'"
                        (click)="tipo_presupuesto('gastos_operativos')">Gastos Operativos (412)</button>
                    <button class="btn btn-white" [class.active]="tipo_pres =='reuniones_eventos'"
                        (click)="tipo_presupuesto('reuniones_eventos')">Gastos Reuniones y Eventos (414)</button>
                    <button class="btn btn-white" [class.active]="tipo_pres =='otorgamientos'"
                        (click)="tipo_presupuesto('otorgamientos')">Otorgamientos (419)</button>
                </div>
            </div>
            <!--Comienzo Tablas-->
            <div *ngIf="tipo_pres == 'tab_sueldos'" id="sueldos">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Digitación de sueldos</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por N° cuenta, nombre cuenta..."
                            (keyup)="updateFilter($event, 'sueldos')" />
                    </div>
                    <div>
                        <a class="btn btn-success m-r-10 text-white" (click)="open(modal_agregar_sueldo)">Agregar
                            Sueldo</a>
                        <a class="btn btn-success text-white" (click)="open(modal_agregar_cuenta_sueldos)">Agregar
                            Cuenta</a>
                    </div>
                </div>
                <!-- begin table-responsive -->
                <hr class="m-0" />
                <div class="table-responsive table-sueldos">
                    <table *ngIf="show_sueldos" class="table">
                        <thead>
                            <tr>
                                <th>Diverso</th>
                                <th class="text-nowrap">Nombre Empleado</th>
                                <th>Departamento</th>
                                <th class="text-nowrap">Nombre Departamento</th>
                                <th *ngFor="let c_cuentas of columna_cuentas" class="text-center">
                                    <div>
                                        {{ c_cuentas.nombre_cuenta }}
                                    </div>
                                    <div>
                                        {{ c_cuentas.cuenta_contable }}
                                    </div>
                                </th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="align-middle" *ngFor="let s of sueldos">
                                <td class="bg-light align-middle">{{s.diverso}}
                                </td>
                                <td class="bg-light align-middle text-nowrap">{{s.nombre}}</td>
                                <td class="align-middle">
                                    <input class="form-control form-control-sm" type="text"
                                        [value]="s.codigo_departamento" #codigo_departamento
                                        (keyup.enter)='modificar_sueldo(s._id,codigo_departamento.value,"Codigo Departamento","")'
                                        (blur)='modificar_sueldo(s._id,codigo_departamento.value,"Codigo Departamento","")'>
                                </td>
                                <td class="bg-light align-middle text-nowrap">{{s.nombre_departamento}}</td>
                                <td *ngFor="let cuentas of s.cuentas; let i=index" class="align-middle">
                                    <input class="form-control form-control-sm mw-150" type="text"
                                        [value]="cuentas.valor | thousandsPipe" #valor (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_sueldo(s._id,valor.value,"Cuenta",i)'
                                        (blur)='modificar_sueldo(s._id,valor.value,"Cuenta",i)'>
                                </td>
                                <td class="bg-light align-middle">${{s.total |number:0}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th class="text-center">Sub Totales</th>
                                <th>-</th>
                                <th>-</th>
                                <th *ngFor="let sub_cuentas of sub_total_cuentas">
                                    ${{ sub_cuentas | number:0}}
                                </th>
                                <th>${{ sub_total_sueldo | number:0}}</th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="m-l-10 mt-2 mb-3" *ngIf="!show_sueldos">
                        <em>No se han encontrado datos para mostrar. Por favor agregue una nueva fila</em>
                    </div>
                </div>
                <!-- end table-responsive -->
                <!-- Modal agregar cuenta -->
                <ng-template #modal_agregar_cuenta_sueldos let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title">Agregar Cuenta</h4>
                        <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                            data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="panel-body panel-form">
                        <form class="form-horizontal form-bordered">
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">N° de la Cuenta</label>
                                <div class="col-md-8">
                                    <input id="n_cuenta_sueldo" class="form-control" type="text" #n_cuenta
                                        #valor="ngModel" name="numero_cuenta" [(ngModel)]="cuenta_sueldo.numero_cuenta"
                                        (blur)='get_nombre_cuenta_sueldo()' />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nombre de la Cuenta</label>
                                <div class="col-md-8">
                                    <input id="nombre_cuenta_sueldo" class="form-control"
                                        value="cuenta_sueldo.nombre_cuenta" type="text" #valor="ngModel"
                                        name="nombre_cuenta" [(ngModel)]="cuenta_sueldo.nombre_cuenta" />
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <a href="javascript:;" class="btn btn-white" (click)="c('Close click')">Cerrar</a>
                        <a href="javascript:;" (click)="agregar_cuenta_sueldos(modal_agregar_cuenta_sueldos)"
                            class="btn btn-success">Guardar</a>
                    </div>
                </ng-template>
                <!-- Modal agregar sueldo -->
                <ng-template #modal_agregar_sueldo let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title">Agregar Sueldo</h4>
                        <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                            data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="panel-body panel-form">
                        <form class="form-horizontal form-bordered">
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">N° Diverso</label>
                                <div class="col-md-8">
                                    <input id="sueldo_diverso" class="form-control" type="text" #diverso
                                        #valor="ngModel" name="diverso" [(ngModel)]="sueldo.diverso"
                                        (blur)="get_datos_sueldo('diverso')" required />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nombre Funcionario</label>
                                <div class="col-md-8">{{sueldo.nombre}}</div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Departamento</label>
                                <div class="col-md-8">
                                    <input id="sueldo_departamento" class="form-control"
                                        value="sueldo.codigo_departamento" type="text" #codigo_departamento
                                        #valor="ngModel" name="codigo_departamento"
                                        [(ngModel)]="sueldo.codigo_departamento"
                                        (blur)="get_datos_sueldo('departamento')" required />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nombre Departamento</label>
                                <div class="col-md-8">{{sueldo.nombre_departamento}}</div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <a href="javascript:;" class="btn btn-white" (click)="c('Close click')">Cerrar</a>
                        <a href="javascript:;" (click)="agregar_sueldo(modal_agregar_sueldo)"
                            class="btn btn-success">Guardar</a>
                    </div>
                </ng-template>
                <!-- Fin #modal Modificar Sueldo Dependiente -->
            </div>
            <!--Fin Tablas-->
            <!-- end panel -->
            <!-- end panel -->
            <!-- Pestaña Cuenta 411-->
            <div *ngIf="tipo_pres == 'sueldos'" id="viajes">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Sueldos</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por N° cuenta, nombre cuenta..."
                            (keyup)="updateFilter($event, 'tabla_sueldos')" />
                    </div>
                </div>
                <!-----------Tabla Sueldos Dependientes-------------->
                <!-- begin table-responsive -->
                <hr class="m-0" />
                <div class="table-responsive cuentas_educacion">
                    <table *ngIf="show_tabla_sueldo" class="table">
                        <thead>
                            <tr class="first text-center">
                                <th></th>
                                <th></th>
                                <th [attr.colspan]="length_totales_tabla_sueldo">
                                    TOTALES GRUPOS DEPARTAMENTOS</th>
                                <th *ngFor="let d of get_header_colegios_cuentas(departamentos_tabla_sueldo)"
                                    [attr.colspan]="d.colspan">
                                    {{d.n_colegio}}
                                </th>
                            </tr>
                            <tr class="second">
                                <th>N° Cuenta/Div</th>
                                <th>Nombre Cuenta</th>
                                <th *ngFor="let t of totales_tabla_sueldo">
                                    {{t.subvencion}}
                                </th>
                                <th *ngFor="let s of departamentos_tabla_sueldo_header" class="text-center">
                                    <div>{{s.nombre_departamento}}</div>
                                    <div>{{s.departamento}}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ingr of tabla_sueldo">
                                <td class="bg-light">
                                    {{ingr.n_cuenta}}

                                </td>
                                <td class="bg-light">
                                    {{ingr.nombre_cuenta}}

                                </td>
                                <td class="align-middle bg-light" *ngFor="let t of ingr.totales">
                                    ${{t.valor |number:0}}
                                </td>
                                <td *ngFor="let s of get_departamentos_cuentas(ingr.departamentos)">
                                    ${{s.valor |number:0}}

                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th class="text-center">
                                    Totales
                                </th>
                                <th *ngFor="let st of sub_totales_tabla_sueldo">
                                    ${{st| number:0}}
                                </th>
                                <th *ngFor="let std of sub_totales_depto_tabla_sueldo">
                                    ${{std.valor | number:0}}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="m-l-10 mt-2 mb-3" *ngIf="!show_tabla_sueldo">
                        <em>No se han encontrado datos para mostrar. Por favor agregue una nueva fila</em>
                    </div>
                </div>
                <!-- end table-responsive -->
                <!-----------Tabla Sueldos Dependientes-------------->
            </div>
            <!-- Fin Pestaña Cuenta 411-->
            <!-- Pestaña Cuenta 412-->
            <div *ngIf="tipo_pres == 'gastos_operativos'">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Gastos operativos</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por N° cuenta, nombre cuenta..."
                            (keyup)="updateFilter($event, 'gastosop')" />
                    </div>
                    <div>
                        <a href="javascript:;" id="abrir_modal_ingreso" (click)="insertar_cuentas('gastos_operativos')"
                            class="btn btn-success">Nueva fila</a>
                    </div>
                </div>
                <!-- begin table-responsive -->
                <hr class="m-0" />
                <div class="table-responsive cuentas_educacion">
                    <table *ngIf="show_gastos_operativos" class="table">
                        <thead>
                            <tr class="first text-center">
                                <th></th>
                                <th></th>
                                <th [attr.colspan]="length_totales_gastos_operativos">
                                    TOTALES GRUPOS DEPARTAMENTOS</th>
                                <th *ngFor="let d of get_header_colegios_cuentas(departamentos_gastos_operativos)"
                                    [attr.colspan]="d.colspan">
                                    {{d.n_colegio}}
                                </th>
                            </tr>
                            <tr class="second">
                                <th>N° Cuenta/Div</th>
                                <th class="text-nowrap">Nombre Cuenta</th>
                                <th *ngFor="let t of totales_gastos_operativos">
                                    {{t.subvencion}}
                                </th>
                                <th *ngFor="let s of departamentos_gastos_operativos_header" class="text-center">
                                    <div>{{s.nombre_departamento}}</div>
                                    <div>{{s.departamento}}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ingr of gastos_operativos">
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" #n_cuenta
                                        [value]="ingr.n_cuenta"
                                        (keyup.enter)='modificar_cuentas("gastos_operativos","Cuenta",n_cuenta.value,ingr._id,"","")'
                                        (blur)='modificar_cuentas("gastos_operativos","Cuenta",n_cuenta.value,ingr._id,"","")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" #nombre_cuenta
                                        [value]="ingr.nombre_cuenta"
                                        (keyup.enter)='modificar_cuentas("gastos_operativos","Nombre",nombre_cuenta.value,ingr._id,"","")'
                                        (blur)='modificar_cuentas("gastos_operativos","Nombre",nombre_cuenta.value,ingr._id,"","")'>
                                </td>
                                <td class="align-middle bg-light" *ngFor="let t of ingr.totales">
                                    ${{t.valor |number:0}}
                                </td>
                                <td *ngFor="let s of get_departamentos_cuentas(ingr.departamentos)">
                                    <input class="form-control form-control-sm mw-150" type="text" #depto
                                        [value]="s.valor | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_cuentas("gastos_operativos","Subvencion",depto.value,ingr._id,s.index_colegio,s.index_subvencion)'
                                        (blur)='modificar_cuentas("gastos_operativos","Subvencion",depto.value,ingr._id,s.index_colegio,s.index_subvencion)'>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th class="text-center">
                                    Totales
                                </th>
                                <th *ngFor="let st of sub_totales_gastos_operativos">
                                    ${{st| number:0}}
                                </th>
                                <th *ngFor="let std of sub_totales_depto_gastos_operativos">
                                    ${{std.valor | number:0}}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="m-l-10 mt-2 mb-3" *ngIf="!show_gastos_operativos">
                        <em>No se han encontrado datos para mostrar. Por favor agregue una nueva fila</em>
                    </div>
                </div>
            </div>
            <!-- Fin Pestaña Cuenta 412 -->
            <!-- Pestaña Cuenta 414-->
            <div *ngIf="tipo_pres == 'reuniones_eventos'" id="viajes">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Reuniones y eventos</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por N° cuenta, nombre cuenta..."
                            (keyup)="updateFilter($event, 'eventos')" />
                    </div>
                    <div>
                        <a href="javascript:;" id="abrir_modal_ingreso" (click)="insertar_cuentas('reuniones_eventos')"
                            class="btn btn-success">Nueva fila</a>
                    </div>
                </div>
                <!-- begin table-responsive -->
                <hr class="m-0" />
                <div class="table-responsive cuentas_educacion">
                    <table *ngIf="show_eventos" class="table">
                        <thead>
                            <tr class="first text-center">
                                <th></th>
                                <th></th>
                                <th [attr.colspan]="length_totales_eventos">
                                    TOTALES GRUPOS DEPARTAMENTOS</th>
                                <th *ngFor="let d of get_header_colegios_cuentas(departamentos_eventos)"
                                    [attr.colspan]="d.colspan">
                                    {{d.n_colegio}}
                                </th>
                            </tr>
                            <tr class="second">
                                <th>N° Cuenta/Div</th>
                                <th>Nombre Cuenta</th>
                                <th *ngFor="let t of totales_eventos">
                                    {{t.subvencion}}
                                </th>
                                <th *ngFor="let s of departamentos_eventos_header" class="text-center">
                                    <div>{{s.nombre_departamento}}</div>
                                    <div>{{s.departamento}}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ingr of eventos">
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" #n_cuenta
                                        [value]="ingr.n_cuenta"
                                        (keyup.enter)='modificar_cuentas("reuniones_eventos","Cuenta",n_cuenta.value,ingr._id,"","")'
                                        (blur)='modificar_cuentas("reuniones_eventos","Cuenta",n_cuenta.value,ingr._id,"","")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" #nombre_cuenta
                                        [value]="ingr.nombre_cuenta"
                                        (keyup.enter)='modificar_cuentas("reuniones_eventos","Nombre",nombre_cuenta.value,ingr._id,"","")'
                                        (blur)='modificar_cuentas("reuniones_eventos","Nombre",nombre_cuenta.value,ingr._id,"","")'>
                                </td>
                                <td class="align-middle bg-light" *ngFor="let t of ingr.totales">
                                    ${{t.valor |number:0}}
                                </td>
                                <td *ngFor="let s of get_departamentos_cuentas(ingr.departamentos)">
                                    <input class="form-control form-control-sm mw-150" type="text" #depto
                                        [value]="s.valor | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_cuentas("reuniones_eventos","Subvencion",depto.value,ingr._id,s.index_colegio,s.index_subvencion)'
                                        (blur)='modificar_cuentas("reuniones_eventos","Subvencion",depto.value,ingr._id,s.index_colegio,s.index_subvencion)'>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th class="text-center">
                                    Totales
                                </th>
                                <th *ngFor="let st of sub_totales_eventos">
                                    ${{st| number:0}}
                                </th>
                                <th *ngFor="let std of sub_totales_depto_eventos">
                                    ${{std.valor | number:0}}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="m-l-10 mt-2 mb-3" *ngIf="!show_eventos">
                        <em>No se han encontrado datos para mostrar. Por favor agregue una nueva fila</em>
                    </div>
                </div>
            </div>
            <!-- Fin Pestaña Cuenta 414-->
            <!-- Pestaña Cuenta 419 -->
            <div *ngIf="tipo_pres == 'otorgamientos'" id="viajes">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Otorgamientos</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por N° cuenta, nombre cuenta..."
                            (keyup)="updateFilter($event, 'otorgamientos')" />
                    </div>
                    <div>
                        <a href="javascript:;" id="abrir_modal_ingreso" (click)="insertar_cuentas('otorgamientos')"
                            class="btn btn-success">Nueva fila</a>
                    </div>
                </div>
                <!-- begin table-responsive -->
                <hr class="m-0" />
                <div class="table-responsive cuentas_educacion">
                    <table *ngIf="show_otorgamientos" class="table">
                        <thead>
                            <tr class="first text-center">
                                <th></th>
                                <th></th>
                                <th [attr.colspan]="length_totales_otorgamientos" class="text-center">
                                    TOTALES GRUPOS DEPARTAMENTOS</th>
                                <th *ngFor="let d of get_header_colegios_cuentas(departamentos_otorgamientos)"
                                    [attr.colspan]="d.colspan">
                                    {{d.n_colegio}}
                                </th>
                            </tr>
                            <tr class="second">
                                <th>N° Cuenta/Div</th>
                                <th>Nombre Cuenta</th>
                                <th *ngFor="let t of totales_otorgamientos">
                                    {{t.subvencion}}
                                </th>
                                <th *ngFor="let s of departamentos_otorgamientos_header" class="text-center">
                                    <div>{{s.nombre_departamento}}</div>
                                    <div>{{s.departamento}}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ingr of otorgamientos">
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" #n_cuenta
                                        [value]="ingr.n_cuenta"
                                        (keyup.enter)='modificar_cuentas("otorgamientos","Cuenta",n_cuenta.value,ingr._id,"","")'
                                        (blur)='modificar_cuentas("otorgamientos","Cuenta",n_cuenta.value,ingr._id,"","")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" #nombre_cuenta
                                        [value]="ingr.nombre_cuenta"
                                        (keyup.enter)='modificar_cuentas("otorgamientos","Nombre",nombre_cuenta.value,ingr._id,"","")'
                                        (blur)='modificar_cuentas("otorgamientos","Nombre",nombre_cuenta.value,ingr._id,"","")'>
                                </td>
                                <td class="align-middle bg-light" *ngFor="let t of ingr.totales">
                                    ${{t.valor |number:0}}
                                </td>
                                <td *ngFor="let s of get_departamentos_cuentas(ingr.departamentos)">
                                    <input class="form-control form-control-sm mw-150" type="text" #depto
                                        [value]="s.valor | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_cuentas("otorgamientos","Subvencion",depto.value,ingr._id,s.index_colegio,s.index_subvencion)'
                                        (blur)='modificar_cuentas("otorgamientos","Subvencion",depto.value,ingr._id,s.index_colegio,s.index_subvencion)'>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th class="text-center">
                                    Totales
                                </th>
                                <th *ngFor="let st of sub_totales_otorgamientos">
                                    ${{st| number:0}}
                                </th>
                                <th *ngFor="let std of sub_totales_depto_otorgamientos">
                                    ${{std.valor | number:0}}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="m-l-10 mt-2 mb-3" *ngIf="!show_otorgamientos">
                        <em>No se han encontrado datos para mostrar. Por favor agregue una nueva fila</em>
                    </div>
                </div>
            </div>
            <!-- Fin Pestaña Cuenta 419 -->
            <!-- Pestaña Ingresos -->
            <div *ngIf="tipo_pres == 'ingresos'  || tipo_pestana == 'cuentas'" id="viajes">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Ingresos</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por N° cuenta, nombre cuenta..."
                            (keyup)="updateFilter($event, 'ingresos')" />
                    </div>
                    <div>
                        <a href="javascript:;" id="abrir_modal_ingreso" (click)="insertar_cuentas('ingresos')"
                            class="btn btn-success">Nueva fila</a>
                    </div>
                </div>
                <!-- begin table-responsive -->
                <hr class="m-0" />
                <div class="table-responsive cuentas_educacion">
                    <table *ngIf="show_ingresos" class="table">
                        <thead>
                            <tr class="first text-center">
                                <th></th>
                                <th></th>
                                <th [attr.colspan]="length_totales_ingresos">
                                    TOTALES GRUPOS DEPARTAMENTOS</th>
                                <th *ngFor="let d of get_header_colegios_cuentas(departamentos_ingresos)"
                                    [attr.colspan]="d.colspan">
                                    {{d.n_colegio}}
                                </th>
                            </tr>
                            <tr class="second">
                                <th>N° Cuenta/Div</th>
                                <th>Nombre Cuenta</th>
                                <th class="text-nowrap" *ngFor="let t of totales_ingresos">
                                    {{t.subvencion}}
                                </th>
                                <th *ngFor="let s of departamentos_ingresos_header" class="text-center">
                                    <div>{{s.nombre_departamento}}</div>
                                    <div>{{s.departamento}}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="show_ingresos">
                            <tr *ngFor="let ingr of ingresos">
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" #n_cuenta
                                        [value]="ingr.n_cuenta"
                                        (keyup.enter)='modificar_cuentas("ingresos","Cuenta",n_cuenta.value,ingr._id,"","")'
                                        (blur)='modificar_cuentas("ingresos","Cuenta",n_cuenta.value,ingr._id,"","")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" #nombre_cuenta
                                        [value]="ingr.nombre_cuenta"
                                        (keyup.enter)='modificar_cuentas("ingresos","Nombre",nombre_cuenta.value,ingr._id,"","")'
                                        (blur)='modificar_cuentas("ingresos","Nombre",nombre_cuenta.value,ingr._id,"","")'>
                                </td>
                                <td class="align-middle bg-light" *ngFor="let t of ingr.totales">
                                    ${{t.valor |number:0}}
                                </td>
                                <td *ngFor="let s of get_departamentos_cuentas(ingr.departamentos)">
                                    <input class="form-control form-control-sm mw-150" type="text" #depto
                                        [value]="s.valor | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_cuentas("ingresos","Subvencion",depto.value,ingr._id,s.index_colegio,s.index_subvencion)'
                                        (blur)='modificar_cuentas("ingresos","Subvencion",depto.value,ingr._id,s.index_colegio,s.index_subvencion)'>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="show_ingresos">
                            <tr>
                                <th></th>
                                <th class="text-center">
                                    Totales
                                </th>
                                <th *ngFor="let st of sub_totales_ingresos">
                                    ${{st| number:0}}
                                </th>
                                <th *ngFor="let std of sub_totales_depto_ingresos">
                                    ${{std.valor | number:0}}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="m-l-10 mt-2 mb-3" *ngIf="!show_ingresos">
                        <em>No se han encontrado datos para mostrar. Por favor agregue una nueva fila</em>
                    </div>
                </div>
            </div>
            <!-- Fin Pestaña Ingresos -->
            <div *ngIf="tipo_pres == 'otorgamientos' " class="m-t-10 m-b-10 d-flex justify-content-center">
                <button type="submit" class="btn btn-primary" (click)="siguiente('cuentas')">Siguiente <i
                        class="fas fa-arrow-right fa-fw"></i></button>
            </div>
            <!--Fin Tablas-->
            <!--Fin Pestaña Cuentas-->
        </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="'resumenes'">
        <a ngbNavLink (click)="tipo_presupuesto('resumenes')">Votar A</a>
        <ng-template ngbNavContent>
            <!--Pestaña Resumenes-->
            <!-- Comienzo Pestaña Votar A -->
            <div *ngIf="tipo_pestana == 'resumenes'">
                <h1 class="mb-4 mt-4 text-center">Votar A</h1>
                <!-- begin table-responsive -->
                <div class="table-responsive ">
                    <div>
                        <table class="table table-bordered" id="">
                            <thead>
                                <tr>
                                    <th class="bg-light"></th>
                                    <th class="bg-light text-center" *ngFor="let r of colegios_votar_a">
                                        {{r}}
                                    </th>
                                    <th class="bg-light">Totales</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="align-middle text-nowrap">
                                        Totales Ingresos
                                    </td>
                                    <td *ngFor="let i of ingresos_votar_a" class="align-middle">
                                        ${{i | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        ${{total_ingresos_votar_a | number:0}}
                                    </td>
                                    <td class="bg-light align-middle text-center" style="width: 100px;">
                                        Proyección
                                    </td>
                                    <td class="bg-light align-middle text-center text-nowrap" style="width: 100px;">
                                        Factor REA
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle text-nowrap">
                                        411 Gastos Sueldos
                                    </td>
                                    <td *ngFor="let s of sueldos_votar_a" class="align-middle">
                                        ${{s | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        ${{total_sueldos_votar_a | number:0}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{proyeccion_sueldos | percent:'1.2'}}
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="factor_rea.factor_411" #f_411Input
                                            (keyup.enter)='modificar_factor_rea(factor_rea._id,"411",f_411Input.value)'
                                            (blur)='modificar_factor_rea(factor_rea._id,"411",f_411Input.value)'>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle text-nowrap">
                                        412 Gastos Operativos
                                    </td>
                                    <td *ngFor="let g_o of gastos_op_votar_a" class="align-middle">
                                        ${{g_o | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        ${{total_gastos_op_votar_a | number:0}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{proyeccion_gastos_operativos | percent:'1.2'}}
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="factor_rea.factor_412" #f_412Input
                                            (keyup.enter)='modificar_factor_rea(factor_rea._id,"412",f_412Input.value)'
                                            (blur)='modificar_factor_rea(factor_rea._id,"412",f_412Input.value)'>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle text-nowrap">
                                        414 Gastos Reuniones y Eventos
                                    </td>
                                    <td *ngFor="let e of eventos_votar_a" class="align-middle">
                                        ${{e | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        ${{total_eventos_votar_a | number:0}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{proyeccion_eventos | percent:'1.2'}}
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="factor_rea.factor_414" #f_414Input
                                            (keyup.enter)='modificar_factor_rea(factor_rea._id,"414",f_414Input.value)'
                                            (blur)='modificar_factor_rea(factor_rea._id,"414",f_414Input.value)'>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle text-nowrap">
                                        419 Otorgamientos
                                    </td>
                                    <td *ngFor="let r of otorgamientos_votar_a" class="align-middle">
                                        ${{r | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        ${{total_otorgamientos_votar_a | number:0}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{proyeccion_otorgamientos | percent:'1.2'}}
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="factor_rea.factor_419" #f_419Input
                                            (keyup.enter)='modificar_factor_rea(factor_rea._id,"419",f_419Input.value)'
                                            (blur)='modificar_factor_rea(factor_rea._id,"419",f_419Input.value)'>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="bg-light align-middle text-nowrap">
                                        Total Gastos
                                    </th>
                                    <th *ngFor="let r of total_gastos_votar_a" class="bg-light">
                                        ${{r | number:0}}
                                    </th>
                                    <th class="bg-light ">
                                        ${{(total_sueldos_votar_a + total_gastos_op_votar_a +
                                        total_otorgamientos_votar_a + total_eventos_votar_a) | number:0}}
                                    </th>
                                </tr>
                                <tr>
                                    <th class="bg-light align-middle text-nowrap">
                                        Diferencia (Ingresos - Gastos)
                                    </th>
                                    <th *ngFor="let r of total_diferencia_votar_a" class="bg-light">
                                        ${{r | number:0}}
                                    </th>
                                    <th class="bg-light ">
                                        ${{total_ingresos_votar_a - (total_sueldos_votar_a +
                                        total_gastos_op_votar_a + total_otorgamientos_votar_a +
                                        total_eventos_votar_a) | number:0}}
                                    </th>
                                    <th class="bg-light ">
                                        {{(total_ingresos_votar_a - (total_sueldos_votar_a +
                                        total_gastos_op_votar_a + total_otorgamientos_votar_a +
                                        total_eventos_votar_a))/total_ingresos_votar_a | percent:'1.2'}}
                                    </th>
                                    <th class="bg-light align-middle">
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="factor_rea.factor_diferencia" #f_diferenciaInput
                                            (keyup.enter)='modificar_factor_rea(factor_rea._id,"Diferencia",f_diferenciaInput.value)'
                                            (blur)='modificar_factor_rea(factor_rea._id,"Diferencia",f_diferenciaInput.value)'>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                        <!-- Print Table -->
                        <table class="table table-bordered hide" id="votar_a_print">
                            <thead>
                                <tr>
                                    <th class="bg-light"></th>
                                    <th class="bg-light" *ngFor="let r of colegios_votar_a">
                                        {{r}}
                                    </th>
                                    <th class="bg-light">Totales</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="align-middle">
                                        Totales Ingresos
                                    </td>
                                    <td *ngFor="let i of ingresos_votar_a" class="align-middle">
                                        ${{i | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        ${{total_ingresos_votar_a | number:0}}
                                    </td>
                                    <td class="bg-light align-middle" style="width: 100px;">
                                        Proyección
                                    </td>
                                    <td class="bg-light align-middle" style="width: 100px;">
                                        Factor REA
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle">
                                        411 Gastos Sueldos
                                    </td>
                                    <td *ngFor="let s of sueldos_votar_a" class="align-middle">
                                        ${{s | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        ${{total_sueldos_votar_a | number:0}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{proyeccion_sueldos | percent:'1.2'}}
                                    </td>
                                    <td class="align-middle">
                                        {{factor_rea.factor_411/100 | percent:'1.2'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle">
                                        412 Gastos Operativos
                                    </td>
                                    <td *ngFor="let g_o of gastos_op_votar_a" class="align-middle">
                                        ${{g_o | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        ${{total_gastos_op_votar_a | number:0}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{proyeccion_gastos_operativos | percent:'1.2'}}
                                    </td>
                                    <td class="align-middle">
                                        {{factor_rea.factor_412/100 | percent:'1.2'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle">
                                        414 Gastos Reuniones y Eventos
                                    </td>
                                    <td *ngFor="let e of eventos_votar_a" class="align-middle">
                                        ${{e | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        ${{total_eventos_votar_a | number:0}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{proyeccion_eventos | percent:'1.2'}}
                                    </td>
                                    <td class="align-middle">
                                        {{factor_rea.factor_414/100 | percent:'1.2'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle">
                                        419 Otorgamientos
                                    </td>
                                    <td *ngFor="let r of otorgamientos_votar_a" class="align-middle">
                                        ${{r | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        ${{total_otorgamientos_votar_a | number:0}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{proyeccion_otorgamientos | percent:'1.2'}}
                                    </td>
                                    <td class="align-middle">
                                        {{factor_rea.factor_419/100 | percent:'1.2'}}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="bg-light align-middle">
                                        Total Gastos
                                    </th>
                                    <th *ngFor="let r of total_gastos_votar_a" class="bg-light">
                                        ${{r | number:0}}
                                    </th>
                                    <th class="bg-light ">
                                        ${{(total_sueldos_votar_a + total_gastos_op_votar_a +
                                        total_otorgamientos_votar_a + total_eventos_votar_a) | number:0}}
                                    </th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th class="bg-light align-middle">
                                        Diferencia (Ingresos - Gastos)
                                    </th>
                                    <th *ngFor="let r of total_diferencia_votar_a" class="bg-light">
                                        ${{r | number:0}}
                                    </th>
                                    <th class="bg-light ">
                                        ${{total_ingresos_votar_a - (total_sueldos_votar_a +
                                        total_gastos_op_votar_a + total_otorgamientos_votar_a +
                                        total_eventos_votar_a) | number:0}}
                                    </th>
                                    <th class="bg-light ">
                                        {{(total_ingresos_votar_a - (total_sueldos_votar_a +
                                        total_gastos_op_votar_a + total_otorgamientos_votar_a +
                                        total_eventos_votar_a))/total_ingresos_votar_a | percent:'1.2'}}
                                    </th>
                                    <th class="bg-light align-middle">
                                        {{factor_rea.factor_diferencia/100 | percent:'1.2'}}
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div id="editor"></div>
                </div>
                <!-- end table-responsive -->
                <div class="m-t-10 d-flex justify-content-center">
                    <button type="button" class="btn btn-primary " (click)='generarPDF_votar_a()'>Imprimir <i
                            class="fas fa-print fa-fw"></i></button>
                </div>
            </div>
            <!-- Fin Pestaña Votar A -->
            <ng-template #modal_errores let-c="close" let-d="dismiss" let-modal>
                <div class="modal-header">
                    <h4 class="modal-title">Errores Importador</h4>
                    <button type="button" class="close btn btn-sm btn-white m-r-1" (click)="d('Cross click')"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <h1 class="text-center text-danger"><i class="fa fa-exclamation-circle fa-3x"></i>
                        <div *ngIf='errores_importador.length > 1'>
                            Se encontraron {{errores_importador.length}} errores
                        </div>
                        <div *ngIf='errores_importador.length == 1'>
                            Se encontro {{errores_importador.length}} error
                        </div>
                    </h1>
                    <div *ngFor='let error of errores_importador' class="m-t-10">
                        <ul class="fa-ul">
                            <li>
                                <span class="fa-li"><i class="fa fa-exclamation-triangle"></i></span>
                                Error: {{ error.error }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-success btn-sm" (click)="archivo_importador(modal_errores, false)">Generar de Todas Formas</button>
                    <a href="javascript:;" class="btn btn-white" (click)="c('Close click')">Cerrar</a>
                </div>
            </ng-template>
            <div class="m-t-10 d-flex justify-content-center">
                <button type="submit" class="btn btn-primary" (click)="archivo_importador(modal_errores, true)">Generar
                    Presupuesto <i class="fas fa-file-excel fa-fw"></i></button>
            </div>
            <div id="notify">
                <div class="ads" style="width: 1px;">

                </div>
            </div>
            <!--Fin Pestaña Resumenes-->
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
<!-- begin breadcrumb -->
<ol class="breadcrumb pull-right">
  <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
  <li class="breadcrumb-item">{{descripcion}}</li>
  <li class="breadcrumb-item active">{{ titulo }}</li>
</ol>
<!-- end breadcrumb -->

<!--################################################COntenido############################################################-->


<!-- begin page-header -->
<h1 class="page-header"> <i class="fa fa-search"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin row -->
<div class="row ">

  <div class="col-md-12">
    <div class="panel panel-iatuch">
      <!-- begin panel-heading -->
      <div class="panel-heading ui-sortable-handle">
        <div class="panel-heading-btn">

        </div>
        <h4 class="panel-title"><i class="fas fa-list fa-fw"></i> Resultado</h4>
      </div>
      <!-- end panel-heading -->
      <!-- begin panel-body -->
      <div class="panel-body ">
        <div class="row">


          <div class="col-md-12 mb-2">
            <div class="btn-group">
              <button id="bt_ing_gas" class="bt_tipo_declaracion btn btn-white" [class.active]="tipo_dec == 'ing_gas'"
                (click)="tipo_declaracion('ing_gas')">Ingresos y Gastos</button>
              <button id="bt_excenciones" class="bt_tipo_declaracion btn btn-white"
                [class.active]="tipo_dec == 'excenciones'" (click)="tipo_declaracion('exenciones')">Excenciones</button>
              <button id="bt_bien_raiz" class="bt_tipo_declaracion btn btn-white"
                [class.active]="tipo_dec == 'bien_raiz'" (click)="tipo_declaracion('bien_raiz')">Bien Raiz</button>
            </div>
          </div>
          <br>

          <div class="col-md-12 pt-3 border-top" *ngIf="descarga_txt == true">
            <div class="row">
              <div class=" ml-3 mr-2">
                <div class="switcher">
                  <label for="switcher_checkbox_1">Rectificar</label>
                  <input type="checkbox" (click)="rectificar()" name="rectificacion" id="switcher_checkbox_1" value="1"
                    #rectificacion="ngModel" [(ngModel)]="parametros_importador.rectificacion">
                </div>
              </div>
              <div class="col-md-1" *ngIf="parametros_importador.rectificacion == true">
                <div class="row">
                  <div class="col-md-2">
                    <label for="">N°</label>
                  </div>
                  <div class="col-md-9">
                    <input class="form-control form-control-sm"
                      [class.is-invalid]="parametros_importador.rectificacion == true && parametros_importador.num_rectificacion == ''"
                      type="text" size="4" #num_rectificacion name="num_rectificacion" #num_rectificacion="ngModel"
                      [(ngModel)]="parametros_importador.num_rectificacion" required autofocus />

                  </div>
                </div>

              </div>
              <div class="col-md-6 mb-3">
                <div class="btn-group mb-2 mt-2" ngbDropdown>
                  <a href="javascript:;" class="btn btn-primary btn-sm dropdown-toggle" ngbDropdownToggle>
                    <i class="fas fa-download fa-fw"></i> Descargar <b class="caret"></b></a>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <a href="javascript:;" class="dropdown-item" (click)="descargaArchivo()">Transf. de Archivo (.zip)</a>
                    <a href="javascript:;" class="dropdown-item" (click)="downloadCsv()">Importador de Datos (.csv)</a>
                  </div>
                </div>
              </div>

            </div>
            <div class="row"
              *ngIf="parametros_importador.rectificacion == true && parametros_importador.num_rectificacion == ''">
              <div class=" ml-3 mr-2">

                <div class="text-red">{{mensaje_num_rectificacion}}</div>
              </div>
            </div>
          </div>
          <div class="col-md-12 border rounder pl-3 pr-3 pt-2 pb-3 table-responsive">
            <div *ngIf="tipo == 101 && tipo_dec == 'ing_gas'" class="alert alert-warning fade show col-md-12"> <span
                class="close" data-dismiss="alert">×</span>
              <i class="fas fa-lg fa-fw m-r-10 fa-exclamation-triangle"></i> Esta Busqueda esta por defecto en Tipo
              Ingreso/Egreso 101 (Ingreso), para cambiar debe seleccionar otro Tipo de declaración.
            </div>
            <div *ngIf="tipo_dec == 'ing_gas'" class="border-bottom border-top form-inline col-md-12">
              <div class="form-group col-md-9">
                <label>Seleccione: &nbsp;</label>
                <div class="btn-group mb-2 mt-2" ngbDropdown>
                  <a href="javascript:;" class="btn btn-light btn-sm dropdown-toggle" ngbDropdownToggle>
                    {{ tipo < 200 ? 'Ingreso en Dinero' :'Egresos en Dinero'}} <b class="caret"></b></a>
                  <!--<a href="#" class="btn btn-default dropdown-toggle" ><b class="caret"></b></a>-->
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <a href="javascript:;" class="dropdown-item" (click)="selTipoIngEgr(101);">101 INGRESO EN DINERO</a>
                    <a href="javascript:;" class="dropdown-item" (click)="selTipoIngEgr(201);">201 EGRESO EN DINERO</a>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-3 justify-content-end">
                <label>Mostrar&nbsp;
                  <select class="form-select" [(ngModel)]="page_info.n_per_page"
                    (change)="getConfiguracionGlobalPeriodo()">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  &nbsp;registros</label>
              </div>
            </div>
            <div *ngIf="tipo_dec == 'ing_gas'" class="table-responsive">
              <table id="ingresos_gastos"
                class="tabla_declaraciones  table table-condensed table-striped table-sm mb-1 border-bottom"
                *ngIf="tipo_dec == 'ing_gas'">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Rut</th>
                    <th>Tipo</th>
                    <th class="text-center">{{ tipo < 200 ? 'Origen' :'Destino'}}</th>
                    <th class="text-center">Relación</th>
                    <th class="text-center">Cantidad</th>
                    <th>Monto</th>
                  </tr>
                </thead>
                <tbody *ngIf="dj_ingreso_gasto_entity_declaration">
                  <tr *ngFor="let ig of dj_ingreso_gasto_entity_declaration;let indice=index">
                    <td>{{((page_info.page_number * page_info.n_per_page) - page_info.n_per_page)+ indice +1}}</td>
                    <td>{{ig.rut}}</td>
                    <td class="font-weight-normal" [ngClass]="ig.tipo_ing_egr < 200 ? 'text-primary ' :'text-danger'">
                      {{ig.tipo_ing_egr +(ig.tipo_ing_egr < 200 ? ' - Ingreso' :' - Egreso')}}</td>
                    <td class="text-center">{{ig.origen_ing > 0 ? ig.origen_ing : ig.destino_egr }}</td>
                    <td class="text-center">{{ig.tipo_relacion}}</td>
                    <td class="text-center">{{ig.count}}</td>
                    <td class="font-weight-normal" [ngClass]="ig.tipo_ing_egr < 200 ? 'text-primary' :'text-danger'">
                      ${{ig.total | number:0}}</td>
                  </tr>
                </tbody>
                <tfoot *ngIf="resultados > 0">
                  <tr class="info font-weight-bold">
                    <td colspan="5">Total</td>
                    <td class="text-center" colspan="1">{{table_total_info.count}}</td>
                    <td [ngClass]="tipo < 200 ? 'text-primary' :'text-danger'" colspan="1">${{table_total_info.total |
                      number:0}}</td>
                  </tr>
                </tfoot>
              </table>
              <div class="form-inline mt-3 col-md-12 ">
                <div class="justify-content-start col-md-9 col-sm-12">
                  <font style="vertical-align: inherit;">Total registros: {{page_info.count_records}}
                  </font>

                </div>
                <div class="form-inline col-md-3 col-sm-12 justify-content-end ">

                  <button [disabled]="page_info.page_number == 1" class="btn btn-sm btn-white mr-1 btn-icon"
                    (click)="changePage('-')"> <i class="fas fa-chevron-left"></i></button>
                  &nbsp; {{page_info.page_number +'/'+ page_info.total_pages}} &nbsp;
                  <button [disabled]="page_info.page_number == page_info.total_pages"
                    class="btn btn btn-sm btn-white btn-icon" (click)="changePage('+')"><i
                      class="fas fa-chevron-right"></i></button>
                </div>
              </div>
            </div>


            <table id="excenciones" class="tabla_declaraciones table table-condensed table-striped"
              *ngIf="tipo_dec == 'exenciones'">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tipo documento</th>
                  <th>Numero</th>
                  <th>Año</th>
                  <th>Tipo Exención</th>
                </tr>
              </thead>
              <tbody *ngIf="dec_excenciones">
                <tr *ngFor="let ex of dec_excenciones;let indice=index">
                  <td>{{indice+1}}</td>
                  <td>{{ex.tipo_documento}}</td>
                  <td>{{ex.numero}}</td>
                  <td>{{ex.ano}}</td>
                  <td>{{ex.tipo_exencion}}</td>
                </tr>
              </tbody>

            </table>

            <table id="bien_raiz" class="tabla_declaraciones table table-condensed table-striped"
              *ngIf="tipo_dec == 'bien_raiz'">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tipo Sección</th>
                  <th>Ubicación bien raiz</th>
                  <th>Rol</th>
                  <th>Comuna</th>
                  <th>Pais</th>
                  <th>Uso</th>
                </tr>
              </thead>
              <tbody *ngIf="dec_bien_raiz">
                <tr *ngFor="let br of dec_bien_raiz;let indice=index">
                  <td>{{indice+1}}</td>
                  <td>{{br.tipo_seccion}}</td>
                  <td>{{br.ubicacion_bien_raiz}}</td>
                  <td>{{br.rol}}</td>
                  <td>{{br.comuna}}</td>
                  <td>{{br.pais}}</td>
                  <td>{{br.uso}}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>


      </div>
    </div>
  </div>
</div>
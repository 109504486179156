export class User{
    constructor(
        public _id: string,
        public usuario: string,
        public nombre: string,
        public apellido: string,
        public rut: string,
        public clave: string,
        public mail: string,
        public level: string,
        public socket: string

    ){}
}

/*usuario: String,
nombre: String,
apellido: String,
rut: String,
clave: String,
mail: String,
level: String*/

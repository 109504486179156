import { environment } from './../../environments/environment';

let localhost = {
	url: "http://localhost:3700/api-declaracion/",
	url_user:  'http://localhost:3700' + "/api-users/",
	url_socket: "http://localhost:3000",
	url_V2:"http://localhost:3700/api-impuestos/",
	url_reportes: "http://localhost:3700/api-reportes/",
	url_dj1945: "http://localhost:3700/api-dj1945/",
	url_jsreport: "http://localhost:5488/api/report",
	url_auth: 'http://localhost:3700/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
	version: "1.1.0.18"
}

let dev = {
	url:  'https://sistemas-dev.adventistas.cl' + "/api-declaracion/",
	url_socket: 'https://devsocketsistemas.adventistas.cl',
	url_user:  'https://sistemas-dev.adventistas.cl' + "/api-users/",
	url_V2: 'https://sistemas-dev.adventistas.cl' + "/api-impuestos/",
	url_reportes: 'https://sistemas-dev.adventistas.cl' + "/api-reportes/",
	url_dj1945: 'https://sistemas-dev.adventistas.cl' + "/api-dj1945/",
	url_jsreport: "https://api-jsreport.adventistas.cl/api/report",
	url_auth: 'https://sistemas-dev.adventistas.cl/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
	version: "1.1.0.18"
}

let test = {
	url: 'https://sistemas-staging.adventistas.cl/api-declaracion/', 
	url_socket: 'https://testsocketsistemas.adventistas.cl/', 
	url_user:  'https://sistemas-staging.adventistas.cl' + "/api-users/",
	url_V2:"https://sistemas-staging.adventistas.cl/api-impuestos/",
	url_reportes: "https://sistemas-staging.adventistas.cl/api-reportes/",
	url_dj1945: 'https://sistemas-staging.adventistas.cl' + "/api-dj1945/",
	url_jsreport: "https://api-jsreport.adventistas.cl/api/report",
	url_auth: 'https://sistemas-staging.adventistas.cl/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
	version: "1.1.0.18"
}

let prod = {
	url: 'https://sistemas.adventistas.cl/api-declaracion/', 
	url_socket: 'https://socketsistemas.adventistas.cl/', 
	url_user:  'https://sistemas.adventistas.cl' + "/api-users/",
	url_V2:"https://sistemas.adventistas.cl/api-impuestos/",
	url_reportes: "https://sistemas.adventistas.cl/api-reportes/",
	url_dj1945: 'https://sistemas.adventistas.cl' + "/api-dj1945/",
	url_jsreport: "https://api-jsreport.adventistas.cl/api/report",
	url_auth: 'https://sistemas.adventistas.cl/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
	version: "1.1.0.18"
}

if (environment.env != 'Production'){
	console.log("Enviroment: " + environment.env)
}

export var Global = environment.env == 'Testing' ? test : (environment.env== 'Production'?prod:(environment.env== 'Development'?dev:localhost))
import { environment } from './../../../environments/environment';
import {
	Component,
	Input,
	Output,
	EventEmitter,
	Renderer2,
	OnDestroy,
	OnInit,
	DoCheck,
} from "@angular/core";
import pageSettings from "../../config/page-settings";
import rutasSinConexion from "../../config/rutas-sin-conexion";
import { UserService } from "../../services/user.service";
import { LocalService } from "../../services/localService";
import { EntidadService } from "../../services/entidad.service";
import { NotificacionService } from "../../services/notificaciones.service";
import { Router, NavigationEnd, ActivatedRoute, Params } from "@angular/router";
import { Entidad } from "../../models/entidad";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { Global } from "../../services/global";
//import 'rxjs/add/observable/interval';
//import {Observable} from 'rxjs/Observable';
import { interval, Subscription } from 'rxjs';
import { LEADING_TRIVIA_CHARS } from "@angular/compiler/src/render3/view/template";
import { InventarioService } from "../../services/inventario.service";
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { BodegaService } from "../../services/bodegaSelect.service";
import { MicrosoftAuthService } from '../../services/authentication.service';
import { SharingService } from '../../services/sharing.service';

@Component({
	selector: "header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.css"],
	providers: [EntidadService, NotificacionService, InventarioService, NgbDropdownConfig],
})
export class HeaderComponent implements OnDestroy {
	@Input() pageSidebarTwo;
	@Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
	@Output() toggleMobileSidebar = new EventEmitter<boolean>();
	@Output() toggleMobileRightSidebar = new EventEmitter<boolean>();
	pageSettings = pageSettings;
	rutasSinConexion = rutasSinConexion;
	@Output() sel_entidad = new EventEmitter<string>();
	@Output() mostrar = new EventEmitter<boolean>();
	public mostrar_header = true

	public identity;
	public token;
	public entidad;
	public entidades = []
	public entidadesTemp = []
	public entidadesLength
	public entidadsel = this._entidadService.getEntidad();
	public update_reg = false;
	public rutas = [];
	public notificacion = [];
	public closeResult: string;
	public ambiente = Global.url;
	public detalle;
	public cant_notificacion;
	public logo = document.location.origin + "/assets/img/logo.png";
	public ipAddress
	public sub;
	public user_img = document.location.origin + "/assets/img/user/user-13.jpg";
	public bodegas = []
	public mostrar_bodegas = false
	public ruta_actual = "otro"
	public temp = []
	bodegaDesdeServicio

	public ruta = window.location.pathname.split("/");
	public path = this.ruta[1];
	public busquedaPath = this.rutasSinConexion.find(x => x == this.path);
	public env = environment.env
	public userData
	public profilePhotoURL
	userDataWithPhotoSubscription: Subscription;
	entitiesSubscription: Subscription;

	mobileSidebarToggle() {
		this.toggleMobileSidebar.emit(true);
	}
	mobileRightSidebarToggle() {
		this.toggleMobileRightSidebar.emit(true);
	}
	toggleSidebarRight() {
		this.toggleSidebarRightCollapsed.emit(true);
	}

	mobileTopMenuToggle() {
		this.pageSettings.pageMobileTopMenuToggled = !this.pageSettings
			.pageMobileTopMenuToggled;
	}

	mobileMegaMenuToggle() {
		this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings
			.pageMobileMegaMenuToggled;
	}

	ngOnDestroy() {
		this.pageSettings.pageMobileTopMenuToggled = false;
		this.pageSettings.pageMobileMegaMenuToggled = false;
		clearInterval(this.token)
		this.pageSettings.pageMobileTopMenuToggled = false;
		this.pageSettings.pageMobileMegaMenuToggled = false;
		this.userDataWithPhotoSubscription.unsubscribe();
		this.entitiesSubscription.unsubscribe();
	}

	constructor(
		private renderer: Renderer2,
		private _router: Router,
		private _userService: UserService,
		private _entidadService: EntidadService,
		private _route: ActivatedRoute,
		private _notificacionService: NotificacionService,
		private _inventarioService: InventarioService,
		private _localService: LocalService,
		private modalService: NgbModal,
		config: NgbDropdownConfig,
		private authMicrosoft: MicrosoftAuthService,
		private _sharingService: SharingService,
		private bodegaSelect: BodegaService
	) {

	  if (this.busquedaPath == undefined) {
	    _router.events.subscribe((event) => {
	      if (event instanceof NavigationEnd) {
		if (event.url.includes("inventario")) {
		  this.ruta_actual = "inventario"
		  this.mostrar_bodegas = true
		  // this.bodegas = this._inventarioService.getBodegasEntidad()
		  this.bodegaSelect.bodegasUsuario.subscribe(bodegas => bodegas ? this.bodegas = this._inventarioService.getBodegasEntidad() : '')
		} else {
		  this.mostrar_bodegas = false
		  this.ruta_actual = "otro"
		}
	      }
	    })

		  
	    // this.identity = this._userService.getIdentity();
	    // // config.placement = 'bottom-rigth'
	    // config.autoClose = true
	    // if (this.identity == null) {
	    //   this._router.navigate(["/login"]);
	    // } else {
	    //   let mostrar_contenido = this._localService.getJsonValue("usar-aqui")
	    //   if (mostrar_contenido == true) {
		// this.usarAqui()
	    //   }
	    //   this.token = this._userService.getToken();
	    //   this.entidad = new Entidad("", 0, "", "", "", 0);
	    //   this.entidad.campo = this.identity.campo;

	    //   if (this.token) {
		// this.getNotificacion();
	    //   }
	    //   this._userService.privilegiosRuta().subscribe(
		// (response) => {
		//   this.rutas = response;
		//   this._localService.setJsonValue("rutas", JSON.stringify(this.rutas));
		// },
		// (error) => {
		//   if (error.error.mensaje == "Token Expirado o invalido!") {
		//     this.logout()
		//   }
		// }
	    //   );
	    //   this._entidadService.getEntidades(this.entidad).subscribe((response) => {
		// this.entidades = response.entidades
		// this.entidadesLength = response.entidades.length
		// this.entidadesTemp = [...this.entidades]
		// this._entidadService.getEntidadSel().subscribe((response) => {
		//   this.entidadsel = response.datos;
		//   this.update_reg = response.update_reg;
		//   if (this.update_reg == true) {
		//     this.logout();
		//   } else {
		//     if (this._localService.getJsonValue("usar-aqui")) {
		//       //console.log(this.update_reg, 
		//       //"entre aqui")
		//       setTimeout(() => {
		// 	this.usarAqui()
		//       }, 1500);
		//     }
		//     this._localService.setJsonValue(
		//       "entidad",
		//       JSON.stringify(this.entidadsel)
		//     );
		//   }
		// });

		// if (this.entidadsel == null) {
		//   this._localService.setJsonValue(
		//     "entidad",
		//     JSON.stringify(this.entidades[0])
		//   );
		// }
	    //   });
	    // }
	    // this.identity = this._userService.getIdentity();
	    // if (this.identity == null) {
	    //   this._router.navigate(["/login"]);
	    // } else {
	    //   let mostrar_contenido = this._localService.getJsonValue("usar-aqui")
	    //   if (mostrar_contenido == true) {
		// this.usarAqui()
	    //   }
	    //   this.token = this._userService.getToken();
	    //   this.entidad = new Entidad("", 0, "", "", "", 0);
	    //   this.entidad.campo = this.identity.campo;

	    //   if (this.token) {
		// this.getNotificacion();
	    //   }
	    //   this._userService.privilegiosRuta().subscribe(
		// (response) => {
		//   this.rutas = response;
		//   this._localService.setJsonValue("rutas", JSON.stringify(this.rutas));
		// },
		// (error) => {
		//   if (error.error.mensaje == "Token Expirado o invalido!") {
		//     this.logout()
		//   }
		// }
	    //   );
	    //   this._entidadService.getEntidades(this.entidad).subscribe((response) => {
		// this.entidades = response.entidades;
		// this._entidadService.getEntidadSel().subscribe((response) => {
		//   this.entidadsel = response.datos;
		//   this.update_reg = response.update_reg;
		//   if (this.update_reg == true) {
		//     this.logout();
		//   } else {
		//     if (this._localService.getJsonValue("usar-aqui")) {
		//       //console.log(this.update_reg, 
		//       //"entre aqui")
		//       setTimeout(() => {
		// 	this.usarAqui()
		//       }, 1500);
		//     }
		//     this._localService.setJsonValue(
		//       "entidad",
		//       JSON.stringify(this.entidadsel)
		//     );
		//   }
		// });

		// if (this.entidadsel == null) {
		//   this._localService.setJsonValue(
		//     "entidad",
		//     JSON.stringify(this.entidades[0])
		//   );
		// }
	    //   });
	    // }

	    // //observable
	    // this.sub = interval(2700000)
	    // .subscribe((val) => {
	    //   this.token = this._userService.getToken()
	    //   this.actualizarToken()
	    // });

	    // this.actualizarToken()
	  }
	}

	getClassLevelEnv(){
		let classEnv = this.env == 'Testing' ? 'label label-warning': 'label label-primary'
		
		return classEnv
	}

	ngOnInit() {
		this.userDataWithPhotoSubscription = this._userService.combinedData$.subscribe(data => {
			if(data){
				this.userData = data.userData
				this.profilePhotoURL = data.photoURL
			}
		});
		this.entitiesSubscription = this._sharingService.sharingEntities.subscribe(entities => {
			this.entidades = entities
			this.entidadesLength = entities.length
			this.entidadesTemp = this.entidades
		})
	}

	async usarAqui() {
	  this.mostrar_header = false
	  this.mostrar.emit(false)
	  try {
	    const alert = await Swal.fire({
	      html: '<p>Ud a estado trabajando en otra ventana. Haga clic en USAR AQUÍ para habilitar esta ventana</p>',
	      showCancelButton: true,
	      allowOutsideClick: false,
	      cancelButtonText: "Cerrar",
	      confirmButtonText: "USAR AQUÍ"
	    }).then((result) => {
	      return result;
	    })

	    await this.resultUsarAqui(alert)
	    //console.log(alert)

	  } catch (e) {
	    console.log("error: ", e)
	  }
	  if (this.mostrar_header) {

	    this.mostrar.emit(true)
	    this._localService.setJsonValue("usar-aqui", false)


	  }
	  //console.log(this.mostrar_header)
	}
	async resultUsarAqui(alert): Promise<any> {

	  if (alert.value) {

	    return new Promise((resolve, reject) => {
	      this._userService.switchSesion("seguir").subscribe(
		response => {
		  var result = response
		  this.mostrar.emit(true)
		  this._localService.setJsonValue("usar-aqui", false)

		  this.mostrar_header = true
		  if (response.update_reg) {
		    this.logout()
		  }
		  resolve(this.mostrar_header)
		}, error => {
		  reject(error)
		}
	      )
	    })
	  } else {
	    return new Promise((resolve, reject) => {

	      this._userService.switchSesion("cerrar").subscribe(
		async response => {
		  this.mostrar.emit(true)
		  this.mostrar_header = true
		  this._localService.setJsonValue("usar-aqui", false)
		  if (response.update_reg) {
		    localStorage.clear()
		    this._router.navigate(["/login"])
		  }
		  resolve(this.mostrar_header)
		}, error => {
		  reject(error)
		})



	    })
	  }
	}


	filtrarEntidades(event) {
	  const val = event.target.value.toLowerCase()
	  // console.log(this.temp)
	  const temp = this.entidadesTemp.filter(function (d) {
	    let dataFilterer
	    if ((d.codigo.toString().toLowerCase().indexOf(val) !== -1 || !val) == false) {
	      if ((d.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
		if ((d.grupo.toLowerCase().indexOf(val) !== -1 || !val) == false) {
		  if ((d.campo.toLowerCase().indexOf(val) !== -1 || !val) == false) {

		  } else {
		    dataFilterer = d.campo.toLowerCase().indexOf(val) !== -1 || !val
		  }
		} else {
		  dataFilterer = d.grupo.toLowerCase().indexOf(val) !== -1 || !val
		}
	      } else {
		dataFilterer = d.nombre.toLowerCase().indexOf(val) !== -1 || !val
	      }
	    } else {
	      dataFilterer = d.codigo.toString().toLowerCase().indexOf(val) !== -1 || !val
	    }
	    return dataFilterer;
	  })
	  this.entidades = temp
	}

	ngDoCheck() {
	  this.identity = this._userService.getIdentity();
	}

	logout() {
		this.authMicrosoft.clearSesion()
		this.authMicrosoft.logout().subscribe(res => {
			let a = document.createElement('a')
			a.href = res.url
			a.click()
		})
	}

	buscarEntidad(event) {
	  const val = event.target.value.toLowerCase()
	  // console.log(this.temp)
	  const temp = this.entidadesTemp.filter(function (d) {
	    let dataFilterer
	    if ((d.codigo.toString().toLowerCase().indexOf(val) !== -1 || !val) == false) {
	      if ((d.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
		if ((d.campo.toLowerCase().indexOf(val) !== -1 || !val) == false) {
		  if ((d.grupo.toLowerCase().indexOf(val) !== -1 || !val) == false) {

		  } else {
		    dataFilterer = d.grupo.toLowerCase().indexOf(val) !== -1 || !val
		  }
		} else {
		  dataFilterer = d.campo.toLowerCase().indexOf(val) !== -1 || !val
		}
	      } else {
		dataFilterer = d.nombre.toLowerCase().indexOf(val) !== -1 || !val
	      }
	    } else {
	      dataFilterer = d.codigo.toString().toLowerCase().indexOf(val) !== -1 || !val
	    }
	    return dataFilterer;
	  })
	  this.entidades = temp;
	}

	actualizarToken() {
	  if (this.token != null) { // Este if es para que se tome en cuenta el ultimo inicio de sesion para todas las sesiones
	    //console.log("actualizado")
	  }
	}
	cambiarbodega(bodega) {
	  this._inventarioService.cambiarBodegaSel(bodega).subscribe(response => { })
	  this._localService.setJsonValue('bodega_sel', bodega)
	  this.bodegaSelect.bodegaSeleccionada()
	}

	cambiaentidad(codigo) {
		this._sharingService.sharingEntityData = codigo
		this._entidadService.cambiarEntidadSel(codigo).subscribe(res => {})
		// this._entidadService.cambiarEntidadSel(codigo).subscribe(res => {})
	}

	cambia() {
	  this._localService.getJsonValue("entidad");
	}


	open(content, modal) {
	  this.detalle = modal;
	//   this.marcarLeido(this.detalle);

	  this.modalService
	  .open(content, { windowClass: "modal-holder", size: "md" })
	  .result.then(
	    (result) => {
	      this.closeResult = `Closed with: ${result}`;
	    },
	    (reason) => {
	      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
	    }
	  );
	}

	private getDismissReason(reason: any): string {
	  if (reason === ModalDismissReasons.ESC) {
	    return "by pressing ESC";
	  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
	    return "by clicking on a backdrop";
	  } else {
	    return `with: ${reason}`;
	  }
	}

	// getNotificacion() {
	//   this._notificacionService.getNotificaciones().subscribe((response) => {
	//     //console.log(response);
	//     this.notificacion = [];
	//     this.notificacion = response;
	//     this.cant_notificacion = 0;
	//     const usuario = JSON.parse(this._localService.getJsonValue("identity"));

	//     for (let i = 0; i < this.notificacion.length; i++) {
	//       let notificacion = this.notificacion[i];

	//       for (let nl = 0; nl < notificacion.destinatarios.length; nl++) {
	// 	const leidos = notificacion.destinatarios[nl];

	// 	if (leidos.id == usuario[0]._id) {
	// 	  notificacion.index = nl;
	// 	  if (!leidos.leido) this.cant_notificacion++;
	// 	  break;
	// 	}
	//       }
	//     }

	//     this.notificacion.sort((a, b) => {
	//       if (a.destinatarios[a.index].leido && !b.destinatarios[b.index].leido)
	// 	return 1;
	//       if (b.destinatarios[b.index].leido && !a.destinatarios[a.index].leido)
	// 	return -1;
	//       return 0;
	//     });
	//     this.notificacion.sort((a, b) => {
	//       if (
	// 	a.destinatarios[a.index].leido &&
	// 	b.destinatarios[b.index].leido &&
	//       a.fecha > b.fecha
	//       )
	//       return -1;
	//       if (
	// 	a.destinatarios[a.index].leido &&
	// 	b.destinatarios[b.index].leido &&
	//       b.fecha > a.fecha
	//       )
	//       return 1;
	//       if (
	// 	!b.destinatarios[b.index].leido &&
	// 	!a.destinatarios[a.index].leido &&
	//       a.fecha > b.fecha
	//       )
	//       return -1;
	//       if (
	// 	!b.destinatarios[b.index].leido &&
	// 	!a.destinatarios[a.index].leido &&
	//       b.fecha > a.fecha
	//       )
	//       return 1;
	//       return 0;
	//     });
	//   },
	//   (error) => {
	//     console.log(error)
	//   }
	// 							 );
	// }

	// marcarLeido(n) {



	//   this._notificacionService.marcarLeido(n._id).subscribe((response) => {


	//     this.getNotificacion()

	//   });
	// }

	// eliminar(id) {
	//   this._notificacionService.eliminar(id).subscribe((response) => {
	//     this.getNotificacion()

	//   });
	// }

	// irRuta(notificacion) {
	//   this.modalService.dismissAll();

	//   if (notificacion.ruta) {
	//     this.cambiaentidad(notificacion.entidad);
	//     this._router.navigate(["/" + notificacion.ruta]);
	//   }
	// }

	// eliminarTodo() {
	//   this._notificacionService.eliminarTodo().subscribe((response) => {
	//     this.notificacion = response;
	//     this.cant_notificacion = 0;
	//   });
	// }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global } from './global';
import { UserService } from './user.service';
import { EntidadService } from '../services/entidad.service';
//import { ConsoleReporter } from 'jasmine';

@Injectable()

export class F29Service {
	public url: string;
	public url_V2: string;
	public identity;
	public token;
	public entidad_sel;

	constructor(
		private _http: HttpClient,
		private _userService: UserService,
		private _entidadService: EntidadService
	) {
		this.url = Global.url;
		this.url_V2 = Global.url_V2;
		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();

	}

	getFile(id): Observable<any> {
		//var declaracion = { id:id }
		//let params = declaracion;//JSON.stringify(declaracion);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);

		return this._http.get(this.url + 'get-file/' + id, { headers: headers });
	}

	guardarDocumentacion(documentacion, periodo, bitacora, tipo): Observable<any> {
		this.entidad_sel = this._entidadService.getEntidad();
		documentacion.id_periodo = periodo._id;
		let params = JSON.stringify({ documentacion: documentacion, bitacora: bitacora, tipo: tipo });//JSON.stringify(declaracion);

		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'guardar-documentacion-f29', params, { headers: headers });
	}

	enviarDocumentacion(periodo, bitacora): Observable<any> {
		this.entidad_sel = this._entidadService.getEntidad();

		let params = JSON.stringify({ periodo: periodo, bitacora: bitacora });//JSON.stringify(declaracion);

		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'enviar-documentacion-f29', params, { headers: headers });
	}

	// Al consultar un periodo abierto de una entidad especifica se consulta este metodo
	traePeriodo(): Observable<any> {
		this.entidad_sel = this._entidadService.getEntidad();

		let params = { entidad: this.entidad_sel };//JSON.stringify(declaracion);
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'trae-periodo-f29', params, { headers: headers });
	}

	consultar_periodo(id, fecha): Observable<any> {
		this.entidad_sel = this._entidadService.getEntidad();

		let params = { entidad: this.entidad_sel, id: id, fecha: fecha };//JSON.stringify(declaracion);
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'consulta-periodo-f29', params, { headers: headers });
	}

	// Consulta los periodos cerrados, trae solo los periodos de la entidad seleccionada (entidad_sel)
	traePeriodos(): Observable<any> {
		this.entidad_sel = this._entidadService.getEntidad();

		let params = { entidad: this.entidad_sel };//JSON.stringify(declaracion);
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'trae-periodos-f29', params, { headers: headers });
	}

	traeInfoEtapa(etapa): Observable<any> {
		this.entidad_sel = this._entidadService.getEntidad();

		let params = JSON.stringify(etapa);
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'trae-etapa-estados-f29', params, { headers: headers });
	}

	traePrivilegios(privilegio): Observable<any> {
		this.entidad_sel = this._entidadService.getEntidad();

		let params = JSON.stringify(privilegio);
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'trae-privilegios-f29', params, { headers: headers });
	}

	// se actualiza el periodo en curso revisado por los contadores supervisores
	actualizaRevision(datos, bitacora): Observable<any> {

		let params = JSON.stringify({ datos: datos, bitacora: bitacora });
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'actualizar-revision-f29', params, { headers: headers });
	}

	// Solo se puede cerrar el periodo si esta previamente aprobado el periodo
	cerrarPeriodo(datos): Observable<any> {

		let params = JSON.stringify(datos);
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'cerrar-periodo-f29', params, { headers: headers });
	}

	// Consulta los periodos abiertos de todas las entidades
	verPeriodoEntidades(): Observable<any> {

		let params = {};
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'ver-periodo-entidades-f29', params, { headers: headers });
	}

	// Se consulta las estadisticas de la entidad seleccionada
	getEstadisticas(datos): Observable<any> {

		let params = JSON.stringify({ datos: datos });
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'get-estadistica-entidad-f29', params, { headers: headers });
	}

	traerF29Grupos(): Observable<any> {

		let params = {};
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'get-grupos-f29', params, { headers: headers });
	}

	// Se consulta los borradores F29 ya sea consolidados (IASD y CORP) como individuales (default)
	getDeclaracionF29(e, periodo): Observable<any> {
		let params
		switch (e) {
			case "IASD":
				params = { entidad: { codigo: e }, periodo: periodo }
				break
			case "CORP":
				params = { entidad: { codigo: e }, periodo: periodo }
				break
			default:
				this.entidad_sel = this._entidadService.getEntidad();
				params = { entidad: this.entidad_sel };//JSON.stringify(declaracion);
				break
		}

		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'get-declaracion-f29', params, { headers: headers });
	}

	// Se guarda el Borrador F29 en la BD
	guardarDeclaracionF29(form): Observable<any> {

		let params = form;
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'guardar-declaracion-f29', params, { headers: headers });
	}

	getPermisoDocumentacion(estado): Observable<any> {
		let params = { estado: estado };
		//return params;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'get-permiso-documentacion-f29', params, { headers: headers });
	}

	getTiposReportes(): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.get(this.url_V2 + 'tipos-reportes', { headers: headers });

	}

	getFileSharepoint(file_id): Observable<Blob> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.get(this.url + 'get-file/' + file_id, { headers: headers, responseType: 'blob' });

	}
}

<!-- begin #header -->
<div *ngIf="mostrar_header == true" id="header" class="header navbar-default" [class.navbar-inverse]="env != 
			  'Production'">
  <!-- begin navbar-header -->
  <div class="navbar-header">
    <button type="button" class="navbar-toggle pull-left" *ngIf="this.pageSidebarTwo"
      (click)="mobileRightSidebarToggle()">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <a href="javascript:;" class="navbar-brand"><img [src]="logo" width="25px"> Sistemas <b
        class="text-info">UCh</b></a>
    <button type="button" class="navbar-toggle pt-0 pb-0 mr-0" (click)="mobileTopMenuToggle()"
      *ngIf="this.pageSettings.pageTopMenu && !this.pageSettings.pageWithoutSidebar">
      <span class="fa-stack fa-lg text-inverse">
        <i class="far fa-square fa-stack-2x"></i>
        <i class="fa fa-cog fa-stack-1x"></i>
      </span>
    </button>
    <span *ngIf="env != 'Production'" [ngClass] = "getClassLevelEnv()"
      style="font-size: 1.6em; border-radius: 5px; text-transform: uppercase;">{{env}}</span>
    <button type="button" class="navbar-toggle pt-0 pb-0 mr-0" (click)="mobileMegaMenuToggle()"
      *ngIf="this.pageSettings.pageMegaMenu">
      <span class="fa-stack fa-lg text-inverse">
        <i class="far fa-square fa-stack-2x"></i>
        <i class="fa fa-cog fa-stack-1x"></i>
      </span>
    </button>
    <button type="button" class="navbar-toggle" (click)="mobileSidebarToggle()"
      *ngIf="!this.pageSettings.pageWithoutSidebar">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <button type="button" class="navbar-toggle" (click)="mobileTopMenuToggle()"
      *ngIf="this.pageSettings.pageTopMenu && this.pageSettings.pageWithoutSidebar">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
  </div>
  <!-- end navbar-header -->

  <!-- begin mega-menu -->
  <div class="collapse navbar-collapse" *ngIf="this.pageSettings.pageMegaMenu"
    [ngStyle]="{ 'display': (this.pageSettings.pageMegaMenu && this.pageSettings.pageMobileMegaMenuToggled) ? 'block' : '' }">
    <ul class="nav navbar-nav">
      <li class="dropdown dropdown-lg" ngbDropdown>
        <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle><i
            class="fa fa-th-large fa-fw mr-1 mr-md-0"></i> Mega <b class="caret ml-1 ml-md-0"></b></a>
        <div class="dropdown-menu dropdown-menu-lg" ngbDropdownMenu>
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="f-w-700 text-inverse mb-2">UI Kits</div>
              <div class="row m-b-20">
                <div class="col-lg-6">
                  <ul class="nav d-block">
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> FontAwesome</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Ionicons</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Simple Line Icons</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Typography</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Media Object</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Widget Boxes</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Tabs & Accordions</a></li>
                  </ul>
                </div>
                <div class="col-lg-6">
                  <ul class="nav d-block">
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Unlimited Nav Tabs</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Modal & Notification</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Buttons</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="f-w-700 text-inverse mb-2">Page Options <span class="label label-pink ml-1">11</span></div>
              <div class="row m-b-20">
                <div class="col-lg-6">
                  <ul class="nav d-block">
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Blank Page</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Footer <span
                          class="badge badge-success pt-1 pb-1">NEW</span></a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Page without Sidebar</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Right Sidebar</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Minified Sidebar</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Two Sidebar</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Line Icons</a></li>
                  </ul>
                </div>
                <div class="col-lg-6">
                  <ul class="nav d-block">
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Full Height Content</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Mega Menu</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Light Sidebar</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i
                          class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Large Sidebar</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="f-w-700 text-inverse mb-2">Paragraph</div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis libero purus, fermentum at libero
                convallis, auctor dignissim mauris. Nunc laoreet pellentesque turpis sodales ornare. Nunc vestibulum
                nunc lorem, at sodales velit malesuada congue. Nam est tortor, tincidunt sit amet eros vitae, aliquam
                finibus mauris.
              </p>
              <p>
                Fusce ac ligula laoreet ante dapibus mattis. Nam auctor vulputate aliquam. Suspendisse efficitur, felis
                sed elementum eleifend, ipsum tellus sodales nisi, ut condimentum nisi sem in nibh. Phasellus suscipit
                vulputate purus at venenatis. Quisque luctus tincidunt tempor.
              </p>
            </div>
          </div>
        </div>
      </li>
      <li>
        <a href="javascript:;">
          <i class="fa fa-gem fa-fw mr-1 mr-md-0"></i> Client
        </a>
      </li>
      <li class="dropdown" ngbDropdown>
        <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
          <i class="fa fa-database fa-fw mr-1 mr-md-0"></i> New <b class="caret ml-1 ml-md-0"></b>
        </a>
        <div class="dropdown-menu" ngbDropdownMenu>
          <a href="javascript:;" class="dropdown-item">Action</a>
          <a href="javascript:;" class="dropdown-item">Another action</a>
          <a href="javascript:;" class="dropdown-item">Something else here</a>
          <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item">Separated link</a>
          <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item">One more separated link</a>
        </div>
      </li>
    </ul>
  </div>
  <!-- end mega-menu -->

  <!-- begin header-nav -->
  <ul class="navbar-nav navbar-right">
    <li *ngIf="mostrar_bodegas" class="dropdown" ngbDropdown>
      <a href="javascript:;" ngbDropdownToggle class="dropdown-toggle f-s-14">
        <i class="fa fa-truck-loading"></i>
        <span class="label label-default">{{bodegas.length}}</span>
      </a>
      <div class="dropdown-menu media-list dropdown-menu-right overflow-auto" style="max-height: 500px;"
        ngbDropdownMenu>
        <div class="dropdown-header">Bodegas ({{bodegas.length}})</div>
        <a *ngFor="let bodega of bodegas" href="javascript:;" (click)="cambiarbodega(bodega)"
          class="dropdown-item media">
          <div class="media-left">
            <i class="text-info fas fa-2x fa-fw m-6 fa-warehouse"></i>
          </div>
          <div class="media-body">
            <h6 class="media-heading">{{bodega.codigo}} <label
                class="label label-light">{{bodega.entidad.campo}}</label></h6>
            <div class="text-muted f-s-11">{{bodega.nombre}}</div>
          </div>
        </a>


        <div class="dropdown-footer text-center">
          <a href="javascript:;"></a>
        </div>
      </div>
    </li>
    <li class="dropdown" ngbDropdown *ngIf="entidadesLength">
      <a href="javascript:;" ngbDropdownToggle class="dropdown-toggle f-s-14">
        <i class="fa fa-university"></i>
        <span class="label label-default">{{entidadesLength}}</span>
      </a>
      <div class="dropdown-menu media-list dropdown-menu-right overflow-auto width-400" style="max-height: 500px;"
        ngbDropdownMenu>
        <div class="serchEntitys">
          <div class="dropdown-header">Entidades ({{entidades.length}})</div>
          <div class="media bg-white" *ngIf="entidadesLength > 5">
            <div class="m-10 width-full">
              <input class="form-control" type="text" placeholder="Busca tu entidad aquí..."
                (keyup)="filtrarEntidades($event)" />
            </div>
          </div>
        </div>
        <div *ngIf="entidades.length > 0">
          <a *ngFor="let entidad of entidades" href="javascript:;" (click)="cambiaentidad(entidad)"
            class="dropdown-item media">
            <div class="media-left">
              <i class="text-info far fa-3x fa-fw m-r-10 fa-check-circle"></i>
            </div>
            <div class="media-body">
              <h6 class="media-heading">{{entidad.codigo}} {{entidad.grupo}} <label
                  class="label label-light">{{entidad.campo}}</label></h6>
              <div class="text-muted f-s-11">{{entidad.nombre}}</div>
            </div>
          </a>
        </div>
        <div *ngIf="entidades.length === 0">
          <a href="javascript:;" class="dropdown-item media">
            <div class="media-left">
              <i class="text-danger far fa-3x fa-fw m-r-10 fa-times-circle"></i>
            </div>
            <div class="media-body ">
              <h6 class="media-heading text-wrap">La entidad que esta buscando no se encontró o no tiene acceso a ella.
              </h6>
            </div>
          </a>
        </div>



        <div class="dropdown-footer text-center">
          <a href="javascript:;"></a>
        </div>
      </div>
    </li>
    <li class="dropdown" ngbDropdown *ngIf="notificacion.length > 0">
      <a href="javascript:;" ngbDropdownToggle class="dropdown-toggle f-s-14">
        <i class="fa fa-bell"></i>
        <span class="label">{{cant_notificacion}}</span>
      </a>
      <div class="dropdown-menu media-list dropdown-menu-right dropdown-scroll  notificacion width-400" ngbDropdownMenu>
        <div *ngIf="notificacion.length > 0" class="dropdown-header">
          NOTIFICACIONES ({{cant_notificacion}})
          <span class="pull-right" (click)="eliminarTodo()">Vaciar Notificaciones</span>
        </div>
        <div *ngIf="notificacion.length == 0" class="dropdown-header">
          SIN NOTIFICACIONES PENDIENTES
        </div>

        <div *ngFor="let n of notificacion">
          <a href="javascript:;" class="dropdown-item media" [class.leido]="!n.destinatarios[n.index].leido">
            <div class="media-left">
              <i class="fa fa-info-circle media-object bg-silver-darker"></i>
            </div>
            <div class="media-body">
              <label class="media-heading" (click)="open(modalnotificacion,n)">{{n.asunto |
                slice:0:25}}</label>
              <p (click)="open(modalnotificacion,n)" class="text-muted 
	      f-s-12">{{n.mensaje}}</p>

              <div class="text-muted f-s-11">Fecha: {{n.fecha | date: "dd-MM"}}
                Hora: {{n.fecha | date:"HH:mm"}} Hrs &nbsp;&nbsp;&nbsp;<a class="t" (click)="eliminar(n._id)"> <i class="fa 
		    fa-trash"></i> </a> </div>

            </div>



          </a>
        </div>
      </div>
    </li>

    <li class="dropdown navbar-user" ngbDropdown>
      <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
        <img *ngIf="profilePhotoURL" [src]="profilePhotoURL" alt="profilePhoto" (error)="profilePhotoURL = null"/>
        <span>
          <span class="d-none d-md-inline">{{userData?.displayName}}</span>
          <b class="caret"></b>
        </span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <a href="https://myworkaccount.microsoft.com/" class="dropdown-item">Mi Cuenta</a>
        <a href="javascript:;" (click)="logout()" class="dropdown-item">Cerrar Sesión</a>
      </div>
    </li>
    <li class="divider d-none d-md-block" *ngIf="this.pageSidebarTwo"></li>
    <li class="d-none d-md-block" *ngIf="this.pageSidebarTwo">
      <a href="javascript:;" (click)="toggleSidebarRight()" class="f-s-14">
        <i class="fa fa-th"></i>
      </a>
    </li>
  </ul>
  <!-- end header navigation right -->
</div>
<!-- end #header -->

<ng-template #modalnotificacion let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title t" id="bitacora">{{detalle.asunto}}</h5>
    <button type="button" class="close btn btn-sm btn-white" (click)="d('Cross click')">×</button>
  </div>
  <div class="modal-body detalle">
    <label class="t">{{detalle.mensaje}}</label>

    <div *ngIf="detalle.observaciones">
      <label>Observaciones: </label>
      <pre class="text-muted f-s-11 t">{{detalle.observaciones}}</pre>
    </div>
  </div>
  <div>
    <hr>
    <div class="pull-left ml-3">
      <span>Emisor: {{detalle.remitente.nombre}}</span><br>
      <span>Fecha: {{detalle.fecha | date: "dd-MM HH:mm"}}</span>
    </div>
    <div class="pull-right">
      <button *ngIf="detalle.ruta" class="btn btn-success mb-2" (click)="irRuta(detalle)"><i class="far fa-eye"></i>
        Ir</button>
      <button class="btn btn-danger mx-2 mb-2" (click)="c('Close click')">Cerrar</button>
    </div>
  </div>

</ng-template>
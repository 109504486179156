
import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { EntidadService } from '../../../../services/entidad.service';
import { UploadService } from '../../../../services/upload.service';
import { Global } from '../../../../services/global';
import { UserService } from '../../../../services/user.service';
import { F29Service } from '../../../../services/declaracion_f29.service';
import { Documentacion_f29 } from '../../../../models/documentacion_f29';
import { RevisionPeriodo } from '../../../../models/revision_periodo';
import { Borrador_f29 } from '../../../../models/f29_borrador';
import { ReportesImpuestosService } from '../../../../services/informesPDF.services';
import { detectAnyAdblocker } from 'just-detect-adblock'

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'


declare var jQuery: any;
declare var $: any;
declare var button: any;
import Swal from 'sweetalert2';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThousandsPipe } from '../../../../pipes/thousands.pipe'
import { COLOR_BLACK } from '../../../../config/globals';
import { LocalService } from '../../../../services/localService';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { fileURLToPath } from 'url';

@Component({
  selector: 'app-reportes-impuesto-renta',
  templateUrl: './impuesto-renta.component.html',
  styleUrls: ['./impuesto-renta.component.css'],
  providers: [EntidadService, UploadService, F29Service, UserService, ThousandsPipe, ReportesImpuestosService]
})
export class ReportesImpuestoRentaComponent implements OnInit {
  @Input() borradorF29;
  @Input() archivo

  public url = Global.url_reportes;
  public filesToUpload;
  public token;
  public entidad_sel;
  public url_blob;
  public blob_url;

  public closeResult: string;
  public minified;
  public tablas
  public d = 6
  constructor(

    private _entidadService: EntidadService,
    private _uploadService: UploadService,
    private _userService: UserService,
    private _f29Service: F29Service,
    private modalService: NgbModal,
    private _reportesService: ReportesImpuestosService,
    private _localService: LocalService,
    private sanitizer: DomSanitizer
  ) {
    this.minified = false;
    this.token = this._userService.getToken();
    this.entidad_sel = this._entidadService.getEntidad()

  }

  ngOnInit() {

  }


  sidebar_minified() {

    if (this.minified == false) {
      //$('#page-container').addClass('page-sidebar-minified');
      this.minified = true;
    } else {
      //$('#page-container').removeClass('page-sidebar-minified');
      this.minified = false;
    }

  }

}

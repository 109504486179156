<div class="table-responsive mt-2" *ngFor="let g of borradorF29.grupo">
  
  <h4 class="text-center">{{g.grupo.grupo | uppercase}}</h4>
  <table class="table table-bordered table-striped">
    <thead>
      <th>Entidad</th>
      <th *ngFor="let cod of borradorF29.reporte.codigos">{{cod.casilla}}</th>
    </thead>
    <tbody >
      <tr *ngFor="let r of g.resultados">
        <td class="fit-content">{{r.entidad.codigo}} - {{r.entidad.nombre}} {{r.entidad.campo}}</td>
        <td *ngFor="let val of r.credito_compra">$ {{ val.valor | number}}</td>
      </tr>
      <tr>
        <td><strong>TOTALES {{g.grupo.nombre | uppercase}}</strong></td>
        <td *ngFor="let total of g.totales.credito_compra"><strong>$ {{ total.valor | number}}</strong></td>
      </tr>
    </tbody>
  </table>

</div>

//Importar modulos del router de Angular
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



//Componentes IATUCh
import { InicioComponent } from './modulos/inicio/inicio.component';
import { LoginComponent } from './modulos/administrar/login/login.component';
import { ModificarinformacionComponent } from './modulos/administrar/modificarinformacion/modificarinformacion.component';
import { ReporteAPSComponent } from './modulos/reportes/aps/reporte_aps.component';
// import { PresEducacionComponent } from './modulos/presupuestos/presupuesto/pres_educacion/pres_educacion.component';

// Guard para validar privilegio al ingresar url
import { RutaGuard } from './guards/ruta.guard'
import { SubirexcelComponent } from './modulos/dj1945/subirexcel/subirexcel.component';
import { VerdeclaracionComponent } from './modulos/dj1945/verdeclaracion/verdeclaracion.component';
import { RegistrosDuplicadosComponent } from
  './modulos/dj1945/registros-duplicados/registros-duplicados.component'
import { DeclararImpuestosComponent } from './modulos/impuestos/declarar-impuestos/declarar-impuestos.component';
import { PeriodoEntidadesImpuestosComponent } from './modulos/impuestos/periodo-entidades-impuestos/periodo-entidades-impuestos.component';
import { GestionUsuariosComponent } from './modulos/administrar/gestion-usuarios/gestion-usuarios.component';
import { VerPeriodosAnterioresComponent } from './modulos/impuestos/ver-periodos-anteriores/ver-periodos-anteriores.component';
import { NotificacionesComponent } from './modulos/administrar/notificaciones/notificaciones.component';
import {from} from 'rxjs';
import { RecuperarClaveComponent } from './modulos/administrar/recuperar-clave/recuperar-clave.component';
import { InformeDJComponent } from './modulos/dj1945/informe-dj/informe-dj.component';
import { ReportesImpuestosComponent } from './modulos/impuestos/reportes/reportes-impuestos.component';
import { PresupuestoComponent } from './modulos/presupuestos/presupuesto/presupuesto.component';
import { ReportesBIComponent } from './modulos/presupuestos/reportes-bi/reportes-bi.component';
import { MaintenanceUserComponent } from './modulos/maintenance/maintenance-user/maintenance-users.component';

// Array de Configuracion de Rutas
const appRoutes: Routes = [
  //Rutas IATUCh
	//{path: '', component: InicioComponent, data: { title: 'Inicio'}},
	// { path: '', redirectTo: '/inicio', pathMatch: 'full' },
	{ path: '', component: InicioComponent, data: { title: 'Inicio'}, pathMatch: "full" },
	// { path: 'login', component: LoginComponent, data: { title: 'Iniciar Sesión'} },
  { path: 'recuperar-clave/:token', component: RecuperarClaveComponent, data: { title: 'Recuperar Clave'}},
	{ path: 'modificarinformacion', component: ModificarinformacionComponent, data: { title: 'Modificar Cuenta'}},

  {path: 'recuperar-clave', redirectTo: '/inicio'},
	{ path: 'modificarinformacion/:generica', component: ModificarinformacionComponent, data: { title: 'Modificar Cuenta'}},
	{ path: 'gestionusuarios', component: GestionUsuariosComponent, data: { title: 'Gestión de Usuarios'}, canActivate: [RutaGuard]},
 // { path: 'gestionusuarios', component: MaintenanceUserComponent, data: { title: 'Gestión de Usuarios'}, canActivate: [RutaGuard]},

  { path: 'notificaciones', component: NotificacionesComponent, data: { title: 'Notificaciones'}, canActivate: [RutaGuard]},

  //DJ1945
  { path: 'subirexcel', component: SubirexcelComponent, data: { title: 'Subir Declaracón' }, canActivate: [RutaGuard] },
  { path: 'verdeclaracion', component: VerdeclaracionComponent, data: { title: 'Ver Declaración' }, canActivate: [RutaGuard] },
  {
    path: 'registros-duplicados', component: RegistrosDuplicadosComponent,
    data: { title: 'Registros Duplicados' }, canActivate: [RutaGuard]
  },
  { path: 'informe-dj', component: InformeDJComponent, data: { title: 'Informe DJ1945' }, canActivate: [RutaGuard] },

  //Presupuesto
{ path: 'ingresodepresupuesto', component: PresupuestoComponent, data: { title: 'Presupuesto' }, canActivate: [RutaGuard]  },
// { path: 'presupuestoeducacion', component: PresEducacionComponent, data: { title: 'Presupuesto Educacion'}, canActivate: [RutaGuard] },
{ path: 'reporte_presupuesto', component: ReportesBIComponent, data: { title: 'Reportes Presupuesto' }, canActivate: [RutaGuard] },

  //Impuesto
  { path: 'declarar-impuestos', component: DeclararImpuestosComponent, data: { title: 'Declarar Impuestos' }, canActivate: [RutaGuard] },
  { path: 'ver-periodo-entidades-f29', component: PeriodoEntidadesImpuestosComponent, data: { title: 'Periodos Activos' }, canActivate: [RutaGuard] },
  { path: 'ver-periodo-anteriores-f29', component: VerPeriodosAnterioresComponent, data: { title: 'Periodos Anteriores' }, canActivate: [RutaGuard] },
  { path: 'reportes-f29', component: ReportesImpuestosComponent, data: { title: 'Reportes / Informes' }, canActivate: [RutaGuard] },

  //Reportes APS
  { path: 'reportesaps', component: ReporteAPSComponent, data: { title: 'Reportes APS' }, canActivate: [RutaGuard] },
  
   //user maintence
   {
    path: 'maintenance',
    loadChildren: () => import('./modulos/maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  { path: '**', component: InicioComponent, data: { title: 'Inicio' } },








];

//Exportar el Modulo del Router
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> =
  RouterModule.forRoot(appRoutes);

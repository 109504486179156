import { Component, OnInit, Injectable } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal, NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { EntidadService } from '../../../../services/entidad.service';
import { PresupuestoService } from '../../../../services/presupuesto.service';
import { UserService } from '../../../../services/user.service';
import { Diversos } from '../../../../models/diverso';
import { Colegios } from '../../../../models/colegio';
import { Cuenta_sueldo } from '../../../../models/cuenta_sueldo_educacion';
import { Sueldos_educacion } from '../../../../models/sueldo_educacion';
import Swal from 'sweetalert2'
import { ThousandsPipe } from '../../../../pipes/thousands.pipe'
import { stringify } from 'querystring';
import { parse } from 'path';
// import jsPDF from 'jspdf'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { truncateSync } from 'fs';
import { Factor_Rea } from '../../../../models/factor_rea';
import { detectAnyAdblocker } from 'just-detect-adblock'
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
// declare var angular: any;
// for ngb datepicker adapter
@Injectable()
export class NgbDateNativeAdapter extends NgbDateAdapter<Date> {

  fromModel(date: Date): NgbDateStruct {
    // console.log(date)
    //   return
    return (date && date.getFullYear) ? { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } : null;
  }

  toModel(date: NgbDateStruct): Date {
    // console.log(date)
    return date ? new Date(date.year, date.month - 1, date.day) : null;
  }
}

@Component({
  selector: 'pres_institucion',
  templateUrl: './pres_institucion.component.html',
  styleUrls: ['./pres_institucion.component.css'],
  providers: [EntidadService, PresupuestoService, UserService, NgbModal, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, ThousandsPipe]
})
export class PresInstitucionComponent implements OnInit {
  public presupuesto = 'Institución'
  public entidad_sel = this._entidadService.getEntidad()
  public tipo_pres = ''
  public nabTab = 'variables'
  public tipo_pestana = 'variables'
  public flag_pres = 1
  public diversos: Diversos
  public colegios: Colegios
  closeResult: string
  public lista_diverso
  temp_lista_diverso
  show_diversos = false
  public div_modify = {}
  public cabeceras_departamentos
  public colegios_departamentos
  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  })
  public sub_total_cuentas
  public sueldos
  temp_sueldos
  show_sueldos = false
  public columna_cuentas
  public sub_total_sueldo
  public cuenta_sueldo: Cuenta_sueldo
  public sueldo: Sueldos_educacion
  public ingresos
  temp_ingresos
  show_ingresos = false
  public totales_ingresos
  public length_totales_ingresos
  public departamentos_ingresos
  public departamentos_ingresos_header
  public sub_totales_ingresos
  public sub_totales_depto_ingresos
  public otorgamientos
  temp_otorgamientos
  show_otorgamientos = false
  public totales_otorgamientos
  public length_totales_otorgamientos
  public departamentos_otorgamientos
  public departamentos_otorgamientos_header
  public sub_totales_otorgamientos
  public sub_totales_depto_otorgamientos
  public eventos
  temp_eventos
  show_eventos = false
  public totales_eventos
  public length_totales_eventos
  public departamentos_eventos
  public departamentos_eventos_header
  public sub_totales_eventos
  public sub_totales_depto_eventos
  public gastos_operativos
  temp_gastos_operativos
  show_gastos_operativos = false
  public totales_gastos_operativos
  public length_totales_gastos_operativos
  public departamentos_gastos_operativos
  public departamentos_gastos_operativos_header
  public sub_totales_gastos_operativos
  public sub_totales_depto_gastos_operativos
  public tabla_sueldo
  temp_tabla_sueldo
  show_tabla_sueldo = false
  public totales_tabla_sueldo
  public length_totales_tabla_sueldo
  public departamentos_tabla_sueldo
  public departamentos_tabla_sueldo_header
  public sub_totales_tabla_sueldo
  public sub_totales_depto_tabla_sueldo
  public mostrar_tabla_sueldos = false
  public colegios_votar_a
  public sueldos_votar_a
  public ingresos_votar_a
  public gastos_op_votar_a
  public otorgamientos_votar_a
  public eventos_votar_a
  public total_sueldos_votar_a
  public total_ingresos_votar_a
  public total_gastos_op_votar_a
  public total_otorgamientos_votar_a
  public total_eventos_votar_a
  public total_gastos_votar_a
  public total_diferencia_votar_a
  public colegios_length_votar_a
  public proyeccion_sueldos
  public proyeccion_gastos_operativos
  public proyeccion_eventos
  public proyeccion_otorgamientos
  public switcher_departamentos_tabla = false
  public factor_rea = { factor_411: 0, factor_412: 0, factor_414: 0, factor_419: 0, factor_diferencia: 0 }
  public fac_rea: Factor_Rea
  public votar_a_factor_411
  public votar_a_factor_412
  public votar_a_factor_414
  public votar_a_factor_419
  public votar_a_factor_diferencia
  public errores_importador
  modalOptions = {
    // size: 'lg',
    scrollable: true,
    animation: true,
    backdrop: null
  }


  constructor(
    private _entidadService: EntidadService,
    private _userService: UserService,
    private modalService: NgbModal,
    private _presupuestoService: PresupuestoService
  ) {
    this.entidad_sel = this._entidadService.getEntidad()
    this.diversos = new Diversos('', null, '', 'Educación')
    this.colegios = new Colegios('', '', this.presupuesto)
    this.trae_datos()
    this.trae_colegios()
    this.cuenta_sueldo = new Cuenta_sueldo('', '')
    this.sueldo = new Sueldos_educacion(null, '', '', '')
    this.fac_rea = new Factor_Rea('', '', 0)
  }

  ngDoCheck() { //esta funcion detecta cualquier cambio dentro del sistema
    var cambioEntidad = this._entidadService.getEntidad(); //detecta siempre el valor de la entidad seleccionada aunq se cambie

    if (this.entidad_sel.codigo != cambioEntidad.codigo) { //Compara si la entidad seleccionada al inicio es distinta a la entidad q tiene ahora seleccionada el sistema
      this.cambio() //ejecuta funcion en caso q haya cambiado
    }


  }

  cambio() { //funcion que deberia ejecutarse cuando haya un cambio de entidad
    this.entidad_sel = this._entidadService.getEntidad(); //actualizar la entidad seleccionada con la que haya en localstoge para que this.entidad_sel sea igual a cambioEntidad en DoCheck hasta que la entidad se vuelva a cambiar nuevamente y asi sucesivamente
    this.trae_datos()
  }

  ngOnInit() {
  }

  open(content) { //abrir modal seleccionada
    // console.log(content)
    // this.modalService.dismissAll()
    // this.modalService.open(content, { windowClass: 'modal-holder', centered: true })
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  //opciones por defecto para cualquier modal
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (this.flag_pres === 1 && (changeEvent.nextId === 'cuentas' || changeEvent.nextId === 'resumenes')) {
      changeEvent.preventDefault()
    } else if (this.flag_pres === 2 && changeEvent.nextId === 'resumenes') {
      changeEvent.preventDefault()
    }
  }

  tipo_presupuesto(tipo) {
    // console.log(tipo + ' En funcion tipo_presupuesto')
    if (tipo != 'departamentos') {
      this.switcher_departamentos_tabla = false
    }
    if (this.flag_pres == 1) {
      if (tipo == 'variables' || tipo == 'diversos' || tipo == 'departamentos') {
        this.tipo_pestana = tipo;
        this.tipo_pres = tipo;
      } else {
        return;
      }
    } else if (this.flag_pres == 2) {

      //this.trae_datos();
      if (tipo != 'resumenes') {
        if (tipo == 'cuentas') {
          this.generar_sueldos()
        }
        if (tipo == 'tab_sueldos') {
          this.traer_sueldos()
        } else if (tipo == 'sueldos') {
          this.generar_tabla_sueldos(true)
        } else {
          this.traer_cuentas(tipo)
        }
        this.tipo_pres = tipo;
        this.tipo_pestana = tipo;
      } else {
        return;
      }
    } else if (this.flag_pres == 3) {
      if (tipo == 'resumenes') {
        this.traer_votar_a()
      } else if (tipo == 'cuentas') {
        this.generar_sueldos()
        // this.trae_datos();
      } else if (tipo == 'sueldos') {
        this.generar_tabla_sueldos(true)
        // this.trae_datos();
      } else {
        this.traer_cuentas(tipo)
      }
      this.tipo_pestana = tipo;
      this.tipo_pres = tipo;
    }
  }

  async siguiente(tipo) {
    if (tipo == 'variables') {
      this.flag_pres = 2;
      this.nabTab = 'cuentas'
      await this.tipo_presupuesto('cuentas');
    } else if (tipo == 'cuentas') {
      await this.generar_tabla_sueldos(false)
      this.flag_pres = 3
      this.nabTab = 'resumenes'
    }
  }

  flag_presupuesto(flag) {
    this.flag_pres = flag;
  }

  trae_datos() {
    this.trae_diversos()
    this.trae_colegios()
  }

  clear_form(form) {
    switch (form) {
      case 'diverso':
        this.diversos = new Diversos('', null, '', 'Educación')
        break;
      case 'colegios':
        this.colegios = new Colegios('', '', this.presupuesto)
        break;
      case 'cuenta sueldos':
        this.cuenta_sueldo = new Cuenta_sueldo('', '')
        break;
      case 'sueldos':
        this.sueldo = new Sueldos_educacion(null, '', '', '')
        break;
    }
  }

  agregar_diverso(modal) {
    const n_div = document.getElementById("n_div");
    const nom_div = document.getElementById("nom_div");
    // this.diversos.diverso = stringify(this.diversos.diverso)
    if (this.diversos.diverso == null || this.diversos.nombre == '') {
      if (this.diversos.diverso == null && this.diversos.nombre == '') {
        n_div.classList.add("is-invalid")
        nom_div.classList.add("is-invalid")
      } else if (this.diversos.diverso == null) {
        n_div.classList.add("is-invalid")
        nom_div.classList.remove("is-invalid")
      } else if (this.diversos.nombre == '') {
        n_div.classList.remove("is-invalid")
        nom_div.classList.add("is-invalid")
      }
    } else {
      this._presupuestoService.insertar_diverso(this.diversos).subscribe(
        response => {
          if (response.estado == true) {
            this.Toast.fire({
              icon: 'success',
              title: response.mensaje
            })
            this.modalService.dismissAll()
            this.clear_form('diverso')
            this.trae_diversos()
          } else {
            this.modalService.dismissAll()
            Swal.fire({
              title: "¡Error!",
              text: response.mensaje,
              icon: 'error',
              showCancelButton: true,
              buttonsStyling: true,
              customClass: {
                confirmButton: 'btn btn-lg btn-primary ml-3',
                cancelButton: 'btn btn-lg btn-danger',
              },
              confirmButtonText: 'Volver a intentar',
              cancelButtonText: 'Cerrar'
            }).then((result) => {
              if (result.value) {
                this.open(modal)
                // console.log(response)
                if (response.diverso == false) {
                  document.getElementById("n_div").classList.add("is-invalid")
                }
              } else {
                this.modalService.dismissAll()
              }
            })
          }

        },
        error => { console.log(error) },
      );
    }
  }

  modificar_estado_diverso(id, status) {
    var div = { _id: id, estado: status };
    //console.log(diverso);
    this._presupuestoService.actualiza_diverso(div).subscribe(
      response => {
        if (response.estado == true) {
          this.Toast.fire({
            icon: 'success',
            title: response.mensaje
          })
          this.trae_diversos();
        }
      },
      error => { console.log(error) },
    );

  }

  modificar_diverso_modal(id, diverso, nombre, modal) {
    // console.log(id, diverso, nombre)
    this.div_modify['id'] = id
    this.diversos.diverso = diverso
    this.diversos.nombre = nombre
    this.open(modal)
  }

  modificar_diverso(modal) {
    const n_div = document.getElementById("numero_div");
    const nom_div = document.getElementById("nombre_div");
    // this.diversos.diverso = stringify(this.diversos.diverso)
    if (this.diversos.diverso) {
      this.diversos.diverso = this.diversos.diverso
    } else {
      this.diversos.diverso = null
    }
    if (this.diversos.diverso == null || this.diversos.nombre == '') {
      if (this.diversos.diverso == null && this.diversos.nombre == '') {
        n_div.classList.add("is-invalid")
        nom_div.classList.add("is-invalid")
      } else if (this.diversos.diverso == null) {
        n_div.classList.add("is-invalid")
        nom_div.classList.remove("is-invalid")
      } else if (this.diversos.nombre == '') {
        n_div.classList.remove("is-invalid")
        nom_div.classList.add("is-invalid")
      }
    } else {
      n_div.classList.remove("is-invalid")
      nom_div.classList.remove("is-invalid")
      this.div_modify['diverso'] = this.diversos.diverso
      this.div_modify['nombre'] = this.diversos.nombre
      this._presupuestoService.modificar_diversos(this.div_modify).subscribe(
        response => {
          if (response.estado == true) {
            this.Toast.fire({
              icon: 'success',
              title: response.mensaje
            })
            this.modalService.dismissAll()
            this.clear_form('diverso')
            this.trae_diversos()
          } else {
            this.modalService.dismissAll()
            Swal.fire({
              title: "¡Error!",
              text: response.mensaje,
              icon: 'error',
              showCancelButton: true,
              buttonsStyling: true,
              customClass: {
                confirmButton: 'btn btn-lg btn-primary ml-3',
                cancelButton: 'btn btn-lg btn-danger',
              },
              confirmButtonText: 'Volver a intentar',
              cancelButtonText: 'Cerrar'
            }).then((result) => {
              if (result.value) {
                this.open(modal)
              } else {
                this.modalService.dismissAll()
              }
            })
          }

        },
        error => { console.log(error) },
      );
    }
  }

  trae_diversos() {
    var diver;

    this._presupuestoService.trae_diversos(diver).subscribe(
      response => {
        // console.log(response);
        this.lista_diverso = response.resultado;
        this.show_diversos = this.lista_diverso.length ? true : false;
        this.temp_lista_diverso = [...this.lista_diverso]
        // console.log(this.lista_diverso);
      },
      error => { console.log(error) },
    );
  }

  agregar_colegio(modal) {
    // console.log(this.colegios)
    const nombre_col = document.getElementById("nombre_col")
    // const nivel_col = document.getElementById("nivel_col")
    if (this.colegios.nombre == ''/* || this.colegios.nivel == ''*/) {
      /*if (this.colegios.nombre == '' && this.colegios.nivel == '') {
        nombre_col.classList.add("is-invalid")
        nivel_col.classList.add("is-invalid")
      } else if (this.colegios.nombre == '') {
        nombre_col.classList.add("is-invalid")
        nivel_col.classList.remove("is-invalid")
      } else if (this.colegios.nivel == '') {
        nombre_col.classList.remove("is-invalid")
        nivel_col.classList.add("is-invalid")
      }*/

    } else {
      // nivel_col.classList.remove("is-invalid")
      nombre_col.classList.remove("is-invalid")
      this._presupuestoService.insertar_colegio(this.colegios).subscribe(
        response => {
          if (response.estado == true) {
            this.Toast.fire({
              icon: 'success',
              title: response.mensaje
            })
            this.modalService.dismissAll()
            this.clear_form('colegios')
            this.trae_datos();
          } else {
            this.modalService.dismissAll()
            Swal.fire({
              title: "¡Error!",
              text: response.mensaje,
              icon: 'error',
              showCancelButton: true,
              buttonsStyling: true,
              customClass: {
                confirmButton: 'btn btn-lg btn-primary ml-3',
                cancelButton: 'btn btn-lg btn-danger',
              },
              confirmButtonText: 'Volver a intentar',
              cancelButtonText: 'Cerrar'
            }).then((result) => {
              if (result.value) {
                this.open(modal)
              } else {
                this.modalService.dismissAll()
              }
            })
          }

        },
        error => { console.log(error) },
      );
      // console.log(this.colegios)
    }

  }

  trae_colegios() {
    this._presupuestoService.traer_colegios().subscribe(
      response => {
        // console.log(response)
        if (response.estado == true) {
          this.cabeceras_departamentos = response.departamentos_cabeceras
          this.colegios_departamentos = response.colegios
          // console.log(this.cabeceras_departamentos)
          // console.log(this.colegios_departamentos)
        } else {
          this.cabeceras_departamentos = ''
          this.colegios_departamentos = ''
        }
      },
      error => { console.log(error) },
    );
  }

  agregar_tipo_subvencion(modal, grupo_departamento) {
    const subvencion = {
      tipo_presupuesto: this.tipo_presupuesto,
      grupo_departamento: grupo_departamento
    }
    const tipo_subvencion = document.getElementById("subvencion")
    if (grupo_departamento == '') {
      tipo_subvencion.classList.add("is-invalid")
    } else {
      tipo_subvencion.classList.remove("is-invalid")
      this._presupuestoService.insertar_departamento_en_colegio(subvencion).subscribe(
        response => {
          // console.log(response)
          if (response.estado == true) {
            this.Toast.fire({
              icon: 'success',
              title: response.mensaje
            })
            this.modalService.dismissAll()
            this.trae_colegios()
          } else {
            this.modalService.dismissAll()
            Swal.fire({
              title: "¡Error!",
              text: response.mensaje,
              icon: 'error',
              showCancelButton: true,
              buttonsStyling: true,
              customClass: {
                confirmButton: 'btn btn-lg btn-primary ml-3',
                cancelButton: 'btn btn-lg btn-danger',
              },
              confirmButtonText: 'Volver a intentar',
              cancelButtonText: 'Cerrar'
            }).then((result) => {
              if (result.value) {
                this.open(modal)
              } else {
                this.modalService.dismissAll()
              }
            })
          }
        },
        error => { console.log(error) },
      );
    }

  }

  modificar_departamentos_colegios(id, tipo, valor, indice) {
    const depto = {
      id: id,
      valor: valor,
      indice: indice,
      tipo: tipo,
      tipo_presupuesto: this.presupuesto
    }
    // console.log(depto)
    this._presupuestoService.modificar_departamentos_colegios(depto).subscribe(
      response => {
        // console.log(response)
        if (response.estado == true) {
          this.Toast.fire({
            icon: 'success',
            title: response.mensaje
          })
          this.trae_colegios()
        } else {
          Swal.fire({
            title: "¡Error!",
            text: response.mensaje,
            icon: 'error',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-danger ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'OK'
          })
        }
      },
      error => { console.log(error) },
    );
  }

  eliminar_departamentos_colegios(valor, tipo) {
    const parametro = {
      valor: valor,
      tipo: tipo,
      tipo_presupuesto: this.presupuesto
    }

    Swal.fire({
      title: tipo == 'Colegio' ? '¿Estás seguro que quieres eliminar este departamento?' : '¿Estás seguro que quieres eliminar este grupo de departamentos?',
      text: tipo == 'Colegio' ? 'Se borrará toda la información asociada a este departamento' : 'Se borrará toda la información asociada a este grupo de departamentos',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: true,
      confirmButtonColor: '#d33',
      /*customClass: {
        confirmButton: 'btn btn-lg btn-danger ml-3',
        cancelButton: 'btn btn-lg btn-primary',
      },*/
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this._presupuestoService.eliminar_departamentos_colegios(parametro).subscribe(
          response => {
            if (response.estado == true) {
              this.Toast.fire({
                icon: 'success',
                title: response.mensaje
              })
              this.trae_colegios()
            } else {
              Swal.fire({
                title: "¡Atención!",
                text: response.mensaje,
                icon: 'warning',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-lg btn-danger ml-3',
                  cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'OK'
              })
            }

          },
          error => { console.log(error) },
        );
      }
    })
    return

  }

  generar_sueldos() {
    Swal.fire({
      text: "Generando sueldos",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    this._presupuestoService.generar_departamentos_cuentas().subscribe(
      response => {
        if (response.estado == true) {
          this._presupuestoService.agregar_sueldos().subscribe(
            response => {
              if (response.estado == true) {
                this.Toast.fire({
                  icon: 'success',
                  title: response.mensaje
                })
                this.traer_cuentas('ingresos')
                this.traer_sueldos()
              } else {
                Swal.close()
                this.traer_cuentas('ingresos')
              }

            },
            error => { console.log(error) },
          );
        } else {
          this.tipo_presupuesto('departamentos')
          Swal.fire({
            title: "¡Atención!",
            text: response.mensaje,
            icon: 'warning',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-danger ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'OK'
          })
        }

      },
      error => { console.log(error) },
    );
  }

  traer_sueldos() {
    this._presupuestoService.traer_sueldo().subscribe(
      response => {
        if (response.estado == true) {
          this.sub_total_cuentas = response.sub_totales_cuentas
          this.sueldos = response.sueldos
          this.show_sueldos = this.sueldos.length ? true : false;
          this.temp_sueldos = [...this.sueldos]
          this.columna_cuentas = response.sueldos[0].cuentas
          this.sub_total_sueldo = response.sub_total
        } else {
          this.sueldos = '';
          this.sub_total_cuentas = '';
          this.columna_cuentas = [];
        }
      },
      error => { console.log(error) },
    );
  }

  get_nombre_cuenta_sueldo() {
    this._presupuestoService.encontrar_cuenta_sueldo(this.cuenta_sueldo).subscribe(
      response => {
        if (response.estado == true) {
          this.cuenta_sueldo.nombre_cuenta = response.nombre_cuenta;
          //console.log(this.genera_cuentas_extras);
        } else {
          Swal.fire({
            title: "¡Error!",
            text: response.mensaje,
            icon: 'error',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-danger ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'OK'
          })
        }
      },
      error => { console.log(error) },
    );
  }

  agregar_cuenta_sueldos(modal) {
    //console.log(this.genera_cuentas_extras); return;
    const n_cuenta_sueldo = document.getElementById("n_cuenta_sueldo")
    const nombre_cuenta_sueldo = document.getElementById("nombre_cuenta_sueldo")
    if (this.cuenta_sueldo.numero_cuenta == '' || this.cuenta_sueldo.nombre_cuenta == '') {
      if (this.cuenta_sueldo.numero_cuenta == '' && this.cuenta_sueldo.nombre_cuenta == '') {
        n_cuenta_sueldo.classList.add("is-invalid")
        nombre_cuenta_sueldo.classList.add("is-invalid")
      } else if (this.cuenta_sueldo.numero_cuenta == '') {
        n_cuenta_sueldo.classList.add("is-invalid")
        nombre_cuenta_sueldo.classList.remove("is-invalid")
      } else if (this.cuenta_sueldo.nombre_cuenta == '') {
        n_cuenta_sueldo.classList.remove("is-invalid")
        nombre_cuenta_sueldo.classList.add("is-invalid")
      }

    } else {
      nombre_cuenta_sueldo.classList.remove("is-invalid")
      n_cuenta_sueldo.classList.remove("is-invalid")

      this._presupuestoService.agregar_cuenta_sueldos(this.cuenta_sueldo).subscribe(
        response => {
          if (response.estado == true) {
            this.traer_sueldos();
            this.clear_form('cuenta sueldos')
            this.modalService.dismissAll()
            this.Toast.fire({
              icon: 'success',
              title: response.mensaje
            })
          } else {
            this.modalService.dismissAll()
            Swal.fire({
              title: "¡Error!",
              text: response.mensaje,
              icon: 'error',
              showCancelButton: true,
              buttonsStyling: true,
              customClass: {
                confirmButton: 'btn btn-lg btn-primary ml-3',
                cancelButton: 'btn btn-lg btn-danger',
              },
              confirmButtonText: 'Volver a intentar',
              cancelButtonText: 'Cerrar'
            }).then((result) => {
              if (result.value) {
                this.open(modal)
              } else {
                this.modalService.dismissAll()
              }
            })
          }
        },
        error => { console.log(error) },
      );
    }
  }

  modificar_sueldo(id, valor, tipo, index) {
    if (valor == '' && tipo != 'Codigo Departamento') {
      valor = 0
    }
    const sueldo_modificado = {
      id: id,
      valor: valor,
      tipo: tipo,
      index: index
    }
    // console.log(sueldo_modificado)
    this._presupuestoService.modificar_sueldo(sueldo_modificado).subscribe(
      response => {
        if (response.estado == true) {
          this.traer_sueldos();
          this.Toast.fire({
            icon: 'success',
            title: response.mensaje
          })
        } else {
          Swal.fire({
            title: "¡Error!",
            text: response.mensaje,
            icon: 'error',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-danger ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'OK'
          })
        }
      },
      error => { console.log(error) },
    );
  }

  get_datos_sueldo(dato) {
    let get_datos
    if (dato == 'diverso') {
      get_datos = {
        tipo_dato: dato,
        valor: this.sueldo.diverso
      }
    } else if (dato == 'departamento') {
      get_datos = {
        tipo_dato: dato,
        valor: this.sueldo.codigo_departamento
      }
    }
    this._presupuestoService.encontrar_datos_sueldo(get_datos).subscribe(
      response => {
        if (response.estado == true) {
          if (dato == 'diverso') {
            this.sueldo.nombre = response.nombre;
          } else if (dato == 'departamento') {
            this.sueldo.nombre_departamento = response.nombre_departamento;
          }
        } else {
          Swal.fire({
            title: "¡Error!",
            text: response.mensaje,
            icon: 'error',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-danger ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'OK'
          })
        }
      },
      error => { console.log(error) },
    );
  }

  agregar_sueldo(modal) {
    //console.log(this.genera_cuentas_extras); return;
    const sueldo_diverso = document.getElementById("sueldo_diverso")
    const sueldo_departamento = document.getElementById("sueldo_departamento")
    if (this.sueldo.nombre == '' || this.sueldo.nombre_departamento == '') {
      if (this.sueldo.nombre == '' && this.sueldo.nombre_departamento == '') {
        sueldo_diverso.classList.add("is-invalid")
        sueldo_departamento.classList.add("is-invalid")
      } else if (this.sueldo.nombre == '') {
        sueldo_diverso.classList.add("is-invalid")
        sueldo_departamento.classList.remove("is-invalid")
      } else if (this.sueldo.nombre_departamento == '') {
        sueldo_diverso.classList.remove("is-invalid")
        sueldo_departamento.classList.add("is-invalid")
      }
    } else {
      sueldo_departamento.classList.remove("is-invalid")
      sueldo_diverso.classList.remove("is-invalid")
      this._presupuestoService.insertar_sueldo(this.sueldo).subscribe(
        response => {
          if (response.estado == true) {
            this.traer_sueldos();
            this.clear_form('sueldos')
            this.modalService.dismissAll()
            this.Toast.fire({
              icon: 'success',
              title: response.mensaje
            })
          } else {
            this.modalService.dismissAll()
            Swal.fire({
              title: "¡Error!",
              text: response.mensaje,
              icon: 'error',
              showCancelButton: true,
              buttonsStyling: true,
              customClass: {
                confirmButton: 'btn btn-lg btn-primary ml-3',
                cancelButton: 'btn btn-lg btn-danger',
              },
              confirmButtonText: 'Volver a intentar',
              cancelButtonText: 'Cerrar'
            }).then((result) => {
              if (result.value) {
                this.open(modal)
              } else {
                this.modalService.dismissAll()
              }
            })
          }
        },
        error => { console.log(error) },
      );
    }
  }

  insertar_cuentas(tipo_cuenta) {
    // console.log(tipo_cuenta)
    const cuenta = {
      tipo_cuenta: tipo_cuenta
    }
    switch (tipo_cuenta) {
      case 'gastos_operativos':
        this._presupuestoService.insertar_cuentas(cuenta).subscribe(
          response => {
            if (response.estado == true) {
              this.Toast.fire({
                icon: 'success',
                title: response.mensaje
              })
              this.traer_cuentas(tipo_cuenta)
            } else {
              Swal.fire({
                title: "¡Error!",
                text: response.mensaje,
                icon: 'error',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-lg btn-danger ml-3',
                  cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'OK'
              })
            }
          },
          error => { console.log(error) },
        )
        break
      case 'reuniones_eventos':
        this._presupuestoService.insertar_cuentas(cuenta).subscribe(
          response => {
            if (response.estado == true) {
              this.Toast.fire({
                icon: 'success',
                title: response.mensaje
              })
              this.traer_cuentas(tipo_cuenta)
            } else {
              Swal.fire({
                title: "¡Error!",
                text: response.mensaje,
                icon: 'error',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-lg btn-danger ml-3',
                  cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'OK'
              })
            }
          },
          error => { console.log(error) },
        )
        break
      case 'otorgamientos':
        this._presupuestoService.insertar_cuentas(cuenta).subscribe(
          response => {
            if (response.estado == true) {
              this.Toast.fire({
                icon: 'success',
                title: response.mensaje
              })
              this.traer_cuentas(tipo_cuenta)
            } else {
              Swal.fire({
                title: "¡Error!",
                text: response.mensaje,
                icon: 'error',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-lg btn-danger ml-3',
                  cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'OK'
              })
            }
          },
          error => { console.log(error) },
        )
        break
      case 'ingresos':
        this._presupuestoService.insertar_cuentas(cuenta).subscribe(
          response => {
            if (response.estado == true) {
              this.Toast.fire({
                icon: 'success',
                title: response.mensaje
              })
              this.traer_cuentas(tipo_cuenta)
            } else {
              Swal.fire({
                title: "¡Error!",
                text: response.mensaje,
                icon: 'error',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-lg btn-danger ml-3',
                  cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'OK'
              })
            }
          },
          error => { console.log(error) },
        )
        break
    }
  }

  traer_cuentas(tipo_cuenta) {
    // console.log(tipo_cuenta)
    if (tipo_cuenta == 'cuentas') {
      tipo_cuenta = 'ingresos'
    }
    const cuenta = {
      tipo_cuenta: tipo_cuenta
    }
    // console.log(tipo_cuenta)
    switch (tipo_cuenta) {
      case 'sueldos':
        this._presupuestoService.traer_cuentas(cuenta).subscribe(
          response => {
            // console.log(response)
            if (response.estado == true) {
              this.tabla_sueldo = response.registro
              this.show_tabla_sueldo = this.tabla_sueldo.length ? true : false;
              this.temp_tabla_sueldo = [...this.tabla_sueldo]
              this.totales_tabla_sueldo = response.registro[0].totales
              this.length_totales_tabla_sueldo = this.totales_tabla_sueldo.length
              this.departamentos_tabla_sueldo = response.registro[0].departamentos
              this.departamentos_tabla_sueldo_header = response.departamentos_header
              this.sub_totales_tabla_sueldo = response.sub_totales
              this.sub_totales_depto_tabla_sueldo = response.sub_totales_deptos
              this.mostrar_tabla_sueldos = true
            } else {
              this.generar_tabla_sueldos(true);
            }
          },
          error => { console.log(error) },
        );
        break
      case 'gastos_operativos':
        this._presupuestoService.traer_cuentas(cuenta).subscribe(
          response => {
            // console.log(response)
            if (response.estado == true) {
              this.gastos_operativos = response.registro
              this.show_gastos_operativos = this.gastos_operativos.length ? true : false;
              this.temp_gastos_operativos = [...this.gastos_operativos]
              this.totales_gastos_operativos = response.registro[0].totales
              this.length_totales_gastos_operativos = this.totales_gastos_operativos.length
              this.departamentos_gastos_operativos = response.registro[0].departamentos
              this.departamentos_gastos_operativos_header = response.departamentos_header
              this.sub_totales_gastos_operativos = response.sub_totales
              this.sub_totales_depto_gastos_operativos = response.sub_totales_deptos
            } else {

            }
          },
          error => { console.log(error) },
        )
        break
      case 'reuniones_eventos':
        this._presupuestoService.traer_cuentas(cuenta).subscribe(
          response => {
            // console.log(response)
            if (response.estado == true) {
              this.eventos = response.registro
              this.show_eventos = this.eventos.length ? true : false;
              this.temp_eventos = [...this.eventos]
              this.totales_eventos = response.registro[0].totales
              this.length_totales_eventos = this.totales_eventos.length
              this.departamentos_eventos = response.registro[0].departamentos
              this.departamentos_eventos_header = response.departamentos_header
              this.sub_totales_eventos = response.sub_totales
              this.sub_totales_depto_eventos = response.sub_totales_deptos
            } else {

            }
          },
          error => { console.log(error) },
        )
        break
      case 'otorgamientos':
        this._presupuestoService.traer_cuentas(cuenta).subscribe(
          response => {
            // console.log(response)
            if (response.estado == true) {
              this.otorgamientos = response.registro
              this.show_otorgamientos = this.otorgamientos.length ? true : false;
              this.temp_otorgamientos = [...this.otorgamientos]
              this.totales_otorgamientos = response.registro[0].totales
              this.length_totales_otorgamientos = this.totales_otorgamientos.length
              this.departamentos_otorgamientos = response.registro[0].departamentos
              this.departamentos_otorgamientos_header = response.departamentos_header
              this.sub_totales_otorgamientos = response.sub_totales
              this.sub_totales_depto_otorgamientos = response.sub_totales_deptos
            } else {

            }
          },
          error => { console.log(error) },
        )
        break
      case 'ingresos':
        this._presupuestoService.traer_cuentas(cuenta).subscribe(
          response => {
            // console.log(response)
            if (response.estado == true) {
              this.ingresos = response.registro
              this.show_ingresos = this.ingresos.length ? true : false;
              this.temp_ingresos = [...this.ingresos]
              this.totales_ingresos = response.registro[0].totales
              this.length_totales_ingresos = this.totales_ingresos.length
              this.departamentos_ingresos = response.registro[0].departamentos
              this.departamentos_ingresos_header = response.departamentos_header
              // console.log(this.departamentos_ingresos)
              this.sub_totales_ingresos = response.sub_totales
              this.sub_totales_depto_ingresos = response.sub_totales_deptos
            } else {

            }
          },
          error => { console.log(error) },
        )
        break
    }
  }

  get_departamentos_cuentas(departamentos) {
    let subvenciones = []
    if (departamentos) {
      // console.log('Hola')
      for (let i = 0; i < departamentos.length; i++) {
        const d = departamentos[i];
        // console.log(d.subvencion.length)
        // return
        for (let j = 0; j < d.subvencion.length; j++) {
          const s = d.subvencion[j];
          if (s.departamento != '') {
            s.index_colegio = i
            s.index_subvencion = j
            subvenciones.push(s)
          }
        }
      }
      // console.log(subvenciones)  
      return subvenciones
    }
  }

  get_header_colegios_cuentas(colegios) {
    let col = []
    if (colegios) {
      for (let i = 0; i < colegios.length; i++) {
        const e = colegios[i];
        if (e.colspan > 0) {
          col.push(e)
        }
      }
      // console.log(col)
      return col
    }
  }

  modificar_cuentas(tipo_cuenta, tipo_modificacion, valor, id, indice_departamento, indice_subvencion) {
    if (tipo_modificacion == 'Subvencion') {
      if (valor) {
        valor = parseInt((valor).toString().replace(".", "").replace(".", "").replace(".", "")) || 0
      } else {
        valor = 0
      }

    }
    const modificacion = {
      tipo_cuenta: tipo_cuenta,
      tipo_modificacion: tipo_modificacion,
      valor: valor,
      id: id,
      indice_departamento: indice_departamento,
      indice_subvencion: indice_subvencion
    }
    // console.log(modificacion)
    // return
    switch (tipo_cuenta) {
      case 'ingresos':
        // console.log('Hola')
        this._presupuestoService.modificar_cuentas_educacion(modificacion).subscribe(
          response => {
            // console.log(response)
            if (response.estado == true) {
              this.Toast.fire({
                icon: 'success',
                title: response.mensaje
              })
              this.traer_cuentas(tipo_cuenta)
            } else {
              Swal.fire({
                title: "¡Error!",
                text: response.mensaje,
                icon: 'error',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-lg btn-danger ml-3',
                  cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'OK'
              })
              this.traer_cuentas(tipo_cuenta)
            }
          },
          error => { console.log(error) },
        )
        break;
      case 'otorgamientos':
        this._presupuestoService.modificar_cuentas_educacion(modificacion).subscribe(
          response => {
            // console.log(response)
            if (response.estado == true) {
              this.Toast.fire({
                icon: 'success',
                title: response.mensaje
              })
              this.traer_cuentas(tipo_cuenta)
            } else {
              Swal.fire({
                title: "¡Error!",
                text: response.mensaje,
                icon: 'error',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-lg btn-danger ml-3',
                  cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'OK'
              })
              this.traer_cuentas(tipo_cuenta)
            }
          },
          error => { console.log(error) },
        )
        break;
      case 'gastos_operativos':
        this._presupuestoService.modificar_cuentas_educacion(modificacion).subscribe(
          response => {
            // console.log(response)
            if (response.estado == true) {
              this.Toast.fire({
                icon: 'success',
                title: response.mensaje
              })
              this.traer_cuentas(tipo_cuenta)
            } else {
              Swal.fire({
                title: "¡Error!",
                text: response.mensaje,
                icon: 'error',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-lg btn-danger ml-3',
                  cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'OK'
              })
              this.traer_cuentas(tipo_cuenta)
            }
          },
          error => { console.log(error) },
        )
        break;
      case 'reuniones_eventos':
        this._presupuestoService.modificar_cuentas_educacion(modificacion).subscribe(
          response => {
            // console.log(response)
            if (response.estado == true) {
              this.Toast.fire({
                icon: 'success',
                title: response.mensaje
              })
              this.traer_cuentas(tipo_cuenta)
            } else {
              Swal.fire({
                title: "¡Error!",
                text: response.mensaje,
                icon: 'error',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-lg btn-danger ml-3',
                  cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'OK'
              })
              this.traer_cuentas(tipo_cuenta)
            }
          },
          error => { console.log(error) },
        )
        break;
    }

  }

  generar_tabla_sueldos(swal_flag): any {
    var validacion_departamentos = true
    if (swal_flag == true) {
      Swal.fire({
        text: "Generando cuentas 411",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })
    }
    this._presupuestoService.generar_tabla_sueldos().subscribe(
      response => {
        // console.log('response de la funcion principal:' + response)
        if (response.estado == true) {
          for (let i = 0; i < this.sueldos.length; i++) {
            const e = this.sueldos[i];
            if (e.codigo_departamento == '') {
              validacion_departamentos = false
            }
          }
          if (validacion_departamentos == true) {
            this.mostrar_tabla_sueldos = true
            if (swal_flag == true) {
              this.Toast.fire({
                icon: 'success',
                title: response.mensaje
              })
            }
            if (!swal_flag) this.tipo_presupuesto('resumenes')
            this.traer_cuentas('sueldos')
          } else {
            this.mostrar_tabla_sueldos = false
            Swal.fire({
              title: "¡Error!",
              text: "Tienes diversos sin un departamento asignado. Corrige este error antes de continuar",
              icon: 'error',
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-lg btn-danger ml-3',
                cancelButton: 'btn btn-lg btn-danger',

              },
              confirmButtonText: 'OK'
            })
          }
        } else if (response.estado == false) {
          this.mostrar_tabla_sueldos = false
          Swal.fire({
            title: "¡Atención!",
            text: response.mensaje,
            icon: 'warning',
            showCancelButton: false,
            buttonsStyling: true,
            customClass: {
              confirmButton: 'btn btn-lg btn-danger ml-3',
            },
            confirmButtonText: 'Ok',
          }).then((result) => {
            if (result.value) {
              this.tipo_presupuesto('tab_sueldos');
              // this.traer_411();
              // Swal.close();
            }
          })
        }
      },
      error => { console.log(error) },
    )

  }

  traer_votar_a() {
    this._presupuestoService.generar_rea().subscribe(
      response => {
        this._presupuestoService.traer_votar_a_instituciones().subscribe(
          response => {
            // console.log(response)
            if (response.estado == true) {
              this.factor_rea = response.factor_reas;
              this.colegios_votar_a = response.colegios
              this.sueldos_votar_a = response.totales_colegios_sueldos
              this.ingresos_votar_a = response.totales_colegios_ingresos
              this.gastos_op_votar_a = response.totales_colegios_gastos_operativos
              this.otorgamientos_votar_a = response.totales_colegios_otorgamientos
              this.eventos_votar_a = response.totales_colegios_eventos
              this.total_sueldos_votar_a = response.totales_sueldos
              this.total_ingresos_votar_a = response.totales_ingresos
              this.total_gastos_op_votar_a = response.totales_gastos_op
              this.total_otorgamientos_votar_a = response.totales_otorgamientos
              this.total_eventos_votar_a = response.totales_eventos
              this.total_gastos_votar_a = response.totales_colegios_gastos
              this.total_diferencia_votar_a = response.totales_colegios_diferencia
              this.colegios_length_votar_a = response.colegios.length
              this.proyeccion_sueldos = (this.total_sueldos_votar_a / this.total_ingresos_votar_a) == Infinity ? 0 : (this.total_sueldos_votar_a / this.total_ingresos_votar_a)
              this.proyeccion_gastos_operativos = (this.total_gastos_op_votar_a / this.total_ingresos_votar_a) == Infinity ? 0 : (this.total_gastos_op_votar_a / this.total_ingresos_votar_a)
              this.proyeccion_eventos = (this.total_eventos_votar_a / this.total_ingresos_votar_a) == Infinity ? 0 : (this.total_eventos_votar_a / this.total_ingresos_votar_a)
              this.proyeccion_otorgamientos = (this.total_otorgamientos_votar_a / this.total_ingresos_votar_a) == Infinity ? 0 : (this.total_otorgamientos_votar_a / this.total_ingresos_votar_a)
              this.votar_a_factor_411 = response.factor_411
              this.votar_a_factor_412 = response.factor_412
              this.votar_a_factor_414 = response.factor_414
              this.votar_a_factor_419 = response.factor_419
              this.votar_a_factor_diferencia = response.factor_diferencia
            }

          },
          error => { console.log(error) },
        )
      },
      error => { console.log(error) },
    );

  }

  modificar_factor_rea(id, tipo, valor) {
    this.fac_rea = new Factor_Rea(id, tipo, valor);
    this._presupuestoService.modificar_rea(this.fac_rea).subscribe(
      response => {
        if (response.estado == true) {
          this.Toast.fire({
            icon: 'success',
            title: response.mensaje
          })
          this.traer_votar_a();
        }
      },
      error => { console.log(error) },
    );
  }

  generarPDF_votar_a() {
    // console.log(this.colegios_length_votar_a)
    let flag_adblock = false
    const foot_length = 1 + this.colegios_length_votar_a
    const head_length = 1 + this.colegios_length_votar_a
    detectAnyAdblocker().then((detected) => {
      if (detected) {
        Swal.fire({
          title: "¡Atención!",
          text: "Hemos detectado que hay instalado un adblock en el navegador. Por favor desactivelo, actualice la página, y vuelva a intentar generar el reporte.",
          icon: 'warning',
          showCancelButton: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-lg btn-danger ml-3',
            cancelButton: 'btn btn-lg btn-danger',

          },
          confirmButtonText: 'OK'
        })
      } else {
        if (this.colegios_length_votar_a > 5) {
          var logoIASD = new Image();
          var marca_agua = new Image();
          logoIASD.src = 'assets/images/cover-sidebar-user1.jpg';
          marca_agua.src = 'assets/images/IASD6.png';
          marca_agua
          Swal.fire({
            text: "Generando reporte Votar A",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading()
            },
          })
          var generar_tabla;
          this._presupuestoService.datos_pdf(generar_tabla).subscribe(
            response => {
              Swal.close();
              var descripcion
              if (response.entidad.nombre_campo) {
                descripcion = response.entidad.nombre_campo + ' - ' + response.entidad.nombre + ' - ' + response.entidad.codigo;
              } else {
                descripcion = response.entidad.nombre + ' - ' + response.entidad.codigo;
              }
              var año = response.year;
              var titulo = 'Presupuesto ' + año;
              var footer = '© 2019 Iglesia Adventista del Séptimo Día - Unión Chilena';
              var pdf = new jsPDF({ orientation: "landscape" });

              pdf.setFontSize(22);
              pdf.text(titulo, 150, 30, { align: "center" });
              pdf.setFontSize(18);
              pdf.text('Votar A', 150, 40, { align: "center" });
              autoTable(pdf, {
                html: '#votar_a_print',
                tableWidth: 'auto',
                headStyles: {
                  fillColor: [226, 231, 235],
                  textColor: [0, 0, 0]
                },
                footStyles: {
                  fillColor: [226, 231, 235],
                  textColor: [0, 0, 0]
                },
                didParseCell: function (data) {
                  if (data.row.section === 'head') {
                    data.cell.styles.halign = 'center' // Use an icon in didDrawCell instead

                  }
                  if (data.row.section === 'head' && (data.column.index > head_length)) {
                    data.cell.styles.fillColor = false;
                  }
                  if (data.row.section === 'head' && data.row.index === 0 && data.column.index <= head_length) {
                    data.cell.styles.lineWidth = 0.1;
                  }
                  if (data.row.section === 'head' && data.column.index < 3) {
                    data.cell.styles.lineWidth = 0.1;
                  }
                  if (data.row.section === 'body' && (data.row.index === 0 && data.column.index > head_length)) {
                    data.cell.styles.fontStyle = 'bold';
                    data.cell.styles.halign = 'center';
                    data.cell.styles.textColor = [0, 0, 0];
                    data.cell.styles.cellWidth = 'wrap'
                  }
                  /*if (data.row.section === 'body' && (data.row.index === 0 && data.column.index <= head_length)) {
                    data.cell.styles.lineWidth = 0.1;
                  }*/
                  if (data.row.section === 'foot' && data.column.index <= head_length && data.row.index === 0) {
                    data.cell.styles.lineWidth = 0.1;
                  }
                  if (data.row.section === 'foot' && data.row.index === 1) {
                    data.cell.styles.lineWidth = 0.1;
                  }
                  if (data.row.section === 'foot' && data.column.index === 0) {
                    data.cell.styles.halign = 'center' // Use an icon in didDrawCell instead
                  }
                  /*if (data.row.section === 'body' && (data.row.index === 5)) {
                      data.cell.styles.fillColor = [226, 231, 235];
                      data.cell.styles.textColor = [0, 0, 0];
                      data.cell.styles.fontStyle = 'bold';
                      if (data.column.index === 1 || data.column.index === 2 || data.column.index === 3) {
                          data.cell.styles.halign = 'left';
                      } else {
                          data.cell.styles.halign = 'center';
                      }
                  }*/
                  if (data.row.section === 'foot' && data.row.index == 0 && data.column.index > foot_length) {
                    data.cell.styles.fillColor = false;
                  }
                },
                didDrawPage: function (data) {
                  // Header
                  pdf.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
                  // Footer
                  pdf.setFontSize(6);
                  pdf.text(descripcion, 280, 200, { align: 'right' });
                  pdf.setFontSize(6);
                  pdf.text(footer, data.settings.margin.left, 200);
                  pdf.addImage(marca_agua, 'PNG', 50, 10, 200, 200);
                },
                margin: { top: 30, bottom: 30 },
                theme: 'grid',
                startY: 70,
                styles: { cellWidth: 'auto', fontSize: 5 },
                columnStyles: { text: { cellWidth: 'auto' } },
                showHead: 'firstPage',
                showFoot: 'lastPage'
              });
              // pdf.autoPrint();
              var blob = pdf.output("blob");
              window.open(URL.createObjectURL(blob));
            },
            error => { console.log(error) },
          );
        } else {
          var logoIASD = new Image();
          var marca_agua = new Image();
          logoIASD.src = 'assets/images/cover-sidebar-user1.jpg';
          marca_agua.src = 'assets/images/IASD6.png';
          marca_agua
          Swal.fire({
            text: "Generando reporte Votar A",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading()
            },
          })
          var generar_tabla;
          this._presupuestoService.datos_pdf(generar_tabla).subscribe(
            response => {
              Swal.close();
              var descripcion
              if (response.entidad.nombre_campo) {
                descripcion = response.entidad.nombre_campo + ' - ' + response.entidad.nombre + ' - ' + response.entidad.codigo;
              } else {
                descripcion = response.entidad.nombre + ' - ' + response.entidad.codigo;
              }
              var año = response.year;
              var titulo = 'Presupuesto ' + año;
              var footer = '© 2019 Iglesia Adventista del Séptimo Día - Unión Chilena';
              var pdf = new jsPDF();

              pdf.setFontSize(22);
              pdf.text(titulo, 70, 30);
              pdf.setFontSize(18);
              pdf.text('Votar A', 90, 40);
              autoTable(pdf, {
                html: '#votar_a_print',
                tableWidth: 'auto',
                headStyles: {
                  fillColor: [226, 231, 235],
                  textColor: [0, 0, 0]
                },
                footStyles: {
                  fillColor: [226, 231, 235],
                  textColor: [0, 0, 0]
                },
                didParseCell: function (data) {
                  if (data.row.section === 'head') {
                    data.cell.styles.halign = 'center' // Use an icon in didDrawCell instead

                  }
                  if (data.row.section === 'head' && (data.column.index > head_length)) {
                    data.cell.styles.fillColor = false;
                  }
                  if (data.row.section === 'head' && data.row.index === 0 && data.column.index <= head_length) {
                    data.cell.styles.lineWidth = 0.1;
                  }
                  if (data.row.section === 'head' && data.column.index < 3) {
                    data.cell.styles.lineWidth = 0.1;
                  }
                  if (data.row.section === 'body' && (data.row.index === 0 && data.column.index > head_length)) {
                    data.cell.styles.fontStyle = 'bold';
                    data.cell.styles.halign = 'center';
                    data.cell.styles.textColor = [0, 0, 0];
                    data.cell.styles.cellWidth = 'wrap'
                  }
                  /*if (data.row.section === 'body' && (data.row.index === 0 && data.column.index <= head_length)) {
                    data.cell.styles.lineWidth = 0.1;
                  }*/
                  if (data.row.section === 'foot' && data.column.index <= head_length && data.row.index === 0) {
                    data.cell.styles.lineWidth = 0.1;
                  }
                  if (data.row.section === 'foot' && data.row.index === 1) {
                    data.cell.styles.lineWidth = 0.1;
                  }
                  if (data.row.section === 'foot' && data.column.index === 0) {
                    data.cell.styles.halign = 'center' // Use an icon in didDrawCell instead
                  }
                  /*if (data.row.section === 'body' && (data.row.index === 5)) {
                      data.cell.styles.fillColor = [226, 231, 235];
                      data.cell.styles.textColor = [0, 0, 0];
                      data.cell.styles.fontStyle = 'bold';
                      if (data.column.index === 1 || data.column.index === 2 || data.column.index === 3) {
                          data.cell.styles.halign = 'left';
                      } else {
                          data.cell.styles.halign = 'center';
                      }
                  }*/
                  if (data.row.section === 'foot' && data.row.index == 0 && data.column.index > foot_length) {
                    data.cell.styles.fillColor = false;
                  }
                },
                didDrawPage: function (data) {
                  // Header
                  pdf.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
                  // Footer
                  pdf.setFontSize(6);
                  pdf.text(descripcion, 196, 280, { align: 'right' });
                  pdf.setFontSize(6);
                  pdf.text(footer, data.settings.margin.left, 280);
                  pdf.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
                },
                margin: { top: 30, bottom: 30 },
                theme: 'grid',
                startY: 50,
                styles: { cellWidth: 'auto', fontSize: 6 },
                columnStyles: { text: { cellWidth: 'auto' } },
                showHead: 'firstPage',
                showFoot: 'lastPage'
              });
              var blob = pdf.output("blob");
              window.open(URL.createObjectURL(blob));
            },
            error => { console.log(error) },
          );
        }
      }
    });

  }

  archivo_importador(modal, validar) {
    // Swal.fire({
    //   title: "¡Lo sentimos!",
    //   text: 'En estos momentos le estamos haciendo mantención al importador. Tan pronto como vuelva a estar operativo avisaremos. Disculpa las molestias.',
    //   icon: 'warning',
    //   showCancelButton: false,
    //   buttonsStyling: false,
    //   customClass: {
    //     confirmButton: 'btn btn-lg btn-primary ml-3',
    //     cancelButton: 'btn btn-lg btn-danger',

    //   },
    //   confirmButtonText: 'OK'
    // })
    // var archivo_importador;

    Swal.fire({
      text: "Generando Archivo Importador",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })

    this._presupuestoService.generar_importador().subscribe(
      response => {
        this.swal_descarga_importador(modal, validar)
      },
      error => { console.log(error) },
    );
    //this.swal_descarga_importador();
  }

  swal_descarga_importador(modal, validacion) {
    var archivo_importador = {
      'diferencia_gastos': this.total_ingresos_votar_a - (this.total_sueldos_votar_a + this.total_gastos_op_votar_a + this.total_otorgamientos_votar_a + this.total_eventos_votar_a),
      'total_sueldos': this.total_sueldos_votar_a,
      'total_gastos_op': this.total_gastos_op_votar_a,
      'total_eventos': this.total_eventos_votar_a,
      'total_otorgamientos': this.total_otorgamientos_votar_a,
      'total_ingresos': this.total_ingresos_votar_a,
      validacion: validacion
    };
    var archivo;
    Swal.fire({
      text: "Revisando Archivo Importador",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    this._presupuestoService.revisar_importador(archivo_importador).subscribe(
      response => {
        this._presupuestoService.traer_importador().subscribe(
          response => {
            // console.log(response)
            if (response.estado == false) {
              this.errores_importador = response.datos;
              Swal.close();
              this.open(modal)
            } else if (response.estado == true) {
              Swal.fire({
                title: "¡Felicidades!",
                text: "El Importador se genero exitosamente",
                icon: 'success',
                showCancelButton: true,
                buttonsStyling: true,
                customClass: {
                  confirmButton: 'btn btn-lg btn-primary ml-3',
                  cancelButton: 'btn btn-lg btn-danger',
                },
                confirmButtonText: 'Descargar',
                cancelButtonText: 'Cerrar'
              }).then((result) => {
                if (result.value) {
                  this.trer_archivo_importador(response);
                  Swal.close();
                }
              })

            }
          },
          error => { console.log(error) },
        );
      },
      error => { console.log(error) },
    );
  }

  trer_archivo_importador(data) {
    //console.log('traigo el archivo');
    //console.log(response.datos.length);//return;
    var nombre_archivo = 'Importador Presupuesto 2021, ' + data.datos[0].EntityCode + ', ';
    this._presupuestoService.exportAsExcelFile(data.datos, nombre_archivo);
  }

  separador_miles(event: any) {
    event.target.value = event.target.value.toString()
      .replace(".", "")
      .replace(".", "")
      .replace(".", "")
      .replace(/(\d+)(\d{3})(\d{3})(\d{3})$/, "$1.$2.$3.$4")
      .replace(/(\d+)(\d{3})(\d{3})$/, "$1.$2.$3")
      .replace(/(\d+)(\d{3})$/, "$1.$2")
  }

  f_error() {
    let timerInterval
    let timer
    let temp2
    Swal.fire({
      title: '¡Se ha detectado un error!',
      icon: 'error',
      html: 'Por seguridad la página se refrescará automáticamente en <b></b> segundos.',
      timer: 5000,
      allowOutsideClick: false,
      showCancelButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            // console.log(b)
            if (b) {
              timer = JSON.stringify(parseInt(JSON.stringify(Swal.getTimerLeft())) / 1000).split('.')
              b.textContent = timer[0]
            }
          }
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        location.reload()
      }
    })
  }

  evento_switcher(event: any) {
    if (this.switcher_departamentos_tabla == false) {
      this.switcher_departamentos_tabla = true
    } else if (this.switcher_departamentos_tabla == true) {
      this.switcher_departamentos_tabla = false
    }

    // console.log(this.switcher_departamentos_tabla)
  }

  updateFilter(event, datos) {
    const val = event.target.value.toLowerCase()
    switch (datos) {
      case 'diverso':
        const tempDiverso = this.temp_lista_diverso.filter(function (d) {
          let dataFilterer
          if ((d.diverso.toString().indexOf(val) !== -1 || !val) == false) {
            if ((d.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
            } else {
              dataFilterer = d.nombre.toLowerCase().indexOf(val) !== -1 || !val
            }
          } else {
            dataFilterer = d.diverso.toString().indexOf(val) !== -1 || !val
          }
          return dataFilterer;
        })
        this.lista_diverso = tempDiverso;
        this.show_diversos = this.lista_diverso.length ? true : false;
        break;
      case 'sueldos':
        const tempSueldos = this.temp_sueldos.filter(function (d) {
          let dataFilterer
          if ((d.diverso.toString().indexOf(val) !== -1 || !val) == false) {
            if ((d.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
              if ((d.nombre_departamento.toLowerCase().indexOf(val) !== -1 || !val) == false) {
              } else {
                dataFilterer = d.nombre_departamento.toLowerCase().indexOf(val) !== -1 || !val
              }
            } else {
              dataFilterer = d.nombre.toLowerCase().indexOf(val) !== -1 || !val
            }
          } else {
            dataFilterer = d.diverso.toString().indexOf(val) !== -1 || !val
          }
          return dataFilterer;
        })
        this.sueldos = tempSueldos;
        this.show_sueldos = this.sueldos.length ? true : false;
        break;
      case 'tabla_sueldos':
        const tempSuelInd = this.temp_tabla_sueldo.filter(function (d) {
          let dataFilterer
          if ((d.n_cuenta.toString().indexOf(val) !== -1 || !val) == false) {
            if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
            } else {
              dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
            }
          } else {
            dataFilterer = d.n_cuenta.toString().indexOf(val) !== -1 || !val
          }
          return dataFilterer;
        })
        this.tabla_sueldo = tempSuelInd;
        this.show_tabla_sueldo = this.tabla_sueldo.length ? true : false;
        break;
      case 'gastosop':
        const tempGasOp = this.temp_gastos_operativos.filter(function (d) {
          let dataFilterer
          if ((d.n_cuenta.toString().indexOf(val) !== -1 || !val) == false) {
            if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
            } else {
              dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
            }
          } else {
            dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
          }
          return dataFilterer;
        })
        this.gastos_operativos = tempGasOp;
        this.show_gastos_operativos = this.gastos_operativos.length ? true : false;
        break;
      case 'eventos':
        const tempEventos = this.temp_eventos.filter(function (d) {
          let dataFilterer
          if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
            if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
            } else {
              dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
            }
          } else {
            dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
          }
          return dataFilterer;
        })
        this.eventos = tempEventos;
        this.show_eventos = this.eventos.length ? true : false;
        break;
      case 'otorgamientos':
        const tempOtorg = this.temp_otorgamientos.filter(function (d) {
          let dataFilterer
          if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
            if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
            } else {
              dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
            }
          } else {
            dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
          }
          return dataFilterer;
        })
        this.otorgamientos = tempOtorg;
        this.show_otorgamientos = this.otorgamientos.length ? true : false;
        break;
      case 'ingresos':
        const tempIngresos = this.temp_ingresos.filter(function (d) {
          let dataFilterer
          if ((d.n_cuenta.indexOf(val) !== -1 || !val) == false) {
            if ((d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
            } else {
              dataFilterer = d.nombre_cuenta.toLowerCase().indexOf(val) !== -1 || !val
            }
          } else {
            dataFilterer = d.n_cuenta.indexOf(val) !== -1 || !val
          }
          return dataFilterer;
        })
        this.ingresos = tempIngresos;
        this.show_ingresos = this.ingresos.length ? true : false;
        break;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Router, ActivatedRoute, Params, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-reportes-bi',
  templateUrl: './reportes-bi.component.html',
  styleUrls: ['./reportes-bi.component.css']
})
export class ReportesBIComponent implements OnInit {

  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    Swal.fire({
      title: 'Información',
      text: "Estamos Trabajando para darle un Mejor Servicio, pronto tendremos nuevas actualizaciones",
      icon: 'info',
      showCancelButton: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-lg btn-info ml-3',
        cancelButton: 'btn btn-lg btn-danger',
        
      },
      confirmButtonText: 'Ok'
    }).then(() => {
      this._router.navigate(['/inicio']);
    })
   }

  ngOnInit() {
  }

}

export class Modificacion_cuentas_departamentos{
    constructor(
        public id: String,
        public valor: Number,
        public cuenta: String,
        public indice: Number,
        public departamento: String,
        public n_departamento: Number,
        public tipo: String

    ){}
}
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { EntidadService } from '../../../services/entidad.service';
import { UploadService } from '../../../services/upload.service';
import { Global } from '../../../services/global';
//import swal from 'sweetalert';

import { Solicitud_Usuario } from '../../../models/solicitud_usuario';
import { NgModel, FormGroup, FormControl, FormArray } from '@angular/forms';
import * as _ from "lodash";
import Swal from 'sweetalert2'
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-gestion-usuarios',
  templateUrl: './gestion-usuarios.component.html',
  styleUrls: ['./gestion-usuarios.component.css'],
  providers:[EntidadService, UploadService, UserService]
})
export class GestionUsuariosComponent implements OnInit {
  closeResult: string;
  public minified;
  public identity;
  public token;
  public entidad_sel;
  public solicitud;
  public cargando:boolean;
  public subirSolicitud;
  public status;
  public planilla;
  public fecha;
  public estado;
  public listaPlanilla = [];
  public listaUsuario = [];
  public listaBusqueda = [];
  public listaPrivilegios = [];
  public entidadChecked = []
  public titulo;
  public titulo_solicitud = "Solicitudes Planillas";
	public descripcion ="Administrar";
  public icono = "fas fa-fw fa-users";
  public nombre_archivo = "";
  public usuarioMod;
  public filesToUpload: Array<File>;
  public tipoCarga = "individual";
  public usuario; //Objeto usuario
  public entidades; // Lista de entidades del usuario actual, la uso para listar las posibles entidades a asignar al nuevo usuario
  public entidadesUsuario = []; //Lista de entidades a asignar al nuevo usuario
  public resultado;   //Si no recibe ningun resultado a las consultas de usuarios y planillas no se muestra la seccion de resultados
  public checkEntidades;

  @ViewChild('archivo_solicitud', {static: false}) archivo_solicitud: ElementRef;
  //@ViewChild('archivo_solicitud') archivo_solicitud: ElementRef;
  rootNode:any;

  constructor(
    private _userService: UserService,
    private _entidadService: EntidadService,
    private _uploadService: UploadService,
    rootNode: ElementRef,
    private modalService: NgbModal
  ){

    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.rootNode = rootNode;
    this.usuario = new Solicitud_Usuario("","","","",[],"","","");
    this.usuarioMod = {
      nombre: "",
      apellido: "",
      rut: "",
      email: "",
      entidades: [],
      cargo: "",
      privilegios: "",
      privilegiosActual: "",
      campo_institucion:""
    }
    this.entidad_sel = this._entidadService.getEntidad();

  }

  ngDoCheck() {
    var cambioEntidad = this._entidadService.getEntidad();

    if(this.entidad_sel.codigo != cambioEntidad.codigo){
      //console.log("Son distintos! "+this.entidad_sel.codigo + "|" + cambioEntidad.codigo)
      this.cambio()
    }


  }

  cambio(){
    this.entidad_sel = this._entidadService.getEntidad();
    //console.log("Cambiando...");
  }

  open(content) {

    this.modalService.open(content,{windowClass: 'modal-holder', centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.close(content)
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.close(content)
    });
  }
  close(content){
    this.modalService.dismissAll()
    this.modalService.open(content,{windowClass: 'animate__animated animate__zoomOut', centered: true})
    setTimeout(() => {
      this.modalService.dismissAll()
    }, 500);


  }

  private getDismissReason(reason: any): string {

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  abrir(){
    document.getElementById('btn_carga_masiva').click();
  }
  ngOnInit() {
    this.getPlanillaUsuario();
    this.carga(this.tipoCarga);
  }

  fileChageEvent(fileInput: any){

    this.filesToUpload = <Array<File>>fileInput.target.files;
    var xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    if(this.filesToUpload.length == 1 && this.filesToUpload[0].type == xlsx){


      this.nombre_archivo = fileInput.target.files[0].name;
    }else{

      Swal.fire({
        title: 'Error!',
        text: 'Debe Ingresar un Archivo Valido (XLSX)',
        icon: 'warning',
        showCancelButton: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-lg btn-warning ml-3',
          cancelButton: 'btn btn-lg btn-danger',

        },
        confirmButtonText: 'OK'
      }).then(() => {

      })
      //console.log("Debe ingresar un Archivo xlxs"); return
    }


  }

  // Agregar y Quitar entidades a la variable entidades del objeto Usuario
  agregarEntidad(entidad){
    this.entidadesUsuario.push(entidad.codigo);
  }

  quitarEntidad(entidad){
    this.entidadesUsuario = this.entidadesUsuario.filter(s => s !== entidad.codigo);
  }

  carga(tipo){
    switch(tipo){
      case "individual":
        this.entidadesUsuario = []
        this.titulo = "Solicitud Usuarios Nuevos";
        this.titulo_solicitud = "Solicitudes Usuarios";
        this.tipoCarga = tipo;
        this.entidades = this.identity.entidad[0].entidades;
        this.getListaPrivilegios();
        this.getsolicitudIndividualUsuario();
        for (let ec = 0; ec < this.entidades.length; ec++) {
          const ic = this.entidades[ec];
          this.entidades[ec].seleccionado = false
        }
        break
      case "masiva":
        this.entidadesUsuario = []
        this.titulo = "Carga Masiva de Usuarios"
        this.tipoCarga = tipo;
        this.getPlanillaUsuario();
        break
      case "administracion":
        this.entidadesUsuario = []
        this.titulo = "Administración de Usuarios";
        this.tipoCarga = tipo;
        this.entidades = this.identity.entidad[0].entidades;
        this.getListaPrivilegios();
        break
    }

  }

  onSubmit(form){
    this.cargando = true
    this.entidad_sel = this._entidadService.getEntidad();
    if (this.filesToUpload) {
      this._uploadService.makeFileRequest(Global.url + "save-planilla/", [], this.filesToUpload, 'archivo', this.token)
        .then((result: any) => {
          form.reset();
          this.cargando = false
          this.nombre_archivo = ""
          this.getPlanillaUsuario();

        },
      error => {
          this.cargando = false;

          Swal.fire({
            title: 'Error!',
            text: 'Ocurrio un error al cargar registros, intente mas tarde!',
            icon: 'error',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-danger ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'OK'
          })
        }
      );
    }
  }

  onSubmit2(form){
    this.cargando = true
    this.entidad_sel = this._entidadService.getEntidad();
    if(this.entidadesUsuario.length > 0){
      this.usuario.entidades = this.entidadesUsuario
      this._userService.solicitudIndividualUsuario(this.usuario, "Nuevo").subscribe(
        response => {
          this.cargando=false
          form.reset()
          this.getsolicitudIndividualUsuario()
        }
      )
    }else{
      Swal.fire({
        //title: 'Error!',
        text: 'Debes seleccionar al menos una Entidad',
        icon: 'warning',
        showCancelButton: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-lg btn-danger ml-3',
          cancelButton: 'btn btn-lg btn-danger',

        },
        confirmButtonText: 'OK'
      })

    }
  }

  onSubmit3(form){
    this.cargando = true;
    this.listaPlanilla = [];
    this.listaUsuario = [];
    if(form.busquedaselect == "") {
      Swal.fire({
        //title: 'Error!',
        text: 'Debe seleccionar algun metodo de busqueda',
        icon: 'info',
        showCancelButton: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-lg btn-info ml-3',
          cancelButton: 'btn btn-lg btn-danger',

        },
        confirmButtonText: 'OK'
      })

      this.cargando = false;
    }else{
      this._userService.administrarUsuario(form.busquedaselect,form.busqueda).subscribe(
        response => {
          if(response == false){
            this.cargando = false;
            this.resultado = false;
            Swal.fire({
              //title: 'Error!',
              text: 'No se encontro resultados',
              icon: 'info',
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-lg btn-info ml-3',
                cancelButton: 'btn btn-lg btn-danger',

              },
              confirmButtonText: 'OK'
            })

          }else{

            this.listaBusqueda = response;
            this.titulo_solicitud = "Busqueda de usuarios";
            this.cargando = false;
            //console.log(response)
            if(this.listaBusqueda.length > 0){
              this.resultado = 3;
            }else{
              this.resultado = 0
            }
          }
        }
      )
    }
  }

  onSubmit4(form){
    if(this.entidadesUsuario.length > 0){
      if(this.usuarioMod.rut != ""){
        //console.log(this.usuarioMod)
        if(this.usuarioMod.privilegios.nombre){
          this.usuarioMod.privilegios = this.usuarioMod.privilegios.nombre
        }
        this.usuarioMod.entidades = this.entidadesUsuario
        //console.log(this.usuarioMod)
        this._userService.solicitudIndividualUsuario(this.usuarioMod, "Actualizar").subscribe(
          response => {
            this.getsolicitudIndividualUsuario()
          }
        )
      }else{
        Swal.fire({
          //title: 'Error!',
          text: 'El Rut es obligatorio para continuar',
          icon: 'warning',
          showCancelButton: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-lg btn-danger ml-3',
            cancelButton: 'btn btn-lg btn-danger',

          },
          confirmButtonText: 'OK'
        })

      }

    }else{
      Swal.fire({
        //title: 'Error!',
        text: 'Debes seleccionar al menos una Entidad',
        icon: 'warning',
        showCancelButton: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-lg btn-danger ml-3',
          cancelButton: 'btn btn-lg btn-danger',

        },
        confirmButtonText: 'OK'
      })

    }
  }

  cambiar_privilegio(tipo){
    this.usuarioMod.privilegios = tipo
    //console.log(this.usuarioMod.privilegios)
  }



  editar(index, tipo){
    switch(tipo){
      case "Modificar":
        var um = this.listaBusqueda[index]
        this.entidadesUsuario = []
        this.usuarioMod = {
          nombre_usuario: um.nombre_usuario,
          nombre: um.nombre,
          apellido: um.apellido,
          rut: um.rut,
          entidadesActuales: um.entidad,
          entidades: um.entidades,
          email: um.email,
          cargo: um.cargo,
          privilegiosActual: {nombre: um.privilegios.nombre, descripcion: um.privilegios.descripcion},
          privilegios: um.privilegios,
          campo_institucion: um.campo_institucion
          
        }
        // Verifica si tiene rut vacio
        if(this.usuarioMod.rut == ""){
          this.usuarioMod.rutValido = false
        }

        // Checkea las entidades seleccionadas
        for (let ec = 0; ec < this.entidades.length; ec++) {
          const ic = this.entidades[ec];
          let seleccionado = false
          for (let es = 0; es < this.usuarioMod.entidades.length; es++) {
            const codEntidad = this.usuarioMod.entidades[es];

            if(ic.codigo == codEntidad){
              seleccionado = true
              this.entidades[ec].seleccionado = seleccionado
              this.entidadesUsuario.push(codEntidad)
            }
          }

          if(seleccionado == false){
            this.entidades[ec].seleccionado = seleccionado
          }
        }

      break
      case "Clave":
        this._userService.solicitudIndividualUsuario(this.listaBusqueda[index], "Clave").subscribe(
          response => {
            Swal.fire({
              //title: 'Error!',
              text: 'Se ha pedido el Reestablecimiento de contraseña ',
              icon: 'success',
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-lg btn-success ml-3',
                cancelButton: 'btn btn-lg btn-danger',

              },
              confirmButtonText: 'OK'
            })

          }
        )
      break
      case "Baja":
        this._userService.solicitudIndividualUsuario(this.listaBusqueda[index], "Baja").subscribe(
          response => {
            Swal.fire({
              //title: 'Error!',
              text: 'Se ha solicitado la baja de usuario ',
              icon: 'success',
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-lg btn-success ml-3',
                cancelButton: 'btn btn-lg btn-danger',

              },
              confirmButtonText: 'OK'
            })

          }
        )
      break
    }
  }

  getListaPrivilegios(){
    this.listaPrivilegios = []
    this.listaUsuario = []
    this.listaPlanilla = [];
    this._userService.getListaPrivilegios().subscribe(
      response => {
        this.listaPrivilegios = response
        //console.log(response)
      }
    )
  }

  getsolicitudIndividualUsuario(){
    this.cargando = true;
    this.listaUsuario = []
    this.listaPlanilla = [];
    this._userService.getSolicitudIndividualUsuario().subscribe(
      response => {
        //console.log(response)
        if(response == false){
          this.cargando = false;
          this.resultado = false;


        }else{


          this.listaUsuario = response;
          this.titulo_solicitud = "Solicitud Individual de Usuarios";
          this.cargando = false;
          if(this.listaUsuario.length > 0){
            this.resultado = 2;
          }else{
            this.resultado = 0
          }
        }
      }
    )
  }

  getPlanillaUsuario(){
    this.cargando = true;
    this.listaUsuario = []
    this.listaPlanilla = [];
    this._userService.getPlanillaUsuario().subscribe(
      response => {
        if(response == false){
          this.cargando = false;
          this.resultado = false;


        }else{



          this.listaPlanilla = response;
          this.titulo_solicitud = "Solicitudes Planillas";
          this.cargando = false;
          if(this.listaPlanilla.length > 0){
            this.resultado = 1;
          }else{
            this.resultado = 0
          }
        }
      }
    )
  }

  getSolicitudUsuario(id_solicitud){
    this.cargando = true;
    this.listaUsuario = []
    this.listaPlanilla = [];
    this._userService.getSolicitudUsuario(id_solicitud).subscribe(
      response => {
        if(response == false){
          this.cargando = false;
          this.resultado = false;

          Swal.fire({
            //title: 'Error!',
            text: 'Aun no ha ingresado ninguna solicitud',
            icon: 'info',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-info ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'OK'
          })

        }else{

          this.listaUsuario = response;
          this.titulo_solicitud = "Solicitud de Usuarios";
          this.cargando = false;
          if(this.listaUsuario.length > 0){
            this.resultado = 2;
          }else{
            this.resultado = 0
          }
        }
      }

    )
  }



}

import { Component, OnInit } from '@angular/core';
import pageSettings from '../../config/page-settings';
import { LocalService } from '../../services/localService';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  pageSettings = pageSettings;

  public titulo = " Inicio";
	public descripcion ="Sistemas UCh";
  public icono = "fas fa-home fa-fw";


  constructor(private _localService: LocalService) {

   // this.pageSettings.pageWithFooter = true

  }

  ngOnInit() {
  }

}

export class Sueldos_independientes{
    constructor(
        public _id: string,
        public codigo_departamento: String,
        public diverso: Number,
        public nombre: String,
        public nombre_departamento: String,
        public fmr_per : Number,
        public sueldo_12: Number,
        public sueldo_13 : Number,
        public prima_inf : Number,
        public ayud_salud : Number,
        public ayud_medica : Number,
        public ayuda_sal_med : Number,
        public apv : String,
        public afp_apv : Number,
        public calefa : Number,
        public alquiler : Number,
        public alquiler_13 : Number,
        public vale_aliment : Number,
        public viajes : Number,
        public pt_telefono : Number,
        public telefono : Number,
        public beca_esco : Number,
        public beca_univ : Number,
        public est_super : Number,
        public ppg : Number,
        public eq_smart_prof : Number,
        public agua : Number,
        public aporte_unit : Number,
        public seguro_vida : Number,
        public seguro_viajes : Number,
        public seguro_equipo : Number,
        public seguro_efectos : Number,
        public seguro_salud : Number,
        public fpv : Number,
        public remb_mudanza : Number,
        public prov_final : Number,
        public bono_dia_esp : Number,
        public aguinaldo : Number,
        public dif_imp_renta : Number,
        public total : Number,
        public red_pers : Number,
        public ced_campos_uch : Number,
        public ultima_actualizacion : Date,
        public year : Number,
        public entidad : JSON

    ){}
}
<!-- begin login -->
<div class="login login-with-news-feed">
  <!-- begin news-feed -->
  <div class="news-feed">
    <div class="news-image" style="background-image: url(../assets/img/login-bg/login-bg-11.jpg)"></div>
    <div class="news-caption">
      <h4 class="caption-title">Sistemas <b>UCh</b></h4>
      <p>
        Iglesia Adventista del Séptimo Día
      </p>
    </div>
  </div>
  <!-- end news-feed -->
  <!-- begin right-content -->
  <div class="right-content">
    <!-- begin login-header -->
    <div class="login-header">
      <div class="brand center">
        <img width="100%" src="assets/img/iasdlogo.png" alt=""/>
      </div>
      <div class="icon hide">
        <i class="fa fa-sign-in-alt"></i>
      </div>
    </div>
    <!-- end login-header -->
    <!-- begin login-content -->
    <div class="login-content">
      <div *ngIf="estadoformulario && recuperarclave" class="container">
        <h4 class="mb-4">Cree una nueva contraseña</h4>
        <input type="password" class="form-control form-control-lg mb-2" name="nuevaClave" placeholder="Nueva contraseña"
               [(ngModel)]="recuperarclave.claveNueva">
        <input type="password" class="form-control form-control-lg mb-3" name="repeticionNuevaClave" placeholder="Repita su nueva contraseña"
               [(ngModel)]="recuperarclave.claveConfirmada">
        <small class="invalid-feedback d-block" [ngClass] = "{'valid-feedback': estado, 'invalid-feedback': !estado}"></small>
        <button class="btn btn-primary btn-block btn-lg" type="submit" (click)="actualizarClave(recuperarclave)">Cambiar contraseña</button>

        <div *ngIf="estado == false && mensaje">
          <div class="alert alert-danger col-md-12 mt-3">
            {{ mensaje }}
          </div>
        </div>
      </div>

    </div>
    <!-- end login-content -->
  </div>
  <!-- end right-container -->
</div>
<!-- end login -->



// Core Module
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { routing, appRoutingProviders } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
//import { MatSortModule, MatTableModule }         from '@angular/material';
//import * as global                               from './config/globals';

// Main Component
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarRightComponent } from './components/sidebar-right/sidebar-right.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { PanelComponent } from './components/panel/panel.component';
import { FloatSubMenuComponent } from './components/float-sub-menu/float-sub-menu.component';
import { InputSearchComponent } from './components/inputSearch/inputSearch.component';


// Component Module
//import { AgmCoreModule }                   from '@agm/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

//import { FullCalendarModule }              from '@fullcalendar/angular';

//import { NgxChartsModule }                 from '@swimlane/ngx-charts';
import { TrendModule } from 'ngx-trend';
//import { HighlightJsModule }               from 'ngx-highlight-js';
//import { CountdownModule }                 from 'ngx-countdown';
//import { ChartsModule }                    from 'ng4-charts/ng4-charts';
import { TagInputModule } from 'ngx-chips';
//import { SweetAlert2Module }               from '@sweetalert2/ngx-sweetalert2';
//import { Ng2TableModule }                  from 'ngx-datatable/ng2-table';
import { NvD3Module } from 'ng2-nvd3';
//import { NgxDaterangepickerMd }            from 'ngx-daterangepicker-material';
//import 'd3';
import 'nvd3';
//import { CalendarModule, DateAdapter }     from 'angular-calendar';
//import { adapterFactory }                  from 'angular-calendar/date-adapters/date-fns';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';




import { InicioComponent } from './modulos/inicio/inicio.component';
import { ReporteAPSComponent } from './modulos/reportes/aps/reporte_aps.component';
import { GestionUsuariosComponent } from './modulos/administrar/gestion-usuarios/gestion-usuarios.component';
import { LoginComponent } from './modulos/administrar/login/login.component';
import { ModificarinformacionComponent } from './modulos/administrar/modificarinformacion/modificarinformacion.component';
import { SubirexcelComponent } from './modulos/dj1945/subirexcel/subirexcel.component';
import { VerdeclaracionComponent } from './modulos/dj1945/verdeclaracion/verdeclaracion.component';
import { DeclararImpuestosComponent } from './modulos/impuestos/declarar-impuestos/declarar-impuestos.component';
import { PeriodoEntidadesImpuestosComponent } from './modulos/impuestos/periodo-entidades-impuestos/periodo-entidades-impuestos.component';
import { VerPeriodosAnterioresComponent } from './modulos/impuestos/ver-periodos-anteriores/ver-periodos-anteriores.component';

//import { RutaGuard } from './guards/ruta.guard';
//import { APP_BASE_HREF } from '@angular/common';


/*********** Pipe *****************/
//importar pipe
import { CalculadoraPipe } from './pipes/calculadora.pipe';
import { ThousandsPipe } from './pipes/thousands.pipe';
//import { LOCALE_ID } from '@angular/core';
import { registerLocaleData, APP_BASE_HREF } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { RutaGuard } from './guards/ruta.guard';
import { NotificacionesComponent } from './modulos/administrar/notificaciones/notificaciones.component';
import { RegistrosDuplicadosComponent } from './modulos/dj1945/registros-duplicados/registros-duplicados.component';
import { RecuperarClaveComponent } from './modulos/administrar/recuperar-clave/recuperar-clave.component';
import { BodegaService } from "./services/bodegaSelect.service";
import { InformeDJComponent } from './modulos/dj1945/informe-dj/informe-dj.component';
import { ReportesImpuestosComponent } from './modulos/impuestos/reportes/reportes-impuestos.component';
import { ReportesDebitoVentasComponent } from './modulos/impuestos/reportes/debito-venta/debito-venta.component';
import { ReportesCreditoCompraComponent } from './modulos/impuestos/reportes/credito-compra/credito-compra.component';
import { ReportesImpuestoRentaComponent } from './modulos/impuestos/reportes/impuesto-renta/impuesto-renta.component';
import { UserService } from './services/user.service';
import { SharingService } from './services/sharing.service';
import { EntidadService } from './services/entidad.service';
import { PresupuestoComponent } from './modulos/presupuestos/presupuesto/presupuesto.component';
import { PresEducacionComponent } from './modulos/presupuestos/presupuesto/pres_educacion/pres_educacion.component';
import { PresIglesiaComponent } from './modulos/presupuestos/presupuesto/pres_iglesia/pres_iglesia.component';
import { PresInstitucionComponent } from './modulos/presupuestos/presupuesto/pres_institucion/pres_institucion.component';


registerLocaleData(localeEs, 'es', localeEsExtra);


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    PanelComponent,
    FloatSubMenuComponent,
    InputSearchComponent,
    ReporteAPSComponent,
    CalculadoraPipe,
    ThousandsPipe,
    InicioComponent,
    GestionUsuariosComponent,
    LoginComponent,
    ModificarinformacionComponent,
    SubirexcelComponent,
    VerdeclaracionComponent,
    DeclararImpuestosComponent,
    PeriodoEntidadesImpuestosComponent,
    VerPeriodosAnterioresComponent,
    NotificacionesComponent,
    RegistrosDuplicadosComponent,
    RecuperarClaveComponent,
    InformeDJComponent,
    ReportesImpuestosComponent,
    ReportesDebitoVentasComponent,
    ReportesCreditoCompraComponent,
    ReportesImpuestoRentaComponent,
    PresupuestoComponent,
    PresEducacionComponent,
    PresIglesiaComponent,
    PresInstitucionComponent,

  ],
  imports: [
    AppRoutingModule,
    //AgmCoreModule.forRoot({ apiKey: 'AIzaSyC5gJ5x8Yw7qP_DqvNq3IdZi2WUSiDjskk' }),
    BrowserAnimationsModule,
    BrowserModule,
    LoadingBarRouterModule,
    MatAutocompleteModule,

    /*CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),*/
    //CountdownModule,
    //ChartsModule,
    //FullCalendarModule,
    FormsModule,
    //HighlightJsModule,
    NgSelectModule,
    //MatSortModule,
    //MatTableModule,
    NgbModule,
    NvD3Module,
    //NgxChartsModule,
    //Ng2TableModule,
    //NgxDaterangepickerMd.forRoot(),
    PerfectScrollbarModule,
    ReactiveFormsModule,
    //SweetAlert2Module.forRoot(),
    TagInputModule,
    TrendModule,
    routing,
    HttpClientModule
  ],
  providers: [
    appRoutingProviders,
    RutaGuard,
    UserService,
    EntidadService,
    SharingService,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'es' },
    Title, {

      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    BodegaService,],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        var title = 'Sistemas UCh | ' + this.route.snapshot.firstChild.data['title'];
        this.titleService.setTitle(title);
      }
    });
  }
}

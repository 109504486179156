<!-- begin breadcrumb -->
<ol class="breadcrumb pull-right">
  <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
  <li class="breadcrumb-item">{{descripcion}}</li>
  <li class="breadcrumb-item active">{{ titulo }}</li>
</ol>
<!-- end breadcrumb -->

<link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.10.19/css/dataTables.bootstrap4.min.css">

<!--################################################COntenido############################################################-->


<!-- begin page-header -->
  <h1 class="page-header"> <i class="fa fa-eye"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
  <!-- end page-header -->

  <!-- begin row
  <div class="row "> -->



  <div class="panel panel-info">
    <div class="panel-heading">
      <div class="panel-heading-btn">

      </div>
      <h4 class="panel-title"> <i class="fa fa-calendar-alt"></i> Periodos</h4>
    </div>
    <div class="panel-body p-t-10 table-responsive">

      <table class="table table-hover table-condensed table-striped">

        <thead>
          <tr>
            <th>Entidad</th>

            <th>Periodo</th>
            <th>Fecha Vencimiento</th>
            <th>Etapa</th>
            <th>Estado</th>
            <th>Fecha Envio</th>
            <th>Revisión</th>
            <th>Revisar</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of periodo; index as i"
            [ngClass]="{
              'table-warning':p.estado.nombre == 'Rechazado',
              'table-info':p.estado.nombre == 'Enviado',
              'table-success':p.estado.nombre == 'Aprobado'
            }">
            <td>{{p.entidad.codigo+" "+p.entidad.nombre+" "+p.entidad.campo}}</td>
            <td>{{ (p.periodo | date:'MM/yyyy')}}</td>
            <td>{{(p.fecha_vencimiento | date:'dd/MM/yyyy HH:mm')+" Hrs"}}</td>
            <td>{{p.etapa.descripcion }}</td>
            <td>{{p.estado.nombre}}</td>
            <td *ngIf="p.primer_envio == null"></td>
            <td *ngIf="p.primer_envio != null">{{(p.primer_envio | date:'dd/MM/yyyy HH:mm')+" Hrs"}}</td>
            <td>{{p.revision.usuario.usuario}}</td>
            <td (click)="cambiaentidad(p.entidad)" ><button class="btn btn-sm btn-outline-info"><i class="fa fa-eye"></i></button></td>
          </tr>

        </tbody>

      </table>




    </div>
  </div>

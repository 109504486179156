import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global } from './global';
import { UserService } from './user.service';
import { EntidadService } from '../services/entidad.service';
//import { ConsoleReporter } from 'jasmine';

@Injectable()

export class ReportesImpuestosService {
	public url_reportes: string;
	public identity;
	public token;
	public entidad_sel;

	constructor(
		private _http: HttpClient,
		private _userService: UserService,
		private _entidadService: EntidadService
	) {
		this.url_reportes = Global.url_reportes;
		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();

	}

    reportePDF(tipo_reporte, periodo, mes):Observable<any>{

		//entidad.campo = this.identity.campo;
		let params = JSON.stringify({tipo_reporte: tipo_reporte, periodo: periodo, mes: mes});
		//definir las cabeceras
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//Hacer peticion ajax
		var resultado = this._http.post(this.url_reportes+'reporte-pdf', params, {headers:headers});
		return resultado;

	}
	createReportePDF(data, tipo): Observable<any> {
		// let params = JSON.stringify({data: data, tipo_reporte: tipo});
		let params = JSON.stringify(data)
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Basic ' + data.auth);
		//Hacer peticion ajax
		var resultado = this._http.post(Global.url_jsreport, params, {headers:headers, responseType: 'blob' as 'json'});
		return resultado;

	}
	getReportePDF(archivo): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.get(this.url_reportes+'reportes-pdf/' + archivo, {headers:headers, responseType: 'blob'});

	}
}

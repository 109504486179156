
<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
	<li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin panel -->
<panel variant="iatuch" title="{{ titulo }}">
    <div class="row">
        <div class="col-md-1">
        <img width="100%" src="assets/img/logoiasd.png">
        </div>
        <div class="col-md-8"><br><br><br>
            <label class="text-dark">Bienvenido al Portal de Sistemas de la Iglesia Adventista en la Unión Chilena.</label>
        </div>
    </div>
    
</panel>

import {Component, OnInit, Renderer2} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { User } from '../../../models/user';
import { RecuperarClave } from '../../../models/administrar/recuperar_clave';
import { UserService } from '../../../services/user.service';
import pageSettings from '../../../config/page-settings';
import { LocalService } from '../../../services/localService';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recuperar-clave',
  templateUrl: './recuperar-clave.component.html',
  styleUrls: ['./recuperar-clave.component.css'],
  providers: [UserService]
})
export class RecuperarClaveComponent implements OnInit {
  pageSettings = pageSettings;
  public tokenrecuperacion: string;
  public recuperarclave: RecuperarClave;
  public mensaje: string;
  public estado: boolean;
  public estadoformulario: boolean;

  constructor(
  private renderer: Renderer2,
  private _userService: UserService,
  private _localService: LocalService,
  private _router: Router,
  private _route: ActivatedRoute,
  ) {
    this.tokenrecuperacion = this._userService.getToken();
    this.pageSettings.pageEmpty = true;
  }

  ngOnInit() {
    this.mensaje = '';
    this.estado = true;
    this._route.params.subscribe((params: Params) => {
      // consultar token
      this._userService.consultarToken(params.token).subscribe(
        response => {
          if (response.estado == true) {
            this.recuperarclave = new RecuperarClave(response.usuario, response.tokenrecuperacion, null, null);
            this.mensaje = response.mensaje;
            this.estado = response.estado;
            this.estadoformulario = this.estado;
          } else {
            // this.mensaje = response.mensaje;
            // this.estado = response.estado;
            // this.estadoformulario = this.estado;
            Swal.fire({
              title: '¡Atención!',
              text: response.mensaje,
              icon: 'warning',
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-lg btn-primary ml-3',
                cancelButton: 'btn btn-lg btn-danger',
              },
              confirmButtonText: 'OK'
            });
            this.login();
          }
        },
        error => {
          console.log(error);
        });
    });
  }

  private actualizarClave(datos) {
    this._userService.actualizarClave(datos).subscribe(
      response => {
          if (response.estado) {
            Swal.fire({
              title: 'Felicidades',
              text: response.mensaje,
              icon: 'success',
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-lg btn-primary ml-3',
                cancelButton: 'btn btn-lg btn-danger',
              },
              confirmButtonText: 'OK'
            });
            this.login();
          }
          this.mensaje = response.mensaje;
          this.estado = response.estado;
      },
      error => {
        console.log(error);
      }
    );
  }

  private login() {
    this._router.navigate(['/login']);
  }

}

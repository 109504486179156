export class Viajes{
    constructor(
        public _id: string,
        public diverso: Number,
        public nombre: String,
        public kilometraje_13: Number,
        public dias_viatico: Number,
        public dias_hotel : Number,
        public valor_dia_hotel: Number,
        public total_hotel : Number,
        public viatico : Number,
        public viajes_regionales : Number,
        public viajes_internacionales : Number,
        public km_mensuales : Number,
        public viajes_urbanos : Number,
        public otros_1 : Number,
        public tag : Number,
        public maleta : Number,
        public otros_2 : Number,
        public total : Number,
        public entidad : JSON,
        public year: Number

    ){}
}
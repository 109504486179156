import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import Swal from 'sweetalert2'

//Services
import { DeclaracionService } from '../../../services/declaracion.service';
import { EntidadService } from '../../../services/entidad.service';
import { UserService } from '../../../services/user.service';
import { DjExcencionService } from '../../../services/dj1945/dj_excencion.service'
import { DjBienRaizService } from '../../../services/dj1945/dj_bien_raiz.service'
import { DjOpcionEntidadService } from '../../../services/dj1945/dj_opcion_entidad.service'
import { Global } from '../../../services/global';
import {Observable, Subject, Subscription} from "rxjs"

//Models
import { Declaracion } from '../../../models/declaracion';
import { DjIngresoGastoEntity } from '../../../models/dj1945/dj_ingreso_gasto_entity.model';
import { DjConfiguracionGlobalPeriodo } from '../../../models/dj1945/dj_configuracion_global_periodo.model';
import { TablePageInfo, TablePageRequest, TablePageResponse, TableTotalsInfo } from '../../../models/table_page.model';
import { DjRowResponse } from '../../../models/dj1945/dj_row_response.model';
import { DjExcencionModel } from '../../../models/dj1945/dj_excencion.model';
import { DjBienRaizModel } from '../../../models/dj1945/dj_bien_raiz.model';
import { DjOpcionEntidad, DjOpcionEntidadDto } from '../../../models/dj1945/dj_opcion_entidad.model'
import { error } from 'console';
import { SharingService } from '../../../services/sharing.service';

@Component({
  selector: 'app-verdeclaracion',
  templateUrl: './verdeclaracion.component.html',
  styleUrls: ['./verdeclaracion.component.css'],
  providers: [DeclaracionService, DjExcencionService, DjBienRaizService, DjOpcionEntidadService]
})
export class VerdeclaracionComponent implements OnInit {
  public titulo = "Ver Declaracion";
  public descripcion = "Declaraciones";
  public entidad_sel = this._entidadService.getEntidad();
  public declaracion;
  public declaraciones;
  public dec_ingresos_gastos;
  public dec_excenciones;
  public dec_bien_raiz;
  public SimpleChanges: any;
  public total_ingreso_gasto;
  public declaraciones_agrupadas;
  public resultados: number;
  public minified;
  public tipo = 101;
  public tipo_dec = "ing_gas";
  public url: string;
  public token;
  public parametros_importador = { rectificacion: false, num_rectificacion: '', entidad_sel: 0, year: 0 };
  public mensaje_num_rectificacion = "Ingrese el N° Rectificacón!"
  public descarga_txt = false
  public dj_config: DjConfiguracionGlobalPeriodo
  public dj_ingreso_gasto_entity_declaration: Array<DjIngresoGastoEntity>
  public page_info: TablePageInfo = {
    n_per_page: 10,
    page_number: 1,
  }
  public table_total_info: TableTotalsInfo
  public n_per_page: number = 10
  public djOpcionEntidad: DjOpcionEntidad
  public djOpcionEntidadDto: DjOpcionEntidadDto
  @ViewChild("num_rectificacion", { static: false }) nameField: ElementRef;
	public entidadSelSubscription: Subscription;
  constructor(
    private _entidadService: EntidadService,
    private _declaracionService: DeclaracionService,
    private _excencionService: DjExcencionService,
    private _bienRaizService: DjBienRaizService,
    private _userService: UserService,
    private _sharingService: SharingService,
    private _opcionEntidadService: DjOpcionEntidadService
  ) {
    this.declaracion = new Declaracion('', '', 0, '', '', '');
    this.url = Global.url;
    this.token = this._userService.getToken();
    this.entidad_sel = this._entidadService.getEntidad();
    //this.trae_declaraciones(101);

  }

  ngOnInit() {
    this.getConfiguracionGlobalPeriodo()
    this.entidadSelSubscription = this._sharingService.sharingEntity.subscribe(
      (entidad) => {
        this.getConfiguracionGlobalPeriodo()
      }
    )
  }

  tipo_declaracion(tipo) {
    this.tipo_dec = tipo
    if (this.tipo_dec == 'ing_gas') {
      this.getConfiguracionGlobalPeriodo()
    } else if (this.tipo_dec == 'exenciones') {
      this.getExcenciones()
    } else {
      this.getBienesRaices()
    }
  }
  selTipoIngEgr(tipo) {
    this.tipo = tipo
    this.getConfiguracionGlobalPeriodo()
  }

  descargaArchivo() {
    if (this.parametros_importador.rectificacion == true &&
      (this.parametros_importador.num_rectificacion == '' || this.parametros_importador.num_rectificacion == '0' || !/^([0-9])*$/.test(this.parametros_importador.num_rectificacion))) {
      this.parametros_importador.num_rectificacion = ''
      this.mensaje_num_rectificacion = "Ingrese un N° Rectificación Valido!"
      this.nameField.nativeElement.focus()
    } else {
      Swal.fire({
        text: "Cargando...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })
      this._declaracionService.exportarDeclaracionTxt(this.parametros_importador).subscribe(
        response => {
          Swal.close();
          var textFile = null

          this._declaracionService.getFile(response.nombre_archivo).subscribe(blob => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(blob)
            a.href = objectUrl
            a.download = response.nombre_archivo;
            a.click();
            URL.revokeObjectURL(objectUrl);
          })
        })
    }
  }

  str2bytes(str: any) {
    var bytes = new Uint8Array(str.length);
    for (var i = 0; i < str.length; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  }

  rectificar() {
    if (this.parametros_importador.rectificacion == false) {
      this.parametros_importador.num_rectificacion = ''
    }
  }

  trae_declaraciones(tipo) {
    Swal.fire({
      text: "Consultando Registros...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    this.entidad_sel = this._entidadService.getEntidad();
    this.tipo = tipo;
    this._declaracionService.verDeclaracion(this.declaracion, tipo).subscribe(
      response => {
        Swal.close();
        this.resultados = 0
        this.descarga_txt = response.descarga_txt
        if (response.ingresos_gastos.length > 0) {
          this.resultados = response.total_ingreso_gasto[0].cantidad;
          this.declaraciones = response;
          this.dec_ingresos_gastos = response.ingresos_gastos;
          this.total_ingreso_gasto = response.total_ingreso_gasto[0].monto;
          this.dec_excenciones = response.excenciones;
          this.dec_bien_raiz = response.bien_raiz;
        } else {
          this.dec_ingresos_gastos = null;
          this.dec_excenciones = response.excenciones;
          this.dec_bien_raiz = response.bien_raiz;

          Swal.fire({
            title: 'Información',
            text: "No se encontraron resultados",
            icon: 'info',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-info ml-3',
              cancelButton: 'btn btn-lg btn-danger',
            },
            confirmButtonText: 'Ok'
          })
        }
      },
      error => {
        Swal.fire({
          title: 'Error!',
          text: "Ocurrio un error al consultar registros, intente mas tarde!",
          icon: 'error',
          showCancelButton: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-lg btn-danger ml-3',
            cancelButton: 'btn btn-lg btn-danger',

          },
          confirmButtonText: 'Ok'
        })

      }

    );
  }
  getBienesRaices() {
    this.dec_bien_raiz = null
    const params: DjBienRaizModel = {
      periodo: this.dj_config.periodo,
      entidad: this.entidad_sel._id
    }
    Swal.fire({
      text: "Consultando Registros...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
        this._bienRaizService.get(params).subscribe((response: DjRowResponse<Array<DjBienRaizModel>>) => {
          this.dec_bien_raiz = response.result;
          Swal.close()
          if (!this.dec_bien_raiz.length)
            this.notFoundResults()
        }, (error) => { console.error(error); this.errorAlert() })
      }
    })

  }

  getExcenciones() {
    this.dec_excenciones = null;
    const params: DjExcencionModel = {
      periodo: this.dj_config.periodo,
      entidad: this.entidad_sel._id
    }
    Swal.fire({
      text: "Consultando Registros...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
        this._excencionService.get(params).subscribe((response: DjRowResponse<Array<DjExcencionModel>>) => {
          this.dec_excenciones = response.result;
          Swal.close()
          if (!this.dec_excenciones.length)
            this.notFoundResults()
        }, (error) => { console.error(error); this.errorAlert() })
      }
    })


  }

  

  getForEntityPage(params: TablePageRequest<DjIngresoGastoEntity>) {
    this.total_ingreso_gasto = null
    this.resultados = null
    this.dj_ingreso_gasto_entity_declaration = null
    Swal.fire({
      text: "Consultando Registros...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
        this._declaracionService.getForEntityPage(params).subscribe((response: TablePageResponse<DjIngresoGastoEntity>) => {
          this.dj_ingreso_gasto_entity_declaration = response.result
          this.page_info = response.page_info
          this.table_total_info = response.totals
          if (this.dj_ingreso_gasto_entity_declaration.length) {
            const initialValue = 0;
            this.resultados = this.dj_ingreso_gasto_entity_declaration.reduce((accumulator, currentValue) => accumulator + currentValue.count,
              initialValue)
            this.total_ingreso_gasto = this.dj_ingreso_gasto_entity_declaration.reduce((accumulator, currentValue) => accumulator + currentValue.total,
              initialValue)

            Swal.close()
          } else {
            this.notFoundResults()

          }

        },
          (error) => { console.error(error); this.errorAlert() })
      },
    })

  }

  notFoundResults() {
    Swal.fire({
      title: 'Información',
      text: "No se encontraron resultados",
      icon: 'info',
      showCancelButton: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-lg btn-info ml-3',
        cancelButton: 'btn btn-lg btn-danger',

      },
      confirmButtonText: 'Ok'
    })
  }

  errorAlert() {
    Swal.fire({
      title: 'Error!',
      text: "Ocurrio un error al consultar registros, intente mas tarde!",
      icon: 'error',
      showCancelButton: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-lg btn-danger ml-3',
        cancelButton: 'btn btn-lg btn-danger',
      },
      confirmButtonText: 'Ok'
    })
  }

  getConfiguracionGlobalPeriodo() {

    this._declaracionService.getGlobalConfiguration().subscribe(
      (response: DjRowResponse<DjConfiguracionGlobalPeriodo>) => {
        this.dj_config = response.result
        const params: TablePageRequest<DjIngresoGastoEntity> = {
          params: {
            periodo: this.dj_config.periodo, tipo_ing_egr: this.tipo, entidad: this.entidad_sel._id
          },
          page_info: this.page_info
        }
        this.djOpcionEntidadDto = {periodo: this.dj_config.periodo, entidad: this.entidad_sel._id}
        this.getOpcionEntidad(this.djOpcionEntidadDto)
        this.getForEntityPage(params)

      }, (error) => {
        console.error(error)
      })

  }

  changePage(operator) {
    if (operator == '+') {
      this.page_info.page_number++
    }
    else {
      this.page_info.page_number--
    }
    this.getConfiguracionGlobalPeriodo()
  }


  getOpcionEntidad(params:DjOpcionEntidadDto){
    this.djOpcionEntidadDto = params
    this._opcionEntidadService.getOne(this.djOpcionEntidadDto).subscribe((response)=>{
      this.djOpcionEntidad = response
      this.descarga_txt = this.djOpcionEntidad?.validaciones.descarga_txt
    }, error=>{ console.error(error);this.errorAlert() })
  }

  downloadCsv(){
    this._declaracionService.downloadCsv().subscribe(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'importador.csv'; // Nombre del archivo que se descargará
      link.click();
    })
  }

}

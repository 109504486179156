import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { Parametros_pres } from '../models/parametros_pres';
import { Global } from './global';
import { EntidadService } from '../services/entidad.service';
import { UserService } from './user.service';
//import { Workbook } from 'exceljs';
import * as ExcelJS from "exceljs/dist/exceljs.min.js"
// declare const ExcelJS: any;
import * as FileSaver from 'file-saver'
//import {Row, Workbook, Worksheet} from 'exceljs';
//import * as XLSX from 'xlsx';
// import * as Excel from 'exceljs/dist/exceljs.min.js';
//import * as ExcelProper from 'Exceljs';
// import * as ExcelJS from 'exceljs';
//import { Workbook } from 'exceljs'
// declare const require: any;
// var ExcelJS = require('exceljs');
//import * as Excel from 'exceljs/dist/exceljs.min.js';
//declare const Excel: Excel;
import Swal from 'sweetalert2'
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()

export class PresupuestoService {

	public url: string;
	public identity;
	public token;
	public Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    })

	constructor(private _http: HttpClient,
		//private _entidadService: EntidadService,
		private _userService: UserService) {
		this.url = Global.url;
		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();
	}

	public exportAsExcelFile(json: any[], excelFileName: string): void {
		var workbook = new ExcelJS.Workbook()
		let worksheet = workbook.addWorksheet('Importador');
		worksheet.columns = [
			{ header: 'FundCode', key: 'FundCode' },
			{ header: 'FunctionCode', key: 'FunctionCode' },
			{ header: 'RestrictionCode', key: 'RestrictionCode' },
			{ header: 'AccountCode', key: 'AccountCode' },
			{ header: 'SubAccountTypeCode', key: 'SubAccountTypeCode' },
			{ header: 'SubAccountCode', key: 'SubAccountCode' },
			{ header: 'SubAccountName', key: 'SubAccountName' },
			{ header: 'Period1', key: 'Period1' },
			{ header: 'Period2', key: 'Period2' },
			{ header: 'Period3', key: 'Period3' },
			{ header: 'Period4', key: 'Period4' },
			{ header: 'Period5', key: 'Period5' },
			{ header: 'Period6', key: 'Period6' },
			{ header: 'Period7', key: 'Period7' },
			{ header: 'Period8', key: 'Period8' },
			{ header: 'Period9', key: 'Period9' },
			{ header: 'Period10', key: 'Period10' },
			{ header: 'Period11', key: 'Period11' },
			{ header: 'Period12', key: 'Period12' },
			{ header: 'Total', key: 'Total' },
			{ header: 'EntityCode', key: 'EntityCode' }//,
			//{ header: 'Item', key: 'item'}
		];
		worksheet.columns.forEach(column => {
			column.width = column.header.length < 12 ? 12 : column.header.length;
		})
		worksheet.addRows(json);
		worksheet.getCell('T1').numFmt = '_-* #.##0 _€_-;-* #.##0 _€_-;_-* "-"?? _€_-;_-@_-';
		worksheet.getCell('T1').alignment = { horizontal: 'center' };
		const hoy = new Date();
		var dia = hoy.getDate();
		var mes = (hoy.getMonth() + 1).toString();
		var año = hoy.getFullYear();
		if (mes < '10') {
			mes = '0' + mes;
		}
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
			FileSaver.saveAs(blob, excelFileName + dia + '-' + mes + '-' + año + EXCEL_EXTENSION)
			this.Toast.fire({
				icon: 'success',
				title: 'Importador generado exitosamente'
			})
		})
	}


	cambioparametros(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'cambiar-parametros', params, { headers: headers });

	}

	traeparametros(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'traeparametros', params, { headers: headers });

	}


	actualizar_sueldos_dependientes(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros); //console.log(params);return;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log(headers);
		return this._http.post(this.url + 'actualizar_sueldos_dependientes', params, { headers: headers });

	}

	trae_sueldos_dependientes(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'trae_sueldos_dependientes', params, { headers: headers });

	}

	insertar_sueldo_dependiente(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_sueldo_dependiente', params, { headers: headers });

	}

	delete_sueldo_dependiente(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'delete_sueldo_dependiente', params, { headers: headers });

	}

	insertar_diverso(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_diverso', params, { headers: headers });

	}

	actualiza_diverso(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'actualiza_diverso', params, { headers: headers });

	}

	trae_diversos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'trae_diversos', params, { headers: headers });

	}

	trae_sueldos_independientes(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'trae_sueldos_independientes', params, { headers: headers });

	}

	insertar_sueldo_independiente(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_sueldo_independiente', params, { headers: headers });

	}

	delete_sueldo_independiente(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'delete_sueldo_independiente', params, { headers: headers });

	}

	actualizar_sueldos_independientes(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros); //console.log(params);return;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'actualizar_sueldos_independientes', params, { headers: headers });

	}

	actualizar_sueldos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		let params = JSON.stringify(parametros); //console.log(params);return;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'actualizar_sueldos', params, { headers: headers });

	}

	insertar_viajes(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros); //console.log(params);return;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_viajes', params, { headers: headers });

	}

	actualizar_viajes(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros); //console.log(params);return;
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'actualizar_viajes', params, { headers: headers });

	}

	trae_viajes(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'trae_viajes', params, { headers: headers });

	}

	delete_viajes(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'delete_viajes', params, { headers: headers });

	}

	insertar_prima_infantil(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_prima_infantil', params, { headers: headers });

	}

	calcular_prima_infantil(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'calcular_prima_infantil', params, { headers: headers });

	}

	traer_prima_infantil(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'traer_prima_infantil', params, { headers: headers });

	}

	actualizar_prima_infantil(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'act_primas_infantiles', params, { headers: headers });

	}

	delete_prima_infantil(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'delete_prima_infantil', params, { headers: headers });

	}

	agregar_sueldos_viajes(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'agregar_sueldos_viajes', params, { headers: headers });

	}

	traer_gastos_reuniones_eventos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_gastos_reuniones_eventos', params, { headers: headers });

	}

	modificar_cuentas(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log(params);
		return this._http.post(this.url + 'modificar_cuentas', params, { headers: headers });

	}

	insertar_departamento_gastos_reuniones_eventos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_departamento_gastos_reuniones_eventos', params, { headers: headers });

	}

	insertar_cuenta_gastos_reuniones_eventos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_cuenta_gastos_reuniones_eventos', params, { headers: headers });

	}

	insertar_departamento_gastos_operativos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_departamento_gastos_operativos', params, { headers: headers });

	}

	insertar_cuenta_gastos_operativos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_cuenta_gastos_operativos', params, { headers: headers });

	}

	traer_gastos_operativos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_gastos_operativos', params, { headers: headers });

	}

	insertar_departamento_otorgamientos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_departamento_otorgamientos', params, { headers: headers });

	}

	insertar_cuenta_otorgamientos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;

		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		// console.log(params);
		return this._http.post(this.url + 'insertar_cuenta_otorgamientos', params, { headers: headers });

	}

	traer_otorgamientos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_otorgamientos', params, { headers: headers });

	}

	insertar_ingreso(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'insertar_ingreso', params, { headers: headers });

	}

	traer_ingreso(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_ingreso', params, { headers: headers });

	}

	modificar_ingreso(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'modificar_ingreso', params, { headers: headers });

	}

	generar_tablas_sueldos_411(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'generar_tablas_sueldos_411', params, { headers: headers });

	}

	traer_tabla_sueldos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_tabla_sueldos', params, { headers: headers });

	}

	traer_votar_b(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_votar_b', params, { headers: headers });

	}

	 generar_votar_b(): Observable<any>{
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'generar_votar_b', params, { headers: headers })
	}

	modificar_votar_b(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'modificar_votar_b', params, { headers: headers });

	}

	actualizar_votar_b(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'actualizar_votar_b', params, { headers: headers });

	}

	traer_votar_a(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_votar_a', params, { headers: headers });

	}

	datos_pdf(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'datos_pdf', params, { headers: headers });

	}

	modificar_rea(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'modificar_rea', params, { headers: headers });

	}

	encontrar_cuenta_extra(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'encontrar_cuenta_extra', params, { headers: headers });

	}

	agregar_columnas_sueldos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'agregar_columnas_sueldos', params, { headers: headers });

	}

	generar_archivo_importador(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'generar_archivo_importador', params, { headers: headers });

	}

	traer_archivo_importador(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_archivo_importador', params, { headers: headers });

	}

	revisar_archivo_importador(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'revisar_archivo_importador', params, { headers: headers });

	}

	filtro_tipo_presupuesto(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {} //JSON.stringify(pass);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'filtro_tipo_presupuesto', params, { headers: headers });

	}

	traer_colegios(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {} //JSON.stringify(pass);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_colegios', params, { headers: headers });

	}

	modificar_departamentos_colegios(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'modificar_departamentos_colegios', params, { headers: headers });

	}

	insertar_colegio(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'insertar_colegio', params, { headers: headers });

	}

	insertar_departamento_en_colegio(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'insertar_departamento_en_colegio', params, { headers: headers });

	}

	agregar_sueldos(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'agregar_sueldos', params, { headers: headers });

	}

	traer_sueldo(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_sueldo', params, { headers: headers });

	}

	encontrar_cuenta_sueldo(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(this.token);return ;
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'encontrar_cuenta_sueldo', params, { headers: headers });

	}

	agregar_cuenta_sueldos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'agregar_cuenta_sueldos', params, { headers: headers });

	}

	modificar_sueldo(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'modificar_sueldo', params, { headers: headers });

	}

	encontrar_datos_sueldo(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'encontrar_datos_sueldo', params, { headers: headers });

	}

	insertar_sueldo(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'insertar_sueldo', params, { headers: headers });

	}

	traer_cuentas(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_cuentas', params, { headers: headers });

	}

	insertar_cuentas(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'insertar_cuentas', params, { headers: headers });

	}

	modificar_cuentas_educacion(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'modificar_cuentas_educacion', params, { headers: headers });

	}

	generar_departamentos_cuentas(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'generar_departamentos_cuentas', params, { headers: headers });

	}

	generar_tabla_sueldos(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'generar_tabla_sueldos', params, { headers: headers });

	}

	traer_votar_a_educacion(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_votar_a_educacion', params, { headers: headers });

	}

	traer_votar_a_instituciones(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_votar_a_instituciones', params, { headers: headers });

	}

	estado_presupuesto(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'estado_presupuesto', params, { headers: headers });

	}

	modificar_diversos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'modificar_diversos', params, { headers: headers });

	}

	nuevo_periodo_iglesia(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'nuevo_periodo_iglesia', params, { headers: headers });

	}

	modificar_diversos_iglesia(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'modificar_diversos_iglesia', params, { headers: headers });

	}

	eliminar_departamentos_colegios(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'eliminar_departamentos_colegios', params, { headers: headers });

	}

	generar_rea(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'generar_rea', params, { headers: headers });

	}

	generar_importador(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'generar_importador', params, { headers: headers });

	}

	revisar_importador(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'revisar_importador', params, { headers: headers });

	}

	traer_importador(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_importador', params, { headers: headers });

	}

	async exportar_presupuesto(parametros) {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		let datos
		await this._http.post(this.url + 'exportar_presupuesto', params, { headers: headers }).toPromise().then(data => {
			datos = data;
		  });
		//   console.log(datos)
		return datos
	}

	nuevo_periodo_edu_inst(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'nuevo_periodo_edu_inst', params, { headers: headers });

	}

	traer_configuraciones_sueldos(): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = {}//JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'traer_configuraciones_sueldos', params, { headers: headers });

	}

	modificar_configuraciones_sueldos(parametros): Observable<any> {
		//this.token = this.getToken();
		//console.log(parametros); return ;
		//console.log(this.token);
		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//console.log();
		return this._http.post(this.url + 'configuraciones_sueldos', params, { headers: headers });

	}


}
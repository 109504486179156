import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global } from '../global';
import { UserService } from '../user.service';
import { EntidadService } from '../entidad.service';
import { DjRowResponse } from '../../models/dj1945/dj_row_response.model';
import { DjBienRaizModel } from '../../models/dj1945/dj_bien_raiz.model';

@Injectable()

export class DjBienRaizService {
	public url: string;
	public urldj195: string;
	public identity;
	public token;
	public entidad_sel;

	constructor(
		private _http: HttpClient,
		private _userService: UserService,
		private _entidadService: EntidadService
	) {
		this.url = Global.url;
		this.urldj195 = Global.url_dj1945;

		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();
	}
	get(params: DjBienRaizModel): Observable<DjRowResponse<Array<DjBienRaizModel>>> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.get<DjRowResponse<Array<DjBienRaizModel>>>(this.urldj195 + 'dj-bien-raiz-for-entity/' + params.periodo + '/' + params.entidad , { headers: headers });
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { Global } from './global';
import { LocalService } from './localService';
import { Entidad } from '../models/entidad';

@Injectable()

export class InventarioService {
    public url: string;
    public identity;
    public token;
    public bodegas_entidad 
    bodegas_total

    constructor(private _http: HttpClient,
        //private _entidadService: EntidadService,
        private _userService: UserService,
        private _localService: LocalService) {
        this.url = Global.url;
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();
    }

    cambiarBodegaSel(bodega):Observable<any>{    // Actualiza la bodega seleccionada en la BD
		let params = JSON.stringify(bodega);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//Hacer peticion ajax
		var resultado = this._http.post(this.url+'cambiar_bodega_sel', params, {headers:headers});
		return resultado;

	}

    getBodegas(){
		let bodegas = this._localService.getJsonValue('bodegas')
		return bodegas;
	}

    getBodegasEntidad(){
        this.bodegas_total = this._localService.getJsonValue('bodegas')
        let entidad_sel = JSON.parse(this._localService.getJsonValue('entidad'))
        this.bodegas_entidad = this.bodegas_total.filter(x => x.entidad._id == entidad_sel._id)
        return this.bodegas_entidad
    }

    traeBodegaSel():Observable<any>{
		let params = [];
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//Hacer peticion ajax
		var resultado = this._http.post(this.url+'trae_bodega_sel', params, {headers:headers});
		return resultado;
	}

    /********** BODEGAS **********/

    listarBodegas(): Observable<any> {
        //this.token = this.getToken();

        let params = {}//JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'traer-bodegas', params, { headers: headers });

    }

    nuevaBodega(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'nueva-bodega', params, { headers: headers });

    }

    modificarBodega(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'modificar-bodega', params, { headers: headers });

    }

    activarBodega(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'activar-bodega', params, { headers: headers });

    }

    /********** CATEGORIAS **********/

    listarCategorias(): Observable<any> {
        //this.token = this.getToken();

        let params = {}//JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'traer-categorias', params, { headers: headers });

    }

    nuevaCategoria(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'nueva-categoria', params, { headers: headers });

    }

    modificarCategoria(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'modificar-categoria', params, { headers: headers });

    }

    activarCategoria(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'activar-categoria', params, { headers: headers });

    }

    /********** PROVEEDORES **********/

    listarProveedores(): Observable<any> {
        //this.token = this.getToken();

        let params = {}//JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'traer-proveedores', params, { headers: headers });

    }

    nuevoProveedor(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'nuevo-proveedor', params, { headers: headers });

    }

    modificarProveedor(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'modificar-proveedor', params, { headers: headers });

    }

    activarProveedor(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'activar-proveedor', params, { headers: headers });

    }

    /********** PRODUCTOS **********/

    listarProductos(): Observable<any> {
        //this.token = this.getToken();

        let params = {}//JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'traer-productos', params, { headers: headers });

    }

    nuevoProducto(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'nuevo-producto', params, { headers: headers });

    }

    modificarProducto(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'modificar-producto', params, { headers: headers });

    }

    activarProducto(parametros): Observable<any> {
        //this.token = this.getToken();

        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'activar-producto', params, { headers: headers });

    }

    /********** INGRESOS **********/
    
    listarIngresos(): Observable<any> {
        //this.token = this.getToken();
        let params = {}//JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'ver-ingresos', params, { headers: headers });

    }

    guardarIngresos(parametros): Observable<any> {
        //this.token = this.getToken();
        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'ingreso-producto', params, { headers: headers });

    }

    consultarPrecio(parametros): Observable<any> {
        let params = JSON.stringify({codigo:parametros});
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'consultar-precio', params, { headers: headers });
    }

    /********** STOCK **********/

    listarStock(): Observable<any> {
        //this.token = this.getToken();
        let params = {}//JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'ver-stock', params, { headers: headers });

    }

    /********** DEVOLUCIONES **********/

    listarDevoluciones(): Observable<any> {
        let params = {}//JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'ver-devoluciones', params, { headers: headers });
    }

    guardarDevolucion(parametros): Observable<any> {
        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'devolucion-producto', params, { headers: headers });
    }

    /********** PRESTAMOS **********/

    listarPrestamos(): Observable<any> {
        let params = {}//JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'ver-prestamos', params, { headers: headers });
    }

    guardarPrestamos(parametros): Observable<any> {
        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'ingreso-prestamo', params, { headers: headers });
    }

    /********** DAR DE BAJA **********/

    verBajas(): Observable<any> {
        let params = {}//JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'ver-bajas', params, { headers: headers });
    }

    guardarBaja(parametros): Observable<any> {
        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'dar-baja', params, { headers: headers });
    }

    /********** CONSUMOS **********/

    verConsumos(): Observable<any> {
        let params = {};
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'listar-consumos', params, { headers: headers });
    }

    guardarConsumo(parametros): Observable<any> {
        let params = JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'ingreso-consumo', params, { headers: headers });
    }

    listarDepartamentos(): Observable<any> {
        let params = {};
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'traer-departamentos', params, { headers: headers });
    }
    
    /********** TIPOS DE BODEGAS **********/

    traerTiposBodegas(): Observable<any> {
        let params = {}//JSON.stringify(parametros);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'trae_tipo_bodega', params, { headers: headers });
    }

    /********** PERMISOS **********/
    gestionPermiso(parametros): Observable<any> {
        let params = JSON.stringify({componente: parametros});
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        return this._http.post(this.url + 'permiso-inventario', params, { headers: headers });
    }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser'
import { User } from '../models/user';
import { Global } from './global';
import { Solicitud_Usuario } from '../models/solicitud_usuario';
import { Planilla_Usuario } from '../models/planilla_usuario';
import { LocalService } from './localService'

@Injectable()

export class UserService {
  public url: string;
  public identity;
  public token;
  public tokenrecuperacion;
  private combinedDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  combinedData$ = this.combinedDataSubject.asObservable();

  constructor(private _http: HttpClient, private _localService: LocalService, private sanitizer: DomSanitizer,) {
    this.url = Global.url;
    this.token = this.getToken();
    this.tokenrecuperacion = this.getTokenRecuperacion();
  }

  
	processUserData(userData?: any) {
		const currentValue = this.combinedDataSubject.getValue();
		const updatedValue = { ...currentValue, userData: userData };
		this.combinedDataSubject.next(updatedValue);
	}

	processUserPhoto(arrayBuffer?: ArrayBuffer) {
		const url = this.arrayBufferToUrl(arrayBuffer);
		const safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
		const currentValue = this.combinedDataSubject.getValue();
		const updatedValue = { ...currentValue, photoURL: safeUrl };
		this.combinedDataSubject.next(updatedValue);
	}

	private arrayBufferToUrl(arrayBuffer: ArrayBuffer): string {
		const array = new Uint8Array(arrayBuffer);
		const blob = new Blob([array], { type: "image/jpeg" });
		return URL.createObjectURL(blob);
	}

  prueba() {
    return "Hola Mundo desde un servicio de angular!";
  }

	register(user): Observable<any> {
    //convertir el objeto del usuario a un JSON string
    let params = JSON.stringify(user);

		//definir las cabeceras
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		//Hacer peticion ajax
		return this._http.post(this.url+'register', params, {headers:headers});
	}

	signup(user, gettoken = null, fecha = null): Observable<any>{
		//Comprobar si llega el getttoken
		if (gettoken != null) {
			user.gettoken = gettoken;
      user.fecha = fecha
		}
    let params = JSON.stringify(user);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'login', params, {headers: headers});

  }

  logout(): Observable<any>{
    let params = {}
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url+'logout', params, {headers: headers});
	}

  switchSesion(usar): Observable<any>{   // Usar Aqui
    let dispositivo = this._localService.getJsonValue("dispositivo")
    let params = { usaraqui: usar, dispositivo: dispositivo }
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url+'usar-aqui', params, {headers: headers});
	}
	getUsuario(){
		let usuario = this._localService.getJsonValue('usuario')
		return usuario;
	}
	getBodegas(){
		let bodegas = this._localService.getJsonValue('bodegas')
		return bodegas;
	}
	getIdentity(){
		let identity = JSON.parse(this._localService.getJsonValue('identity'));

		if (identity && identity != null && identity != undefined && identity != "undefined" ) {
			this.identity = identity[0];
		}else{
			this.identity = null;
		}
		return this.identity;
	}

	getToken() {
		let token = this._localService.getJsonValue('access_token');
		if (token && token != null && token != undefined && token != "undefined") {
			this.token = token;
		} else {
			this.token = null;
		}
		return this.token;
	}

	getTokenRecuperacion() {
    let tokenrecuperacion = this._localService.getJsonValue('tokenrecuperacion');

    if (tokenrecuperacion && tokenrecuperacion != null && tokenrecuperacion != undefined && tokenrecuperacion != "undefined" ) {
      this.tokenrecuperacion = tokenrecuperacion;
    } else {
      this.tokenrecuperacion = null;
    }
    return this.tokenrecuperacion;
  }

	cambioclave(pass): Observable<any> {
    this.token = this.getToken();

		let params = JSON.stringify(pass);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);

		return this._http.post(this.url+'cambiocontrasena', params, {headers: headers});

	}

	getMenu(sistema): Observable<any>{
		this.token = this.getToken()
		let params 
		//let params = JSON.stringify(level);
		if(sistema){
			params = {sistema: sistema}
		}

		let headers = new HttpHeaders().set('Content-Type','application/json').set('Authorization', this.token);

		var post = this._http.post(this.url+'get-menu', params, {headers: headers});
		return post;

		

	}

	privilegiosMenu(): Observable<any>{
		this.token = this.getToken();

		let params = {};//JSON.stringify(pass);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);

		return this._http.post(this.url+'get-privilegios-menu', params, {headers: headers});

	}

	privilegiosRuta():Observable<any>{
		this.token = this.getToken();
		// (this.token); return ;

		let params = {};//JSON.stringify(pass);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);

		return this._http.post(this.url+'get-privilegios-ruta', params, {headers: headers});
		
	}


	///////////////////      Contenedor Usuarios Nuevos     /////////////////////////////
	getListaPrivilegios():Observable<any>{
		this.token = this.getToken();
		let params = {};
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url+'get-privilegios-mantenedor', params, {headers: headers});
	}

	administrarUsuario(tipo, dato):Observable<any>{
		this.token = this.getToken();
		let params = {tipo:tipo, dato:dato};
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url+'admin-usuarios', params, {headers: headers});
	}

	solicitudUsuario():Observable<any>{
		this.token = this.getToken();
		let params = {};
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url+'save-planilla', params, {headers: headers});
  }

	solicitudIndividualUsuario(usuario: Solicitud_Usuario, tipo: String):Observable<any>{
		this.token = this.getToken();
		let params = {usuario_solicitado : usuario, marca: tipo};
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url+'save-usuario', params, {headers: headers});
	}

	getSolicitudIndividualUsuario():Observable<any>{
		this.token = this.getToken();
		let params = {};
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url+'get-usuario-individual', params, {headers: headers});
	}

	getPlanillaUsuario():Observable<any>{
		this.token = this.getToken();
		let params = {};
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url+'get-planilla-usuario', params, {headers: headers});
	}

	getSolicitudUsuario(id_planilla):Observable<any>{
		this.token = this.getToken();
		let params = {id_planilla: id_planilla};
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url+'get-solicitud-usuario', params, {headers: headers});
	}

	eliminarPlanilla(id_planilla):Observable<any>{
		this.token = this.getToken();
		let params = {id_planilla: id_planilla};
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url+'eliminar-planilla', params, {headers: headers});
	}

	actualizarUsuario(user: User):Observable<any> {
		this.token = this.getToken();
		let params = {usuario: user};
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.post(this.url + 'actualizar-usuario', params, {headers: headers});
	}

  recuperarClave(usuario): Observable <any> {
    let sitio = window.location.origin + '/recuperar-clave';
    let params = {usuario: usuario, sitio: sitio};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + 'recuperarclave', params, {headers: headers});
  }
  consultarToken(token): Observable <any> {
    let params = {tokenrecuperacion: token};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + 'consultartoken', params, {headers: headers});
  }
  actualizarClave(datos): Observable <any> {
    let params = JSON.stringify(datos);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + 'actualizarclave', params, {headers: headers});
  }
}

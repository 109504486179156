<!-- begin login -->
<div class="login login-with-news-feed">
  <!-- begin news-feed -->
  <div class="news-feed">
    <div class="news-image" style="background-image: url(../assets/img/login-bg/login-bg-11.jpg)"></div>
    <div class="news-caption">
      <h4 class="caption-title">Sistemas <b>UCh</b></h4>
      <p>
        Iglesia Adventista del Séptimo día
      </p>
    </div>
  </div>
  <!-- end news-feed -->
  <!-- begin right-content -->
  <div class="right-content">
    <!-- begin login-header -->
    <div class="login-header">
      <div class="brand center">
        <img width="100%" src="assets/img/iasdlogo.png" alt=""/>
        <!--<span class="logo"></span> <b>Color</b> Admin
  <small>responsive bootstrap 4 admin template</small>-->
      </div>
      <div class="icon hide">
        <i class="fa fa-sign-in-alt"></i>
      </div>
    </div>
    <!-- end login-header -->
    <!-- begin login-content -->
    <div class="login-content">
      <div class="alert alert-success col-md-12 mt-3" *ngIf="status == 'success'">
        Te has identificado Correctamente
      </div>
      <div class="alert alert-danger col-md-12 mt-3" *ngIf="status == 'error'">
        Usuario o Clave Incorrecta!
      </div>
      <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)" class="margin-bottom-0">
        <div class="form-group m-b-15">
          <input type="text" name="usuario" #usuario="ngModel" [(ngModel)]="user.usuario"
                 class="form-control form-control-lg" placeholder="Usuario" required/>
          <small class="invalid-feedback d-block" *ngIf="!usuario.valid && usuario.touched">El Usuario no es
            valido!</small>
        </div>
        <div class="form-group m-b-15">
          <input type="password" name="clave" #clave="ngModel" [(ngModel)]="user.clave"
                 class="form-control form-control-lg" placeholder="Contraseña" required/>
          <small class="invalid-feedback d-block" *ngIf="!clave.valid && clave.touched">La contraseña no es
            valida!</small>
        </div>

        <div class="login-buttons">
          <button type="submit" class="btn btn-success btn-block btn-lg"
                  [disabled]="loginForm.invalid">Iniciar Sesión
          </button>

        </div>
        <div class="mt-2" align="right">
          <a href="javascript:;" (click)="open(modalrecuperarclave)">¿Olvidaste tu contraseña?</a>
        </div>

        <hr/>
        <p class="text-center text-grey-darker">
          &copy; 2019-{{year}} Iglesia Adventista del Séptimo día - Unión Chilena
        </p>
      </form>
    </div>
    <!-- end login-content -->
  </div>
  <!-- end right-container -->
</div>
<!-- end login -->

<ng-template #modalrecuperarclave let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Recupera tu contraseña</h5>
    <button type="button" class="close btn btn-sm btn-white" (click)="d('Cross click')">×</button>
  </div>
  <div class="modal-body">

    <h6 class="mb-1">Ingresa tu nombre de usuario o correo electrónico</h6>
    <div class="mb-3">Se te enviará un correo con las instrucciones para recuperar tu contraseña</div>
    <input type="text" [(ngModel)]="enviarUsuario" class="form-control" placeholder="Nombre de usuario o correo"/>
    <div class="alert col-md-12 mt-3" *ngIf="mensaje" [ngClass]="{'alert-success': estado, 'alert-danger': !estado}">
      {{ mensaje }}
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-white" (click)="c('Close click')">Cerrar</button>
    <button type="submit" class="btn btn-success" (click)="recuperarClave(enviarUsuario)">Enviar</button>
  </div>
</ng-template>

export class Borrador_f29{
	constructor(
        public _id: String,
        public debito_venta: JSON,
        public credito_compra: JSON,
        public impuesto_renta: JSON,
        public tributacion_simplificada: JSON,
        public impuesto_adicional: JSON,
        public cambio_sujeto: JSON,
        public creditos_especiales: JSON,
        public remanente_creditos_especiales: JSON
        
	){}
}
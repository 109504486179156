<!-- begin breadcrumb -->
<ol class="breadcrumb pull-right">
  <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
  <li class="breadcrumb-item">{{descripcion}}</li>
  <li class="breadcrumb-item active">{{ titulo }}</li>
</ol>
<!-- end breadcrumb -->

<link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.10.19/css/dataTables.bootstrap4.min.css">

<!--################################################COntenido############################################################-->


<!-- begin page-header -->
  <h1 class="page-header"> <i class="fa fa-folder-open"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
  <!-- end page-header -->

  <!-- begin row -->
  <div class="row ">



    <div class="col-md-12">
      <div class="panel panel-iatuch">
      <!-- begin panel-heading -->
      <div class="panel-heading ui-sortable-handle">
          <div class="panel-heading-btn">
              <!--<a href="#modal-dialog" data-toggle="modal"  style="color: azure"><i class="fa fa-calendar-check"></i> Periodo</a>-->
              <!--#modal-dialog data-toggle="modal"-->
          </div>
          <h4 *ngIf="periodo_abierto == true" class="panel-title"><i class="fas fa-file-alt fa-fw"></i> Documentos Periodo: {{mes_periodo | date:'MM/yyyy'}} &nbsp; Entidad: {{entidad_periodo}}</h4>
      </div>

      <div *ngIf="alerta == true" class="alert alert-danger fade show">
        <span class="close" data-dismiss="alert">×</span>
        <i class="fa fa-exclamation-triangle fa-2x pull-left m-r-10"></i>
        <p>{{mensaje_alerta}} <span *ngIf="registro_periodo == true" class="semi-bold"> {{(fecha_vencimiento | date:'dd/MM/yyyy HH:mm')+" Hrs"}}</span></p>
      </div>
      <div *ngIf="alerta == false" class="alert alert-info fade show">
        <span class="close" data-dismiss="alert">×</span>
        <i class="fa fa-info-circle fa-2x pull-left m-r-10"></i>
        <p>{{mensaje_alerta}} <span *ngIf="registro_periodo == true" class="semi-bold"> {{(fecha_vencimiento | date:'dd/MM/yyyy HH:mm')+" Hrs"}} </span></p>
        <!--<p>El Plazo para subir la Documentación Termina el {{fecha_vencimiento | date:'dd/MM/yyyy'}}</p>-->
      </div>

      <!-- end panel-heading -->
      <!-- begin panel-body -->

      <div *ngIf="periodo_abierto == true" class="table-responsive">
        <table id="user" class="table table-condensed table-bordered border-bottom border-top">
          <tbody>
            <tr>
              <td>
                <label>Borrador F-29</label><br>
                {{tipo_archivo.borradorF29.archivo}}

              </td>
              <td>
                <!--  Boton para abrir el modal del formulario F29 del SII -->
                <button type="button" class="btn btn-sm btn-info m-r-3"
                  (click)="open(borradorF29, 'entidad')">Borrador F29</button>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_borrador_f29')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_borrador_f29" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('borradorF29',$event)">
                </span>
                <!--<a [attr.download]="archivo" [class.disabled]="tipo_archivo.borradorF29.check_file == false" [attr.href]="url+'get-file/'+tipo_archivo.borradorF29.ruta"  class="btn btn-sm btn-primary"><i class="fa fa-download" ></i></a> &nbsp;-->
                <button [disabled]="tipo_archivo.borradorF29.check_file == false"
                  (click)="trae_archivo(tipo_archivo.borradorF29.ruta)"
                   class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button> &nbsp;
                <label *ngIf="tipo_archivo.borradorF29.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.borradorF29.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>
              </td>
              <td>
                <label for="">Reporte APS Boleta de Honorarios (PDF)</label>&nbsp;<font color="red">*</font><br>
                {{tipo_archivo.reporteAPSBoletaDeHonorariosPDF.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_reporteAPSBoletaDeHonorariosPDF')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_reporteAPSBoletaDeHonorariosPDF" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('reporteAPSBoletaDeHonorariosPDF', $event)" required>
                </span>
                <!--<a [attr.download]="archivo" [class.disabled]="tipo_archivo.borradorF29.check_file == false" [attr.href]="url+'get-file/'+tipo_archivo.reporteAPSBoletaDeHonorariosPDF.ruta"  class="btn btn-sm btn-primary"><i class="fa fa-download" ></i></a> &nbsp;-->
                <button [disabled]="tipo_archivo.reporteAPSBoletaDeHonorariosPDF.check_file == false"
                  (click)="trae_archivo(tipo_archivo.reporteAPSBoletaDeHonorariosPDF.ruta)"
                  class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i>
                </button>&nbsp;
                <label *ngIf="tipo_archivo.reporteAPSBoletaDeHonorariosPDF.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.reporteAPSBoletaDeHonorariosPDF.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>
              </td>
            </tr>
            <tr>
              <td>
                <label for=""> Archivo Plano APS Boleta de Honorarios (TXT)</label><br>
                {{tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_archivoPlanoAPSBoletadeHonorariosTXT')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_archivoPlanoAPSBoletadeHonorariosTXT" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('archivoPlanoAPSBoletadeHonorariosTXT', $event)" required>
                </span>
                <button [disabled]="tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.check_file == false"
                  (click)="trae_archivo(tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.ruta)"
                  class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.archivoPlanoAPSBoletadeHonorariosTXT.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>
              </td>
              <td>
                <label for=""> Reporte APS Impuesto Único (PDF)</label>&nbsp;<font color="red">*</font><br>
                {{tipo_archivo.reporteAPSImpuestoUnicoPDF.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_reporteAPSImpuestoUnicoPDF')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_reporteAPSImpuestoUnicoPDF" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('reporteAPSImpuestoUnicoPDF', $event)" required>
                </span>
                <button [disabled]="tipo_archivo.reporteAPSImpuestoUnicoPDF.check_file == false"
                  (click)="trae_archivo(tipo_archivo.reporteAPSImpuestoUnicoPDF.ruta)"
                   class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.reporteAPSImpuestoUnicoPDF.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.reporteAPSImpuestoUnicoPDF.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>
              </td>
            </tr>

            <tr>
              <td>
                <label>Archivo Plano APS Impuesto Único (TXT)</label><br>
                {{tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_archivoPlanoAPSImpuestoUnicoTXT')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_archivoPlanoAPSImpuestoUnicoTXT" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('archivoPlanoAPSImpuestoUnicoTXT', $event)" required>
                </span>

                <button [disabled]="tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.check_file == false"
                  (click)="trae_archivo(tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.ruta)"
                  class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.archivoPlanoAPSImpuestoUnicoTXT.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>
              </td>
              <td>
                <label for="">Libro Mayor Impuesto Único</label><br>
                {{tipo_archivo.libroMayorImpuestoUnico.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_libroMayorImpuestoUnico')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_libroMayorImpuestoUnico" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('libroMayorImpuestoUnico', $event)" required>
                </span>
                <button [disabled]="tipo_archivo.libroMayorImpuestoUnico.check_file == false"
                  (click)="trae_archivo(tipo_archivo.libroMayorImpuestoUnico.ruta)"
                  class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.libroMayorImpuestoUnico.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.libroMayorImpuestoUnico.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>

              </td>
            </tr>
            <tr>
              <td>
                <label for=""> Libro Mayor Boleta de Honorarios</label><br>
                {{tipo_archivo.libroMayorBoletaDeHonorarios.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_libroMayorBoletaDeHonorarios')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_libroMayorBoletaDeHonorarios" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('libroMayorBoletaDeHonorarios', $event)" required>
                </span>
                <button [disabled]="tipo_archivo.libroMayorBoletaDeHonorarios.check_file == false"
                  (click)="trae_archivo(tipo_archivo.libroMayorBoletaDeHonorarios.ruta)"
                  class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.libroMayorBoletaDeHonorarios.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.libroMayorBoletaDeHonorarios.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>
              </td>
              <td>
                <label for="">Captura Libro Compra facturacion.cl</label><br>
                {{tipo_archivo.capturaLibroCompraFacturacioncl.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_capturaLibroCompraFacturacioncl')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_capturaLibroCompraFacturacioncl" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('capturaLibroCompraFacturacioncl', $event)" required>
                </span>

                <button [disabled]="tipo_archivo.capturaLibroCompraFacturacioncl.check_file == false"
                  (click)="trae_archivo(tipo_archivo.capturaLibroCompraFacturacioncl.ruta)"
                  class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.capturaLibroCompraFacturacioncl.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.capturaLibroCompraFacturacioncl.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>
              </td>
            </tr>
            <tr>
              <td>
                <label>Captura Libro Ventas facturacion.cl</label><br>
                {{tipo_archivo.capturaLibroVentasFacturacioncl.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_capturaLibroVentasFacturacioncl')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_capturaLibroVentasFacturacioncl" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('capturaLibroVentasFacturacioncl', $event)" required>
                </span>
                <button [disabled]="tipo_archivo.capturaLibroVentasFacturacioncl.check_file == false"
                  (click)="trae_archivo(tipo_archivo.capturaLibroVentasFacturacioncl.ruta)"
                  class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.capturaLibroVentasFacturacioncl.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.capturaLibroVentasFacturacioncl.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>
              </td>
              <td>
                <label for="">Deposito o transferencia bancaria (PDF)</label><br>
                {{tipo_archivo.depositoOTransferenciaBancariaPDF.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_depositoOTransferenciaBancariaPDF')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_depositoOTransferenciaBancariaPDF" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('depositoOTransferenciaBancariaPDF', $event)" required>
                </span>

                <button [disabled]="tipo_archivo.depositoOTransferenciaBancariaPDF.check_file == false"
                  (click)="trae_archivo(tipo_archivo.depositoOTransferenciaBancariaPDF.ruta)"
                  class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.depositoOTransferenciaBancariaPDF.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.depositoOTransferenciaBancariaPDF.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>

              </td>
            </tr>
            <tr>
              <td>
                <label for="">Libro Mayor IVA Credito Fiscal (PDF)</label><br>
                {{tipo_archivo.libroMayorIVACreditoFiscalPDF.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_libroMayorIVACreditoFiscalPDF')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_libroMayorIVACreditoFiscalPDF" type="file" name="archivo[]" multiple
                    (change)="fileChageEvent('libroMayorIVACreditoFiscalPDF', $event)" required>
                </span>
                <button [disabled]="tipo_archivo.libroMayorIVACreditoFiscalPDF.check_file == false"
                  (click)="trae_archivo(tipo_archivo.libroMayorIVACreditoFiscalPDF.ruta)"
                  class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.libroMayorIVACreditoFiscalPDF.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.libroMayorIVACreditoFiscalPDF.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>
              </td>
              <td>
                <label for=""> Libro Mayor IVA Debito Fiscal (PDF)</label><br>
                {{tipo_archivo.libroMayorIVADebitoFiscalPDF.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_libroMayorIVADebitoFiscalPDF')" placement="top" ngbTooltip="Adjuntar Archivo..."><i
                    class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_libroMayorIVADebitoFiscalPDF" type="file" multiple
                    (change)="fileChageEvent('libroMayorIVADebitoFiscalPDF', $event)" required>
                </span>
                <button [disabled]="tipo_archivo.libroMayorIVADebitoFiscalPDF.check_file == false"
                  (click)="trae_archivo(tipo_archivo.libroMayorIVADebitoFiscalPDF.ruta)"
                  class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.libroMayorIVADebitoFiscalPDF.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.libroMayorIVADebitoFiscalPDF.cargando == true" class="text-info"><i
                    class="fas fa-sync  fa-pulse fa-lg"></i> </label>
              </td>
            </tr>
            <tr>
              <td>
                <label for=""> Otros:</label><br>
                {{tipo_archivo.otros.archivo}}
              </td>
              <td>
                <button *ngIf="estado_periodo == 'Rechazado' && alerta == false || estado_periodo == 'Recopilando Información' && alerta == false" class="btn btn-info btn-sm m-r-3" (click)="abrir('archivo_otros')" placement="top" ngbTooltip="Adjuntar Archivo..."><i class="fa fa-plus"></i></button>
                <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                  <i class="fa fa-plus"></i>
                  <span> Adjuntar Archivo...</span>
                  <input id="archivo_otros" type="file" name="archivo[]" multiple (change)="fileChageEvent('otros', $event)"
                    required>
                </span>
                <button [disabled]="tipo_archivo.otros.check_file == false"
                  (click)="trae_archivo(tipo_archivo.otros.ruta)" class="btn btn-sm btn-primary" placement="top" ngbTooltip="Descargar Archivo">
                  <i class="fa fa-download"></i></button>&nbsp;
                <label *ngIf="tipo_archivo.otros.check_file == true" class="text-success"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check-circle"></i></label>
                <label *ngIf="tipo_archivo.otros.cargando == true" class="text-info"><i class="fas fa-sync  fa-pulse fa-lg"></i>
                </label>
                <br>
                <input [disabled]="adjuntar_disabled == true" class="form-control m-t-10" type="text" name="descripcion"
                  #descripcion="ngModel" [(ngModel)]="tipo_archivo.otros.descripcion" placeholder="Ingrese Descripción">
              </td>
              <td><label for="">Observaciones:</label></td>
              <td>
                <textarea [disabled]="adjuntar_disabled == true" class="form-control" name="observaciones"
                  #observaciones="ngModel" [(ngModel)]="tipo_archivo.observaciones" id="" cols="30" rows="4"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
          <div class="col-md-12 m-t-20 m-b-20">
            <button [disabled]="guardar_disabled == true" (click) = "guardar('guardar')" class="btn btn-primary"><i class="fa fa-save"></i> Guardar</button> &nbsp;

            <button [disabled]="enviar_disabled == true" (click) = "guardar('enviar')" class="btn btn-success"><i class="fa fa-paper-plane"></i> Enviar Documentación</button>
            <br><br>
            <font color="red">*</font><label for="">&nbsp;Estos Archivos son Obligatorios.</label>

          </div>
          <div class="col-md-12 m-t-20">


            <div class="note" [class.note-warning] = "estado_periodo == 'Rechazado'" [class.note-info] = "estado_periodo != 'Rechazado'" [class.note-success] = "estado_periodo == 'Aprobado'">
              <div class="note-icon"><i class="fa fa-info"></i></div>
              <div class="note-content">
                <h4><b>Información del Periodo</b></h4>
                <div class="row">
                  <div class="col-md-10" *ngIf="bitacora.length > 0">
                    <label>Fecha:</label> {{ultBitacora.fecha | date:'dd/MM/yyyy HH:mm'}} &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>Etapa:</label> {{ultBitacora.etapa}} <br>
                    <label>Estado:</label> {{ultBitacora.estado}} &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>Observaciones:</label> {{ultBitacora.observaciones}}
                  </div>
                  <div *ngIf="bitacora.length == 0">
                    <label>Etapa:</label> {{etapa_periodo}} &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>Estado:</label> {{estado_periodo}}
                  </div>
                  <div *ngIf="bitacora.length > 0">
                    <button type="button" class="btn btn-sm btn-success" (click)="open(modalbitacora, 'bitacora')">Ver Bitacora</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf = "privilegio.revision_periodo == true" class="panel panel-primary">
    <div class="panel-heading">
      <div class="panel-heading-btn">

      </div>
      <div class="col d-flex justify-content-between">
        <div>
          <h4 class="panel-title">Revisión del Periodo</h4>
        </div>
        <div>
          <div class="btn-group" ngbDropdown>
            <button class="btn btn-info dropdown-toggle" ngbDropdownToggle><i class="far fa-chart-bar"></i></button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <a class="nav-item nav-link btn-outline-info" (click)="open(modalestadistica, 'estadistica')">ESTADISTICAS ENTIDAD: {{entidad_periodo}}</a>
              <a *ngFor="let g of f29_grupos" class="nav-item nav-link btn-outline-info" (click)="open(borradorF29,g.grupo)">VER CONSOLIDADO F29 {{g.grupo}}</a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="panel-body p-t-10">


      <!--[attr.selected]="passenger.Title==title.Text ? true : null"-->
      <div class="row form-group m-b-10">
        <label class="col-md-2 col-form-label">Estado Revisión:</label>
        <div class="col-md-3">
          <select class="form-control" name="estado_revision" #estado_revision="ngModel" [(ngModel)] ="revision_periodo.estado_revision" id="">
            <option  value="" disabled>Seleccione...</option>
            <option  *ngFor="let e of estados_revision;let indice=index" [value]="e._id" >
            {{e.nombre}}
            </option>
          </select>
        </div>
      </div>
      <div *ngIf="revision_periodo.estado_revision == '5d7a4b8a4938a3d5df0ae2af'"  class="row form-group m-b-12">
          <label class="col-md-2 col-form-label">Fecha de Vencimiento:</label>
          <div class="col-md-5 form-check">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input type="checkbox" class="form-check-input" #fecha_vencimiento_revision="ngModel" [(ngModel)] ="revision_periodo.fecha_vencimiento" /><label for="">Si</label>  <br>
            <div *ngIf="revision_periodo.fecha_vencimiento == true">
              <label for="">Fecha: </label><input class="form-control col-sm-4" value="" type="date" #fecha_v_revision="ngModel" [(ngModel)] ="revision_periodo.fecha">
              <label for="">Hora: </label><input type="time" class="form-control col-sm-3" #hora_v_revision="ngModel" [(ngModel)] ="revision_periodo.hora">
            </div>
          </div>

      </div>
      <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label">Observaciones:</label>
          <div class="col-md-6">
            <textarea class="form-control" name="observacion_revision" #observacion_revision="ngModel" [(ngModel)] ="revision_periodo.observaciones" id="" cols="30" rows="5"></textarea>
          </div>
      </div>

      <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label">&nbsp;</label>
          <div class="col-md-6">
            <button (click) = "actualizar_estado()" class="btn btn-success"><i class="fas fa-fw m-r-10 fa-calendar-check"></i>Actualizar Estado</button> &nbsp;
            <button *ngIf = "btn_cerrar_periodo == true" (click) = "open(modalCerrarPeriodo, cerrar)" class="btn btn-primary"><i class="fas fa-fw m-r-10 fa-lock"></i>Cerrar Periodo</button>
          </div>
      </div>
    </div>
  </div>

  <ng-template #modalCerrarPeriodo let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title" id="bitacora2">Cerrar Periodo</h5>
      <button type="button" class="close btn btn-sm btn-white" (click)="d('Cross click')">×</button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label>Fecha Vencimiento Siguiente Periodo: </label>
        <input type="datetime-local" class="form-control" [(ngModel)]="vencimiento_sig_periodo">
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-success" (click)="preCierre_periodo()">Aceptar</button>
      <button class="btn btn-danger" (click)="c('Close click')">Cerrar</button>
    </div>
  </ng-template>

  <ng-template #modalbitacora let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title" id="bitacora2">Bitácora del periodo</h5>
      <button type="button" class="close btn btn-sm btn-white" (click)="d('Cross click')">×</button>
    </div>
    <div class="modal-body">
      <div *ngIf="bitacora.length > 0" id="tabla_bitacora" class="table-responsive">
        <table class="table table-stripped">
          <tr>
            <th>Fecha</th>
            <th>Usuario</th>
            <th>Etapa</th>
            <th>Estado</th>
            <th>Observaciones</th>
          </tr>
          <tr *ngFor="let b of bitacora.slice().reverse()" [class.table-warning] = "b.estado == 'RECHAZADO'" [class.table-success]="b.estado == 'APROBADO'"  [class.table-info] = "b.estado != 'RECHAZADO'">
            <td>{{b.fecha | date:'dd/MM/yyyy HH:mm'}}</td>
            <td>{{b.usuario}}</td>
            <td>{{b.etapa}}</td>
            <td>{{b.estado}}</td>
            <td>{{b.observaciones}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="c('Close click')">Cerrar</button>
    </div>
  </ng-template>

  <ng-template #modalestadistica let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title" id="bitacora2">Estadisticas Entidad: {{entidad_periodo}}</h5>
      <button type="button" class="close btn btn-sm btn-white" (click)="d('Cross click')">×</button>
    </div>
    <div class="modal-body">
      <div *ngIf="estadisticas.activo == 1">
        <table class="table">
          <tr>
            <td><strong>Entrega la documentación a tiempo el: </strong></td>
            <td>{{estadisticas.porcentaje_aTiempo}}% de las veces</td>
          </tr>
          <tr>
            <td><strong>Entrega la Documentación con: </strong></td>
            <td>{{estadisticas.dias_entrega}}</td>
          </tr>
          <tr>
            <td><strong>Promedio de rechazos por periodo: </strong></td>
            <td>{{estadisticas.promedio_rechazos}} veces</td>
          </tr>
        </table>
      </div>
      <div *ngIf="estadisticas.activo == 0">
        <label>Aún no hay datos para mostrar</label>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="c('Close click')">Cerrar</button>
    </div>
</ng-template>



 <ng-template #borradorF29 let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Borrador F-29 &nbsp;&nbsp;&nbsp; <b>{{categoria_borrador}}</b></h4>
      <label *ngIf="cargando_pdf == true" class="text-info"><i class="fas fa-sync  fa-pulse fa-lg"></i> </label>
      <button type="button" class="close btn btn-sm btn-white" (click)="d('Cross click')">×</button>
    </div>
    


    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink (click)="categoria_borrador = 'Débitos y Ventas'" class="text-center">Débitos y <br>Ventas</a>
        <ng-template ngbNavContent>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr class="table-info">
                <th colspan="6">INFORMACIÓN DE INGRESOS</th>
                <th colspan="2" class="align-middle">Cantidad de Documentos</th>
                <th colspan="3" class="align-middle">Monto Neto</th>
              </tr>
              <tr>
                <td class="table-secondary">1</td>
                <td class="align-middle" colspan="5">Exportaciones</td>
                <td class="align-middle table-info">585</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()"  [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">20</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" value="{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.monto_neto.cantidad | thousandsPipe}}" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.monto_neto.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">2</td>
                <td class="align-middle" colspan="5">Ventas y/o Servicios prestados Exentos o No Gravados del giro</td>
                <td class="align-middle table-info">586</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">142</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumericNegative($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.monto_neto.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.monto_neto.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">3</td>
                <td class="align-middle" colspan="5">Ventas con retención sobre el margen de comercialización (contribuyentes retenidos)</td>
                <td class="align-middle table-info">731</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">732</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.monto_neto.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.monto_neto.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">4</td>
                <td class="align-middle" colspan="5">Ventas y/o Servicios prestados exentos o No Gravados que no son del giro</td>
                <td class="align-middle table-info">714</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">715</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.monto_neto.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.monto_neto.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">5</td>
                <td class="align-middle" colspan="5">Facturas de Compras recibidas con retención total (contribuyentes retenidos)</td>
                <td class="align-middle table-info">515</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">587</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.monto_neto.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.monto_neto.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">6</td>
                <td class="align-middle" colspan="7">Facturas de compras recibidas con retención parcial (Total neto según línea N°12)</td>
                <td class="align-middle table-info">720</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_6.monto_neto.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_6.monto_neto.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary"></td>
              </tr>
              <tr class="table-info">
                <th colspan="6">GENERA DÉBITO</th>
                <th colspan="2" class="align-middle">Cantidad de Documentos</th>
                <th colspan="3" class="align-middle">Débito</th>
              </tr>
              <tr>
                <td class="table-secondary">7</td>
                <td class="align-middle" colspan="5">Facturas emitidas por ventas y servicios del giro</td>
                <td class="align-middle table-info">503</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_7.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_7.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">502</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_7.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_7.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">8</td>
                <td class="align-middle" colspan="5">Facturas emitidas por la venta de bienes inmuebles afectas a IVA</td>
                <td class="align-middle table-info">763</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_8.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_8.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">764</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_8.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_8.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">9</td>
                <td class="align-middle" colspan="5">Facturas, Notas de Débito y Notas de Crédito emitidas por ventas que no son del giro (activo fijo y otros)</td>
                <td class="align-middle table-info">716</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_9.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_9.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">717</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_9.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_9.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">10</td>
                <td class="align-middle" colspan="5">Boletas</td>
                <td class="align-middle table-info">110</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_10.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_10.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">111</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_10.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_10.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">11</td>
                <td class="align-middle" colspan="5">Comprobantes o Recibos de Pago generados en transacciones pagadas a través de medios electrónicos</td>
                <td class="align-middle table-info">758</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_11.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_11.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">759</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_11.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_11.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">12</td>
                <td class="align-middle" colspan="5">Notas de Débito emitidas al giro</td>
                <td class="align-middle table-info">512</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_12.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_12.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">513</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_12.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_12.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">13</td>
                <td class="align-middle" colspan="5">Notas de Crédito emitidas por Facturas del giro</td>
                <td class="align-middle table-info">509</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_13.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_13.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">510</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_13.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_13.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">14</td>
                <td class="align-middle" colspan="5">Notas de Crédito emitidas por Vales de máquinas autorizadas por el Servicio</td>
                <td class="align-middle table-info">708</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_14.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_14.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">709</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_14.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_14.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">15</td>
                <td class="align-middle" colspan="5">Notas de Crédito emitidas por ventas y servicios que no son del giro (activo fijo y otros)</td>
                <td class="align-middle table-info">733</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_15.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_15.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">734</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_15.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_15.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">16</td>
                <td class="align-middle" colspan="5">Facturas de Compra recibidas con retención parcial (contribuyentes retenidos)</td>
                <td class="align-middle table-info">516</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_16.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_16.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">517</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_16.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_16.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">17</td>
                <td class="align-middle" colspan="5">Liquidación Factura</td>
                <td class="align-middle table-info">500</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_17.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_17.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"></td>
                <td class="align-middle table-info">501</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_17.debitos.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_17.debitos.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">18</td>
                <td class="align-middle" colspan="7">Adiciones al Débito Fiscal del mes</td>
                <td class="align-middle table-info">154</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.linea_18.debitos | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.linea_18.debitos = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">19</td>
                <td class="align-middle" colspan="7">Restitución Adicional por proporción de operaciones exentas y/o no gravadas por concepto Art.27 bis, inc.2° (Ley 19.738/01)</td>
                <td class="align-middle table-info">518</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.linea_19.debitos | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.linea_19.debitos = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">20</td>
                <td class="align-middle" colspan="7">Reintegro del Impuesto de Timbres y Estampillas, Art 3° Ley N° 20.259 e IVA determinado en el Arrendamiento esporádico de BBRR amoblados</td>
                <td class="align-middle table-info">713</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.linea_20.debitos | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.linea_20.debitos = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr class="table-info">
                <th colspan="2"></th>
                <th colspan="2">M3</th>
                <th colspan="2">Base</th>
                <th colspan="2">Variable</th>
                <th colspan="3">Débitos</th>
              </tr>
              <tr>
                <td class="table-secondary">21</td>
                <td class="align-middle">Adiciones al Débito por IEPD Ley 20.765 </td>
                <td class="align-middle table-info">738</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.linea_21.m3.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.linea_21.m3.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">739</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.linea_21.base.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.linea_21.base.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">740</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.linea_21.variable.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.linea_21.variable.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">741</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.debito_venta.linea_21.debitos | thousandsPipe" (ngModelChange)="borrador_f29.debito_venta.linea_21.debitos = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">22</td>
                <td class="align-middle" colspan="7">TOTAL DEBITOS</td>
                <td class="align-middle table-info">538</td>
                <td class="align-middle"><input type="text" class="form-control" on-focus="cambiarCero($event)" [ngModel]="borrador_f29.debito_venta.linea_22.debitos | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion" readonly>=</td>
              </tr>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink (click)="categoria_borrador = 'Créditos y Compras'" class="text-center"> Créditos y <br>Compras</a>
        <ng-template ngbNavContent>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr class="table-info">
                <th colspan="5">IMPUESTO AL VALOR AGREGADO D.L.825/74</th>
                <th colspan="2" class="align-middle">Crédito</th>
                <th colspan="4" class="align-middle">Sin Derecho a Crédito</th>
              </tr>
              <tr>
                <td class="table-secondary">23</td>
                <td class="align-middle" colspan="4">IVA por documentos electrónicos recibidos</td>
                <td class="align-middle table-info">511</td>
                <td class="align-middle"><input type="text" id="linea_23" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.linea_23.con_derecho_credito.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.linea_23.con_derecho_credito.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true || alerta == true" ></td>
                <td class="align-middle table-info">514</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.linea_23.sin_derecho_credito.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.linea_23.sin_derecho_credito.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary"></td>
              </tr>
              <tr class="table-info">
                <th colspan="5">SIN DERECHO A CRÉDITO FISCAL</th>
                <th colspan="2" class="align-middle">Cantidad de Documentos</th>
                <th colspan="4" class="align-middle">Monto Neto</th>
              </tr>
              <tr>
                <td class="table-secondary">24</td>
                <td class="align-middle" colspan="4">Internas Afectas</td>
                <td class="align-middle table-info">564</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">521</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.monto_neto.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.monto_neto.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">25</td>
                <td class="align-middle" colspan="4">Importaciones</td>
                <td class="align-middle table-info">566</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">560</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.monto_neto.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.monto_neto.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">26</td>
                <td class="align-middle" colspan="4">Internas exentas, o no gravadas</td>
                <td class="align-middle table-info">584</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">562</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.monto_neto.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.monto_neto.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary"></td>
              </tr>
              <tr class="table-info">
                <td colspan="11">INTERNAS</td>
              </tr>
              <tr class="table-info">
                <th colspan="5">CON DERECHO A CRÉDITO FISCAL</th>
                <th colspan="2" class="align-middle">Cantidad de Documentos</th>
                <th colspan="4" class="align-middle">Crédito,Recuperación y Reintegro</th>
              </tr>
              <tr>
                <td class="table-secondary">27</td>
                <td class="align-middle" colspan="4">Facturas recibidas del giro y Facturas de compras emitidas</td>
                <td class="align-middle table-info">519</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">520</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">28</td>
                <td class="align-middle" colspan="4">Facturas recibidas de Proveedores: Supermercados y Comercios similares, Art. 23 Nº4 D.L.825, de 1974 (Ley Nº20.780)</td>
                <td class="align-middle table-info">761</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">762</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">29</td>
                <td class="align-middle" colspan="4">Facturas recibidas por Adquisición o Construcción de Bienes Inmuebles, Art.8° transitorio (Ley N°20.780)</td>
                <td class="align-middle table-info">765</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">766</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">30</td>
                <td class="align-middle" colspan="4">Facturas activo fijo</td>
                <td class="align-middle table-info">524</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">525</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">31</td>
                <td class="align-middle" colspan="4">Notas de Crédito recibidas</td>
                <td class="align-middle table-info">527</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">528</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">32</td>
                <td class="align-middle" colspan="4">Notas de Débito recibidas</td>
                <td class="align-middle table-info">531</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">532</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr class="table-info">
                <td colspan="11">IMPORTACIONES</td>
              </tr>
              <tr>
                <td class="table-secondary">33</td>
                <td class="align-middle" colspan="4">Formulario de pago de importaciones del giro</td>
                <td class="align-middle table-info">534</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">535</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">34</td>
                <td class="align-middle" colspan="4">Formulario de pago de importaciones de activo fijo</td>
                <td class="align-middle table-info">536</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.cantidad_doc.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.cantidad_doc.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">553</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">35</td>
                <td class="align-middle" colspan="6">Remanente Crédito Fiscal mes anterior  <font color="red">&nbsp;&nbsp;&nbsp;&nbsp;(USO EXCLUSIVO UNIÓN CHILENA)</font></td>
                <td class="align-middle table-info">504</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.linea_35.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.linea_35.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="borrador_f29._id || etapa_periodo == 'ENVIADO' || estado_periodo == 'Aprobado'"></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">36</td>
                <td class="align-middle" colspan="6">Devolución Solicitud Art. 36 (Exportadores)</td>
                <td class="align-middle table-info">593</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.linea_36.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.linea_36.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">37</td>
                <td class="align-middle" colspan="6">Devolución Solicitud Art.27 bis (Activo fijo)</td>
                <td class="align-middle table-info">594</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.linea_37.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.linea_37.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">38</td>
                <td class="align-middle" colspan="6">Certificado Imputación Art.27 bis (Activo fijo)</td>
                <td class="align-middle table-info">592</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.linea_38.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.linea_38.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">39</td>
                <td class="align-middle" colspan="6">Devolución Solicitud Art.3 (Cambio de sujeto)</td>
                <td class="align-middle table-info">539</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.linea_39.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.linea_39.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">40</td>
                <td class="align-middle" colspan="6">Devolución Solicitud Ley N° 20.258 por remanente CF IVA originado en Impuesto Específico Petróleo Diesel (Generadoras Eléctricas)</td>
                <td class="align-middle table-info">718</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.linea_40.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.linea_40.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">41</td>
                <td class="align-middle" colspan="6">Monto Reintegrado por Devolución Indebida de Crédito Fiscal D.S. 348 (Exportadores)</td>
                <td class="align-middle table-info">164</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.linea_41.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.linea_41.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr class="table-info">
                <th colspan="2"></th>
                <th colspan="3">M3 Comprados con derecho a crédito</th>
                <th colspan="2">Componentes del impuesto</th>
                <th colspan="3">Crédito,Recuperación y Reintegro</th>
              </tr>
              <tr>
                <td class="table-secondary" rowspan="2">42</td>
                <td class="align-middle" rowspan="2">Recuperación del Impuesto Específico al Petróleo Diesel (Art. 7º Ley 18.502, Arts. 1º y 3º D.S. Nº311/86)</td>
                <td class="align-middle table-info" rowspan="2">730</td>
                <td class="align-middle" rowspan="2"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.ley_20765.linea_42.m3_comprados_con_derecho_credito.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle">Base</td>
                <td class="align-middle table-info">742</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.ley_20765.linea_42.componente_impuesto.base.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.ley_20765.linea_42.componente_impuesto.base.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"></td>
                <td class="align-middle table-info" rowspan="2">127</td>
                <td class="align-middle" rowspan="2"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.ley_20765.linea_42.credito_recuperacion_reintegro.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion" rowspan="2">+</td>
              </tr>
              <tr>
                <td class="align-middle">Variable</td>
                <td class="align-middle table-info">743</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.ley_20765.linea_42.componente_impuesto.variable.cantidad | thousandsPipe" readonly></td>
              </tr>
              <tr>
                <td class="table-secondary" rowspan="2">43</td>
                <td class="align-middle" rowspan="2">Recuperación del Impuesto Específico al Petróleo Diesel soportado por Transportistas de Carga (Art. 2º Ley 19.764)</td>
                <td class="align-middle table-info" rowspan="2">729</td>
                <td class="align-middle" rowspan="2"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.ley_20765.linea_43.m3_comprados_con_derecho_credito.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle">Base</td>
                <td class="align-middle table-info">744</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.ley_20765.linea_43.componente_impuesto.base.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.ley_20765.linea_43.componente_impuesto.base.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info" rowspan="2">544</td>
                <td class="align-middle" rowspan="2"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.ley_20765.linea_43.credito_recuperacion_reintegro.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion" rowspan="2">+</td>
              </tr>
              <tr>
                <td class="align-middle">Variable</td>
                <td class="align-middle table-info">745</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.ley_20765.linea_43.componente_impuesto.variable.cantidad | thousandsPipe" readonly></td>
              </tr>
              <tr>
                <td class="table-secondary">44</td>
                <td class="align-middle" colspan="6">Crédito del Art. 11º Ley 18.211 (correspondiente a Zona Franca de Extensión)</td>
                <td class="align-middle table-info">523</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.linea_44.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.linea_44.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">45</td>
                <td class="align-middle" colspan="6">Crédito por Impuesto de Timbres y Estampillas, Art. 3º Ley 20.259</td>
                <td class="align-middle table-info">712</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.linea_45.credito_recuperacion_reintegro.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.credito_compra.linea_45.credito_recuperacion_reintegro.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">46</td>
                <td class="align-middle" colspan="6">Crédito por IVA restituido a aportantes sin domicilio ni residencia en Chile (Art. 83, del artículo primero Ley 20.712) </td>
                <td class="align-middle table-info">757</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.credito_compra.linea_46.credito_recuperacion_reintegro.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">47</td>
                <td class="align-middle" colspan="6"><strong>TOTAL CREDITOS</strong></td>
                <td class="align-middle table-info">537</td>
                <td class="align-middle"><input type="text" class="form-control" [ngModel]="borrador_f29.credito_compra.linea_47.credito_recuperacion_reintegro.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">=</td>
              </tr>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink (click)="categoria_borrador = 'Impuesto a la Renta'" class="text-center">Impuesto a <br>la Renta</a>
        <ng-template ngbNavContent>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr class="table-info">
                <th colspan="12"></th>
                <th colspan="3">IMPUESTOS DETERMINADOS</th>
              </tr>
              <tr>
                <td class="table-secondary">48</td>
                <td class="align-middle" colspan="4">Remanentes de crédito fiscal para el período siguiente</td>
                <td class="align-middle table-info">77</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.linea_48.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.linea_48.cantidad = ($event)" readonly></td>
                <td class="align-middle" colspan="4"></td>
                <td class="align-middle">IVA Determinado</td>
                <td class="align-middle table-info">89</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.linea_48.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.linea_48.impuesto_determinado.cantidad = ($event)" readonly></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">49</td>
                <td class="align-middle" colspan="11">Restitución de devolución por concepto de Art. 27 ter D.L. 825, de 1974, inc. 2° (Ley N° 20.720)</td>
                <td class="align-middle table-info">760</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.linea_49.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.linea_49.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">50</td>
                <td class="align-middle" colspan="11">Certificado Imputación Art. 27 ter D.L. 825, de 1974, inc. 1° (Ley N° 20.720)</td>
                <td class="align-middle table-info">767</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.linea_50.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.linea_50.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr class="table-info">
                <td colspan="15">RETENCIONES</td>
              </tr>
              <tr>
                <td class="table-secondary">51</td>
                <td class="align-middle" colspan="11">Retención Impuesto Primera Categoría por rentas de capitales mobiliarios del Art.20 N°2, según Art.73 LIR</td>
                <td class="align-middle table-info">50</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_51.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_51.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary" rowspan="2">52</td>
                <td class="align-middle" rowspan="2" colspan="4">Retención Impuesto Unico a los Trabajadores, según Art.74 Nº1 LIR</td>
                <td class="align-middle" rowspan="2">Créditos</td>
                <td class="align-middle" colspan="2">Donación Art. 8° Ley 18.985</td>
                <td class="align-middle" colspan="2">Donación Ley 20.444/2010</td>
                <td class="align-middle" rowspan="2" colspan="2">Impuesto Único 2da. Categoría a Pagar</td>
                <td class="align-middle table-info" rowspan="2">48</td>
                <td class="align-middle" rowspan="2"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_52.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_52.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion" rowspan="2">+</td>
              </tr>
              <tr>
                <td class="align-middle table-info">751</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_52.donacion_articulo_8_ley_18985.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_52.donacion_articulo_8_ley_18985.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"></td>
                <td class="align-middle table-info">735</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_52.donacion_ley_20444_2010.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_52.donacion_ley_20444_2010.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
              </tr>
              <tr>
                <td class="table-secondary">53</td>
                <td class="align-middle" colspan="11">Retención de Impuesto con tasa de 10,75% sobre las rentas del Art.42 Nº2, según Art.74 Nº2 LIR</td>
                <td class="align-middle table-info">151</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_53.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_53.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">54</td>
                <td class="align-middle" colspan="11">Retención de Impuesto con tasa del 10% sobre las rentas del Art. 48, según Art. 74 N°3 LIR</td>
                <td class="align-middle table-info">153</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_54.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_54.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">55</td>
                <td class="align-middle" colspan="11">Retención a Suplementeros, según Art. 74 N° 5 (tasa 0,5%) LIR</td>
                <td class="align-middle table-info">54</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_55.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_55.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr> 
              <tr>
                <td class="table-secondary">56</td>
                <td class="align-middle" colspan="11">Retención sobre rentas Art. 42 N°1 LIR con tasa del 3%, por reintregro del préstamo tasa 0%, según art. 9° letra a) Ley N° 21.252</td>
                <td class="align-middle table-info">49</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_63.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_63.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr> 
              <tr>
                <td class="table-secondary">57</td>
                <td class="align-middle" colspan="11">Retención sobre rentas Art. 42 N°2 LIR con tasa del 3%, por reintregro del préstamo tasa 0%, según art. 7° Ley N° 21.242 y art. 9° letra b) Ley N° 21.252</td>
                <td class="align-middle table-info">155</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_64.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_64.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">58</td>
                <td class="align-middle" colspan="11">Retención por compra de productos mineros, según Art. 74 N° 6 LIR</td>
                <td class="align-middle table-info">56</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_56.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_56.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">59</td>
                <td class="align-middle" colspan="11">Retención sobre cantidades pagadas en cumplimiento de Seguros Dotales del Art.17, N°3 (tasa 15%)</td>
                <td class="align-middle table-info">588</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_57.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_57.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">60</td>
                <td class="align-middle" colspan="11">Retención sobre retiros de Ahorro Previsional Voluntario del Art. 42 bis LIR (tasa 15%)</td>
                <td class="align-middle table-info">589</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.retenciones.linea_58.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.retenciones.linea_58.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr class="sub-cat">
                <td colspan="16">PPM</td>
              </tr>
              <tr class="table-info">
                <th colspan="2"></th>
                <th colspan="2">Acogido a Suspension PPM</th>
                <th colspan="2">Monto Perdida Art.90</th>
                <th colspan="2">Base Imponible</th>
                <th colspan="2">Tasa</th>
                <th colspan="2">Crédito/Tope Suspension PPM</th>
                <th colspan="3">PPM Neto Determinado</th>
              </tr>
              <tr >
                <td class="table-secondary">61</td>
                <td class="align-middle">1ra. Categoría Art. 84 a)</td>
                <td class="align-middle table-info">750</td>
                <td><input type="checkbox" [checked]="borrador_f29.impuesto_renta.ppm.linea_59.acogido_suspension_ppm == true"></td>
                <td class="align-middle table-info">30</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_59.monto_perdida_art90.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_59.monto_perdida_art90.cantidad = ($event)"  [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">563</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_59.base_imponible.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_59.base_imponible.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">115</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_59.tasa.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_59.tasa.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">68</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_59.credito_tope_suspension_ppm.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_59.credito_tope_suspension_ppm.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">62</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_59.ppm_neto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_59.ppm_neto_determinado.cantidad = ($event)" readonly></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr >
                <td class="table-secondary">62</td>
                <td class="align-middle" colspan="3">Mineros Art. 84 a)</td>
                <td class="align-middle table-info">565</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_60.monto_perdida_art90.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_60.monto_perdida_art90.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">120</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_60.base_imponible.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_60.base_imponible.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">542</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_60.tasa.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_60.tasa.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">122</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_60.credito_tope_suspension_ppm.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_60.credito_tope_suspension_ppm.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">123</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_60.ppm_neto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_60.ppm_neto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr >
                <td class="table-secondary">63</td>
                <td class="align-middle" colspan="3">Explotador Minero Art. 84 h)</td>
                <td class="align-middle table-info">700</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_61.monto_perdida_art90.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_61.monto_perdida_art90.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">701</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_61.base_imponible.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_61.base_imponible.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">702</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_61.tasa.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_61.tasa.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">711</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_61.credito_tope_suspension_ppm.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_61.credito_tope_suspension_ppm.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">703</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_61.ppm_neto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr >
                <td class="table-secondary">64</td>
                <td class="align-middle" colspan="11">Transportistas acogidos a Renta Presunta, Art. 84, e) y f) (tasa de 0,3%)</td>
                <td class="align-middle table-info">66</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_62.ppm_neto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_62.ppm_neto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr class="table-info">
                <td colspan="4"></td>
                <td class="align-middle" colspan="2">Crédito del Mes</td>
                <td class="align-middle" colspan="2">Remanente mes anterior</td>
                <td class="align-middle" colspan="2">Remanente periodo siguiente</td>
                <td class="align-middle" colspan="5"></td>
              </tr>
              <tr >
                <td class="table-secondary">65</td>
                <td class="align-middle" colspan="3">Crédito Capacitación, Ley 19.518/97</td>
                <td class="align-middle table-info">721</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_63.credito_mes.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_63.credito_mes.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-info">722</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_63.remanente_mes_anterior.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_63.remanente_mes_anterior.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-info">724</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_63.remanente_periodo_siguiente.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle" colspan="2">Crédito a imputar</td>
                <td class="align-middle table-info">723</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_63.ppm_neto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr >
                <td class="table-secondary">66</td>
                <td class="align-middle" colspan="11">2da. Categoría Art. 84, b) (tasa según Ley 21.133)</td>
                <td class="align-middle table-info">152</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_64.ppm_neto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_64.ppm_neto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr >
                <td class="table-secondary">67</td>
                <td class="align-middle" colspan="11">Taller artesanal Art. 84, c) (tasa de 1,5% o 3%)</td>
                <td class="align-middle table-info">70</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_renta.ppm.linea_65.ppm_neto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_renta.ppm.linea_65.ppm_neto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"  ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td colspan="15"></td>
              </tr>
              <tr >
                <td class="table-secondary">68</td>
                <td class="align-middle" colspan="11">SUB TOTAL IMPUESTO DETERMINADO ANVERSO. (Suma de las líneas 40 a 55, columna Impuesto y/o PPM determinado)</td>
                <td class="align-middle table-info">595</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.linea_66.ppm_neto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion" readonly>=</td>
              </tr>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink (click)="categoria_borrador = 'Tributación Simplificada'" class="text-center">Tributación <br>Simplificada </a>
        <ng-template ngbNavContent>
          <div class="table-responsive">
            <table class="table table-bordered" id="t67_69">
              <tr class="table-info">
                <th colspan="2"></th>
                <th colspan="4">IMPUESTOS DETERMINADOS</th>
              </tr>
              <tr>
                <td class="table-secondary">69</td>
                <td class="align-middle">Ventas del período</td>
                <td class="align-middle table-info">529</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.tributacion_simplificada.linea_67.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.tributacion_simplificada.linea_67.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="table-secondary" colspan="3"></td>
              </tr>
              <tr>
                <td class="table-secondary">70</td>
                <td class="align-middle">Crédito del período</td>
                <td class="align-middle table-info">530</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.tributacion_simplificada.linea_68.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.tributacion_simplificada.linea_68.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="table-secondary" colspan="3"></td>
              </tr>
              <tr>
                <td class="table-secondary">71</td>
                <td class="align-middle">IVA determinado por concepto de Tributación Simplificada</td>
                <td class="align-middle table-info">409</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.tributacion_simplificada.linea_69.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.tributacion_simplificada.linea_69.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink (click)="categoria_borrador = 'Impuesto Adicional'" class="text-center">Impuesto <br>Adicional</a>
        <ng-template ngbNavContent>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr class="table-info">
                <th colspan="5"></th>
                <th colspan="3">IMPUESTOS DETERMINADOS</th>
              </tr>
              <tr>
                <td class="table-secondary">72</td>
                <td class="align-middle" colspan="4">Letras e), h), i), l) (tasa 15%)</td>
                <td class="align-middle table-info">522</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_adicional.linea_70.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_adicional.linea_70.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">73</td>
                <td class="align-middle" colspan="4">Letra j) (tasa 50%)</td>
                <td class="align-middle table-info">526</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_adicional.linea_71.impuesto_determinado.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_adicional.linea_71.impuesto_determinado.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">74</td>
                <td class="align-middle" colspan="4">Débito de Impuesto Adicional Ventas Art. 37 letras a), b) y c) y Art. 40 D.L. 825 (tasa 15%)</td>
                <td class="align-middle table-info">113</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_adicional.linea_72.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_adicional.linea_72.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">75</td>
                <td class="align-middle" colspan="4">Crédito de Impuesto Adicional Art. 37 letras a), b) y c) D.L. 825</td>
                <td class="align-middle table-info">28</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_adicional.linea_73.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_adicional.linea_73.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">76</td>
                <td class="align-middle" colspan="4">Monto reintegrado por devolución indebida de crédito por exportadores D.L. 825</td>
                <td class="align-middle table-info">548</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_adicional.linea_74.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_adicional.linea_74.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">77</td>
                <td class="align-middle" colspan="4">Remanente crédito Art. 37 mes anterior D.L. 825</td>
                <td class="align-middle table-info">540</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_adicional.linea_75.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_adicional.linea_75.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">78</td>
                <td class="align-middle" colspan="4">Devolución Solicitud Art. 36 relativa al Impuesto Adicional Art. 37 letras a), b) y c) D.L. 825</td>
                <td class="align-middle table-info">541</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_adicional.linea_76.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_adicional.linea_76.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">79</td>
                <td class="align-middle">Remanente crédito impuesto Art. 37 para período siguiente</td>
                <td class="align-middle table-info">549</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_adicional.linea_77.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.impuesto_adicional.linea_77.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle">Impuesto Adicional Art. 37 y Art. 40 determinado</td>
                <td class="align-middle table-info">550</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.impuesto_adicional.linea_77.impuesto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="6">
        <a ngbNavLink (click)="categoria_borrador = 'Cambio Sujeto'" class="text-center">Cambio <br>Sujeto</a>
        <ng-template ngbNavContent>
          <div class="table-responsive">
            <table class="table table-bordered" >
              <tr class="table-info">
                <th colspan="6">ANTICIPO CAMBIO DE SUJETO (CONTRIBUYENTES RETENIDOS)</th>
                <th colspan="3">IMPUESTOS DETERMINADOS</th>
              </tr>
              <tr>
                <td class="table-secondary">99</td>
                <td class="align-middle" colspan="5">IVA anticipado del período</td>
                <td class="align-middle table-info">556</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.parte_1.linea_99.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.cambio_sujeto.parte_1.linea_99.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">100</td>
                <td class="align-middle" colspan="5">Remanente del mes anterior</td>
                <td class="align-middle table-info">557</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.parte_1.linea_100.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.cambio_sujeto.parte_1.linea_100.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">101</td>
                <td class="align-middle" colspan="5">Devolución del mes anterior</td>
                <td class="align-middle table-info">558</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.parte_1.linea_101.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.cambio_sujeto.parte_1.linea_101.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">102</td>
                <td class="align-middle" colspan="5">Total de Anticipo</td>
                <td class="align-middle table-info">543</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.parte_1.linea_102.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion" readonly>=</td>
              </tr>
              <tr>
                <td class="table-secondary">103</td>
                <td class="align-middle">Remanente Anticipos Cambio Sujeto para período siguiente</td>
                <td class="align-middle table-info">573</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.linea_103.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle" colspan="2">Anticipo a imputar</td>
                <td class="align-middle table-info">598</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.linea_103.impuesto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr class="table-info">
                <th colspan="9">CAMBIO DE SUJETO (AGENTE RETENEDOR)</th>
              </tr>
              <tr>
                <td class="table-secondary">104</td>
                <td class="align-middle">IVA total retenido a terceros (tasa Art.14 DL 825/74)</td>
                <td class="align-middle table-info">39</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.parte_2.linea_104.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.cambio_sujeto.parte_2.linea_104.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">105</td>
                <td class="align-middle">IVA parcial retenido a terceros (según tasa)</td>
                <td class="align-middle table-info">554</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.parte_2.linea_105.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.cambio_sujeto.parte_2.linea_105.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">106</td>
                <td class="align-middle">IVA Retenido por notas de crédito emitidas</td>
                <td class="align-middle table-info">736</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.parte_2.linea_106.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.cambio_sujeto.parte_2.linea_106.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">107</td>
                <td class="align-middle">Retención del margen de comercialización</td>
                <td class="align-middle table-info">597</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.parte_2.linea_107.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.cambio_sujeto.parte_2.linea_107.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">108</td>
                <td class="align-middle">Retención Anticipo de Cambio de Sujeto</td>
                <td class="align-middle table-info">555</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.parte_2.linea_108.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.cambio_sujeto.parte_2.linea_108.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle table-secondary operacion">+</td>
                <td class="align-middle">Retención Cambio de Sujeto</td>
                <td class="align-middle table-info">596</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.cambio_sujeto.parte_2.linea_108.impuesto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="7">
        <a ngbNavLink (click)="categoria_borrador = 'Creditos Especiales'" class="text-center">Creditos <br>Especiales</a>
        <ng-template ngbNavContent>
          <div class="table-responsive">
            <table class="table table-bordered" id="t109_115">
              <tr class="table-info">
                <th colspan="8"></th>
                <th colspan="4">IMPUESTOS DETERMINADOS</th>
              </tr>
              <tr>
                <td class="table-secondary">109</td>
                <td class="align-middle">Crédito por Sistemas Solares Térmicos, Ley Nº 20.365</td>
                <td class="align-middle table-info">725</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_109.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle">Remanente mes anterio</td>
                <td class="align-middle table-info">737</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_109.remanente_mes_anterior.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle">Total Crédito</td>
                <td class="align-middle table-info">727</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_109.impuesto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">110</td>
                <td class="align-middle">Imputación del Pago Patentes Aguas, Ley Nº 20.017</td>
                <td class="align-middle table-info">704</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_110.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.creditos_especiales.linea_110.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle">Remanente mes anterio</td>
                <td class="align-middle table-info">705</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_110.remanente_mes_anterior.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.creditos_especiales.linea_110.remanente_mes_anterior.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle">Total Crédito</td>
                <td class="align-middle table-info">706</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_110.impuesto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">111</td>
                <td class="align-middle">Cotización Adicional, Ley Nº 18.566</td>
                <td class="align-middle table-info">160</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_111.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.creditos_especiales.linea_111.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle">Remanente mes anterio</td>
                <td class="align-middle table-info">161</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_111.remanente_mes_anterior.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.creditos_especiales.linea_111.remanente_mes_anterior.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle">Total Crédito</td>
                <td class="align-middle table-info">570</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_111.impuesto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">112</td>
                <td class="align-middle">Crédito Especial Empresas Constructoras</td>
                <td class="align-middle table-info">126</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_112.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.creditos_especiales.linea_112.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle">Remanente mes anterio</td>
                <td class="align-middle table-info">128</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_112.remanente_mes_anterior.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.creditos_especiales.linea_112.remanente_mes_anterior.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle">Total Crédito</td>
                <td class="align-middle table-info">571</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_112.impuesto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">113</td>
                <td class="align-middle">Recup. Peajes Transportistas Pasajero, Ley Nº 19.764</td>
                <td class="align-middle table-info">572</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_113.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.creditos_especiales.linea_113.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle">Remanente mes anterio</td>
                <td class="align-middle table-info">568</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_113.remanente_mes_anterior.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.creditos_especiales.linea_113.remanente_mes_anterior.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="align-middle">Total Crédito</td>
                <td class="align-middle table-info">590</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_113.impuesto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">114</td>
                <td class="align-middle">Crédito por desembolsos directos trazabilidad</td>
                <td class="align-middle table-info">768</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_114.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle">Remanente mes anterio</td>
                <td class="align-middle table-info">769</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_114.remanente_mes_anterior.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle">Total Crédito</td>
                <td class="align-middle table-info">770</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.creditos_especiales.linea_114.impuesto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">-</td>
              </tr>
              <tr>
                <td class="table-secondary">115</td>
                <td class="align-middle" colspan="7">TOTAL DETERMINADO</td>
                <td class="align-middle table-info">547</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.linea_115.impuesto_determinado.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">=</td>
              </tr>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="8">
        <a ngbNavLink (click)="categoria_borrador = 'Remanente Creditos Especiales'" class="text-center">Remanente Creditos <br>Especiales</a>
        <ng-template ngbNavContent>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr>
                <td class="table-secondary">116</td>
                <td class="align-middle">Remanente Crédito por Sistemas Solares Térmicos, Ley Nº 20.365</td>
                <td class="align-middle table-info">728</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.remanente_creditos_especiales.linea_116.cantidad | thousandsPipe" readonly></td>
                <td class="table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">117</td>
                <td class="align-middle">Remanente periodo siguiente Patente Aguas, Ley Nº 20.017</td>
                <td class="align-middle table-info">707</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.remanente_creditos_especiales.linea_117.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.remanente_creditos_especiales.linea_117.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">118</td>
                <td class="align-middle">Remanente de Cotizacion Adicional Ley Nº 18.566</td>
                <td class="align-middle table-info">73</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.remanente_creditos_especiales.linea_118.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.remanente_creditos_especiales.linea_118.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true"></td>
                <td class="table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">119</td>
                <td class="align-middle">Remanente Crédito Especial Empresas Constructoras</td>
                <td class="align-middle table-info">130</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.remanente_creditos_especiales.linea_119.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.remanente_creditos_especiales.linea_119.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">120</td>
                <td class="align-middle">Remanente Recup. de Peajes Trans. Pasajeros Ley Nº 19.764</td>
                <td class="align-middle table-info">591</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.remanente_creditos_especiales.linea_120.cantidad | thousandsPipe" (ngModelChange)="borrador_f29.remanente_creditos_especiales.linea_120.cantidad = ($event)" [readonly]="!borrador_f29._id || estado_periodo == 'Enviado' || estado_periodo == 'Aprobado' || alerta == true" ></td>
                <td class="table-secondary"></td>
              </tr>
              <tr>
                <td class="table-secondary">121</td>
                <td class="align-middle">Remanente Crédito por desembolsos directos trazabilidad</td>
                <td class="align-middle table-info">771</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.remanente_creditos_especiales.linea_120.cantidad | thousandsPipe" readonly></td>
                <td class="table-secondary"></td>
              </tr>
              <tr>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td class="table-secondary">122</td>
                <td class="align-middle">TOTAL A PAGAR EN PLAZO LEGAL</td>
                <td class="align-middle table-info">91</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.linea_122.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion" readonly>=</td>
              </tr>
              <tr>
                <td class="table-secondary">123</td>
                <td class="align-middle">Más IPC</td>
                <td class="align-middle table-info">92</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.linea_123.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">124</td>
                <td class="align-middle">Más Intereses y multas</td>
                <td class="align-middle table-info">93</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.linea_124.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">+</td>
              </tr>
              <tr>
                <td class="table-secondary">125</td>
                <td class="align-middle">TOTAL A PAGAR CON RECARGO</td>
                <td class="align-middle table-info">94</td>
                <td class="align-middle"><input type="text" class="form-control" (keypress)="restrictNumeric($event)" on-focus="cambiarCero($event)" on-blur="guardarDeclaracionF29()" [ngModel]="borrador_f29.linea_125.cantidad | thousandsPipe" readonly></td>
                <td class="align-middle table-secondary operacion">=</td>
              </tr>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="9">
        <a ngbNavLink (click)="generarPDF_consolidado()" class="text-center"><i class="fas fa-file-pdf fa-2x"></i></a>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>


    <div hidden> 
      <table class="table table-bordered" id="tablas">
        <tr class="table-info">
          <th colspan="6">INFORMACIÓN DE INGRESOS</th>
          <th colspan="2" class="align-middle">Cantidad de Documentos</th>
          <th colspan="3" class="align-middle">Monto Neto</th>
        </tr>
        <tr>
          <td class="table-secondary">1</td>
          <td class="align-middle" colspan="5">Exportaciones</td>
          <td class="align-middle table-info">585</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">20</td>
          <td class="align-middle" colspan="2"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.monto_neto.cantidad | thousandsPipe}}</td>
        </tr>
        <tr>
          <td class="table-secondary">2</td>
          <td class="align-middle" colspan="5">Ventas y/o Servicios prestados Exentos o No Gravados del giro</td>
          <td class="align-middle table-info">586</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">142</td>
          <td class="align-middle" colspan="2"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.monto_neto.cantidad | thousandsPipe}}</td>
        </tr>
        <tr>
          <td class="table-secondary">3</td>
          <td class="align-middle" colspan="5">Ventas con retención sobre el margen de comercialización (contribuyentes retenidos)</td>
          <td class="align-middle table-info">731</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">732</td>
          <td class="align-middle" colspan="2"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.monto_neto.cantidad | thousandsPipe}}</td>
        </tr>
        <tr>
          <td class="table-secondary">4</td>
          <td class="align-middle" colspan="5">Ventas y/o Servicios prestados exentos o No Gravados que no son del giro</td>
          <td class="align-middle table-info">714</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">715</td>
          <td class="align-middle" colspan="2"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.monto_neto.cantidad | thousandsPipe}}</td>
        </tr>
        <tr>
          <td class="table-secondary">5</td>
          <td class="align-middle" colspan="5">Facturas de Compras recibidas con retención total (contribuyentes retenidos)</td>
          <td class="align-middle table-info">515</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">587</td>
          <td class="align-middle" colspan="2"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.monto_neto.cantidad | thousandsPipe}}</td>
        </tr>
        <tr>
          <td class="table-secondary">6</td>
          <td class="align-middle" colspan="7">Facturas de compras recibidas con retención parcial (Total neto según línea N°12)</td>
          <td class="align-middle table-info">720</td>
          <td class="align-middle" colspan="2"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.info_ingreso.linea_6.monto_neto.cantidad | thousandsPipe}}</td>
        </tr>
        <tr class="table-info">
          <th colspan="6">GENERA DÉBITO</th>
          <th colspan="2" class="align-middle">Cantidad de Documentos</th>
          <th colspan="3" class="align-middle">Débito</th>
        </tr>
        <tr>
          <td class="table-secondary">7</td>
          <td class="align-middle" colspan="5">Facturas emitidas por ventas y servicios del giro</td>
          <td class="align-middle table-info">503</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_7.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">502</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_7.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">8</td>
          <td class="align-middle" colspan="5">Facturas emitidas por la venta de bienes inmuebles afectas a IVA</td>
          <td class="align-middle table-info">763</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_8.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">764</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_8.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">9</td>
          <td class="align-middle" colspan="5">Facturas, Notas de Débito y Notas de Crédito emitidas por ventas que no son del giro (activo fijo y otros)</td>
          <td class="align-middle table-info">716</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_9.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">717</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_9.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">10</td>
          <td class="align-middle" colspan="5">Boletas</td>
          <td class="align-middle table-info">110</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_10.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">111</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_10.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">11</td>
          <td class="align-middle" colspan="5">Comprobantes o Recibos de Pago generados en transacciones pagadas a través de medios electrónicos</td>
          <td class="align-middle table-info">758</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_11.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">759</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_11.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">12</td>
          <td class="align-middle" colspan="5">Notas de Débito emitidas al giro</td>
          <td class="align-middle table-info">512</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_12.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">513</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_12.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">13</td>
          <td class="align-middle" colspan="5">Notas de Crédito emitidas por Facturas del giro</td>
          <td class="align-middle table-info">509</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_13.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">510</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_13.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">14</td>
          <td class="align-middle" colspan="5">Notas de Crédito emitidas por Vales de máquinas autorizadas por el Servicio</td>
          <td class="align-middle table-info">708</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_14.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">709</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_14.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">15</td>
          <td class="align-middle" colspan="5">Notas de Crédito emitidas por ventas y servicios que no son del giro (activo fijo y otros)</td>
          <td class="align-middle table-info">733</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_15.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">734</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_15.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">16</td>
          <td class="align-middle" colspan="5">Facturas de Compra recibidas con retención parcial (contribuyentes retenidos)</td>
          <td class="align-middle table-info">516</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_16.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">517</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_16.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">17</td>
          <td class="align-middle" colspan="5">Liquidación Factura</td>
          <td class="align-middle table-info">500</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_17.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">501</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.ventas_serv_prestados.genera_debito.linea_17.debitos.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">18</td>
          <td class="align-middle" colspan="7">Adiciones al Débito Fiscal del mes</td>
          <td class="align-middle table-info">154</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.linea_18.debitos | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">19</td>
          <td class="align-middle" colspan="7">Restitución Adicional por proporción de operaciones exentas y/o no gravadas por concepto Art.27 bis, inc.2° (Ley 19.738/01)</td>
          <td class="align-middle table-info">518</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.linea_19.debitos | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">20</td>
          <td class="align-middle" colspan="7">Reintegro del Impuesto de Timbres y Estampillas, Art 3° Ley N° 20.259 e IVA determinado en el Arrendamiento esporádico de BBRR amoblados</td>
          <td class="align-middle table-info">713</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.linea_20.debitos | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr class="table-info">
          <th colspan="2"></th>
          <th colspan="2">M3</th>
          <th colspan="2">Base</th>
          <th colspan="2">Variable</th>
          <th colspan="3">Débitos</th>
        </tr>
        <tr>
          <td class="table-secondary">21</td>
          <td class="align-middle">Adiciones al Débito por IEPD Ley 20.765 </td>
          <td class="align-middle table-info">738</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.linea_21.m3.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">739</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.linea_21.base.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">740</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.linea_21.variable.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">741</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.linea_21.debitos | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">22</td>
          <td class="align-middle" colspan="7">TOTAL DEBITOS</td>
          <td class="align-middle table-info">538</td>
          <td class="align-middle"><input type="text">{{borrador_f29.debito_venta.linea_22.debitos | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion" >=</td>
        </tr>
      </table>
      <table class="table table-bordered" id="tablas2">
        <tr class="table-info">
          <th colspan="5">IMPUESTO AL VALOR AGREGADO D.L.825/74</th>
          <th colspan="2" class="align-middle">Crédito</th>
          <th colspan="3" class="align-middle">Sin Derecho a Crédito</th>
        </tr>
        <tr>
          <td class="table-secondary">23</td>
          <td class="align-middle" colspan="4">IVA por documentos electrónicos recibidos</td>
          <td class="align-middle table-info">511</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.linea_23.con_derecho_credito.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">514</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.linea_23.sin_derecho_credito.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary"></td>
        </tr>
        <tr class="table-info">
          <th colspan="5">SIN DERECHO A CRÉDITO FISCAL</th>
          <th colspan="2" class="align-middle">Cantidad de Documentos</th>
          <th colspan="4" class="align-middle">Monto Neto</th>
        </tr>
        <tr>
          <td class="table-secondary">24</td>
          <td class="align-middle" colspan="4">Internas Afectas</td>
          <td class="align-middle table-info">564</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">521</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.monto_neto.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary"></td>
        </tr>
        <tr>
          <td class="table-secondary">25</td>
          <td class="align-middle" colspan="4">Importaciones</td>
          <td class="align-middle table-info">566</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">560</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.monto_neto.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary"></td>
        </tr>
        <tr>
          <td class="table-secondary">26</td>
          <td class="align-middle" colspan="4">Internas exentas, o no gravadas</td>
          <td class="align-middle table-info">584</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">562</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.monto_neto.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary"></td>
        </tr>
        <tr class="table-info">
          <td colspan="11">INTERNAS</td>
        </tr>
        <tr class="table-info">
          <th colspan="5">CON DERECHO A CRÉDITO FISCAL</th>
          <th colspan="2" class="align-middle">Cantidad de Documentos</th>
          <th colspan="4" class="align-middle">Crédito,Recuperación y Reintegro</th>
        </tr>
        <tr>
          <td class="table-secondary">27</td>
          <td class="align-middle" colspan="4">Facturas recibidas del giro y Facturas de compras emitidas</td>
          <td class="align-middle table-info">519</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">520</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">28</td>
          <td class="align-middle" colspan="4">Facturas recibidas de Proveedores: Supermercados y Comercios similares, Art. 23 Nº4 D.L.825, de 1974 (Ley Nº20.780)</td>
          <td class="align-middle table-info">761</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">762</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">29</td>
          <td class="align-middle" colspan="4">Facturas recibidas por Adquisición o Construcción de Bienes Inmuebles, Art.8° transitorio (Ley N°20.780)</td>
          <td class="align-middle table-info">765</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">766</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">30</td>
          <td class="align-middle" colspan="4">Facturas activo fijo</td>
          <td class="align-middle table-info">524</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">525</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">31</td>
          <td class="align-middle" colspan="4">Notas de Crédito recibidas</td>
          <td class="align-middle table-info">527</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">528</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">32</td>
          <td class="align-middle" colspan="4">Notas de Débito recibidas</td>
          <td class="align-middle table-info">531</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">532</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr class="table-info">
          <td colspan="11">IMPORTACIONES</td>
        </tr>
        <tr>
          <td class="table-secondary">33</td>
          <td class="align-middle" colspan="4">Formulario de pago de importaciones del giro</td>
          <td class="align-middle table-info">534</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">535</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">34</td>
          <td class="align-middle" colspan="4">Formulario de pago de importaciones de activo fijo</td>
          <td class="align-middle table-info">536</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.cantidad_doc.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">553</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">35</td>
          <td class="align-middle" colspan="6">Remanente Crédito Fiscal mes anterior  <font color="red">&nbsp;&nbsp;&nbsp;&nbsp;(USO EXCLUSIVO UNIÓN CHILENA)</font></td>
          <td class="align-middle table-info">504</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.linea_35.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">36</td>
          <td class="align-middle" colspan="6">Devolución Solicitud Art. 36 (Exportadores)</td>
          <td class="align-middle table-info">593</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.linea_36.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">37</td>
          <td class="align-middle" colspan="6">Devolución Solicitud Art.27 bis (Activo fijo)</td>
          <td class="align-middle table-info">594</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.linea_37.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">38</td>
          <td class="align-middle" colspan="6">Certificado Imputación Art.27 bis (Activo fijo)</td>
          <td class="align-middle table-info">592</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.linea_38.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">39</td>
          <td class="align-middle" colspan="6">Devolución Solicitud Art.3 (Cambio de sujeto)</td>
          <td class="align-middle table-info">539</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.linea_39.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">40</td>
          <td class="align-middle" colspan="6">Devolución Solicitud Ley N° 20.258 por remanente CF IVA originado en Impuesto Específico Petróleo Diesel (Generadoras Eléctricas)</td>
          <td class="align-middle table-info">718</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.linea_40.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">41</td>
          <td class="align-middle" colspan="6">Monto Reintegrado por Devolución Indebida de Crédito Fiscal D.S. 348 (Exportadores)</td>
          <td class="align-middle table-info">164</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.linea_41.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr class="table-info">
          <th colspan="2"></th>
          <th colspan="3">M3 Comprados con derecho a crédito</th>
          <th colspan="2">Componentes del impuesto</th>
          <th colspan="3">Crédito,Recuperación y Reintegro</th>
        </tr>
        <tr>
          <td class="table-secondary" rowspan="2">42</td>
          <td class="align-middle" rowspan="2">Recuperación del Impuesto Específico al Petróleo Diesel (Art. 7º Ley 18.502, Arts. 1º y 3º D.S. Nº311/86)</td>
          <td class="align-middle table-info" rowspan="2">730</td>
          <td class="align-middle" rowspan="2"><input type="text">{{borrador_f29.credito_compra.ley_20765.linea_42.m3_comprados_con_derecho_credito.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Base</td>
          <td class="align-middle table-info">742</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.ley_20765.linea_42.componente_impuesto.base.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info" rowspan="2">127</td>
          <td class="align-middle" rowspan="2"><input type="text">{{borrador_f29.credito_compra.ley_20765.linea_42.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion" rowspan="2">+</td>
        </tr>
        <tr>
          <td class="align-middle">Variable</td>
          <td class="align-middle table-info">743</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.ley_20765.linea_42.componente_impuesto.variable.cantidad | thousandsPipe}}</td>
        </tr>
        <tr>
          <td class="table-secondary" rowspan="2">43</td>
          <td class="align-middle" rowspan="2">Recuperación del Impuesto Específico al Petróleo Diesel soportado por Transportistas de Carga (Art. 2º Ley 19.764)</td>
          <td class="align-middle table-info" rowspan="2">729</td>
          <td class="align-middle" rowspan="2"><input type="text">{{borrador_f29.credito_compra.ley_20765.linea_43.m3_comprados_con_derecho_credito.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Base</td>
          <td class="align-middle table-info">744</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.ley_20765.linea_43.componente_impuesto.base.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info" rowspan="2">544</td>
          <td class="align-middle" rowspan="2"><input type="text">{{borrador_f29.credito_compra.ley_20765.linea_43.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion" rowspan="2">+</td>
        </tr>
        <tr>
          <td class="align-middle">Variable</td>
          <td class="align-middle table-info">745</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.ley_20765.linea_43.componente_impuesto.variable.cantidad | thousandsPipe}}</td>
        </tr>
        <tr>
          <td class="table-secondary">44</td>
          <td class="align-middle" colspan="6">Crédito del Art. 11º Ley 18.211 (correspondiente a Zona Franca de Extensión)</td>
          <td class="align-middle table-info">523</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.linea_44.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">45</td>
          <td class="align-middle" colspan="6">Crédito por Impuesto de Timbres y Estampillas, Art. 3º Ley 20.259</td>
          <td class="align-middle table-info">712</td>
          <td class="align-middle"><input type="text">{{borrador_f29.credito_compra.linea_45.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">46</td>
          <td class="align-middle" colspan="6">Crédito por IVA restituido a aportantes sin domicilio ni residencia en Chile (Art. 83, del artículo primero Ley 20.712) </td>
          <td class="align-middle table-info">757</td>
          <td class="align-middle"><input type="text" >{{borrador_f29.credito_compra.linea_46.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">47</td>
          <td class="align-middle" colspan="6"><strong>TOTAL CREDITOS</strong></td>
          <td class="align-middle table-info">537</td>
          <td class="align-middle"><input type="text" >{{borrador_f29.credito_compra.linea_47.credito_recuperacion_reintegro.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">=</td>
        </tr>
      </table>
      <table class="table table-bordered" id="tablas3">
        <tr class="table-info">
          <th colspan="12"></th>
          <th colspan="3">IMPUESTOS DETERMINADOS</th>
        </tr>
        <tr>
          <td class="table-secondary">48</td>
          <td class="align-middle" colspan="4">Remanentes de crédito fiscal para el período siguiente</td>
          <td class="align-middle table-info">77</td>
          <td class="align-middle"><input type="text" class="form-control">{{ borrador_f29.linea_48.cantidad | thousandsPipe}}</td>
          <td class="align-middle" colspan="4"></td>
          <td class="align-middle">IVA Determinado</td>
          <td class="align-middle table-info">89</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.linea_48.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">49</td>
          <td class="align-middle" colspan="11">Restitución de devolución por concepto de Art. 27 ter D.L. 825, de 1974, inc. 2° (Ley N° 20.720)</td>
          <td class="align-middle table-info">760</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.linea_49.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">50</td>
          <td class="align-middle" colspan="11">Certificado Imputación Art. 27 ter D.L. 825, de 1974, inc. 1° (Ley N° 20.720)</td>
          <td class="align-middle table-info">767</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.linea_50.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr class="table-info">
          <td colspan="15">RETENCIONES</td>
        </tr>
        <tr>
          <td class="table-secondary">51</td>
          <td class="align-middle" colspan="11">Retención Impuesto Primera Categoría por rentas de capitales mobiliarios del Art.20 N°2, según Art.73 LIR</td>
          <td class="align-middle table-info">50</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_51.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary" rowspan="2">52</td>
          <td class="align-middle" rowspan="2" colspan="4">Retención Impuesto Unico a los Trabajadores, según Art.74 Nº1 LIR</td>
          <td class="align-middle" rowspan="2">Créditos</td>
          <td class="align-middle" colspan="2">Donación Art. 8° Ley 18.985</td>
          <td class="align-middle" colspan="2">Donación Ley 20.444/2010</td>
          <td class="align-middle" rowspan="2" colspan="2">Impuesto Único 2da. Categoría a Pagar</td>
          <td class="align-middle table-info" rowspan="2">48</td>
          <td class="align-middle" rowspan="2"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_52.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion" rowspan="2">+</td>
        </tr>
        <tr>
          <td class="align-middle table-info">751</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_52.donacion_articulo_8_ley_18985.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">735</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_52.donacion_ley_20444_2010.cantidad | thousandsPipe}}</td>
        </tr>
        <tr>
          <td class="table-secondary">53</td>
          <td class="align-middle" colspan="11">Retención de Impuesto con tasa de 10,75% sobre las rentas del Art.42 Nº2, según Art.74 Nº2 LIR</td>
          <td class="align-middle table-info">151</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_53.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">54</td>
          <td class="align-middle" colspan="11">Retención de Impuesto con tasa del 10% sobre las rentas del Art. 48, según Art. 74 N°3 LIR</td>
          <td class="align-middle table-info">153</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_54.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">55</td>
          <td class="align-middle" colspan="11">Retención a Suplementeros, según Art. 74 N° 5 (tasa 0,5%) LIR</td>
          <td class="align-middle table-info">54</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_55.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">56</td>
          <td class="align-middle" colspan="11">Retención sobre rentas Art. 42 N°1 LIR con tasa del 3%, por reintregro del préstamo tasa 0%, según art. 9° letra a) Ley N° 21.252</td>
          <td class="align-middle table-info">49</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_63.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">57</td>
          <td class="align-middle" colspan="11">Retención sobre rentas Art. 42 N°2 LIR con tasa del 3%, por reintregro del préstamo tasa 0%, según art. 7° Ley N° 21.242 y art. 9° letra b) Ley N° 21.252</td>
          <td class="align-middle table-info">155</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_64.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">58</td>
          <td class="align-middle" colspan="11">Retención por compra de productos mineros, según Art. 74 N° 6 LIR</td>
          <td class="align-middle table-info">56</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_56.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">59</td>
          <td class="align-middle" colspan="11">Retención sobre cantidades pagadas en cumplimiento de Seguros Dotales del Art.17, N°3 (tasa 15%)</td>
          <td class="align-middle table-info">588</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_57.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">60</td>
          <td class="align-middle" colspan="11">Retención sobre retiros de Ahorro Previsional Voluntario del Art. 42 bis LIR (tasa 15%)</td>
          <td class="align-middle table-info">589</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.retenciones.linea_58.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr class="sub-cat">
          <td colspan="16">PPM</td>
        </tr>
        <tr class="table-info">
          <th colspan="2"></th>
          <th colspan="2">Acogido a Suspension PPM</th>
          <th colspan="2">Monto Perdida Art.90</th>
          <th colspan="2">Base Imponible</th>
          <th colspan="2">Tasa</th>
          <th colspan="2">Crédito/Tope Suspension PPM</th>
          <th colspan="3">PPM Neto Determinado</th>
        </tr>
        <tr >
          <td class="table-secondary">61</td>
          <td class="align-middle">1ra. Categoría Art. 84 a)</td>
          <td class="align-middle table-info">750</td>
          <td><input type="checkbox" [checked]="borrador_f29.impuesto_renta.ppm.linea_59.acogido_suspension_ppm == true"></td>
          <td class="align-middle table-info">30</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_59.monto_perdida_art90.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">563</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_59.base_imponible.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">115</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_59.tasa.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">68</td>
          <td class="align-middle"><input type="text" class="form-control">{{ borrador_f29.impuesto_renta.ppm.linea_59.credito_tope_suspension_ppm.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">62</td>
          <td class="align-middle"><input type="text" class="form-control">{{ borrador_f29.impuesto_renta.ppm.linea_59.ppm_neto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr >
          <td class="table-secondary">62</td>
          <td class="align-middle" colspan="3">Mineros Art. 84 a)</td>
          <td class="align-middle table-info">565</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_60.monto_perdida_art90.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">120</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_60.base_imponible.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">542</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_60.tasa.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">122</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_60.credito_tope_suspension_ppm.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">123</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_60.ppm_neto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr >
          <td class="table-secondary">63</td>
          <td class="align-middle" colspan="3">Explotador Minero Art. 84 h)</td>
          <td class="align-middle table-info">700</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_61.monto_perdida_art90.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">701</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_61.base_imponible.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">702</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_61.tasa.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">711</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_61.credito_tope_suspension_ppm.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">703</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_61.ppm_neto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr >
          <td class="table-secondary">64</td>
          <td class="align-middle" colspan="11">Transportistas acogidos a Renta Presunta, Art. 84, e) y f) (tasa de 0,3%)</td>
          <td class="align-middle table-info">66</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_62.ppm_neto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr class="table-info">
          <td colspan="4"></td>
          <td class="align-middle" colspan="2">Crédito del Mes</td>
          <td class="align-middle" colspan="2">Remanente mes anterior</td>
          <td class="align-middle" colspan="2">Remanente periodo siguiente</td>
          <td class="align-middle" colspan="5"></td>
        </tr>
        <tr >
          <td class="table-secondary">65</td>
          <td class="align-middle" colspan="3">Crédito Capacitación, Ley 19.518/97</td>
          <td class="align-middle table-info">721</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_63.credito_mes.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">722</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_63.remanente_mes_anterior.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-info">724</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_63.remanente_periodo_siguiente.cantidad | thousandsPipe}}</td>
          <td class="align-middle" colspan="2">Crédito a imputar</td>
          <td class="align-middle table-info">723</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_63.ppm_neto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr >
          <td class="table-secondary">66</td>
          <td class="align-middle" colspan="11">2da. Categoría Art. 84, b) (tasa según Ley 21.133)</td>
          <td class="align-middle table-info">152</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_64.ppm_neto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr >
          <td class="table-secondary">67</td>
          <td class="align-middle" colspan="11">Taller artesanal Art. 84, c) (tasa de 1,5% o 3%)</td>
          <td class="align-middle table-info">70</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.impuesto_renta.ppm.linea_65.ppm_neto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td colspan="15"></td>
        </tr>
        <tr >
          <td class="table-secondary">68</td>
          <td class="align-middle" colspan="11">SUB TOTAL IMPUESTO DETERMINADO ANVERSO. (Suma de las líneas 40 a 55, columna Impuesto y/o PPM determinado)</td>
          <td class="align-middle table-info">595</td>
          <td class="align-middle"><input type="text" class="form-control" >{{borrador_f29.linea_66.ppm_neto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion" readonly>=</td>
        </tr>
      </table>
      <table class="table table-bordered" id="tablas4">
        <tr class="table-info">
          <th colspan="2"></th>
          <th colspan="3">IMPUESTOS DETERMINADOS</th>
        </tr>
        <tr>
          <td class="table-secondary">69</td>
          <td class="align-middle">Ventas del período</td>
          <td class="align-middle table-info">529</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.tributacion_simplificada.linea_67.cantidad | thousandsPipe}}</td>
          <td class="table-secondary"></td>
        </tr>
        <tr>
          <td class="table-secondary">70</td>
          <td class="align-middle">Crédito del período</td>
          <td class="align-middle table-info">530</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.tributacion_simplificada.linea_68.cantidad | thousandsPipe}}</td>
          <td class="table-secondary"></td>
        </tr>
        <tr>
          <td class="table-secondary">71</td>
          <td class="align-middle">IVA determinado por concepto de Tributación Simplificada</td>
          <td class="align-middle table-info">409</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.tributacion_simplificada.linea_69.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
      </table>
      <table class="table table-bordered" id="tablas5">
        <tr class="table-info">
          <th colspan="5"></th>
          <th colspan="3">IMPUESTOS DETERMINADOS</th>
        </tr>
        <tr>
          <td class="table-secondary">72</td>
          <td class="align-middle" colspan="4">Letras e), h), i), l) (tasa 15%)</td>
          <td class="align-middle table-info">522</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.impuesto_adicional.linea_70.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">73</td>
          <td class="align-middle" colspan="4">Letra j) (tasa 50%)</td>
          <td class="align-middle table-info">526</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.impuesto_adicional.linea_71.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">74</td>
          <td class="align-middle" colspan="4">Débito de Impuesto Adicional Ventas Art. 37 letras a), b) y c) y Art. 40 D.L. 825 (tasa 15%)</td>
          <td class="align-middle table-info">113</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.impuesto_adicional.linea_72.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">75</td>
          <td class="align-middle" colspan="4">Crédito de Impuesto Adicional Art. 37 letras a), b) y c) D.L. 825</td>
          <td class="align-middle table-info">28</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.impuesto_adicional.linea_73.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">76</td>
          <td class="align-middle" colspan="4">Monto reintegrado por devolución indebida de crédito por exportadores D.L. 825</td>
          <td class="align-middle table-info">548</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.impuesto_adicional.linea_74.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">77</td>
          <td class="align-middle" colspan="4">Remanente crédito Art. 37 mes anterior D.L. 825</td>
          <td class="align-middle table-info">540</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.impuesto_adicional.linea_75.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">78</td>
          <td class="align-middle" colspan="4">Devolución Solicitud Art. 36 relativa al Impuesto Adicional Art. 37 letras a), b) y c) D.L. 825</td>
          <td class="align-middle table-info">541</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.impuesto_adicional.linea_76.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">79</td>
          <td class="align-middle">Remanente crédito impuesto Art. 37 para período siguiente</td>
          <td class="align-middle table-info">549</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.impuesto_adicional.linea_77.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Impuesto Adicional Art. 37 y Art. 40 determinado</td>
          <td class="align-middle table-info">550</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.impuesto_adicional.linea_77.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
      </table>
      <table class="table table-bordered" id="tablas6">
        <tr class="table-info">
          <th colspan="6">ANTICIPO CAMBIO DE SUJETO (CONTRIBUYENTES RETENIDOS)</th>
          <th colspan="3">IMPUESTOS DETERMINADOS</th>
        </tr>
        <tr>
          <td class="table-secondary">99</td>
          <td class="align-middle" colspan="5">IVA anticipado del período</td>
          <td class="align-middle table-info">556</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.parte_1.linea_99.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">100</td>
          <td class="align-middle" colspan="5">Remanente del mes anterior</td>
          <td class="align-middle table-info">557</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.parte_1.linea_100.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">101</td>
          <td class="align-middle" colspan="5">Devolución del mes anterior</td>
          <td class="align-middle table-info">558</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.parte_1.linea_101.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">102</td>
          <td class="align-middle" colspan="5">Total de Anticipo</td>
          <td class="align-middle table-info">543</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.parte_1.linea_102.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion" readonly>=</td>
        </tr>
        <tr>
          <td class="table-secondary">103</td>
          <td class="align-middle">Remanente Anticipos Cambio Sujeto para período siguiente</td>
          <td class="align-middle table-info">573</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.linea_103.cantidad | thousandsPipe}}</td>
          <td class="align-middle" colspan="2">Anticipo a imputar</td>
          <td class="align-middle table-info">598</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.linea_103.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr class="table-info">
          <th colspan="9">CAMBIO DE SUJETO (AGENTE RETENEDOR)</th>
        </tr>
        <tr>
          <td class="table-secondary">104</td>
          <td class="align-middle">IVA total retenido a terceros (tasa Art.14 DL 825/74)</td>
          <td class="align-middle table-info">39</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.parte_2.linea_104.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">105</td>
          <td class="align-middle">IVA parcial retenido a terceros (según tasa)</td>
          <td class="align-middle table-info">554</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.parte_2.linea_105.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">106</td>
          <td class="align-middle">IVA Retenido por notas de crédito emitidas</td>
          <td class="align-middle table-info">736</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.parte_2.linea_106.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">107</td>
          <td class="align-middle">Retención del margen de comercialización</td>
          <td class="align-middle table-info">597</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.parte_2.linea_107.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">108</td>
          <td class="align-middle">Retención Anticipo de Cambio de Sujeto</td>
          <td class="align-middle table-info">555</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.parte_2.linea_108.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
          <td class="align-middle">Retención Cambio de Sujeto</td>
          <td class="align-middle table-info">596</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.cambio_sujeto.parte_2.linea_108.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
      </table>
      <table class="table table-bordered" id="tablas7">
        <tr class="table-info">
          <th colspan="8"></th>
          <th colspan="3">IMPUESTOS DETERMINADOS</th>
        </tr>
        <tr>
          <td class="table-secondary">109</td>
          <td class="align-middle">Crédito por Sistemas Solares Térmicos, Ley Nº 20.365</td>
          <td class="align-middle table-info">725</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_109.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Remanente mes anterio</td>
          <td class="align-middle table-info">737</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_109.remanente_mes_anterior.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Total Crédito</td>
          <td class="align-middle table-info">727</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_109.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">110</td>
          <td class="align-middle">Imputación del Pago Patentes Aguas, Ley Nº 20.017</td>
          <td class="align-middle table-info">704</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_110.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Remanente mes anterio</td>
          <td class="align-middle table-info">705</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_110.remanente_mes_anterior.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Total Crédito</td>
          <td class="align-middle table-info">706</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_110.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">111</td>
          <td class="align-middle">Cotización Adicional, Ley Nº 18.566</td>
          <td class="align-middle table-info">160</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_111.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Remanente mes anterio</td>
          <td class="align-middle table-info">161</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_111.remanente_mes_anterior.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Total Crédito</td>
          <td class="align-middle table-info">570</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_111.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">112</td>
          <td class="align-middle">Crédito Especial Empresas Constructoras</td>
          <td class="align-middle table-info">126</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_112.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Remanente mes anterio</td>
          <td class="align-middle table-info">128</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_112.remanente_mes_anterior.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Total Crédito</td>
          <td class="align-middle table-info">571</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_112.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">113</td>
          <td class="align-middle">Recup. Peajes Transportistas Pasajero, Ley Nº 19.764</td>
          <td class="align-middle table-info">572</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_113.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Remanente mes anterio</td>
          <td class="align-middle table-info">568</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_113.remanente_mes_anterior.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Total Crédito</td>
          <td class="align-middle table-info">590</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_113.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">114</td>
          <td class="align-middle">Crédito por desembolsos directos trazabilidad</td>
          <td class="align-middle table-info">768</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_114.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Remanente mes anterio</td>
          <td class="align-middle table-info">769</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_114.remanente_mes_anterior.cantidad | thousandsPipe}}</td>
          <td class="align-middle">Total Crédito</td>
          <td class="align-middle table-info">770</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.creditos_especiales.linea_114.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">-</td>
        </tr>
        <tr>
          <td class="table-secondary">115</td>
          <td class="align-middle" colspan="7">TOTAL DETERMINADO</td>
          <td class="align-middle table-info">547</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.linea_115.impuesto_determinado.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">=</td>
        </tr>
      </table>
      <table class="table table-bordered" id="tablas8">
        <tr class="table-info">
          <th colspan="3"></th>
          <th colspan="2">IMPUESTOS DETERMINADOS</th>
        </tr>
        <tr>
          <td class="table-secondary">116</td>
          <td class="align-middle">Remanente Crédito por Sistemas Solares Térmicos, Ley Nº 20.365</td>
          <td class="align-middle table-info">728</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.remanente_creditos_especiales.linea_116.cantidad | thousandsPipe}}</td>
          <td class="table-secondary"></td>
        </tr>
        <tr>
          <td class="table-secondary">117</td>
          <td class="align-middle">Remanente periodo siguiente Patente Aguas, Ley Nº 20.017</td>
          <td class="align-middle table-info">707</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.remanente_creditos_especiales.linea_117.cantidad | thousandsPipe}}</td>
          <td class="table-secondary"></td>
        </tr>
        <tr>
          <td class="table-secondary">118</td>
          <td class="align-middle">Remanente de Cotizacion Adicional Ley Nº 18.566</td>
          <td class="align-middle table-info">73</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.remanente_creditos_especiales.linea_118.cantidad | thousandsPipe}}</td>
          <td class="table-secondary"></td>
        </tr>
        <tr>
          <td class="table-secondary">119</td>
          <td class="align-middle">Remanente Crédito Especial Empresas Constructoras</td>
          <td class="align-middle table-info">130</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.remanente_creditos_especiales.linea_119.cantidad | thousandsPipe}}</td>
          <td class="table-secondary"></td>
        </tr>
        <tr>
          <td class="table-secondary">120</td>
          <td class="align-middle">Remanente Recup. de Peajes Trans. Pasajeros Ley Nº 19.764</td>
          <td class="align-middle table-info">591</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.remanente_creditos_especiales.linea_120.cantidad | thousandsPipe}}</td>
          <td class="table-secondary"></td>
        </tr>
        <tr>
          <td class="table-secondary">121</td>
          <td class="align-middle">Remanente Crédito por desembolsos directos trazabilidad</td>
          <td class="align-middle table-info">771</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.remanente_creditos_especiales.linea_120.cantidad | thousandsPipe}}</td>
          <td class="table-secondary"></td>
        </tr>
        <tr>
          <td colspan="5"></td>
        </tr>
        <tr>
          <td class="table-secondary">122</td>
          <td class="align-middle">TOTAL A PAGAR EN PLAZO LEGAL</td>
          <td class="align-middle table-info">91</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.linea_122.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion" readonly>=</td>
        </tr>
        <tr>
          <td class="table-secondary">123</td>
          <td class="align-middle">Más IPC</td>
          <td class="align-middle table-info">92</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.linea_123.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">124</td>
          <td class="align-middle">Más Intereses y multas</td>
          <td class="align-middle table-info">93</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.linea_124.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">+</td>
        </tr>
        <tr>
          <td class="table-secondary">125</td>
          <td class="align-middle">TOTAL A PAGAR CON RECARGO</td>
          <td class="align-middle table-info">94</td>
          <td class="align-middle"><input type="text" class="form-control">{{borrador_f29.linea_125.cantidad | thousandsPipe}}</td>
          <td class="align-middle table-secondary operacion">=</td>
        </tr>
      </table>
    </div>
  </ng-template>

export class Parametros_pres{
    constructor(
        public _id: string,
        public entidad: JSON,
        public year: number,
        public valorFMR: number,
        public viatico: number,
        public ppg: number,
        public codigo_entidad: number

    ){}
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Declaracion } from '../models/declaracion';
import { Observable } from 'rxjs/Observable';
import { Global } from './global';
import { UserService } from './user.service';
import { EntidadService } from '../services/entidad.service';
import { DjDuplicateRecordRequest, DjDuplicateRecordResponse } from '../models/dj1945/duplicate_record/dj_duplicate_record.model';
import { DjOptionDuplicateRecordUpdateRequest, DjOptionDuplicateRecordUpdateResponse } from '../models/dj1945/duplicate_record/dj_option_duplicate_record.model';
import { DjAutoCorrectionDuplicateRecordRequest, DjAutoCorrectionDuplicateRecordResponse } from '../models/dj1945/duplicate_record/dj_auto_correction_duplicate_record.model';
import { DjIngresoGastoEntity } from '../models/dj1945/dj_ingreso_gasto_entity.model';
import { TablePageRequest, TablePageResponse } from '../models/table_page.model';
import { DjRowResponse } from '../models/dj1945/dj_row_response.model';
import { DjConfiguracionGlobalPeriodo } from '../models/dj1945/dj_configuracion_global_periodo.model';
import { SharingService } from './sharing.service';


@Injectable()

export class DeclaracionService {
	public declaraciones: Array<Declaracion>;
	public url: string;
	public urldj195: string;

	public identity;
	public token;
	public entidad_sel;

	constructor(
		private _http: HttpClient,
		private _userService: UserService,
		private _entidadService: EntidadService,
		private _sharingService: SharingService,
	) {
		this.url = Global.url;
		this.urldj195 = Global.url_dj1945;

		//this.dj_config = this.dj_config.result
		this._sharingService.sharingIdentity.subscribe(data => {
			this.identity = data
		})
		//this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();

	}

	getTexto() {
		return "Hola Mundo desde un Servicio";
	}

	getDeclaraciones(id): Observable<any> {
		var declaracion = { id: id }
		let params = declaracion;//JSON.stringify(declaracion);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);

		return this._http.post(this.url + 'get-declaracion', params, { headers: headers });
	}

	getFile(id): Observable<Blob> {
		//var declaracion = { id:id }
		//let params = declaracion;//JSON.stringify(declaracion);
		let headers = new HttpHeaders().set('Authorization', this.token);

		return this._http.get(this.url + 'file-dj/' + id, { headers: headers, responseType: 'blob' });
		//.map((res) => new Blob([res['_body']], { type: 'application/zip' }));
	}
	downloadCsv(): Observable<Blob> {
		let headers = new HttpHeaders().set('Authorization', this.token);

		return this._http.get(this.urldj195 + 'dj-exportar-declaracion-csv', { headers: headers, responseType: 'blob' });
	}
	subirDeclaracionExcel(declaracion: Declaracion): Observable<any> {

		declaracion.entidad = this._entidadService.getEntidad();
		let params = JSON.stringify(declaracion);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);

		return this._http.post(this.url + 'save-declaracion', params, { headers: headers });
	}
	guardarDeclaraciones(declaraciones, extenciones, bien_raiz, id_archivo): Observable<any> {
		this.entidad_sel = this._entidadService.getEntidad();
		var fecha = new Date();
		var ano = fecha.getFullYear();

		let params = {
			declaraciones: declaraciones,
			extenciones: extenciones,
			bien_raiz: bien_raiz,
			id_archivo: id_archivo,
			usuario: this.identity.usuario,
			entidad: this.entidad_sel,
			year: ano
		};
		//JSON.stringify(declaracion);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);

		return this._http.post(this.url + 'guardar-declaracion', params, { headers: headers });
	}

	verDeclaracion(declaracion, tipo): Observable<any> {

		this.entidad_sel = this._entidadService.getEntidad();
		var fecha = new Date();
		var ano = fecha.getFullYear();

		declaracion.entidad = this.entidad_sel.id_ ?
			this.entidad_sel.id_ : (this.entidad_sel._id ?
				this.entidad_sel._id :
				this.entidad_sel.id)
		declaracion.year = ano;
		declaracion.tipo = tipo ? tipo : 101;


		let params = JSON.stringify(declaracion);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);

		return this._http.post(this.url + 'ver-declaracion', params, { headers: headers });
	}

	getForEntity(params: DjIngresoGastoEntity): Observable<DjRowResponse<Array<DjIngresoGastoEntity>>> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.get<DjRowResponse<Array<DjIngresoGastoEntity>>>(this.urldj195 + 'dj-ingreso-gasto-for-entity/' + params.periodo + '/' + params.entidad + '/' + params.tipo_ing_egr, { headers: headers });
	}
	getForEntityPage(params: TablePageRequest<DjIngresoGastoEntity>): Observable<TablePageResponse<DjIngresoGastoEntity>> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.get<TablePageResponse<DjIngresoGastoEntity>>(this.urldj195 + 'dj-ingreso-gasto-for-entity-page/' + params.page_info.n_per_page + '/' + params.page_info.page_number,
			{
				params: {
					periodo: String(params.params.periodo),
					tipo_ing_egr: String(params.params.tipo_ing_egr),
					entidad: String(params.params.entidad)
				}, headers: headers
			});
	}

	exportarDeclaracionTxt(parametros): Observable<any> {

		this.entidad_sel = this._entidadService.getEntidad();
		var fecha = new Date();
		var ano = fecha.getFullYear();

		parametros.entidad_sel = this.entidad_sel.codigo;
		//parametros.rectificacion = 0;
		//parametros.num_rectificacion = '';
		parametros.year = ano;


		let params = JSON.stringify(parametros);
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);

		return this._http.post(this.url + 'exportar-declaracion-txt', params, { headers: headers });
	}


	getGlobalConfiguration(): Observable<DjRowResponse<DjConfiguracionGlobalPeriodo>> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.get<DjRowResponse<DjConfiguracionGlobalPeriodo>>(this.urldj195 + 'dj-configuracion-global-periodo', { headers: headers });
	}
	getDuplicateRecords(params: DjDuplicateRecordRequest): Observable<DjDuplicateRecordResponse> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.get<DjDuplicateRecordResponse>(this.urldj195 + "dj-ingreso-gasto-duplicate-records/" + params.periodo + "/" + params.grupo_entidad,
			{ headers: headers });
	}
	updateDuplicateRecord(params: DjOptionDuplicateRecordUpdateRequest): Observable<DjOptionDuplicateRecordUpdateResponse> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.put<DjOptionDuplicateRecordUpdateResponse>(this.urldj195 + 'dj-ingreso-gasto-duplicate-record/' + params.periodo + '/' + params.grupo_entidad + '/' + params.rut + '/' + params.tipo_ing_egr,
			params.request, { headers: headers });
	}

	autoCorrectionDuplicateRecords(params: DjAutoCorrectionDuplicateRecordRequest): Observable<DjAutoCorrectionDuplicateRecordResponse> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		return this._http.put<DjAutoCorrectionDuplicateRecordResponse>(this.urldj195 + 'dj-ingreso-gasto-autocorrection-duplicate-record',
			params, { headers: headers });
	}

	informeDJ(): Observable<any> {

		//this.entidad_sel = this._entidadService.getEntidad();
		var fecha = new Date();
		var year = fecha.getFullYear();



		let params = { year: year - 1 };
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);

		return this._http.post(this.url + 'informe-dj1945', params,
			{ headers: headers });
	}
}

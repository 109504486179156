import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import pageSettings from '../../../config/page-settings';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { LocalService } from '../../../services/localService';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BodegaService } from "../../../services/bodegaSelect.service";
import Swal from "sweetalert2";
declare var App: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService]
})
export class LoginComponent implements OnInit {

  pageSettings = pageSettings;
  public closeResult: string;
  public page_title: string;
  public user: User;
  public status: string;
  public mensaje: string;
  public estado: boolean;
  public identity;
  public token;
  public enviarUsuario: string;
  public generica = false;
  public ruta;
  public fecha = new Date();
  public year = this.fecha.getFullYear();

  //public rutas = []

  constructor(
    //private router: Router,
    private renderer: Renderer2,
    private _userService: UserService,
    private _localService: LocalService,
    private _router: Router,
    private _route: ActivatedRoute,
    private modalService: NgbModal,
		private bodegaSelect: BodegaService,
  ) {
    this.pageSettings.pageEmpty = true;
    this.renderer.addClass(document.body, 'bg-white');

    //this._localService.setJsonValue('componente', 'login');
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.page_title = "Iniciar Sesion";
    this.user = new User('', '', '', '', '', '', '', '', '');

    if(this.identity != null && this.token != null){
      this._router.navigate(["/inicio"]);
    }

    //console.log(this.identity +"+"+ this.token)
  }

  onSubmit(form){
    //console.log(this.user);
    //CONSEGUIR OBJETO COMPLETO DEL USUARIO LOGUEADO
    this._userService.signup(this.user).subscribe(
      response => {
        // console.log(response);
        let version = response.generica
        if(response.user && response.user[0]._id){

          //Guardamos el usuario en una propiedad
          this.identity = response.user;
          this._localService.setJsonValue('identity', JSON.stringify(this.identity));
          this._localService.setJsonValue('id_usuario', response.user[0]._id)
          this._localService.setJsonValue('usuario', response.usuario)
          this._localService.setJsonValue('sistema_sel', response.usuario.accesos.seleccionada)
          if(response.user[0].bodega){
            this._localService.setJsonValue('bodegas', response.user[0].bodega.bodegas)
            if(response.user[0].bodega.seleccionada){
              this._localService.setJsonValue('bodega_sel', response.user[0].bodega.seleccionada)
              this.bodegaSelect.validarBodegas(true)
            } else {
              this._localService.setJsonValue('bodega_sel', '{}')
            }
          }
          //console.log(response.user[0]._id);
          //CONSEGUIR EL TOKEN DEL USUARIO IDENTIFICADO
          let fecha = new Date().getTime().toString()
          this._localService.setJsonValue('dispositivo', fecha)

          this._userService.signup(this.user, true, this._localService.getJsonValue('dispositivo')).subscribe(
            response => {
              if(response.token){
              //console.log(response);return;

                //GUARDAMOS EL TOKEN DEL USUARIO EN PROPIEDAD
                this.token = response.token;
                this._localService.setJsonValue('token', JSON.stringify(this.token));

                this.status = 'success';
                if(version > 0){
                  this._router.navigate(["/modificarinformacion", version])
                }else{
                  this._router.navigate(["/inicio"]);
                }

              }else{
                this.status = 'error';
              }
            },
            error =>{
              this.status = 'error';
              console.log(error);
            }
          );

          //console.log(response);
        }else{
          this.status = 'error';
        }

      },
      error =>{
        this.status = 'error';
        console.log(error);
      }
    );

  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
    this.renderer.removeClass(document.body, 'bg-white');
  }
  /*formSubmit(f: NgForm) {
    this._router.navigate(['login']);
  }*/

  ngOnInit() {
     if(this.identity != null){
      this.pageSettings.pageEmpty = false;
      this.renderer.removeClass(document.body, 'bg-white');
       //$('body').removeClass('pace-top bg-white');
     }else{
      this.pageSettings.pageEmpty = true;
      this.renderer.addClass(document.body, 'bg-white');
      // $('body').addClass('pace-top bg-white');

      // App.init();
     }
  }

  open(content) {
    let options = {
      size: 'md',
      windowClass: 'modal-holder',
      // animation: true
    };
    this.modalService.open(content, options).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  private recuperarClave(usuario) {
    this.mensaje = '';
    this.estado = true;
    this._userService.recuperarClave(usuario).subscribe(
      response => {
        if (response.estado) {
          this.modalService.dismissAll();
          Swal.fire({
            title: '¡Atención!',
            text: response.mensaje,
            icon: 'success',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-primary ml-3',
              cancelButton: 'btn btn-lg btn-danger',
            },
            confirmButtonText: 'OK'
          });
        }
        this.mensaje = response.mensaje;
        this.estado = response.estado;
      },
      error => {
        console.log(error);
      }
      );
  }


}

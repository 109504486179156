export class Documentacion_f29{
	constructor(
        public _id: string,	
        public borradorF29: JSON,
        public reporteAPSBoletaDeHonorariosPDF: JSON,
        public archivoPlanoAPSBoletadeHonorariosTXT: JSON,
        public reporteAPSImpuestoUnicoPDF: JSON,
        public archivoPlanoAPSImpuestoUnicoTXT : JSON,
        public libroMayorImpuestoUnico:JSON,	
        public libroMayorBoletaDeHonorarios: JSON,
        public capturaLibroCompraFacturacioncl: JSON,
        public capturaLibroVentasFacturacioncl: JSON,
        public depositoOTransferenciaBancariaPDF: JSON,
        public libroMayorIVACreditoFiscalPDF: JSON,
        public libroMayorIVADebitoFiscalPDF: JSON,
        public otros: JSON,
        public observaciones:string
		
	){}
}
export class Declaracion{
	constructor(
		public usuario: string,
		public entidad: string,
		public year: number,
		public nombre: string,
		public archivo: string,
		public ruta: string,
		
	){}
}
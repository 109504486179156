import { Component, OnInit } from '@angular/core';
import pageSettings from '../../../config/page-settings';
import { EntidadService } from '../../../services/entidad.service';
import Swal from 'sweetalert2'
import { UserService } from '../../../services/user.service';
import { DeclaracionService } from '../../../services/declaracion.service';
import { RegistroDuplicado } from '../../../models/dj1945/registro_duplicado';
import {Observable, Subject, Subscription} from "rxjs"

import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DjConfiguracionGlobalPeriodo } from '../../../models/dj1945/dj_configuracion_global_periodo.model';
import { DjDuplicateRecord, DjDuplicateRecordRequest, DjDuplicateRecordResponse } from '../../../models/dj1945/duplicate_record/dj_duplicate_record.model';
import { DjOptionDuplicateRecord, DjOptionDuplicateRecordUpdateRequest, DjOptionDuplicateRecordUpdateResponse } from '../../../models/dj1945/duplicate_record/dj_option_duplicate_record.model';
import { DjAutoCorrectionDuplicateRecordRequest, DjAutoCorrectionDuplicateRecordResponse } from '../../../models/dj1945/duplicate_record/dj_auto_correction_duplicate_record.model';
import { DjRowResponse } from '../../../models/dj1945/dj_row_response.model';
import { SharingService } from '../../../services/sharing.service';

@Component({
  selector: 'app-registros-duplicados',
  templateUrl: './registros-duplicados.component.html',
  styleUrls: ['./registros-duplicados.component.css'],
  providers: [EntidadService, DeclaracionService, NgbModal]
})
export class RegistrosDuplicadosComponent implements OnInit{

  pageSettings = pageSettings;
  modal: NgbModalRef
  modalReference: any
  public titulo = " Registros Duplicados";
  public descripcion = "DJ1945";
  public icono = "fas fa-clone fa-fw";
  public registros_duplicados: Array<RegistroDuplicado>
  public opcion_duplicado: Array<DjOptionDuplicateRecordUpdateRequest>;
  public dj_config: DjConfiguracionGlobalPeriodo
  public dj_duplicate_records: Array<DjDuplicateRecord>
  public entity = this._entidadService.getEntidad();
	public entidadSelSubscription: Subscription;
  public closeResult: string
  constructor(
    private _entidadService: EntidadService,
    private _declaracionService: DeclaracionService,
    private _userService: UserService,
    private _sharingService: SharingService,
    private modalService: NgbModal
  ) { }

  async ngOnInit(): Promise<void> {
    this.entidadSelSubscription = this._sharingService.sharingEntity.subscribe(
      (entidad) => {
        this.getConfiguracionGlobalPeriodo()
      }
    )
  }


  // FUNCIONES DE MODAL

  open(content, record) {
    this.elegirRegistrosDuplicados(record)
    let size = "lg"
    let modal_class = {
      windowClass: "modal-holder", size: size
    }
    this.modalService.open(content, modal_class).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  //=====================================================================

  elegirRegistrosDuplicados(record) {
    this.opcion_duplicado = record.records.map(x => {
      x.record = record
      return x
    })
  }
  agruparRegistro(od) {
    Swal.fire({
      title: 'Advertencia',
      text: "¿Está Seguro de Agrupar estos Registros?",
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-lg btn-info mr-3',
        cancelButton: 'btn btn-lg btn-danger',

      },
      confirmButtonText: 'Sí, Agrupar'
    }).then((result) => {
      if (result.isConfirmed) {
        let option_duplicate_record: DjOptionDuplicateRecordUpdateRequest = {
          periodo: od.record.periodo,
          grupo_entidad: od.record.grupo_entidad,
          rut: od.record.rut,
          tipo_ing_egr: od.record.tipo_ing_egr,
          request: {
            origen_ing: od.origen_ing,
            destino_egr: od.destino_egr,
            tipo_relacion: od.tipo_relacion,
          }
        }
        this.updateDuplicateRecord(option_duplicate_record)
      }
    })


  }
  closeModal() {
    this.modalService.dismissAll();
    //this.modalReference.close()
    //this.modal.close()
  }

  getConfiguracionGlobalPeriodo() {

    this._declaracionService.getGlobalConfiguration().subscribe(
      (response: DjRowResponse<DjConfiguracionGlobalPeriodo>) => {
        this.dj_config = response.result
        this.getDuplicateRecords({ periodo: this.dj_config.periodo, grupo_entidad: this.entity.grupo })

      }, (error) => {
        console.error(error)
      })

  }

  getDuplicateRecords(params: DjDuplicateRecordRequest) {

    Swal.fire({
      title: "Cargando",
      text: "Consultando registros duplicados...",
      allowOutsideClick: false,
      //timer: 2000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        this._declaracionService.getDuplicateRecords(params).subscribe(
          (response: DjDuplicateRecordResponse) => {
            this.dj_duplicate_records = response.result
            if (this.dj_duplicate_records.length)
              Swal.close()
            else
              Swal.fire({
                title: 'Información',
                text: "No se ha encontrado registros duplicados",
                icon: 'info',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-lg btn-info mr-3',
                  //cancelButton: 'btn btn-lg btn-danger',

                },
                confirmButtonText: 'Ok'
              })
          }, (error) => {
            console.error(error)
          })
      },
    })

  }

  updateDuplicateRecord(params: DjOptionDuplicateRecordUpdateRequest) {
    this._declaracionService.updateDuplicateRecord(params).subscribe(
      (response: DjOptionDuplicateRecordUpdateResponse) => {
        this.getConfiguracionGlobalPeriodo()
        this.closeModal()
        Swal.fire('Exito!', 'Se ha Agrupado el Registro', 'success')
      }, (error) => {
        console.error(error)
      })
  }
  autocorrection() {

    Swal.fire({
      title: 'Advertencia',
      text: "Esta Acción corregirá las declaraciones tomando en cuenta periodos anteriores ¿Está Seguro de Realizar esta Acción?",
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-lg btn-info mr-3',
        cancelButton: 'btn btn-lg btn-danger',
      },
      confirmButtonText: 'Sí, Corregir'
    }).then((result) => {

      Swal.fire({
        title: "Autocorrección",
        text: "Se están corrigiendo los registros tomando en cuenta los periodos anteriores...",
        allowOutsideClick: false,
        //timer: 2000,
        timerProgressBar: true,
        onBeforeOpen: () => {
          Swal.showLoading()
          this.autoCorrectionDuplicateRecords({ periodo: this.dj_config.periodo, grupo_entidad: this.entity.grupo })
        },
      })
    })
  }

  autoCorrectionDuplicateRecords(params: DjAutoCorrectionDuplicateRecordRequest) {

    this._declaracionService.autoCorrectionDuplicateRecords(params).subscribe(
      (response: DjAutoCorrectionDuplicateRecordResponse) => {
        this.getConfiguracionGlobalPeriodo()
        if (response.result.length)
          Swal.fire('Exito!', 'Se han actualizado ' + response.result.length + ' registros.', 'success')
        else
          Swal.fire('Información', 'No se ha encontrado registros que se puedan corregir de manera automatica', 'info')

      }, (error) => {
        console.log(error)
      })
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ActivatedRouteSnapshot } from '@angular/router';
import { Pass } from '../../../models/pass';
import { UserService } from '../../../services/user.service';
import { EntidadService } from '../../../services/entidad.service';
import { empty, identity } from 'rxjs';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

//import swal from 'sweetalert';
//import { User } from 'src/app/models/user';

@Component({
  selector: 'app-modificarinformacion',
  templateUrl: './modificarinformacion.component.html',
  styleUrls: ['./modificarinformacion.component.css'],
  providers: [UserService, EntidadService]
})
export class ModificarinformacionComponent implements OnInit {

  public titulo = " Modificar Cuenta ";
	public descripcion ="Mi Perfil";
  public icono = "fas fa-upload fa-fw";
  public estado;
  public message = '';
  public messages = '';
  public minified;
  public status = false;
  public generica: number;

  public pass: Pass;
  public update
  public identity;
  /*public actpass;
  public newpass;
  public confnewpass;*/


  constructor(
    private _userService: UserService,
    private _entidadService: EntidadService,
    private _router: Router,
    private _route: ActivatedRoute

  ) {
    this.pass = new Pass('','','');
    //console.log(document.location);
    //this.identity = this._userService.getIdentity();
    //this.token = this._userService.getToken();



  }

  ngOnInit() {
    this._route.params.subscribe((params: Params) => {
      this.generica = parseInt(params.generica)

      switch(this.generica){
        case 1:
          this.messages = "Por su seguridad cambie su contraseña"
        break
        case 2:
          this.messages = "Para un mejor funcionamiento del sistema actualice su información personal"
        break
      }
      //console.log(this.generica);
    });
    this.identity = this._userService.getIdentity()
    this.update = {
      _id: this.identity._id,
      usuario: this.identity.usuario,
      nombre: this.identity.nombre,
      apellido: this.identity.apellido,
      rut: this.identity.rut,
      clave: "",
      mail: this.identity.mail,
      level: this.identity.level
    }
    if(this.update.rut != ""){
      this.update.rutX = true
    }
   this.minified = false;
  }


  onSubmit(form){
    //console.log(this.pass);
    this._userService.cambioclave(this.pass).subscribe(
      response => {
       // console.log(response);
        this.estado = response.estado;
        this.message = response.message;

        // localStorage.clear();
        // this._router.navigate(['/login']);
        //this._router.navigate(['/login']);
        if(response.estado == true){
            form.reset();
            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-lg btn-success ml-3',
                cancelButton: 'btn btn-lg btn-danger',

              },
              buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
              title: 'Exito!',
              text: "Se han realizado los cambios correctamente",
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Continuar',
              cancelButtonText: 'Cancelar',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                localStorage.clear();
                this._router.navigate(['/login']);
              } {
                localStorage.clear();
                this._router.navigate(['/login']);
              }
            })

        }
        //console.log(this.estado);
        //console.log (this.message);
      },
      error => {},
    );

    /*this.actpass = this.pass.claveactual;
    this.newpass = this.pass.clavenueva;
    this.confnewpass = this.pass.claveconfirmacion;*/

  }

  onSubmit2(form){

    this._userService.actualizarUsuario(this.update).subscribe(
      response => {
        if(response.mensaje == "Actualizado correctamente"){

          Swal.fire({
            title: 'Exito!',
            text: "Se han realizado los cambios correctamente",
            icon: 'success',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-success ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'Continuar'
          })

          //this._router.navigate(['/inicio']);
          localStorage.clear();
          this._router.navigate(['/login']);

        }else{
          Swal.fire({
            title: 'Hubo un problema',
            text: response.mensaje,
            icon: 'warning',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-warning ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'Continuar'
          })

        }
      }
    )
  }

  sidebar_minified(){

    if(this.minified == false){
      //$('#page-container').addClass('page-sidebar-minified');
      this.minified = true;
    }else{
      //$('#page-container').removeClass('page-sidebar-minified');
      this.minified = false;
    }

  }
}

import {Component, OnInit} from '@angular/core';
//import {getMatFormFieldDuplicatedHintError} from '@angular/material';
import {LocalService} from "../../services/localService";
import {Router} from '@angular/router';
import {Global} from '../../services/global'

@Component({
	selector: 'footer',
	templateUrl: './footer.component.html'
})

export class FooterComponent implements OnInit {

	public fecha = new Date()
	public year = this.fecha.getFullYear()
	public version = Global.version

	constructor(private _localService: LocalService, private _router: Router) {}

	ngOnInit() {
		

	}
}

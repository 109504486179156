import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { F29Service } from '../../../services/declaracion_f29.service';
import { EntidadService } from '../../../services/entidad.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { LocalService } from '../../../services/localService';
import { SharingService } from '../../../services/sharing.service';

@Component({
  selector: 'app-periodo-entidades-impuestos',
  templateUrl: './periodo-entidades-impuestos.component.html',
  styleUrls: ['./periodo-entidades-impuestos.component.css'],
  providers: [F29Service]
})
export class PeriodoEntidadesImpuestosComponent implements OnInit {

  @Output() sel_entidad = new EventEmitter<string>();

  public titulo = " Ver Periodos ";
  public descripcion = " Declaraciones (F-29) ";
  public periodo = [];

  public minified;

  constructor(
    private _f29Service: F29Service,
    private _router: Router,
    private _entidadService: EntidadService,
    private _sharingService: SharingService,
    private _localService: LocalService
  ) {

    this.verPeriodos();
    this.minified = false;
  }

  ngOnInit() {
  }

  verPeriodos() {

    this._f29Service.verPeriodoEntidades().subscribe(
      response => {

        this.periodo = response.periodos;


      },
      error => { }
    );

  }

  cambiaentidad(codigo) {
    this._entidadService.cambiarEntidadSel(codigo).subscribe(
      response => {
        this._sharingService.sharingEntityData = codigo
        this._router.navigate(["/declarar-impuestos"]);
      }
    );
  }

  sidebar_minified() {

    if (this.minified == false) {
      //$('#page-container').addClass('page-sidebar-minified');
      this.minified = true;
    } else {
      //$('#page-container').removeClass('page-sidebar-minified');
      this.minified = false;
    }

  }


}


<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
	<li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin panel -->
<panel variant="iatuch" title="{{ titulo }}">
    <div class="table-responsive">
        <table class="table table-striped align-middle table-sm table-hover">
            <thead>
                <th>#</th>
                <th>Entidad</th>
                <th>Campo</th>
                <th>Tipo</th>
                <th>Ing/Egr</th>
                <th>Estado</th>
                <th>Total</th>
            </thead>
            <tbody>
                <tr *ngFor="let d of data; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{ d.entidad }}</td>
                    <td>{{ d.campo }}</td>
                    <td>{{ d.tipo }}</td>
                    <td>{{ d.tipo_ing_egr }}</td>
                    <td><span class="badge bg-{{d.color}}">{{ d.status }}</span> </td>
                    <td><span class="text-{{d.total_color}}">{{ d.total}}</span> </td>
                </tr>
                
            </tbody>
        </table>
    </div>
    
</panel>

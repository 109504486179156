<ol class="breadcrumb pull-right">
    <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
    <li class="breadcrumb-item">{{descripcion}}</li>
    <li class="breadcrumb-item active">{{ titulo }}</li>
</ol>

<!-- begin page-header -->
<h1 class="page-header"> <i class="fa fa-money-bill-alt"></i> {{ titulo }} {{year}} <small> {{ descripcion }}</small>
</h1>
<!-- end page-header -->

<div class="row ">
    <div class="col-md-12">
        <pres_iglesia [permiso]="nuevo_periodo" *ngIf="tipo_entidad == 'Iglesia'"></pres_iglesia>
        <pres_iglesia [permiso]="nuevo_periodo" *ngIf="tipo_entidad == 'Corporación'"></pres_iglesia>
        <pres_educacion *ngIf="tipo_entidad == 'Fundación'"></pres_educacion>
        <pres_institucion *ngIf="tipo_entidad == 'Institución'"></pres_institucion>
    </div>
    <div>
        <div *ngIf="nuevo_periodo == true" class="aling-center col-md-12">
            <button class="btn btn-primary" (click)="generar_nuevo_periodo()">Generar nuevo Periodo</button>
        </div>
    </div>

</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import * as ExcelJS from "exceljs/dist/exceljs.min.js"

@Injectable()

export class XlsxService {

    constructor() { }

    centrar_datos(worksheet, rango_filas, rango_columnas) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F };
                worksheet.getCell(this.n_cell(cell_address)).alignment = { vertical: 'middle', horizontal: 'center' };
            }
        }
        return worksheet
    }

    ajustar_cabeceras(worksheet, rango_filas, rango_columnas) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F };
                worksheet.getCell(this.n_cell(cell_address)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            }
        }
        return worksheet
    }

    derecha_datos(worksheet, rango_filas, rango_columnas) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F };
                worksheet.getCell(this.n_cell(cell_address)).alignment = { vertical: 'middle', horizontal: 'right' };
            }
        }
        return worksheet
    }

    insertar_valor_celda(worksheet, celda, valor) {
        celda = this.n_cell(celda)
        worksheet.getCell(celda).value = valor
        return worksheet
    }

    juntar_celdas(worksheet, celda_comienzo, celda_fin) {
        celda_comienzo = this.n_cell(celda_comienzo)
        celda_fin = this.n_cell(celda_fin)
        let rango = celda_comienzo + ':' + celda_fin
        worksheet.mergeCells(rango)
        return worksheet
    }

    bordes_delgados(worksheet, rango_filas, rango_columnas) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F };
                worksheet.getCell(this.n_cell(cell_address)).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                }
            }
        }
        return worksheet
    }

    bordes_gruesos(worksheet, rango_filas, rango_columnas) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F };
                worksheet.getCell(this.n_cell(cell_address)).border = {
                    top: { style: 'medium' },
                    left: { style: 'medium' },
                    bottom: { style: 'medium' },
                    right: { style: 'medium' }
                }
            }
        }
        return worksheet
    }

    bordes_gruesos_menos_arriba(worksheet, rango_filas, rango_columnas) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F };
                worksheet.getCell(this.n_cell(cell_address)).border = {
                    top: { style: 'thin' },
                    left: { style: 'medium' },
                    bottom: { style: 'medium' },
                    right: { style: 'medium' }
                }
            }
        }
        return worksheet
    }

    bordes_gruesos_derecha(worksheet, rango_filas, rango_columnas) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F };
                worksheet.getCell(this.n_cell(cell_address)).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'medium' }
                }
            }
        }
        return worksheet
    }
    texto_en_negrita(worksheet, rango_filas, rango_columnas) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F }
                worksheet.getCell(this.n_cell(cell_address)).font = { bold: true }
            }
        }
        return worksheet
    }

    eliminar_valores(worksheet, rango_filas, rango_columnas, valor) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F }
                worksheet.getCell(this.n_cell(cell_address)).value = valor
            }
        }
        return worksheet
    }

    nombre_tabla(worksheet, fila, columnas, nombre_tabla) {
        worksheet = this.eliminar_cabeceras(worksheet, fila, columnas)
        worksheet = this.insertar_valor_celda(worksheet, { c: 0, r: fila }, nombre_tabla)
        worksheet = this.juntar_celdas(worksheet, { c: 0, r: fila }, { c: columnas, r: fila })
        worksheet = this.centrar_datos(worksheet, { c: fila, f: fila }, { c: 0, f: columnas })
        worksheet.getCell(this.n_cell({ c: 0, r: fila })).font = { bold: true, size: 15 }
        return worksheet
    }

    eliminar_cabeceras(worksheet, fila, columnas) {
        for (let F = fila; F <= fila; ++F) {
            for (let C = 0; C <= columnas; ++C) {
                let cell_address = { c: C, r: F }
                worksheet.getCell(this.n_cell(cell_address)).value = ''
            }
        }
        return worksheet
    }

    tamaño_fuente(worksheet, rango_filas, rango_columnas, valor) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F }
                worksheet.getCell(this.n_cell(cell_address)).font = { size: valor }
            }
        }
        return worksheet
    }

    agregar_filas(worksheet, numero_filas) {
        for (let i = 0; i < numero_filas; i++) {
            worksheet.addRow([''])
        }
        return worksheet
    }

    formato_miles(worksheet, rango_filas, rango_columnas) {
        for (let F = rango_filas.c; F <= rango_filas.f; ++F) {
            for (let C = rango_columnas.c; C <= rango_columnas.f; ++C) {
                let cell_address = { c: C, r: F }
                worksheet.getCell(this.n_cell(cell_address)).numFmt = '#,##0'
            }
        }
        return worksheet
    }

    insertar_tabla_gasto(worksheet, comienzo_tabla, fin_tabla, tabla_header_length, datos_tabla, nombre_tabla) {
        worksheet.addRows(datos_tabla)
        worksheet = this.nombre_tabla(worksheet, comienzo_tabla - 1, tabla_header_length - 1, nombre_tabla)
        worksheet = this.juntar_celdas(worksheet, { c: 0, r: comienzo_tabla }, { c: 3, r: comienzo_tabla })
        worksheet = this.insertar_valor_celda(worksheet, { c: 0, r: comienzo_tabla }, 'Departamentos')
        worksheet = this.ajustar_cabeceras(worksheet, { c: comienzo_tabla, f: comienzo_tabla }, { c: 0, f: tabla_header_length - 1 })
        worksheet = this.centrar_datos(worksheet, { c: comienzo_tabla + 1, f: comienzo_tabla + 1 }, { c: 0, f: tabla_header_length - 1 })
        worksheet = this.juntar_celdas(worksheet, { c: 0, r: fin_tabla }, { c: 1, r: fin_tabla })
        worksheet = this.centrar_datos(worksheet, { c: fin_tabla, f: fin_tabla }, { c: 0, f: 0 })
        worksheet = this.insertar_valor_celda(worksheet, { c: 0, r: fin_tabla }, 'Sub Totales')
        worksheet = this.formato_miles(worksheet, { c: comienzo_tabla + 2, f: fin_tabla }, { c: 2, f: tabla_header_length - 1 })
        worksheet = this.bordes_gruesos(worksheet, { c: comienzo_tabla - 1, f: comienzo_tabla + 1 }, { c: 0, f: tabla_header_length - 1 })
        worksheet = this.bordes_gruesos(worksheet, { c: fin_tabla, f: fin_tabla }, { c: 0, f: tabla_header_length - 1 })
        worksheet = this.bordes_gruesos_derecha(worksheet, { c: comienzo_tabla + 2, f: fin_tabla - 1 }, { c: 0, f: tabla_header_length - 1 })
        worksheet = this.texto_en_negrita(worksheet, { c: comienzo_tabla, f: comienzo_tabla + 1 }, { c: 0, f: tabla_header_length - 1 })
        worksheet = this.texto_en_negrita(worksheet, { c: fin_tabla, f: fin_tabla }, { c: 0, f: tabla_header_length - 1 })
        worksheet = this.agregar_filas(worksheet, 4)
        return worksheet
    }

    n_cell(cell) {
        let cell_ref = XLSX.utils.encode_cell(cell)
        cell_ref = cell_ref.toString()
        return cell_ref
    }
}
import { Component, OnInit } from '@angular/core';
import { EntidadService } from '../../../services/entidad.service';
import { PresupuestoService } from '../../../services/presupuesto.service';
import { UserService } from '../../../services/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-presupuesto',
  templateUrl: './presupuesto.component.html',
  styleUrls: ['./presupuesto.component.css'],
  providers: [EntidadService, PresupuestoService, UserService]
})
export class PresupuestoComponent implements OnInit {
  public titulo = " Presupuesto "
  public descripcion = "Generar mi presupuesto"
  public icono = "fas fa-upload fa-fw"
  public tipo_presupuesto
  public tipo_entidad
  public year
  public entidad_sel = this._entidadService.getEntidad()
  public nuevo_periodo
  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  })
  constructor(
    private _entidadService: EntidadService,
    private _userService: UserService,
    private _presupuestoService: PresupuestoService,
    private _router: Router,
  ) {

    this.entidad_sel = this._entidadService.getEntidad()
  }

  ngDoCheck() { //esta funcion detecta cualquier cambio dentro del sistema
    var cambioEntidad = this._entidadService.getEntidad(); //detecta siempre el valor de la entidad seleccionada aunq se cambie

    if (this.entidad_sel.codigo != cambioEntidad.codigo) { //Compara si la entidad seleccionada al inicio es distinta a la entidad q tiene ahora seleccionada el sistema
      // console.log('diferente')
      this.cambio()
    }
  }

  cambio() { //funcion que deberia ejecutarse cuando haya un cambio de entidad
    this.entidad_sel = this._entidadService.getEntidad(); //actualizar la entidad seleccionada con la que haya en localstoge para que this.entidad_sel sea igual a cambioEntidad en DoCheck hasta que la entidad se vuelva a cambiar nuevamente y asi sucesivamente
    //console.log("Cambiando...");
    this.enrutamiento_presupuesto()
    // console.log(this.tipo_entidad)
  }

  verificacion_estado() {
    this._presupuestoService.estado_presupuesto().subscribe(
      response => {
        // console.log(response)
        // this.tipo_entidad = response.grupo
        if (response.estado == false) {
          Swal.fire({
            title: '¡Atencion!',
            text: response.mensaje,
            icon: response.icon,
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-danger ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'OK'
          })
          this._router.navigate(['/inicio']);
        } else {
          this.enrutamiento_presupuesto()
        }
      },
      error => { console.log(error) },
    )
  }

  enrutamiento_presupuesto() {
    this.tipo_presupuesto = this.entidad_sel
    // console.log(this.tipo_presupuesto.grupo)
    this._presupuestoService.filtro_tipo_presupuesto().subscribe(
      response => {
        this.nuevo_periodo = response.permiso_nuevo_periodo
        this.year = response.año
        this.tipo_entidad = response.grupo
        
      },
      error => { console.log(error) },
    )
  }

  ngOnInit() {

    this.verificacion_estado()
  }

  generar_nuevo_periodo() {
    if (this.entidad_sel.grupo == 'IASD') {
      this._presupuestoService.nuevo_periodo_iglesia().subscribe(
        response => {
          if (response.estado == true) {
            this.Toast.fire({
              icon: 'success',
              title: response.mensaje
            })
            setTimeout(() => {
              location.reload();
              return false;
            }, 2000);

          }
        },
        error => { console.log(error) },
      )
    } else {
      this._presupuestoService.nuevo_periodo_edu_inst().subscribe(
        response => {
          if (response.estado == true) {
            this.Toast.fire({
              icon: 'success',
              title: response.mensaje
            })
            setTimeout(() => {
              location.reload();
              return false;
            }, 2000);

          }
        },
        error => { console.log(error) },
      )
    }

  }

}

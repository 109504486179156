<div class="row">
    <div id="inputSelect" class="col-3 input-icons">
        <i class="fa fa-user icon"></i>
        <input id="search" type="text" class="form-control inputSelect" [ngClass]="[(isInvalid) ? 'is-invalid' : '']"
            [(ngModel)]="opcionSeleccionada" (focus)="toggleOptions()" (keyup)="filterOptions($event)">
        <div id="options" class="hide elecciones">
            <option id="option" *ngFor="let d of data" class="opSelect" [value]="d.nombre" (click)="selectOp(d.nombre)">
                {{d.nombre}}</option>
        </div>
    </div>
</div>
<ol class="breadcrumb pull-right">
    <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
    <li class="breadcrumb-item">{{descripcion}}</li>
    <li class="breadcrumb-item active">{{ titulo }}</li>
</ol>

<!-- begin page-header -->
<h1 class="page-header"> <i class="fa fa-users"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<div class="row center">
    <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-blue">
            <div class="stats-icon"><i class="fa fa-desktop"></i>
            </div>
            <div class="stats-info">
                <h4>TOTAL EMPLEADOS ACTIVOS</h4>
                <p>3,291,922</p>
            </div>
            <!-- <div class="stats-link">
                <a href="javascript:;">View Detail <i class="fa fa-arrow-alt-circle-right"></i>
                </a>
            </div> -->
        </div>
    </div>
    <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-info">
            <div class="stats-icon">
                <i class="fa fa-link"></i>
            </div>
            <div class="stats-info">
                <h4>TOTAL MISIONEROS</h4>
                <p>20.44% / 20.44%</p>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-orange">
            <div class="stats-icon">
                <i class="fa fa-users"></i>
            </div>
            <div class="stats-info">
                <h4>% MISIONEROS</h4>
                <p>1,291,922</p>
            </div>
        </div>
    </div>
    <!--<div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-red">
            <div class="stats-icon">
                <i class="fa fa-clock"></i>
            </div>
            <div class="stats-info">
                <h4>AVG TIME ON SITE</h4>
                <p>00:12:23</p>
            </div>
        </div>
    </div>-->
</div>

<!-- begin panel -->
<panel variant='iatuch'>
    <ng-container header>
        <h4 class="panel-title">Filtros</h4>
        <div class="panel-heading-btn ng-star-inserted">
            <a class="btn btn-xs btn-icon btn-circle btn-default" href="javascript:;">
                <i class="fa fa-expand"></i>
            </a>
            <a class="btn btn-xs btn-icon btn-circle btn-success" href="javascript:;">
                <i class="fa fa-redo"></i>
            </a>
            <a class="btn btn-xs btn-icon btn-circle btn-warning" href="javascript:;">
                <i class="fa fa-minus"></i>
            </a>
            <a class="btn btn-xs btn-icon btn-circle btn-danger" href="javascript:;">
                <i class="fa fa-times"></i>
            </a>
        </div>
    </ng-container>

    <div class="row">
        <div class="card border-dark text-dark m-b-10 m-r-10">
            <h4 class="card-title m-t-10 m-b-0 text-center">Sexo</h4>
            <!-- <div class="card-header f-w-600"> Sexo </div> -->
            <div class="card-body">
                <div class="radio radio-css">
                    <input type="radio" name="sexo" id="cssRadio1" checked />
                    <label for="cssRadio1">Todo</label>
                  </div>
                  <div class="radio radio-css">
                    <input type="radio" name="sexo" id="cssRadio2"/>
                    <label for="cssRadio2">Femenino</label>
                  </div>
                  <div class="radio radio-css">
                    <input type="radio" name="sexo" id="cssRadio3"/>
                    <label for="cssRadio3">Masculino</label>
                  </div>
            </div>
        </div>
        <div class="card border-warning text-warning m-b-10">
            <div class="card-body">
                <blockquote class="blockquote mb-0">
                    <p class="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a
                        ante.</p>
                    <span class="blockquote-footer text-warning-transparent-8">Someone famous in <cite
                            title="Source Title">Source Title</cite>
                    </span>
                </blockquote>
            </div>
        </div>
        <div class="card border-warning text-warning m-b-10">
            <div class="card-body">
                <blockquote class="blockquote mb-0">
                    <p class="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a
                        ante.</p>
                    <span class="blockquote-footer text-warning-transparent-8">Someone famous in <cite
                            title="Source Title">Source Title</cite>
                    </span>
                </blockquote>
            </div>
        </div>
    </div>

</panel>
<!-- end panel -->
import { Component, OnInit } from '@angular/core';
import { EntidadService } from '../../../services/entidad.service';
import { Router } from '@angular/router';
import { F29Service } from '../../../services/declaracion_f29.service';
import { UserService } from '../../../services/user.service';
import { Documentacion_f29 } from '../../../models/documentacion_f29';
import { Borrador_f29 } from '../../../models/f29_borrador'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Global } from '../../../services/global';

import {Subscription} from "rxjs"

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import Swal from 'sweetalert2';
import { detectAnyAdblocker } from 'just-detect-adblock'
import { COLOR_BLACK } from '../../../config/globals';
import { SharingService } from '../../../services/sharing.service';

@Component({
  selector: 'app-ver-periodos-anteriores',
  templateUrl: './ver-periodos-anteriores.component.html',
  styleUrls: ['./ver-periodos-anteriores.component.css'],
  providers: [F29Service, UserService, EntidadService]
})
export class VerPeriodosAnterioresComponent implements OnInit {

  public titulo = " Consultar periodos "
  public descripcion = " Declaraciones (F-29) "
  public periodo = {_id:"", periodo: ""}
  public periodos = []
  public bitacora = []
  public entidad_sel;
  public closeResult: string;
  public borrador_f29;
  public tipo_archivo;
  public url = Global.url
  public active = 1

  public privilegio = { revision_periodo: false }
  public fecha_consolidado
  public tipo_consolidado = ""
  public f29_grupos = []
  public meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
	public entidadSelSubscription: Subscription;
  constructor(
    private _f29Service: F29Service,
    private _router: Router,
    private _entidadService: EntidadService,
    private _sharingService: SharingService,
    private modalService: NgbModal
  ) {
    this.entidad_sel = this._entidadService.getEntidad()
    this.tipo_archivo = new Documentacion_f29("", JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, '');
    this.borrador_f29 = new Borrador_f29("",JSON,JSON,JSON,JSON,JSON,JSON,JSON,JSON)
    this.trae_privilegios("revision_periodo")
    this.traerPeriodos()
    this.traer_grupos()
  }

  ngOnInit() {
    this.entidadSelSubscription = this._sharingService.sharingEntity.subscribe(
      (entidad) => {
        this.traerPeriodos()
      }
    )
  }

  open(content, id) {

    if(id.length > 4){
      (id == "entidad") ? this.consultar_periodo(this.periodo._id) : this.consultar_periodo(id)
    }else{
      this.consultar_consolidado_periodo(id)
    }
    let size = "xl"

    this.modalService.open(content, { windowClass : "modal-holder", size: size}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


  async trae_archivo(file) {
    Swal.fire({
      text: "Cargando...",
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const nombre_archivo = file.name
    this._f29Service.getFileSharepoint(file._id).subscribe(res => {
      let a = document.createElement("a");
      a.href = URL.createObjectURL(res);
      a.download = nombre_archivo, a.href;
      a.click();
      window.URL.revokeObjectURL(a.href);
      Swal.close()
    },(error) => {
      Swal.fire({
        title: "Error",
        text: "No se ha podido descargar el archivo, favor intente nuevamente",
        icon: 'error'
      })
    })
  }

  traerPeriodos(){
    this.entidad_sel = this._entidadService.getEntidad()

    this._f29Service.traePeriodos().subscribe(
      response => {
        this.periodos = response.periodos
      }
    )
  }

  traer_grupos(){
    this._f29Service.traerF29Grupos().subscribe(
      response => {
        this.f29_grupos = response
      }
    )
  }

  trae_privilegios(code){

    var privilegio = { code: code };
    this._f29Service.traePrivilegios(privilegio).subscribe(
      response => {
        var status = response.status;
        if(status == true){
          switch (code) {
            case "revision_periodo":
              this.privilegio.revision_periodo = true;
            break;
          }
        }
      },
      error => {}
    );

  }

  consultar_periodo(id){

    this.borrador_f29.debito_venta = {
      ventas_serv_prestados: {
        info_ingreso: {
          linea_1: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_2: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_3: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_4: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_5: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_6: {monto_neto: {cantidad: 0}}
        },
        genera_debito: {
          linea_7: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_8: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_9: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_10: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_11: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_12: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_13: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_14: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_15: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_16: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_17: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}}
        }
      },
      linea_18: {debitos: 0},
      linea_19: {debitos: 0},
      linea_20: {debitos: 0},
      linea_21: {debitos: 0 , m3: {cantidad: 0}, base: {cantidad: 0}, variable: {cantidad: 0}},
      linea_22: {debitos: 0}
    }
    this.borrador_f29.credito_compra = {
      compras_serv_utilizados: {
        linea_23: {con_derecho_credito: {cantidad: 0}, sin_derecho_credito: {cantidad: 0}},
        sin_derecho_credito_fiscal: {
          linea_24: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_25: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_26: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
        },
        con_derecho_credito_fiscal: {
          internas: {
            linea_27: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_28: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_29: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_30: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_31: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_32: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}}
          },
          importaciones: {
            linea_33: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_34: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}}
          }
        }
      },
      linea_35:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_36:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_37:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_38:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_39:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_40:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_41:{credito_recuperacion_reintegro: {cantidad: 0}},
      ley_20765: {
        linea_42:{credito_recuperacion_reintegro: {cantidad: 0},
                m3_comprados_con_derecho_credito: {cantidad: 0},
                componente_impuesto: {base: {cantidad: 0},
                                      variable: {cantidad: 0}}},

        linea_43:{credito_recuperacion_reintegro: {cantidad: 0},
                m3_comprados_con_derecho_credito: {cantidad: 0},
                componente_impuesto: {base: {cantidad: 0},
                                      variable: {cantidad: 0}}},
      },
      linea_44:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_45:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_46:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_47:{credito_recuperacion_reintegro: {cantidad: 0}}
    }
    this.borrador_f29.linea_48 = {cantidad: 0, impuesto_determinado:{cantidad: 0}}
    this.borrador_f29.linea_49 = {impuesto_determinado:{cantidad: 0}}
    this.borrador_f29.linea_50 = {impuesto_determinado:{cantidad: 0}}
    this.borrador_f29.impuesto_renta = {
      retenciones: {
        linea_51: {impuesto_determinado: {cantidad: 0}},
        linea_52: {donacion_articulo_8_ley_18985: {cantidad: 0},
                  impuesto_determinado: {cantidad: 0},
                  donacion_ley_20444_2010: {cantidad: 0}},
        linea_53: {impuesto_determinado: {cantidad: 0}},
        linea_54: {impuesto_determinado: {cantidad: 0}},
        linea_55: {impuesto_determinado: {cantidad: 0}},
        linea_56: {impuesto_determinado: {cantidad: 0}},
        linea_57: {impuesto_determinado: {cantidad: 0}},
        linea_58: {impuesto_determinado: {cantidad: 0}},
        linea_63: {impuesto_determinado: {cantidad: 0}},
        linea_64: {impuesto_determinado: {cantidad: 0}},
      },
      ppm: {
        linea_59: {acogido_suspension_ppm : 0,
                  monto_perdida_art90: {cantidad:0},
                  base_imponible: {cantidad: 0},
                  tasa: {cantidad: 0},
                  credito_tope_suspension_ppm:{ cantidad: 0},
                  ppm_neto_determinado: {cantidad: 0}},
        linea_60: {monto_perdida_art90: {cantidad: 0},
                  base_imponible: {cantidad: 0},
                  tasa: {cantidad: 0},
                  credito_tope_suspension_ppm:{ cantidad: 0},
                  ppm_neto_determinado: {cantidad: 0}},
        linea_61: {monto_perdida_art90: {cantidad: 0},
                base_imponible: {cantidad: 0},
                tasa: {cantidad: 0},
                credito_tope_suspension_ppm:{ cantidad: 0},
                ppm_neto_determinado: {cantidad: 0}},
        linea_62:{ppm_neto_determinado: {cantidad: 0}},
        linea_63: {credito_mes: {cantidad: 0},
                remanente_mes_anterior: {cantidad: 0},
                remanente_periodo_siguiente: {cantidad: 0},
                ppm_neto_determinado: {cantidad: 0}},
        linea_64:{ppm_neto_determinado: {cantidad: 0}},
        linea_65:{ppm_neto_determinado: {cantidad: 0}}
      }
    }
    this.borrador_f29.linea_66 = {ppm_neto_determinado: {cantidad: 0}}
    this.borrador_f29.tributacion_simplificada = {
      linea_67: {cantidad: 0},
      linea_68: {cantidad: 0},
      linea_69: {impuesto_determinado: {cantidad: 0}},
    }
    this.borrador_f29.impuesto_adicional = {
      linea_70: {impuesto_determinado: {cantidad: 0}},
      linea_71: {impuesto_determinado: {cantidad: 0}},
      linea_72: {cantidad: 0},
      linea_73: {cantidad: 0},
      linea_74: {cantidad: 0},
      linea_75: {cantidad: 0},
      linea_76: {cantidad: 0},
      linea_77: {cantidad: 0, impuesto_determinado: {cantidad: 0}},
    }
    this.borrador_f29.cambio_sujeto = {
      parte_1: {
        linea_99:{cantidad: 0},
        linea_100:{cantidad: 0},
        linea_101:{cantidad: 0},
        linea_102:{cantidad: 0},
      },
      linea_103: {cantidad: 0, impuesto_determinado: {cantidad: 0}},
      parte_2: {
        linea_104: {cantidad: 0},
        linea_105: {cantidad: 0},
        linea_106: {cantidad: 0},
        linea_107: {cantidad: 0},
        linea_108: {cantidad: 0, impuesto_determinado: {cantidad: 0}},
      }
    }
    this.borrador_f29.creditos_especiales = {
      linea_109: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}},
      linea_110: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}},
      linea_111: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}},
      linea_112: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}},
      linea_113: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}},
      linea_114: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}}
    }
    this.borrador_f29.linea_115 = {impuesto_determinado: {cantidad: 0}}
    this.borrador_f29.remanente_creditos_especiales = {
      linea_116: {cantidad: 0},
      linea_117: {cantidad: 0},
      linea_118: {cantidad: 0},
      linea_119: {cantidad: 0},
      linea_120: {cantidad: 0},
      linea_121: {cantidad: 0}
    }
    this.borrador_f29.linea_122 = {cantidad: 0}
    this.borrador_f29.linea_123 = {cantidad: 0}
    this.borrador_f29.linea_124 = {cantidad: 0}
    this.borrador_f29.linea_125 = {cantidad: 0}


    this._f29Service.consultar_periodo(id, "").subscribe(
      response => {
        this.periodo = response.periodo
        if(response.borrador){
          this.borrador_f29.debito_venta = {
            ventas_serv_prestados: {
              info_ingreso: {
                linea_1: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.cantidad_doc.cantidad}, monto_neto: {cantidad: (response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.monto_neto.cantidad)}},
                linea_2: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.monto_neto.cantidad}},
                linea_3: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.monto_neto.cantidad}},
                linea_4: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.monto_neto.cantidad}},
                linea_5: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.monto_neto.cantidad}},
                linea_6: {monto_neto: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_6.monto_neto.cantidad}}
              },
              genera_debito: {
                linea_7: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_7.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_7.debitos.cantidad}},
                linea_8: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_8.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_8.debitos.cantidad}},
                linea_9: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_9.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_9.debitos.cantidad}},
                linea_10: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_10.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_10.debitos.cantidad}},
                linea_11: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_11.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_11.debitos.cantidad}},
                linea_12: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_12.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_12.debitos.cantidad}},
                linea_13: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_13.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_13.debitos.cantidad}},
                linea_14: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_14.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_14.debitos.cantidad}},
                linea_15: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_15.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_15.debitos.cantidad}},
                linea_16: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_16.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_16.debitos.cantidad}},
                linea_17: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_17.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_17.debitos.cantidad}},
              }
            },
            linea_18: {debitos: response.borrador.debito_venta.linea_18.debitos},
            linea_19: {debitos: response.borrador.debito_venta.linea_19.debitos},
            linea_20: {debitos: response.borrador.debito_venta.linea_20.debitos},
            linea_21: {debitos: response.borrador.debito_venta.linea_21.debitos , m3: {cantidad: response.borrador.debito_venta.linea_21.m3.cantidad}, base: {cantidad: response.borrador.debito_venta.linea_21.base.cantidad}, variable: {cantidad: response.borrador.debito_venta.linea_21.variable.cantidad}},
            linea_22: {debitos: response.borrador.debito_venta.linea_22.debitos}
          }
          this.borrador_f29.credito_compra = {
            compras_serv_utilizados: {
              linea_23: {con_derecho_credito: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.linea_23.con_derecho_credito.cantidad}, sin_derecho_credito: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.linea_23.sin_derecho_credito.cantidad}},
              sin_derecho_credito_fiscal: {
                linea_24: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.monto_neto.cantidad}},
                linea_25: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.monto_neto.cantidad}},
                linea_26: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.monto_neto.cantidad}},
              },
              con_derecho_credito_fiscal: {
                internas: {
                  linea_27: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.credito_recuperacion_reintegro.cantidad}},
                  linea_28: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.credito_recuperacion_reintegro.cantidad}},
                  linea_29: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.credito_recuperacion_reintegro.cantidad}},
                  linea_30: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.credito_recuperacion_reintegro.cantidad}},
                  linea_31: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.credito_recuperacion_reintegro.cantidad}},
                  linea_32: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.credito_recuperacion_reintegro.cantidad}}
                },
                importaciones: {
                  linea_33: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.credito_recuperacion_reintegro.cantidad}},
                  linea_34:{cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.credito_recuperacion_reintegro.cantidad}}
                }
              }
            },
            linea_35:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_35.credito_recuperacion_reintegro.cantidad}},
            linea_36:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_36.credito_recuperacion_reintegro.cantidad}},
            linea_37:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_37.credito_recuperacion_reintegro.cantidad}},
            linea_38:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_38.credito_recuperacion_reintegro.cantidad}},
            linea_39:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_39.credito_recuperacion_reintegro.cantidad}},
            linea_40:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_40.credito_recuperacion_reintegro.cantidad}},
            linea_41:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_41.credito_recuperacion_reintegro.cantidad}},
            ley_20765: {
              linea_42:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.ley_20765.linea_42.credito_recuperacion_reintegro.cantidad},
                      m3_comprados_con_derecho_credito: {cantidad: response.borrador.credito_compra.ley_20765.linea_42.m3_comprados_con_derecho_credito.cantidad},
                      componente_impuesto: {base: {cantidad: response.borrador.credito_compra.ley_20765.linea_42.componente_impuesto.base.cantidad},
                                            variable: {cantidad: response.borrador.credito_compra.ley_20765.linea_42.componente_impuesto.variable.cantidad}}},

              linea_43:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.ley_20765.linea_43.credito_recuperacion_reintegro.cantidad},
                      m3_comprados_con_derecho_credito: {cantidad: response.borrador.credito_compra.ley_20765.linea_43.m3_comprados_con_derecho_credito.cantidad},
                      componente_impuesto: {base: {cantidad: response.borrador.credito_compra.ley_20765.linea_43.componente_impuesto.base.cantidad},
                                            variable: {cantidad: response.borrador.credito_compra.ley_20765.linea_43.componente_impuesto.variable.cantidad}}},
            },
            linea_44:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_44.credito_recuperacion_reintegro.cantidad}},
            linea_45:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_45.credito_recuperacion_reintegro.cantidad}},
            linea_46:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_46.credito_recuperacion_reintegro.cantidad}},
            linea_47:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_47.credito_recuperacion_reintegro.cantidad}}
          }
          this.borrador_f29.linea_48 = {cantidad: response.borrador.linea_48.cantidad, impuesto_determinado:{cantidad: response.borrador.linea_48.impuesto_determinado.cantidad}}
          this.borrador_f29.linea_49 = {impuesto_determinado:{cantidad: response.borrador.linea_49.impuesto_determinado.cantidad}}
          this.borrador_f29.linea_50 = {impuesto_determinado:{cantidad: response.borrador.linea_50.impuesto_determinado.cantidad}}
          this.borrador_f29.impuesto_renta = {
            retenciones: {
              linea_51: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_51.impuesto_determinado.cantidad}},
              linea_52: {donacion_articulo_8_ley_18985: {cantidad: response.borrador.impuesto_renta.retenciones.linea_52.donacion_articulo_8_ley_18985.cantidad},
                        impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_52.impuesto_determinado.cantidad},
                        donacion_ley_20444_2010: {cantidad: response.borrador.impuesto_renta.retenciones.linea_52.donacion_ley_20444_2010.cantidad}},
              linea_53: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_53.impuesto_determinado.cantidad}},
              linea_54: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_54.impuesto_determinado.cantidad}},
              linea_55: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_55.impuesto_determinado.cantidad}},
              linea_56: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_56.impuesto_determinado.cantidad}},
              linea_57: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_57.impuesto_determinado.cantidad}},
              linea_58: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_58.impuesto_determinado.cantidad}},
              linea_63: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_63 ? response.borrador.impuesto_renta.retenciones.linea_63.impuesto_determinado.cantidad : 0}},
              linea_64: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_64 ? response.borrador.impuesto_renta.retenciones.linea_64.impuesto_determinado.cantidad : 0}},
            },
            ppm: {
              linea_59: {acogido_suspension_ppm : response.borrador.impuesto_renta.ppm.linea_59.acogido_suspension_ppm,
                        monto_perdida_art90: {cantidad:response.borrador.impuesto_renta.ppm.linea_59.monto_perdida_art90.cantidad},
                        base_imponible: {cantidad: response.borrador.impuesto_renta.ppm.linea_59.base_imponible.cantidad},
                        tasa: {cantidad:response.borrador.impuesto_renta.ppm.linea_59.tasa.cantidad},
                        credito_tope_suspension_ppm:{ cantidad: response.borrador.impuesto_renta.ppm.linea_59.credito_tope_suspension_ppm.cantidad},
                        ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_59.ppm_neto_determinado.cantidad}},
              linea_60: {monto_perdida_art90: {cantidad:response.borrador.impuesto_renta.ppm.linea_60.monto_perdida_art90.cantidad},
                        base_imponible: {cantidad: response.borrador.impuesto_renta.ppm.linea_60.base_imponible.cantidad},
                        tasa: {cantidad:response.borrador.impuesto_renta.ppm.linea_60.tasa.cantidad},
                        credito_tope_suspension_ppm:{ cantidad: response.borrador.impuesto_renta.ppm.linea_60.credito_tope_suspension_ppm.cantidad},
                        ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_60.ppm_neto_determinado.cantidad}},
              linea_61: {monto_perdida_art90: {cantidad:response.borrador.impuesto_renta.ppm.linea_61.monto_perdida_art90.cantidad},
                      base_imponible: {cantidad: response.borrador.impuesto_renta.ppm.linea_61.base_imponible.cantidad},
                      tasa: {cantidad:response.borrador.impuesto_renta.ppm.linea_61.tasa.cantidad},
                      credito_tope_suspension_ppm:{ cantidad: response.borrador.impuesto_renta.ppm.linea_61.credito_tope_suspension_ppm.cantidad},
                      ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_61.ppm_neto_determinado.cantidad}},
              linea_62:{ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_62.ppm_neto_determinado.cantidad}},
              linea_63: {credito_mes: {cantidad:response.borrador.impuesto_renta.ppm.linea_63.credito_mes.cantidad},
                      remanente_mes_anterior: {cantidad: response.borrador.impuesto_renta.ppm.linea_63.remanente_mes_anterior.cantidad},
                      remanente_periodo_siguiente: {cantidad:response.borrador.impuesto_renta.ppm.linea_63.remanente_periodo_siguiente.cantidad},
                      ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_63.ppm_neto_determinado.cantidad}},
              linea_64:{ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_64.ppm_neto_determinado.cantidad}},
              linea_65:{ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_65.ppm_neto_determinado.cantidad}}
            }
          }
          this.borrador_f29.linea_66 = {ppm_neto_determinado: {cantidad: response.borrador.linea_66.ppm_neto_determinado.cantidad}}
          this.borrador_f29.tributacion_simplificada = {
            linea_67: {cantidad: response.borrador.tributacion_simplificada.linea_67.cantidad},
            linea_68: {cantidad: response.borrador.tributacion_simplificada.linea_68.cantidad},
            linea_69: {impuesto_determinado: {cantidad: response.borrador.tributacion_simplificada.linea_69.impuesto_determinado.cantidad}},
          }
          this.borrador_f29.impuesto_adicional = {
            linea_70: {impuesto_determinado: {cantidad: response.borrador.impuesto_adicional.linea_70.impuesto_determinado.cantidad}},
            linea_71: {impuesto_determinado: {cantidad: response.borrador.impuesto_adicional.linea_71.impuesto_determinado.cantidad}},
            linea_72: {cantidad: response.borrador.impuesto_adicional.linea_72.cantidad},
            linea_73: {cantidad: response.borrador.impuesto_adicional.linea_73.cantidad},
            linea_74: {cantidad: response.borrador.impuesto_adicional.linea_74.cantidad},
            linea_75: {cantidad: response.borrador.impuesto_adicional.linea_75.cantidad},
            linea_76: {cantidad: response.borrador.impuesto_adicional.linea_76.cantidad},
            linea_77: {cantidad: response.borrador.impuesto_adicional.linea_77.cantidad, impuesto_determinado: {cantidad:response.borrador.impuesto_adicional.linea_77.impuesto_determinado.cantidad}},
          }
          this.borrador_f29.cambio_sujeto = {
            parte_1: {
              linea_99:{cantidad: response.borrador.cambio_sujeto.parte_1.linea_99.cantidad},
              linea_100:{cantidad: response.borrador.cambio_sujeto.parte_1.linea_100.cantidad},
              linea_101:{cantidad: response.borrador.cambio_sujeto.parte_1.linea_101.cantidad},
              linea_102:{cantidad: response.borrador.cambio_sujeto.parte_1.linea_102.cantidad},
            },
            linea_103: {cantidad: response.borrador.cambio_sujeto.linea_103.cantidad, impuesto_determinado: {cantidad: response.borrador.cambio_sujeto.linea_103.impuesto_determinado.cantidad}},
            parte_2: {
              linea_104: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_104.cantidad},
              linea_105: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_105.cantidad},
              linea_106: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_106.cantidad},
              linea_107: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_107.cantidad},
              linea_108: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_108.cantidad, impuesto_determinado: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_108.impuesto_determinado.cantidad}},
            }
          }
          this.borrador_f29.creditos_especiales = {
            linea_109: {cantidad: response.borrador.creditos_especiales.linea_109.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_109.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_109.impuesto_determinado.cantidad}},
            linea_110: {cantidad: response.borrador.creditos_especiales.linea_110.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_110.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_110.impuesto_determinado.cantidad}},
            linea_111: {cantidad: response.borrador.creditos_especiales.linea_111.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_111.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_111.impuesto_determinado.cantidad}},
            linea_112: {cantidad: response.borrador.creditos_especiales.linea_112.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_112.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_112.impuesto_determinado.cantidad}},
            linea_113: {cantidad: response.borrador.creditos_especiales.linea_113.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_113.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_113.impuesto_determinado.cantidad}},
            linea_114: {cantidad: response.borrador.creditos_especiales.linea_114.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_114.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_114.impuesto_determinado.cantidad}}
          }
          this.borrador_f29.linea_115 = {impuesto_determinado: {cantidad: response.borrador.linea_115.impuesto_determinado.cantidad}}
          this.borrador_f29.remanente_creditos_especiales = {
            linea_116: {cantidad: response.borrador.remanente_creditos_especiales.linea_116.cantidad},
            linea_117: {cantidad: response.borrador.remanente_creditos_especiales.linea_117.cantidad},
            linea_118: {cantidad: response.borrador.remanente_creditos_especiales.linea_118.cantidad},
            linea_119: {cantidad: response.borrador.remanente_creditos_especiales.linea_119.cantidad},
            linea_120: {cantidad: response.borrador.remanente_creditos_especiales.linea_120.cantidad},
            linea_121: {cantidad: response.borrador.remanente_creditos_especiales.linea_121.cantidad}
          }
          this.borrador_f29.linea_122 = {cantidad: response.borrador.linea_122.cantidad}
          this.borrador_f29.linea_123 = {cantidad: response.borrador.linea_123.cantidad}
          this.borrador_f29.linea_124 = {cantidad: response.borrador.linea_124.cantidad}
          this.borrador_f29.linea_125 = {cantidad: response.borrador.linea_125.cantidad}
        }
        this.bitacora = response.periodo.bitacora
        this.tipo_archivo = response.periodo.documentacion
        this.fecha_consolidado = response.periodo.periodo.split(10)
      }
    )
  }

  consultar_consolidado_periodo(id){

    this.borrador_f29.debito_venta = {
      ventas_serv_prestados: {
        info_ingreso: {
          linea_1: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_2: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_3: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_4: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_5: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_6: {monto_neto: {cantidad: 0}}
        },
        genera_debito: {
          linea_7: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_8: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_9: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_10: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_11: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_12: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_13: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_14: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_15: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_16: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}},
          linea_17: {cantidad_doc: {cantidad: 0}, debitos: {cantidad: 0}}
        }
      },
      linea_18: {debitos: 0},
      linea_19: {debitos: 0},
      linea_20: {debitos: 0},
      linea_21: {debitos: 0 , m3: {cantidad: 0}, base: {cantidad: 0}, variable: {cantidad: 0}},
      linea_22: {debitos: 0}
    }
    this.borrador_f29.credito_compra = {
      compras_serv_utilizados: {
        linea_23: {con_derecho_credito: {cantidad: 0}, sin_derecho_credito: {cantidad: 0}},
        sin_derecho_credito_fiscal: {
          linea_24: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_25: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
          linea_26: {cantidad_doc: {cantidad: 0}, monto_neto: {cantidad: 0}},
        },
        con_derecho_credito_fiscal: {
          internas: {
            linea_27: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_28: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_29: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_30: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_31: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_32: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}}
          },
          importaciones: {
            linea_33: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}},
            linea_34: {cantidad_doc: {cantidad: 0}, credito_recuperacion_reintegro: {cantidad: 0}}
          }
        }
      },
      linea_35:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_36:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_37:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_38:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_39:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_40:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_41:{credito_recuperacion_reintegro: {cantidad: 0}},
      ley_20765: {
        linea_42:{credito_recuperacion_reintegro: {cantidad: 0},
                m3_comprados_con_derecho_credito: {cantidad: 0},
                componente_impuesto: {base: {cantidad: 0},
                                      variable: {cantidad: 0}}},

        linea_43:{credito_recuperacion_reintegro: {cantidad: 0},
                m3_comprados_con_derecho_credito: {cantidad: 0},
                componente_impuesto: {base: {cantidad: 0},
                                      variable: {cantidad: 0}}},
      },
      linea_44:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_45:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_46:{credito_recuperacion_reintegro: {cantidad: 0}},
      linea_47:{credito_recuperacion_reintegro: {cantidad: 0}}
    }
    this.borrador_f29.linea_48 = {cantidad: 0, impuesto_determinado:{cantidad: 0}}
    this.borrador_f29.linea_49 = {impuesto_determinado:{cantidad: 0}}
    this.borrador_f29.linea_50 = {impuesto_determinado:{cantidad: 0}}
    this.borrador_f29.impuesto_renta = {
      retenciones: {
        linea_51: {impuesto_determinado: {cantidad: 0}},
        linea_52: {donacion_articulo_8_ley_18985: {cantidad: 0},
                  impuesto_determinado: {cantidad: 0},
                  donacion_ley_20444_2010: {cantidad: 0}},
        linea_53: {impuesto_determinado: {cantidad: 0}},
        linea_54: {impuesto_determinado: {cantidad: 0}},
        linea_55: {impuesto_determinado: {cantidad: 0}},
        linea_56: {impuesto_determinado: {cantidad: 0}},
        linea_57: {impuesto_determinado: {cantidad: 0}},
        linea_58: {impuesto_determinado: {cantidad: 0}},
        linea_63: {impuesto_determinado: {cantidad: 0}},
        linea_64: {impuesto_determinado: {cantidad: 0}},
      },
      ppm: {
        linea_59: {acogido_suspension_ppm : 0,
                  monto_perdida_art90: {cantidad:0},
                  base_imponible: {cantidad: 0},
                  tasa: {cantidad: 0},
                  credito_tope_suspension_ppm:{ cantidad: 0},
                  ppm_neto_determinado: {cantidad: 0}},
        linea_60: {monto_perdida_art90: {cantidad: 0},
                  base_imponible: {cantidad: 0},
                  tasa: {cantidad: 0},
                  credito_tope_suspension_ppm:{ cantidad: 0},
                  ppm_neto_determinado: {cantidad: 0}},
        linea_61: {monto_perdida_art90: {cantidad: 0},
                base_imponible: {cantidad: 0},
                tasa: {cantidad: 0},
                credito_tope_suspension_ppm:{ cantidad: 0},
                ppm_neto_determinado: {cantidad: 0}},
        linea_62:{ppm_neto_determinado: {cantidad: 0}},
        linea_63: {credito_mes: {cantidad: 0},
                remanente_mes_anterior: {cantidad: 0},
                remanente_periodo_siguiente: {cantidad: 0},
                ppm_neto_determinado: {cantidad: 0}},
        linea_64:{ppm_neto_determinado: {cantidad: 0}},
        linea_65:{ppm_neto_determinado: {cantidad: 0}}
      }
    }
    this.borrador_f29.linea_66 = {ppm_neto_determinado: {cantidad: 0}}
    this.borrador_f29.tributacion_simplificada = {
      linea_67: {cantidad: 0},
      linea_68: {cantidad: 0},
      linea_69: {impuesto_determinado: {cantidad: 0}},
    }
    this.borrador_f29.impuesto_adicional = {
      linea_70: {impuesto_determinado: {cantidad: 0}},
      linea_71: {impuesto_determinado: {cantidad: 0}},
      linea_72: {cantidad: 0},
      linea_73: {cantidad: 0},
      linea_74: {cantidad: 0},
      linea_75: {cantidad: 0},
      linea_76: {cantidad: 0},
      linea_77: {cantidad: 0, impuesto_determinado: {cantidad: 0}},
    }
    this.borrador_f29.cambio_sujeto = {
      parte_1: {
        linea_99:{cantidad: 0},
        linea_100:{cantidad: 0},
        linea_101:{cantidad: 0},
        linea_102:{cantidad: 0},
      },
      linea_103: {cantidad: 0, impuesto_determinado: {cantidad: 0}},
      parte_2: {
        linea_104: {cantidad: 0},
        linea_105: {cantidad: 0},
        linea_106: {cantidad: 0},
        linea_107: {cantidad: 0},
        linea_108: {cantidad: 0, impuesto_determinado: {cantidad: 0}},
      }
    }
    this.borrador_f29.creditos_especiales = {
      linea_109: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}},
      linea_110: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}},
      linea_111: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}},
      linea_112: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}},
      linea_113: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}},
      linea_114: {cantidad: 0, remanente_mes_anterior: {cantidad: 0}, impuesto_determinado: {cantidad: 0}}
    }
    this.borrador_f29.linea_115 = {impuesto_determinado: {cantidad: 0}}
    this.borrador_f29.remanente_creditos_especiales = {
      linea_116: {cantidad: 0},
      linea_117: {cantidad: 0},
      linea_118: {cantidad: 0},
      linea_119: {cantidad: 0},
      linea_120: {cantidad: 0},
      linea_121: {cantidad: 0}
    }
    this.borrador_f29.linea_122 = {cantidad: 0}
    this.borrador_f29.linea_123 = {cantidad: 0}
    this.borrador_f29.linea_124 = {cantidad: 0}
    this.borrador_f29.linea_125 = {cantidad: 0}


    this._f29Service.consultar_periodo(id, this.fecha_consolidado).subscribe(
      response => {
        if(response.borrador){
          this.borrador_f29.debito_venta = {
            ventas_serv_prestados: {
              info_ingreso: {
                linea_1: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.cantidad_doc.cantidad}, monto_neto: {cantidad: (response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_1.monto_neto.cantidad)}},
                linea_2: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_2.monto_neto.cantidad}},
                linea_3: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_3.monto_neto.cantidad}},
                linea_4: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_4.monto_neto.cantidad}},
                linea_5: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_5.monto_neto.cantidad}},
                linea_6: {monto_neto: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.info_ingreso.linea_6.monto_neto.cantidad}}
              },
              genera_debito: {
                linea_7: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_7.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_7.debitos.cantidad}},
                linea_8: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_8.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_8.debitos.cantidad}},
                linea_9: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_9.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_9.debitos.cantidad}},
                linea_10: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_10.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_10.debitos.cantidad}},
                linea_11: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_11.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_11.debitos.cantidad}},
                linea_12: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_12.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_12.debitos.cantidad}},
                linea_13: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_13.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_13.debitos.cantidad}},
                linea_14: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_14.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_14.debitos.cantidad}},
                linea_15: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_15.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_15.debitos.cantidad}},
                linea_16: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_16.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_16.debitos.cantidad}},
                linea_17: {cantidad_doc: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_17.cantidad_doc.cantidad}, debitos: {cantidad: response.borrador.debito_venta.ventas_serv_prestados.genera_debito.linea_17.debitos.cantidad}},
              }
            },
            linea_18: {debitos: response.borrador.debito_venta.linea_18.debitos},
            linea_19: {debitos: response.borrador.debito_venta.linea_19.debitos},
            linea_20: {debitos: response.borrador.debito_venta.linea_20.debitos},
            linea_21: {debitos: response.borrador.debito_venta.linea_21.debitos , m3: {cantidad: response.borrador.debito_venta.linea_21.m3.cantidad}, base: {cantidad: response.borrador.debito_venta.linea_21.base.cantidad}, variable: {cantidad: response.borrador.debito_venta.linea_21.variable.cantidad}},
            linea_22: {debitos: response.borrador.debito_venta.linea_22.debitos}
          }
          this.borrador_f29.credito_compra = {
            compras_serv_utilizados: {
              linea_23: {con_derecho_credito: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.linea_23.con_derecho_credito.cantidad}, sin_derecho_credito: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.linea_23.sin_derecho_credito.cantidad}},
              sin_derecho_credito_fiscal: {
                linea_24: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_24.monto_neto.cantidad}},
                linea_25: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_25.monto_neto.cantidad}},
                linea_26: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.cantidad_doc.cantidad}, monto_neto: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.sin_derecho_credito_fiscal.linea_26.monto_neto.cantidad}},
              },
              con_derecho_credito_fiscal: {
                internas: {
                  linea_27: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_27.credito_recuperacion_reintegro.cantidad}},
                  linea_28: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_28.credito_recuperacion_reintegro.cantidad}},
                  linea_29: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_29.credito_recuperacion_reintegro.cantidad}},
                  linea_30: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_30.credito_recuperacion_reintegro.cantidad}},
                  linea_31: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_31.credito_recuperacion_reintegro.cantidad}},
                  linea_32: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.internas.linea_32.credito_recuperacion_reintegro.cantidad}}
                },
                importaciones: {
                  linea_33: {cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_33.credito_recuperacion_reintegro.cantidad}},
                  linea_34:{cantidad_doc: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.cantidad_doc.cantidad}, credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.compras_serv_utilizados.con_derecho_credito_fiscal.importaciones.linea_34.credito_recuperacion_reintegro.cantidad}}
                }
              }
            },
            linea_35:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_35.credito_recuperacion_reintegro.cantidad}},
            linea_36:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_36.credito_recuperacion_reintegro.cantidad}},
            linea_37:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_37.credito_recuperacion_reintegro.cantidad}},
            linea_38:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_38.credito_recuperacion_reintegro.cantidad}},
            linea_39:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_39.credito_recuperacion_reintegro.cantidad}},
            linea_40:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_40.credito_recuperacion_reintegro.cantidad}},
            linea_41:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_41.credito_recuperacion_reintegro.cantidad}},
            ley_20765: {
              linea_42:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.ley_20765.linea_42.credito_recuperacion_reintegro.cantidad},
                      m3_comprados_con_derecho_credito: {cantidad: response.borrador.credito_compra.ley_20765.linea_42.m3_comprados_con_derecho_credito.cantidad},
                      componente_impuesto: {base: {cantidad: response.borrador.credito_compra.ley_20765.linea_42.componente_impuesto.base.cantidad},
                                            variable: {cantidad: response.borrador.credito_compra.ley_20765.linea_42.componente_impuesto.variable.cantidad}}},

              linea_43:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.ley_20765.linea_43.credito_recuperacion_reintegro.cantidad},
                      m3_comprados_con_derecho_credito: {cantidad: response.borrador.credito_compra.ley_20765.linea_43.m3_comprados_con_derecho_credito.cantidad},
                      componente_impuesto: {base: {cantidad: response.borrador.credito_compra.ley_20765.linea_43.componente_impuesto.base.cantidad},
                                            variable: {cantidad: response.borrador.credito_compra.ley_20765.linea_43.componente_impuesto.variable.cantidad}}},
            },
            linea_44:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_44.credito_recuperacion_reintegro.cantidad}},
            linea_45:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_45.credito_recuperacion_reintegro.cantidad}},
            linea_46:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_46.credito_recuperacion_reintegro.cantidad}},
            linea_47:{credito_recuperacion_reintegro: {cantidad: response.borrador.credito_compra.linea_47.credito_recuperacion_reintegro.cantidad}}
          }
          this.borrador_f29.linea_48 = {cantidad: response.borrador.linea_48.cantidad, impuesto_determinado:{cantidad: response.borrador.linea_48.impuesto_determinado.cantidad}}
          this.borrador_f29.linea_49 = {impuesto_determinado:{cantidad: response.borrador.linea_49.impuesto_determinado.cantidad}}
          this.borrador_f29.linea_50 = {impuesto_determinado:{cantidad: response.borrador.linea_50.impuesto_determinado.cantidad}}
          this.borrador_f29.impuesto_renta = {
            retenciones: {
              linea_51: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_51.impuesto_determinado.cantidad}},
              linea_52: {donacion_articulo_8_ley_18985: {cantidad: response.borrador.impuesto_renta.retenciones.linea_52.donacion_articulo_8_ley_18985.cantidad},
                        impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_52.impuesto_determinado.cantidad},
                        donacion_ley_20444_2010: {cantidad: response.borrador.impuesto_renta.retenciones.linea_52.donacion_ley_20444_2010.cantidad}},
              linea_53: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_53.impuesto_determinado.cantidad}},
              linea_54: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_54.impuesto_determinado.cantidad}},
              linea_55: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_55.impuesto_determinado.cantidad}},
              linea_56: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_56.impuesto_determinado.cantidad}},
              linea_57: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_57.impuesto_determinado.cantidad}},
              linea_58: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_58.impuesto_determinado.cantidad}},
              linea_63: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_63 ? response.borrador.impuesto_renta.retenciones.linea_63.impuesto_determinado.cantidad : 0}},
              linea_64: {impuesto_determinado: {cantidad: response.borrador.impuesto_renta.retenciones.linea_64 ? response.borrador.impuesto_renta.retenciones.linea_64.impuesto_determinado.cantidad : 0}},
            },
            ppm: {
              linea_59: {acogido_suspension_ppm : response.borrador.impuesto_renta.ppm.linea_59.acogido_suspension_ppm,
                        monto_perdida_art90: {cantidad:response.borrador.impuesto_renta.ppm.linea_59.monto_perdida_art90.cantidad},
                        base_imponible: {cantidad: response.borrador.impuesto_renta.ppm.linea_59.base_imponible.cantidad},
                        tasa: {cantidad:response.borrador.impuesto_renta.ppm.linea_59.tasa.cantidad},
                        credito_tope_suspension_ppm:{ cantidad: response.borrador.impuesto_renta.ppm.linea_59.credito_tope_suspension_ppm.cantidad},
                        ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_59.ppm_neto_determinado.cantidad}},
              linea_60: {monto_perdida_art90: {cantidad:response.borrador.impuesto_renta.ppm.linea_60.monto_perdida_art90.cantidad},
                        base_imponible: {cantidad: response.borrador.impuesto_renta.ppm.linea_60.base_imponible.cantidad},
                        tasa: {cantidad:response.borrador.impuesto_renta.ppm.linea_60.tasa.cantidad},
                        credito_tope_suspension_ppm:{ cantidad: response.borrador.impuesto_renta.ppm.linea_60.credito_tope_suspension_ppm.cantidad},
                        ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_60.ppm_neto_determinado.cantidad}},
              linea_61: {monto_perdida_art90: {cantidad:response.borrador.impuesto_renta.ppm.linea_61.monto_perdida_art90.cantidad},
                      base_imponible: {cantidad: response.borrador.impuesto_renta.ppm.linea_61.base_imponible.cantidad},
                      tasa: {cantidad:response.borrador.impuesto_renta.ppm.linea_61.tasa.cantidad},
                      credito_tope_suspension_ppm:{ cantidad: response.borrador.impuesto_renta.ppm.linea_61.credito_tope_suspension_ppm.cantidad},
                      ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_61.ppm_neto_determinado.cantidad}},
              linea_62:{ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_62.ppm_neto_determinado.cantidad}},
              linea_63: {credito_mes: {cantidad:response.borrador.impuesto_renta.ppm.linea_63.credito_mes.cantidad},
                      remanente_mes_anterior: {cantidad: response.borrador.impuesto_renta.ppm.linea_63.remanente_mes_anterior.cantidad},
                      remanente_periodo_siguiente: {cantidad:response.borrador.impuesto_renta.ppm.linea_63.remanente_periodo_siguiente.cantidad},
                      ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_63.ppm_neto_determinado.cantidad}},
              linea_64:{ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_64.ppm_neto_determinado.cantidad}},
              linea_65:{ppm_neto_determinado: {cantidad: response.borrador.impuesto_renta.ppm.linea_65.ppm_neto_determinado.cantidad}}
            }
          }
          this.borrador_f29.linea_66 = {ppm_neto_determinado: {cantidad: response.borrador.linea_66.ppm_neto_determinado.cantidad}}
          this.borrador_f29.tributacion_simplificada = {
            linea_67: {cantidad: response.borrador.tributacion_simplificada.linea_67.cantidad},
            linea_68: {cantidad: response.borrador.tributacion_simplificada.linea_68.cantidad},
            linea_69: {impuesto_determinado: {cantidad: response.borrador.tributacion_simplificada.linea_69.impuesto_determinado.cantidad}},
          }
          this.borrador_f29.impuesto_adicional = {
            linea_70: {impuesto_determinado: {cantidad: response.borrador.impuesto_adicional.linea_70.impuesto_determinado.cantidad}},
            linea_71: {impuesto_determinado: {cantidad: response.borrador.impuesto_adicional.linea_71.impuesto_determinado.cantidad}},
            linea_72: {cantidad: response.borrador.impuesto_adicional.linea_72.cantidad},
            linea_73: {cantidad: response.borrador.impuesto_adicional.linea_73.cantidad},
            linea_74: {cantidad: response.borrador.impuesto_adicional.linea_74.cantidad},
            linea_75: {cantidad: response.borrador.impuesto_adicional.linea_75.cantidad},
            linea_76: {cantidad: response.borrador.impuesto_adicional.linea_76.cantidad},
            linea_77: {cantidad: response.borrador.impuesto_adicional.linea_77.cantidad, impuesto_determinado: {cantidad:response.borrador.impuesto_adicional.linea_77.impuesto_determinado.cantidad}},
          }
          this.borrador_f29.cambio_sujeto = {
            parte_1: {
              linea_99:{cantidad: response.borrador.cambio_sujeto.parte_1.linea_99.cantidad},
              linea_100:{cantidad: response.borrador.cambio_sujeto.parte_1.linea_100.cantidad},
              linea_101:{cantidad: response.borrador.cambio_sujeto.parte_1.linea_101.cantidad},
              linea_102:{cantidad: response.borrador.cambio_sujeto.parte_1.linea_102.cantidad},
            },
            linea_103: {cantidad: response.borrador.cambio_sujeto.linea_103.cantidad, impuesto_determinado: {cantidad: response.borrador.cambio_sujeto.linea_103.impuesto_determinado.cantidad}},
            parte_2: {
              linea_104: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_104.cantidad},
              linea_105: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_105.cantidad},
              linea_106: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_106.cantidad},
              linea_107: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_107.cantidad},
              linea_108: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_108.cantidad, impuesto_determinado: {cantidad: response.borrador.cambio_sujeto.parte_2.linea_108.impuesto_determinado.cantidad}},
            }
          }
          this.borrador_f29.creditos_especiales = {
            linea_109: {cantidad: response.borrador.creditos_especiales.linea_109.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_109.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_109.impuesto_determinado.cantidad}},
            linea_110: {cantidad: response.borrador.creditos_especiales.linea_110.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_110.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_110.impuesto_determinado.cantidad}},
            linea_111: {cantidad: response.borrador.creditos_especiales.linea_111.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_111.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_111.impuesto_determinado.cantidad}},
            linea_112: {cantidad: response.borrador.creditos_especiales.linea_112.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_112.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_112.impuesto_determinado.cantidad}},
            linea_113: {cantidad: response.borrador.creditos_especiales.linea_113.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_113.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_113.impuesto_determinado.cantidad}},
            linea_114: {cantidad: response.borrador.creditos_especiales.linea_114.cantidad, remanente_mes_anterior: {cantidad: response.borrador.creditos_especiales.linea_114.remanente_mes_anterior.cantidad}, impuesto_determinado: {cantidad: response.borrador.creditos_especiales.linea_114.impuesto_determinado.cantidad}}
          }
          this.borrador_f29.linea_115 = {impuesto_determinado: {cantidad: response.borrador.linea_115.impuesto_determinado.cantidad}}
          this.borrador_f29.remanente_creditos_especiales = {
            linea_116: {cantidad: response.borrador.remanente_creditos_especiales.linea_116.cantidad},
            linea_117: {cantidad: response.borrador.remanente_creditos_especiales.linea_117.cantidad},
            linea_118: {cantidad: response.borrador.remanente_creditos_especiales.linea_118.cantidad},
            linea_119: {cantidad: response.borrador.remanente_creditos_especiales.linea_119.cantidad},
            linea_120: {cantidad: response.borrador.remanente_creditos_especiales.linea_120.cantidad},
            linea_121: {cantidad: response.borrador.remanente_creditos_especiales.linea_121.cantidad}
          }
          this.borrador_f29.linea_122 = {cantidad: response.borrador.linea_122.cantidad}
          this.borrador_f29.linea_123 = {cantidad: response.borrador.linea_123.cantidad}
          this.borrador_f29.linea_124 = {cantidad: response.borrador.linea_124.cantidad}
          this.borrador_f29.linea_125 = {cantidad: response.borrador.linea_125.cantidad}
        
        }
      }
    )
  }

  generarPDF_consolidado() {

    detectAnyAdblocker().then((detected) => {
      if(detected){
        Swal.fire({
          title: "¡Atención!",
          text: "Hemos detectado que hay instalado un adblock en el navegador. Por favor desactivelo, actualice la página, y vuelva a intentar generar el borrador.",
          icon: 'warning',
          showCancelButton: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-lg btn-danger ml-3',
            cancelButton: 'btn btn-lg btn-danger',

          },
          confirmButtonText: 'OK'
        })
      }else{
        const logoIASD = new Image();
        const marca_agua = new Image();
        logoIASD.src = 'assets/images/cover-sidebar-user1.jpg';
        marca_agua.src = 'assets/images/IASD6.png';
        const mes_periodo = new Date(this.fecha_consolidado).getMonth()
        const mes = this.meses[mes_periodo]
        const ano = new Date(this.fecha_consolidado).getFullYear()
        const footer = '© 2020 Iglesia Adventista del Séptimo Día - Unión Chilena';
        
        switch(this.tipo_consolidado){
          case "CORP":
            var titulo = 'Consolidado Corporación';
            var descripcion = 'Consolidado Corporación Periodo '+ mes + " " + ano
            var x_titulo = 64
          break
          case "IASD":
            var titulo = 'Consolidado IASD';
            var descripcion = 'Consolidado IASD Periodo '+ mes + " " + ano
            var x_titulo = 76
          break
          default:
            var titulo = 'Borrador F29';
            var descripcion = 'Borrador F29 - ' + this.entidad_sel.codigo + ' - ' + this.entidad_sel.nombre+ ' - ' + ' Periodo '+ mes + " " + ano
            var x_titulo = 85
          break
        }

        Swal.fire({
          text: "Generando borrador F29",
          allowOutsideClick: false,
          timer: 1000,
          onBeforeOpen: () => {
              Swal.showLoading()
          },
        }).then(() => {
          Swal.close()

          let doc = new jsPDF()

          doc.setFontSize(22);
          doc.text(titulo, x_titulo, 30);
          doc.setFontSize(18);
          doc.text('Periodo ' + mes + " " + ano, 108, 40, {align: 'center'});


          autoTable(doc,{
            html: '#tablas',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 7) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 22) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 24) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 50,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: {
              text: { cellWidth: 'auto' }
            },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          })

          doc.addPage()

          autoTable(doc,{
            html: '#tablas2',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0
                || data.row.index === 2
                || data.row.index === 6
                || data.row.index === 7
                || data.row.index === 14
                || data.row.index === 24) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 32) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 30,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: {
              1 : {cellWidth: 80},
              text: { cellWidth: 'auto' }
            },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          })

          doc.addPage()

          autoTable(doc, {
            html: '#tablas3',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0
                || data.row.index === 4
                || data.row.index === 16
                || data.row.index === 17
                || data.row.index === 22) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 27) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 30,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: { text: { cellWidth: 'auto' } },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          })

          doc.addPage()

          autoTable(doc,{
            html: '#tablas4',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 30,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: { text: { cellWidth: 'auto' } },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          });

          autoTable(doc, {
            html: '#tablas5',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 60,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: {
              1: {cellWidth: 80},
              text: { cellWidth: 'auto' }
            },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          })

          autoTable(doc,{
            html: '#tablas6',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0
                || data.row.index === 6) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if(data.row.index > 6 && data.row.index < 11) {
                if(data.column.index > 4){
                  data.cell.styles.fillColor = false;
                  data.cell.styles.lineWidth = 0;
                }
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 130,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: { text: { cellWidth: 'auto' } },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          });

          doc.addPage()

          autoTable(doc,{
            html: '#tablas7',
            tableWidth: 'auto',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index === 7) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 50,
            styles: { cellWidth: 'auto', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: { text: { cellWidth: 'auto' } },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          });

          autoTable(doc, {
            html: '#tablas8',
            tableWidth: 'wrap',
            didDrawPage: function (data) {
              // Header
              doc.addImage(logoIASD, 'JPG', 5, 0, 30, 20);
              // Footer
              doc.setFontSize(6);
              doc.text(descripcion, 196, 280, { align: 'right' });
              doc.setFontSize(6);
              doc.text(footer, data.settings.margin.left, 280);
              doc.addImage(marca_agua, 'PNG', 5, 40, 200, 200);
            },
            didParseCell: function (data) {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
                data.cell.styles.halign = "center"
              }
              if (data.row.index > 7) {
                data.cell.styles.fillColor = [226, 231, 235];
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
            margin: { top: 30, bottom: 30 },
            theme: 'grid',
            startY: 110,
            styles: { cellWidth: 'wrap', fontSize: 7, lineColor: COLOR_BLACK, textColor: COLOR_BLACK },
            columnStyles: {
              1: {cellWidth: 80},
              text: { cellWidth: 'wrap' }
            },
            showHead: 'firstPage',
            showFoot: 'lastPage'
          })
          this.active = 1
          var blob = doc.output("blob")
          window.open(URL.createObjectURL(blob));
          this.modalService.dismissAll()
        })
      }
    })
  }
}


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reportes_aps',
  templateUrl: './reporte_aps.component.html',
  styleUrls: ['./reporte_aps.component.css']
})
export class ReporteAPSComponent implements OnInit {
  public titulo = " APS "
  public descripcion = "Reportes"
  public icono = "fas fa-user"


  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Global } from './global';
import { LocalService } from './localService'
import { UserService } from './user.service';
import { SharingService } from './sharing.service';

@Injectable({
    providedIn: 'root'
})

export class MicrosoftAuthService {
    private url;
    private url_back;
    private accessToken: string;
    private refreshToken: string;
    private logged = new ReplaySubject<boolean>(1);
    isLogged = this.logged.asObservable();
    private loginAvailable = new BehaviorSubject<boolean>(true);


    constructor(private _http: HttpClient, private _localService: LocalService, private _userService: UserService, private _sharingService: SharingService) {
        this.url = Global.url_api_auth
        this.url_back = Global.url_auth
        // this._sharingService.sharingToken.subscribe(data => {
        //     this.token = data
        // })
        // this._sharingService.sharingRefreshToken.subscribe(data => {
        //     this.refreshToken = data
        // })
    }

    

    getTokenAPI(): Observable<any> {
        //convertir el objeto del usuario a un JSON string
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //definir las cabeceras
        return this._http.get(this.url + "get-token", {headers: headers});
    }

    getAccessToken(data): Observable<any> {
        //convertir el objeto del usuario a un JSON string
        let params = JSON.stringify({code: data})
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + "authorize-token", params, { headers: headers});
    }

    decryptToken(): Observable<any> {
        //convertir el objeto del usuario a un JSON string
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
        //definir las cabeceras
        return this._http.get(this.url_back + "decrypt-token", {headers: headers});
    }

    refreshTokenAPI(): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        let refresh_token = this.refresh
        return this._http.get(this.url + "refresh-token/" + refresh_token, { headers: headers });
    }

    logout(): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'text/html; charset=utf-8').set('Authorization', 'Bearer ' + this.token);
        return this._http.get(this.url + "logout-session/" + this.token, { headers: headers });
    }

    getPhoto(): Observable<any> {
        //convertir el objeto del usuario a un JSON string
        let headers = new HttpHeaders().set('Content-Type', 'application/blob').set('Authorization', this.token);
        //definir las cabeceras
        return this._http.get(this.url + "get-photo", {headers: headers});
    }

    getLoginAvailable(): Observable<boolean> {
        return this.loginAvailable.asObservable();
    }

    setLoginAvailable(value: boolean): void {
        this.loginAvailable.next(value);
    }

    set token(token: string) {
        this.accessToken = token;
        this._localService.setJsonValue('access_token', token);
    }

    get token(): string {
        if (!this.accessToken) {
            this.accessToken = this._localService.getJsonValue('access_token');
        }
        return this.accessToken;
    }

    set refresh(refresh: string) {
        this.refreshToken = refresh;
        this._localService.setJsonValue('refresh_token', refresh);
    }

    get refresh(): string {
        if (!this.refreshToken) {
            this.refreshToken = this._localService.getJsonValue('refresh_token');
        }
        return this.refreshToken;
    }


    clearSesion() {
        this._localService.clearToken()
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('access_token')
        // localStorage.removeItem('access_login')
    }

    checkStatus() {
        if (this.token) {
            this.logged.next(true);
        } else {
            this.logged.next(false);
        }

        return this.logged
    }


}


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Entidad } from '../models/entidad';
import { Observable } from 'rxjs/Observable';
import { Global } from './global';
import { UserService } from './user.service';
import { LocalService } from './localService';
import { SharingService } from './sharing.service';


@Injectable()

export class EntidadService{
	public entidades: Array<Entidad>;
	public url:string;
	public identity;
	public token;
	public entidad;


	constructor(
		private _http: HttpClient,
    private _userService: UserService,
    private _localService: LocalService,
	private _sharingService: SharingService

	) {
		this.url = Global.url;
		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();
		this._sharingService.sharingEntity.subscribe((data) => {
			this.entidad = data
		});
		//this.entidad = new Entidad('',0,'','', '', 0);

	}

	getEntidades(entidad):Observable<any>{

		//entidad.campo = this.identity.campo;
		let params = JSON.stringify(entidad);
		//definir las cabeceras
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//Hacer peticion ajax
		var resultado = this._http.post(this.url+'get-entidades', params, {headers:headers});
		return resultado;

	}

	getEntidad() {
		try {
			return this.entidad;
		} catch (error) {
			console.error("Error al obtener la entidad:", error);
			throw error;
		}
		
	}

	cambiarEntidadSel(entidad):Observable<any>{

		//entidad.campo = this.identity.campo;
		let params = JSON.stringify(entidad);
		//definir las cabeceras
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//Hacer peticion ajax
		var resultado = this._http.post(this.url+'cambiar_entidad_sel', params, {headers:headers});
		return resultado;

	}

	getEntidadSel():Observable<any>{

		//entidad.campo = this.identity.campo;
		let params = [];
		//return entidad;
		//definir las cabeceras
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//Hacer peticion ajax
		var resultado = this._http.post(this.url+'trae_entidad_sel', params, {headers:headers});
		return resultado;

	}


}

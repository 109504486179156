<ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="nabTab" (navChange)="onNavChange($event)">
    <li ngbNavItem [ngbNavItem]="'variables'">
        <a ngbNavLink (click)="tipo_presupuesto('variables')">Variables</a>
        <ng-template ngbNavContent>
            <!-- Botones de navegación -->
            <div class="mb-3">
                <div class="btn-group">
                    <button class="btn btn-white" [class.active]="tipo_pres =='diversos' || tipo_pestana == 'variables'"
                        (click)="tipo_presupuesto('diversos')">Diversos</button>
                    <button class="btn btn-white" [class.active]="tipo_pres =='prima_infantil'"
                        (click)="tipo_presupuesto('prima_infantil')">Prima Infantil</button>
                </div>
            </div>
            <!-- Fin botones de navegación -->
            <!--Comienzo Diversos-->
            <div *ngIf="tipo_pres == 'diversos' || tipo_pestana == 'variables'">
                <div class="d-flex align-content-center justify-content-between mb-3 mt-2">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por diverso, nombre..."
                            (keyup)="updateFilter($event, 'diverso')" />
                    </div>
                    <div>
                        <a id='abrir_modal_diverso' class="btn btn-success text-white" (click)="open(modal_diverso)">
                            Agregar Diverso
                        </a>
                    </div>
                </div>
                <ng-container outsideBody>
                    <hr class="m-0" />
                    <div class="table-responsive text-nowrap table-data">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Diverso</th>
                                    <th>Nombre Funcionario</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="lista_diverso.length">
                                <tr *ngFor="let d of lista_diverso; let indice = index">
                                    <td [ngClass]="{'table-danger':d.estado == 'inactive'}">{{d.diverso}}</td>
                                    <td [ngClass]="{'table-danger':d.estado == 'inactive'}">{{d.nombre}}</td>
                                    <td class="with-btn-group" [ngClass]="{'table-danger':d.estado == 'inactive'}">
                                        <div class="d-flex justify-content-start">
                                            <button type="button" *ngIf="d.estado == 'active'"
                                                (click)='modificar_diverso_modal(d._id, d.diverso, d.nombre, d.tipo_sueldo, modal_modificar_diverso)'
                                                class="btn btn-warning btn-xs m-r-5 " placement="top"
                                                ngbTooltip="Editar"><i class="fas fa-edit"></i></button>
                                            <button type="button" *ngIf="d.estado == 'active'"
                                                (click)='modificar_estado_diverso(d._id,"inactive")'
                                                class="btn btn-danger btn-xs m-r-5" placement="top"
                                                ngbTooltip="Desactivar"><i class="fas fa-ban"></i></button>
                                            <button type="button" *ngIf="d.estado == 'inactive'"
                                                (click)='modificar_estado_diverso(d._id,"active")'
                                                class="btn btn-green btn-xs m-r-5" placement="top"
                                                ngbTooltip="Activar"><i class="fas fa-check"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="lista_diverso.length">
                                <tr>
                                    <th colspan="3">{{lista_diverso.length}} en Total</th>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="m-l-10 mb-2" *ngIf="!lista_diverso.length">
                            <em>No se han encontrado datos para mostrar</em>
                        </div>
                    </div>
                </ng-container>
                <!-- Modal diverso -->
                <ng-template #modal_diverso let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Agregar Diverso</h5>
                        <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                            data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="panel-body panel-form">
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">N° Diverso</label>
                                <div class="col-md-8">
                                    <input type="text" [class.is-invalid]="!diverso.valid && diverso.touched "
                                        #diverso="ngModel" name="diverso" [(ngModel)]="diversos.diverso"
                                        class="form-control" required />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nombre Funcionario</label>
                                <div class="col-md-8">
                                    <input type="text" [class.is-invalid]="!nombre.valid && nombre.touched "
                                        #nombre="ngModel" name="nombre" [(ngModel)]="diversos.nombre"
                                        class="form-control" required />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Tipo Diverso</label>
                                <div class="col-md-8">
                                    <select class="form-control" [(ngModel)]="diversos.tipo_diverso">
                                        <option disabled value="" selected="">Seleccione un tipo de diverso
                                        </option>
                                        <option *ngFor="let tp of tipo_diverso">{{tp}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" (click)="close('modal_diverso')">Cerrar</button>
                        <button type="button" (click)="agregar_diverso(modal_diverso)"
                            class="btn btn-primary">Guardar</button>
                    </div>
                </ng-template>
                <!-- Fin Modal Diverso-->
                <!-- Modal diverso -->
                <ng-template #modal_modificar_diverso let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Modificar Diverso</h5>
                        <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                            data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="panel-body panel-form">
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">N° Diverso</label>
                                <div class="col-md-8">
                                    <input type="text" [class.is-invalid]="!diverso.valid && diverso.touched "
                                        #diverso="ngModel" name="diverso" [(ngModel)]="diversos.diverso"
                                        class="form-control" required />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nombre Funcionario</label>
                                <div class="col-md-8">
                                    <input type="text" [class.is-invalid]="!nombre.valid && nombre.touched "
                                        #nombre="ngModel" name="nombre" [(ngModel)]="diversos.nombre"
                                        class="form-control" required />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Tipo Diverso</label>
                                <div class="col-md-8">
                                    <select class="form-control" [(ngModel)]="diversos.tipo_diverso">
                                        <option disabled value="" selected="">Seleccione un tipo de diverso
                                        </option>
                                        <option *ngFor="let tp of tipo_diverso">{{tp}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            (click)="close('modal_modificar_diverso')">Cerrar</button>
                        <button type="button" class="btn btn-primary"
                            (click)="modificar_diverso(modal_modificar_diverso)">Guardar</button>
                    </div>
                </ng-template>
                <!-- Fin Modal Diverso-->
                <div class=" row m-t-10 m-b-10">
                    <label class="col-md-4 ">&nbsp;&nbsp;&nbsp;</label>
                    <div class="col-md-2 d-flex justify-content-center">
                        <button type="submit" class="btn btn-primary"
                            (click)="tipo_presupuesto('prima_infantil')">Siguiente
                            <i class="fas fa-arrow-right fa-fw"></i></button>
                    </div>
                </div>
            </div>
            <!--Fin Diversos-->
            <!--Comienzo Prima-->
            <div *ngIf="tipo_pres == 'prima_infantil'">
                <div class="d-flex align-content-center justify-content-between mb-3 mt-2">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por diverso, nombre..."
                            (keyup)="updateFilter($event, 'prima')" />
                    </div>
                    <div>
                        <a class="btn btn-success text-white" (click)="actualizarPrimaInfantil()">
                            Actualizar Prima Infantil
                        </a>
                        <a id="abrir_modal_prima_infantil" class="btn btn-success text-white m-l-10"
                            (click)="open(modal_prima_infantil)">
                            Agregar Prima Infantil
                        </a>
                    </div>
                </div>
                <ng-container outsideBody>
                    <hr class="m-0" />
                    <div class="table-responsive text-nowrap table-data">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>N° Diverso</th>
                                    <th>Nombre Funcionario</th>
                                    <th>Nombre Hijo</th>
                                    <th>Edad</th>
                                    <th>Prima Calculada</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="prim_inf.length">
                                <tr *ngFor="let pi of prim_inf; let indice = index">
                                    <td>{{pi.diverso}}</td>
                                    <td>{{pi.nombre_func}}</td>
                                    <td>{{pi.nombre_hijo}}</td>
                                    <td>{{pi.edad}}</td>
                                    <td>${{pi.prima_calculada | number: 0}}</td>
                                    <td class="with-btn-group">
                                        <div class="d-flex justify-content-start">
                                            <button class="btn btn-xs btn-danger" placement="top" ngbTooltip="Eliminar"
                                                (click)='delete_sueldo(pi._id ,"prima_infantil")'><i
                                                    class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="prim_inf.length">
                                <tr>
                                    <th colspan="8">{{prim_inf.length}} en Total</th>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="m-l-10 mb-2" *ngIf="!prim_inf.length">
                            <em>No se han encontrado datos para mostrar</em>
                        </div>
                    </div>
                </ng-container>
                <!-- Modal diverso -->
                <ng-template #modal_prima_infantil let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Agregar Hijo Funcionario</h5>
                        <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                            data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="panel-body panel-form">
                            <form class="form-horizontal form-bordered">
                                <div class="form-group row">
                                    <label class="col-md-4 col-form-label">N° Diverso</label>
                                    <div class="col-md-8">
                                        <input type="text" [class.is-invalid]="!diverso.valid && diverso.touched "
                                            #diverso="ngModel" name="diverso" [(ngModel)]="prima_infantil.diverso"
                                            class="form-control" required />
                                        <div class="invalid-feedback d-block" *ngIf="!diverso.valid && diverso.touched">
                                            Campo
                                            Requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-4 col-form-label">Nombre Hijo</label>
                                    <div class="col-md-8">
                                        <input type="text"
                                            [class.is-invalid]="!nombre_hijo.valid && nombre_hijo.touched "
                                            #nombre_hijo="ngModel" name="nombre_hijo"
                                            [(ngModel)]="prima_infantil.nombre_hijo" class="form-control" required />
                                        <div class="invalid-feedback d-block"
                                            *ngIf="!nombre_hijo.valid && nombre_hijo.touched">
                                            Campo
                                            Requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-4 col-form-label">Fecha de Nacimiento</label>
                                    <div class="col-md-8">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="yyyy-mm-dd" name="fecha_nacimiento"
                                                #c2="ngModel" [(ngModel)]="prima_infantil.fecha_nacimiento"
                                                ngbDatepicker #fecha_nacimiento="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary"
                                                    (click)="fecha_nacimiento.toggle()" type="button">
                                                    <i class="fa fa-calendar"></i>
                                                </button>
                                            </div>
                                            <div class="invalid-feedback d-block"
                                                *ngIf="!fecha_nacimiento.valid && fecha_nacimiento.touched">Campo
                                                Requerido
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            (click)="close(modal_prima_infantil)">Cerrar</button>
                        <button type="button" (click)="agregar_prima_infantil(modal_prima_infantil)"
                            class="btn btn-primary">Guardar</button>
                    </div>
                </ng-template>
                <!-- Fin Modal Diverso-->
                <div class=" row m-t-10 m-b-10">
                    <label class="col-md-4 ">&nbsp;&nbsp;&nbsp;</label>
                    <div class="col-md-2 d-flex justify-content-center">
                        <button type="submit" class="btn btn-primary" (click)="siguiente('variables')">Siguiente <i
                                class="fas fa-arrow-right fa-fw"></i></button>
                    </div>
                </div>

            </div>
            <!--Fin Prima Infantil-->
        </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="'cuentas'">
        <a ngbNavLink (click)="tipo_presupuesto('cuentas')">Cuentas</a>
        <ng-template ngbNavContent>
            <div class="btn-group">
                <button id="tabSueldo" class="btn btn-white"
                    [class.active]="tipo_pres =='tab_sueldos' || tipo_pestana == 'cuentas'"
                    (click)="tipo_presupuesto('tab_sueldos')">Tabulación Sueldos</button>
                <button class="btn btn-white" [class.active]="tipo_pres =='viajes'"
                    (click)="tipo_presupuesto('viajes')">Viajes</button>
                <button class="btn btn-white" [class.active]="tipo_pres =='sueldos' "
                    (click)="tipo_presupuesto('sueldos')">Sueldos (411)</button>
                <button class="btn btn-white" [class.active]="tipo_pres =='gastos_operativos'"
                    (click)="tipo_presupuesto('gastos_operativos')">Gastos Operativos (412)</button>
                <button class="btn btn-white" [class.active]="tipo_pres =='reuniones_eventos'"
                    (click)="tipo_presupuesto('reuniones_eventos')">Gastos Reuniones y Eventos (414)</button>
                <button class="btn btn-white" [class.active]="tipo_pres =='otorgamientos'"
                    (click)="tipo_presupuesto('otorgamientos')">Otorgamientos (419)</button>
                <button class="btn btn-white" [class.active]="tipo_pres =='ingresos'"
                    (click)="tipo_presupuesto('ingresos')">Ingresos</button>
            </div>
            <!--Comienzo tablas-->
            <!--Comienzo Tablas-->
            <div class="" *ngIf="tipo_pres == 'tab_sueldos' || tipo_pestana == 'cuentas'" id="sueldos">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Sueldos Dependientes</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por diverso, nombre..."
                            (keyup)="updateFilter($event, 'sueldosdep')" />
                    </div>
                    <div>
                        <a href="javascript:;" class="btn btn-success text-white m-r-10"
                            (click)="open(modal_agregar_cuenta_dep)">Agregar
                            Cuenta
                        </a>
                        <a href="javascript:;" class="btn btn-success text-white"
                            (click)="open(modal_configurar_sueldos)"><i class="fas fa-cog"></i> Configurar Sueldos
                        </a>
                    </div>
                </div>
                <div class="">
                    <!-- begin table-responsive -->
                    <hr class="m-0" />
                    <div class="table-responsive table-sueldos">
                        <!-- <div class=""> -->
                        <table class="table">
                            <thead>
                                <tr class="text-nowrap">
                                    <th>Diverso</th>
                                    <th>Nombre Empleado</th>
                                    <th>Departamento</th>
                                    <th>Nombre Departamento</th>
                                    <th class="w-100">% FMR</th>
                                    <th class="text-center">
                                        <div>12 Sueldos</div>
                                        <div>(4111001)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>13° Sueldo</div>
                                        <div>(4111004)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Prima Infantil</div>
                                        <div>(4111008)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Ayuda Salud</div>
                                        <div>(4111036)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Ayuda Medica</div>
                                        <div>(4113025)</div>
                                    </th>
                                    <th class="text-center">APV</th>
                                    <th class="text-center">
                                        <div>Otros Imponibles</div>
                                        <div>(4111027)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Calefacción</div>
                                        <div>(4111031)</div>
                                    </th>
                                    <th class="text-center">Alquiler</th>
                                    <th class="text-center">13° Alquiler</th>
                                    <th class="text-center">
                                        <div>Alquiler Total</div>
                                        <div>(4111034)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Vale Alimentación</div>
                                        <div>(4111065)</div>
                                    </th>
                                    <th class="text-center zindex-first">
                                        <div>Gastos Viajes
                                            <span class="ms-2">
                                                <i class="fa fa-info-circle" triggers="mouseenter:mouseleave"
                                                    placement="bottom"
                                                    ngbPopover="Reúne los ítems de 13° kilometraje, tag, maleta, viajes urbanos y otros gastos de viajes"></i>
                                            </span>
                                        </div>
                                        <div>(4111042)</div>
                                    </th>
                                    <th class="text-center zindex-second">
                                        <div>Reembolsos Gastos Viajes
                                            <span class="ms-2">
                                                <i class="fa fa-info-circle" triggers="mouseenter:mouseleave"
                                                    placement="bottom"
                                                    ngbPopover="Reúne los ítems de total hotel, viatico, viajes regionales, viajes internacionales y otros reembolsos de viajes"></i>
                                            </span>
                                        </div>
                                        <div>(4113030)</div>
                                    </th>
                                    <th class="text-center">Asig. Mensual Telefono</th>
                                    <th class="text-center">
                                        <div>Telefono</div>
                                        <div>(4111047)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Beca Escolar</div>
                                        <div>(4111050)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Beca Universitaria</div>
                                        <div>(4111099)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Estudios Superiores</div>
                                        <div>(4111045)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>PPG</div>
                                        <div>(4112025)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Equipos Smartphone</div>
                                        <div>(4113010)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Agua</div>
                                        <div>(4111033)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Aporte Patronal</div>
                                        <div>(4112030)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Seguro de Vida</div>
                                        <div>(4114035)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Seguro de Viajes</div>
                                        <div>(4114037)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Seguro de Equipo</div>
                                        <div>(4114038)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Seguro Efectos</div>
                                        <div>(4114039)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Seguro de Salud Compl.</div>
                                        <div>(4114010)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>FPV</div>
                                        <div>(4114020)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Reembolso Mudanza</div>
                                        <div>(4111032)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Provisión Finiquito</div>
                                        <div>(4111021)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Servicio Limpieza</div>
                                        <div>(4119020)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Bono Día Especial</div>
                                        <div>(4111608)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Aguinaldo de Fiestas</div>
                                        <div>(4111616)</div>
                                    </th>
                                    <th class="text-center" *ngFor="let c_e_dep of columna_cuentas_extras_dep">
                                        <div>{{ c_e_dep.nombre_cuenta }}</div>
                                        <div>{{ c_e_dep.cuenta_contable }}</div>
                                    </th>
                                    <th class="text-center">Totales</th>
                                    <th class="text-center">
                                        <div>Reducción Persona</div>
                                        <div>(4119514)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Capacitación</div>
                                        <div>(4114003)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Cred. Campos UCh</div>
                                        <div>(4119526)</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="text-nowrap" *ngIf="sueldo_dependiente.length">
                                <tr class="align-middle" *ngFor="let sd of sueldo_dependiente">
                                    <td class="bg-light align-middle">{{sd.diverso}}
                                    </td>
                                    <td class="bg-light align-middle text-nowrap">{{sd.nombre}}</td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="sd.codigo_departamento" #codigo_departamento_depInput
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,codigo_departamento_depInput.value,"Codigo Departamento","","dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,codigo_departamento_depInput.value,"Codigo Departamento","","dependiente")'>
                                    </td>
                                    <td class="bg-light align-middle text-nowrap">{{sd.nombre_departamento}}</td>
                                    <td class="align-middle ">
                                        <input class="form-control form-control-sm" type="text" [value]="sd.fmr_per"
                                            #fmr_per_depInput
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,fmr_per_depInput.value,"% FMR","","dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,fmr_per_depInput.value,"% FMR","","dependiente")'>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per != 0">
                                        <input *ngIf="sd.fmr_per == 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[0].valor | thousandsPipe"
                                            #sueldo_12_depInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,sd_depInput.value,"12 Sueldos",0,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,sueldo_12_depInput.value,"12 Sueldos",0,"dependiente")'>
                                        <div *ngIf="sd.fmr_per != 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[0].valor | number:0 }}
                                        </div>
                                    </td>
                                    <td class="bg-light align-middle">
                                        <div class="d-inline m-r-20">
                                            ${{sd.cuentas[1].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{sd.cuentas[2].valor |number:0 }}
                                    </td>
                                    <td class="bg-light align-middle">
                                        <div class="d-inline m-r-20">
                                            ${{sd.cuentas[3].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[4].valor | thousandsPipe" #ayud_medica_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,ayud_medica_depInput.value,"Ayuda Médica",4,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,ayud_medica_depInput.value,"Ayuda Médica",4,"dependiente")'>
                                    </td>
                                    <!-- <td style="width: 170px;" class="bg-light align-middle">
                                        ${{sd.cuentas[3].valor |number:0 }}</td> -->
                                    <td class="bg-light align-middle">{{sd.apv}}
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm"
                                            (keyup)="separador_miles($event)" type="text"
                                            [value]="sd.cuentas[5].valor | thousandsPipe" #remun_depInput
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,remun_depInput.value,"Remuneración",5,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,remun_depInput.value,"Remuneración",5,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[5].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[6].valor | thousandsPipe" #calefa_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,calefa_depInput.value,"Calefación",6,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,calefa_depInput.value,"Calefación",6,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[6].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.alquiler | thousandsPipe" #alquiler_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,alquiler_depInput.value,"Alquiler","","dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,alquiler_depInput.value,"Alquiler","","dependiente")'>
                                        <!-- <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.alquiler |number:0 }}
                                        </div> -->
                                    </td>
                                    <td class="align-middle bg-light">${{sd.alquiler_13 |number:0 }}</td>
                                    <td class="align-middle bg-light">${{sd.cuentas[7].valor |number:0 }}</td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[8].valor | thousandsPipe" #vale_aliment_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,vale_aliment_depInput.value,"Vale Alimentación",8,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,vale_aliment_depInput.value,"Vale Alimentación",8,"dependiente")'>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{sd.cuentas[9].valor |number:0}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{sd.cuentas[10].valor |number:0}}
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.pt_telefono" #pt_telefono_depInput
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,pt_telefono_depInput.value,"Puntos Telefono","","dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,pt_telefono_depInput.value,"Puntos Telefono","","dependiente")'>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{sd.cuentas[11].valor |number:0}}
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[12].valor | thousandsPipe"
                                            #beca_esco_depInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,beca_esco_depInput.value,"Beca Escolar",12,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,beca_esco_depInput.value,"Beca Escolar",12,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[12].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[13].valor | thousandsPipe" #beca_univ_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,beca_univ_depInput.value,"Beca Universitaria",13,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,beca_univ_depInput.value,"Beca Universitaria",13,"dependiente")'>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[14].valor | thousandsPipe"
                                            #est_super_depInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,est_super_depInput.value,"Estudios Superiores",14,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,est_super_depInput.value,"Estudios Superiores",14,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[14].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{sd.cuentas[15].valor |number:0 }}
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[16].valor | thousandsPipe"
                                            #eq_smart_prof_depInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,eq_smart_prof_depInput.value,"Equipos Profesores",16,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,eq_smart_prof_depInput.value,"Equipos Profesores",16,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[16].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[17].valor | thousandsPipe" #agua_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,agua_depInput.value,"Agua",17,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,agua_depInput.value,"Agua",17,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[17].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{sd.cuentas[18].valor |number:0}}
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[19].valor | thousandsPipe" #seguro_vida_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,seguro_vida_depInput.value,"Seguro de Vida",19,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,seguro_vida_depInput.value,"Seguro de Vida",19,"dependiente")'>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[20].valor | thousandsPipe"
                                            #seguro_viajes_depInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,seguro_viajes_depInput.value,"Seguro de Viajes",20,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,seguro_viajes_depInput.value,"Seguro de Viajes",20,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[20].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[21].valor | thousandsPipe"
                                            #seguro_equipo_depInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,seguro_equipo_depInput.value,"Seguro de Equipo",21,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,seguro_equipo_depInput.value,"Seguro de Equipo",21,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[21].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[22].valor | thousandsPipe"
                                            #seguro_efectos_depInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,seguro_efectos_depInput.value,"Seguro de Efectos",22,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,seguro_efectos_depInput.value,"Seguro de Efectos",22,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[22].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[23].valor | thousandsPipe"
                                            #seguro_salud_depInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,seguro_salud_depInput.value,"Seguro de Salud",23,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,seguro_salud_depInput.value,"Seguro de Salud",23,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[23].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[24].valor | thousandsPipe" #fpv_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,fpv_depInput.value,"FPV",24,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,fpv_depInput.value,"FPV",24,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[24].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="sd.fmr_per == 0">
                                        <input *ngIf="sd.fmr_per != 0" class="form-control form-control-sm mw-inp-150"
                                            type="text" [value]="sd.cuentas[25].valor | thousandsPipe"
                                            #remb_mudanza_depInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,remb_mudanza_depInput.value,"Reembolso Mudanza",25,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,remb_mudanza_depInput.value,"Reembolso Mudanza",25,"dependiente")'>
                                        <div *ngIf="sd.fmr_per == 0" class="d-inline m-r-20">
                                            ${{sd.cuentas[25].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[26].valor | thousandsPipe" #prov_final_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,prov_final_depInput.value,"Provisión Final",26,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,prov_final_depInput.value,"Provisión Final",26,"dependiente")'>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[27].valor | thousandsPipe" #serv_limp_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,serv_limp_depInput.value,"Servicio Limpieza",27,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,serv_limp_depInput.value,"Servicio Limpieza",27,"dependiente")'>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[28].valor | thousandsPipe" #bono_dia_esp_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,bono_dia_esp_depInput.value,"Bono Día Especial",28,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,bono_dia_esp_depInput.value,"Bono Día Especial",28,"dependiente")'>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[29].valor | thousandsPipe" #aguinaldo_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,aguinaldo_depInput.value,"Aguinaldo",29,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,aguinaldo_depInput.value,"Aguinaldo",29,"dependiente")'>
                                    </td>
                                    <td *ngFor="let c_e_dep of sd.cuentas_extras; let i=index" class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="c_e_dep.valor | thousandsPipe" #valor
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,valor.value,"Cuentas Extras",i,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,valor.value,"Cuentas Extras",i,"dependiente")'>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{sd.total |number:0}}
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[30].valor | thousandsPipe" #red_pers_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,red_pers_depInput.value,"Reducción de Personal",30,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,red_pers_depInput.value,"Reducción de Personal",30,"dependiente")'>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[31]!.valor | thousandsPipe" #capacitacion_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,capacitacion_depInput.value,"Capacitación",31,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,capacitacion_depInput.value,"Capacitación",31,"dependiente")'>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-150" type="text"
                                            [value]="sd.cuentas[32]!.valor | thousandsPipe" #ced_campos_uch_depInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(sd._id,ced_campos_uch_depInput.value,"Cred. Campos UCh",32,"dependiente")'
                                            (blur)='modal_modificar_sueldo_dep(sd._id,ced_campos_uch_depInput.value,"Cred. Campos UCh",32,"dependiente")'>
                                    </td>
                                </tr>

                            </tbody>
                            <tfoot *ngIf="sueldo_dependiente.length">
                                <tr>
                                    <th></th>
                                    <th>Sub Totales</th>
                                    <th>{{sub_totales_sueldo_dep.codigo_departamento }}</th>
                                    <th>{{sub_totales_sueldo_dep.nombre_departamento }}</th>
                                    <th>{{sub_totales_sueldo_dep.fmr_per}}</th>
                                    <th>${{sub_total_cuentas_dep[0] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[1] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[2] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[3] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[4] | number:0}}</th>
                                    <th>{{sub_totales_sueldo_dep.apv}}</th>
                                    <th>${{sub_total_cuentas_dep[5] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[6] | number:0}}</th>
                                    <th>${{sub_totales_sueldo_dep.alquiler | number:0}}</th>
                                    <th>${{sub_totales_sueldo_dep.alquiler_13 | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[7] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[8] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[9] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[10] | number:0}}</th>
                                    <th>{{sub_totales_sueldo_dep.pt_telefono}}</th>
                                    <th>${{sub_total_cuentas_dep[11] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[12] | number:0}}</th>
                                    <!-- <th>${{sub_total_cuentas_dep[10] | number:0}}</th> -->
                                    <th>${{sub_total_cuentas_dep[13] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[14] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[15] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[16] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[17] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[18] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[19] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[20] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[21] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[22] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[23] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[24] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[25] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[26] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[27] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[28] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[29] | number:0}}</th>
                                    <th *ngFor="let sub_c_e_dep of sub_total_cuentas_extras_dep">
                                        ${{ sub_c_e_dep | number:0}}
                                    </th>
                                    <th>${{sub_totales_sueldo_dep.total | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[30] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[31] | number:0}}</th>
                                    <th>${{sub_total_cuentas_dep[32] | number:0}}</th>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="m-l-10 mb-3 mt-3" *ngIf="!sueldo_dependiente.length">
                            <em>No se han encontrado datos para mostrar</em>
                        </div>

                        <!-- </div> -->
                    </div>
                    <!-- end table-responsive -->
                    <!-- #modal Modificar Sueldo Dependiente -->
                    <!-- <div class="modal fade" id="modal-agregar-cuenta-dep">
                            <div class="modal-dialog ">
                                <div class="modal-content"> -->
                    <ng-template #modal_agregar_cuenta_dep let-c="close" let-d="dismiss" let-modal>
                        <div class="modal-header">
                            <h4 class="modal-title">Agregar Cuenta</h4>
                            <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                                data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="panel-body panel-form">
                            <form class="form-horizontal form-bordered">
                                <div class="form-group row">
                                    <label class="col-md-4 col-form-label">N° de la Cuenta</label>
                                    <div class="col-md-8">
                                        <input class="form-control" type="text" #n_cuenta #valor="ngModel"
                                            name="numero_cuenta" [(ngModel)]="genera_cuentas_extras.numero_cuenta"
                                            (blur)='get_nombre_cuenta_extra(n_cuenta.value)' />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-4 col-form-label">Nombre de la Cuenta</label>
                                    <div class="col-md-8">
                                        <input class="form-control" value="genera_cuentas_extras.nombre_cuenta"
                                            type="text" #valor="ngModel" name="nombre_cuenta"
                                            [(ngModel)]="genera_cuentas_extras.nombre_cuenta" />
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div class="modal-footer">
                            <a href="javascript:;" class="btn btn-white" (click)="c('Close click')">Cerrar</a>
                            <a href="javascript:;"
                                (click)="agregar_cuenta_extra('Dependiente', modal_agregar_cuenta_dep)"
                                class="btn btn-success">Guardar</a>
                        </div>
                    </ng-template>
                    <ng-template #modal_configurar_sueldos let-c="close" let-d="dismiss" let-modal>
                        <div class="modal-header">
                            <h4 class="modal-title">Configuración de Sueldos</h4>
                            <button (click)="d('Cross click')" type="button" class="close btn btn-white btn-sm"
                                data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="panel-body panel-form">
                            <form class="form-horizontal form-bordered">
                                <div class="form-group row">
                                    <label class="col-md-7 col-form-label">Aporte patronal dependiente</label>
                                    <div class="col-md-5">
                                        <input class="form-control" type="text" #n_cuenta #valor="ngModel"
                                            name="numero_cuenta" [(ngModel)]="configuracionSueldos.aportPatroDep" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-7 col-form-label">Aporte patronal independiente</label>
                                    <div class="col-md-5">
                                        <input class="form-control" type="text" #valor="ngModel" name="nombre_cuenta"
                                            [(ngModel)]="configuracionSueldos.aportPatroInd" />
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div class="modal-footer">
                            <a href="javascript:;" class="btn btn-white" (click)="c('Close click')">Cerrar</a>
                            <a href="javascript:;" (click)="modificarConfigSueldos()"
                                class="btn btn-success">Guardar</a>
                        </div>
                    </ng-template>
                    <!-- Fin #modal Modificar Sueldo Dependiente -->
                </div>
            </div>
            <!--Fin Tablas-->
            <!-- end panel -->
            <!--Comienzo Tablas-->
            <div *ngIf="tipo_pres == 'tab_sueldos' || tipo_pestana == 'cuentas'" id="sueldos">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Sueldos Independientes</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por diverso, nombre..."
                            (keyup)="updateFilter($event, 'sueldosind')" />
                    </div>
                    <div>
                        <a id="modal_agregar_cuenta_ind" class="btn btn-success text-white"
                            (click)="open(modal_agregar_cuenta_ind)">Agregar
                            Cuenta</a>
                    </div>
                </div>
                <div class="">
                    <!-- begin table-responsive -->
                    <hr class="m-0" />
                    <div class="table-responsive table-sueldos">
                        <!-- <div class="scrollable"> -->
                        <table class="table">
                            <thead>
                                <tr class="text-nowrap">
                                    <th>Diverso</th>
                                    <th>Nombre Empleado</th>
                                    <th>Departamento</th>
                                    <th>Nombre Departamento</th>
                                    <th class="text-center"> % FMR</th>
                                    <th class="text-center">
                                        <div>12 Sueldos</div>
                                        <div>(4115001)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>13° Sueldo</div>
                                        <div>(4115003)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Prima Infantil</div>
                                        <div>(4115008)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Ayuda Salud</div>
                                        <div>(4115036)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Ayuda Medica</div>
                                        <div>(4117025)</div>
                                    </th>
                                    <!-- <th class="text-center">
                                        <div>Ayuda Salud y Medica</div>
                                        <div>(4117025)</div>
                                    </th> -->
                                    <th class="text-center">PDR</th>
                                    <th class="text-center">
                                        <div>PDR</div>
                                        <div>(4118002)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Calefacción</div>
                                        <div>(4115031)</div>
                                    </th>
                                    <th>Alquiler</th>
                                    <th>13° Alquiler</th>
                                    <th class="text-center">
                                        <div>Alquiler Total</div>
                                        <div>(4115034)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Vale Alimentación</div>
                                        <div>(4115065)</div>
                                    </th>
                                    <th class="text-center zindex-first">
                                        <div>Gastos Viaje
                                            <span class="ms-2 zindex-top">
                                                <i class="fa fa-info-circle" triggers="mouseenter:mouseleave"
                                                    placement="bottom"
                                                    ngbPopover="Reúne los ítems de 13° kilometraje, tag, maleta, viajes urbanos y otros gastos de viajes"></i>
                                            </span>
                                        </div>
                                        <div>(4115042)</div>
                                    </th>
                                    <th class="text-center zindex-second">
                                        <div>Reembolso Gastos Viaje
                                            <span class="ms-2 zindex-top">
                                                <i class="fa fa-info-circle" triggers="mouseenter:mouseleave"
                                                    placement="bottom"
                                                    ngbPopover="Reúne los ítems de total hotel, viatico, viajes regionales, viajes internacionales y otros reembolsos de viajes"></i>
                                            </span>
                                        </div>
                                        <div>(4117030)</div>
                                    </th>
                                    <th class="text-center">Asig. Mensual Telefono</th>
                                    <th class="text-center">
                                        <div>Telefono</div>
                                        <div>(4115047)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Beca Escolar</div>
                                        <div>(4115050)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Beca Universitaria</div>
                                        <div>(4118099)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Estudios Superiores</div>
                                        <div>(4115045)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>PPG</div>
                                        <div>(4116015)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Equipos Smartphone</div>
                                        <div>(4117010)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Agua</div>
                                        <div>(4115033)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Aporte Patronal</div>
                                        <div>(4116031)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Seguro de Vida</div>
                                        <div>(4118035)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Seguro de Viajes</div>
                                        <div>(4118037)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Seguro de Equipo</div>
                                        <div>(4118038)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Seguro Efectos</div>
                                        <div>(4118039)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Seguro de Salud Compl.</div>
                                        <div>(4118010)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>FPV</div>
                                        <div>(4118020)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Reembolso Mudanza</div>
                                        <div>(4117015)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Provisión Finiquito</div>
                                        <div>(4115021)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Bono Día Especial</div>
                                        <div>(4118060)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Aguinaldo de Fiestas</div>
                                        <div>(4118061)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Diferencia Impusto Renta</div>
                                        <div>(4115035)</div>
                                    </th>
                                    <th class="text-center" *ngFor="let c_e_ind of columna_cuentas_extras_ind">
                                        <div>{{ c_e_ind.nombre_cuenta }}</div>
                                        <div>({{c_e_ind.cuenta_contable}})</div>
                                    </th>
                                    <th class="text-center">Totales</th>
                                    <th class="text-center">
                                        <div>Reducción Personal</div>
                                        <div>(4119514)</div>
                                    </th>
                                    <th class="text-center">
                                        <div>Cred. Campos UCh</div>
                                        <div>(4119526)</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="text-nowrap" *ngIf="sueldo_independiente.length">
                                <tr *ngFor="let si of sueldo_independiente">
                                    <td class="bg-light align-middle">{{si.diverso}}
                                    </td>
                                    <td class=" bg-light align-middle">{{si.nombre}}</td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="si.codigo_departamento" #codigo_departamento_indInput
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,codigo_departamento_indInput.value,"Codigo Departamento","","independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,codigo_departamento_indInput.value,"Codigo Departamento","","independiente")'>
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{si.nombre_departamento}}</td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm" type="text" [value]="si.fmr_per"
                                            #fmr_per_indInput
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,fmr_per_indInput.value,"% FMR","","independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,fmr_per_indInput.value,"% FMR","","independiente")'>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per != 0">
                                        <input *ngIf="si.fmr_per == 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[0].valor | thousandsPipe"
                                            #sueldo_12_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,sueldo_12_indInput.value,"12 Sueldos",0,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,sueldo_12_indInput.value,"12 Sueldos",0,"independiente")'>
                                        <div *ngIf="si.fmr_per != 0" class="d-inline m-r-20">
                                            ${{si.cuentas[0].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{si.cuentas[1].valor |number:0 }}</td>
                                    <td class="bg-light align-middle">
                                        ${{si.cuentas[2].valor |number:0 }}</td>
                                    <td class="bg-light align-middle">
                                        ${{si.cuentas[3].valor |number:0 }}</td>
                                    <td>
                                        <input class="form-control form-control-sm mw-inp-100" type="text"
                                            [value]="si.cuentas[4].valor | thousandsPipe" #ayud_medica_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,ayud_medica_indInput.value,"Ayuda Médica",4,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,ayud_medica_indInput.value,"Ayuda Médica",4,"independiente")'>
                                    </td>
                                    <!-- <td class="bg-light align-middle">
                                        ${{si.cuentas[3].valor |number:0 }}</td> -->
                                    <td class="bg-light align-middle">
                                        <select class="form-control form-control-sm" #apv_indInput
                                            (keyup)="separador_miles($event)"
                                            (blur)='modal_modificar_sueldo_dep(si._id,apv_indInput.value,"APV","","independiente")'>
                                            <option class="hide" value="">{{si.apv}}</option>
                                            <option value="1">Si</option>
                                            <option value="0">No</option>
                                        </select>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{si.cuentas[5].valor |number:0}}</td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[6].valor | thousandsPipe" #calefa_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,calefa_indInput.value,"Calefación",6,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,calefa_indInput.value,"Calefación",6,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[6].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.alquiler | thousandsPipe" #alquiler_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,alquiler_indInput.value,"Alquiler","","independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,alquiler_indInput.value,"Alquiler","","independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.alquiler |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle bg-light">${{si.alquiler_13 |number:0 }}</td>
                                    <td class="align-middle bg-light">${{si.cuentas[7].valor |number:0 }}</td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[8].valor | thousandsPipe"
                                            #vale_aliment_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,vale_aliment_indInput.value,"Vale Alimentación",8,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,vale_aliment_indInput.value,"Vale Alimentación",8,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[8].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{si.cuentas[9].valor |number:0}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{si.cuentas[10].valor |number:0}}
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-100" type="text"
                                            [value]="si.pt_telefono | thousandsPipe" #pt_telefono_indInput
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,pt_telefono_indInput.value,"Puntos Telefono","","independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,pt_telefono_indInput.value,"Puntos Telefono","","independiente")'>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{si.cuentas[11].valor |number:0}}
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[12].valor | thousandsPipe"
                                            #beca_esco_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,beca_esco_indInput.value,"Beca Escolar",12,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,beca_esco_indInput.value,"Beca Escolar",12,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[12].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[13].valor | thousandsPipe"
                                            #beca_univ_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,beca_univ_indInput.value,"Beca Universitaria",13,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,beca_univ_indInput.value,"Beca Universitaria",13,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[13].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[14].valor | thousandsPipe"
                                            #est_super_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,est_super_indInput.value,"Estudios Superiores",14,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,est_super_indInput.value,"Estudios Superiores",14,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[14].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{si.cuentas[15].valor |number:0 }}
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[16].valor | thousandsPipe"
                                            #eq_smart_prof_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,eq_smart_prof_indInput.value,"Equipos Profesores",16,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,eq_smart_prof_indInput.value,"Equipos Profesores",16,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[16].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[17].valor | thousandsPipe" #agua_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,agua_indInput.value,"Agua",17,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,agua_indInput.value,"Agua",17,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[17].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{si.cuentas[18].valor |number:0}}
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[19].valor | thousandsPipe"
                                            #seguro_vida_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,seguro_vida_indInput.value,"Seguro de Vida",19,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,seguro_vida_indInput.value,"Seguro de Vida",19,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[19].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[20].valor | thousandsPipe"
                                            #seguro_viajes_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,seguro_viajes_indInput.value,"Seguro de Viajes",20,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,seguro_viajes_indInput.value,"Seguro de Viajes",20,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[20].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[21].valor | thousandsPipe"
                                            #seguro_equipo_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,seguro_equipo_indInput.value,"Seguro de Equipo",21,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,seguro_equipo_indInput.value,"Seguro de Equipo",21,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[21].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[22].valor | thousandsPipe"
                                            #seguro_efectos_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,seguro_efectos_indInput.value,"Seguro de Efectos",22,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,seguro_efectos_indInput.value,"Seguro de Efectos",22,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[22].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[23].valor | thousandsPipe"
                                            #seguro_salud_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,seguro_salud_indInput.value,"Seguro de Salud",23,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,seguro_salud_indInput.value,"Seguro de Salud",23,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[23].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[24].valor | thousandsPipe" #fpv_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,fpv_indInput.value,"FPV",24,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,fpv_indInput.value,"FPV",24,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[24].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle" [class.bg-light]="si.fmr_per == 0">
                                        <input *ngIf="si.fmr_per != 0" class="form-control form-control-sm mw-inp-100"
                                            type="text" [value]="si.cuentas[25].valor | thousandsPipe"
                                            #remb_mudanza_indInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,remb_mudanza_indInput.value,"Reembolso Mudanza",25,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,remb_mudanza_indInput.value,"Reembolso Mudanza",25,"independiente")'>
                                        <div *ngIf="si.fmr_per == 0" class="d-inline m-r-20">
                                            ${{si.cuentas[25].valor |number:0 }}
                                        </div>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-100" type="text"
                                            [value]="si.cuentas[26].valor | thousandsPipe" #prov_final_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,prov_final_indInput.value,"Provisión Final",26,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,prov_final_indInput.value,"Provisión Final",26,"independiente")'>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-100" type="text"
                                            [value]="si.cuentas[27].valor | thousandsPipe" #bono_dia_esp_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,bono_dia_esp_indInput.value,"Bono Día Especial",27,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,bono_dia_esp_indInput.value,"Bono Día Especial",27,"independiente")'>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-100" type="text"
                                            [value]="si.cuentas[28].valor | thousandsPipe" #aguinaldo_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,aguinaldo_indInput.value,"Aguinaldo",28,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,aguinaldo_indInput.value,"Aguinaldo",28,"independiente")'>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-100" type="text"
                                            [value]="si.cuentas[29].valor | thousandsPipe" #dif_imp_renta_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,dif_imp_renta_indInput.value,"Diferencia Impuesto Renta",29,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,dif_imp_renta_indInput.value,"Diferencia Impuesto Renta",29,"independiente")'>
                                    </td>
                                    <td *ngFor="let c_e_ind of si.cuentas_extras;let i=index" class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-100" type="text"
                                            [value]="c_e_ind.valor | thousandsPipe" #valor
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,valor.value,"Cuentas Extras",i,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,valor.value,"Cuentas Extras",i,"independiente")'>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{si.total |number:0}}
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-100" type="text"
                                            [value]="si.cuentas[30]!.valor | thousandsPipe" #red_pers_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,red_pers_indInput.value,"Reducción de Personal",30,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,red_pers_indInput.value,"Reducción de Personal",30,"independiente")'>
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm mw-inp-100" type="text"
                                            [value]="si.cuentas[31]!.valor | thousandsPipe" #ced_campos_uch_indInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(si._id,ced_campos_uch_indInput.value,"Cred. Campos UCh",31,"independiente")'
                                            (blur)='modal_modificar_sueldo_dep(si._id,ced_campos_uch_indInput.value,"Cred. Campos UCh",31,"independiente")'>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="sueldo_independiente.length">
                                <tr>
                                    <th></th>
                                    <th>Sub Totales</th>
                                    <th>{{sub_totales_sueldo_ind.codigo_departamento }}</th>
                                    <th>{{sub_totales_sueldo_ind.nombre_departamento }}</th>
                                    <th>{{sub_totales_sueldo_ind.fmr_per}}</th>
                                    <th>${{sub_total_cuentas_ind[0] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[1] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[2] | number:0}}</th>
                                    <!-- <th>${{sub_totales_sueldo_ind.ayud_salud | number:0}}</th>
                                    <th>${{sub_totales_sueldo_ind.ayud_medica | number:0}}</th> -->
                                    <th>${{sub_total_cuentas_ind[3] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[4] | number:0}}</th>
                                    <th>{{sub_totales_sueldo_ind.apv}}</th>
                                    <th>${{sub_total_cuentas_ind[5] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[6] | number:0}}</th>
                                    <th>${{sub_totales_sueldo_ind.alquiler | number:0}}</th>
                                    <th>${{sub_totales_sueldo_ind.alquiler_13 | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[7] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[8] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[9] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[10] | number:0}}</th>
                                    <th>{{sub_totales_sueldo_ind.pt_telefono}}</th>
                                    <th>${{sub_total_cuentas_ind[11] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[12] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[13] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[14] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[15] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[16] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[17] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[18] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[19] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[20] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[21] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[22] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[23] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[24] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[25] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[26] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[27] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[28] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[29] | number:0}}</th>
                                    <th *ngFor="let sub_c_e_ind of sub_total_cuentas_extras_ind">
                                        ${{ sub_c_e_ind | number:0}}
                                    </th>
                                    <th>${{sub_totales_sueldo_ind.total | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[30] | number:0}}</th>
                                    <th>${{sub_total_cuentas_ind[31] | number:0}}</th>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="m-l-10 mb-3 mt-3" *ngIf="!sueldo_independiente.length">
                            <em>No se han encontrado datos para mostrar</em>
                        </div>
                        <!-- </div> -->
                    </div>

                </div>
                <!-- end table-responsive -->
                <!-- #modal Modificar Sueldo Independiente -->
                <!-- <div class="modal fade" id="modal_agregar_cuenta_ind">
                        <div class="modal-dialog ">
                            <div class="modal-content"> -->
                <ng-template #modal_agregar_cuenta_ind let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title">Agregar Cuenta</h4>
                        <button type="button" class="close btn btn-sm btn-white m-r-1" (click)="d('Cross click')"><span
                                aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="panel-body panel-form">
                        <form class="form-horizontal form-bordered">
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">N° de la Cuenta</label>
                                <div class="col-md-8">
                                    <input class="form-control" type="text" #n_cuenta #valor="ngModel"
                                        name="numero_cuenta" [(ngModel)]="genera_cuentas_extras.numero_cuenta"
                                        (blur)='get_nombre_cuenta_extra(n_cuenta.value)' />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nombre de la Cuenta</label>
                                <div class="col-md-8">
                                    <input class="form-control" value="genera_cuentas_extras.nombre_cuenta" type="text"
                                        #valor="ngModel" name="nombre_cuenta"
                                        [(ngModel)]="genera_cuentas_extras.nombre_cuenta" />
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <a href="javascript:;" class="btn btn-white" (click)="c('Close click')">Cerrar</a>
                        <a href=" javascript:;"
                            (click)="agregar_cuenta_extra('Independiente', modal_agregar_cuenta_ind)"
                            class="btn btn-success">Guardar</a>
                    </div>
                </ng-template>
                <!-- Fin #modal Modificar Sueldo Independiente -->
            </div>
            <!--Fin Tablas-->
            <!-- end panel -->
            <!-- Pestaña Viajes-->
            <div *ngIf="tipo_pres == 'viajes'" id="viajes">
                <div class="panel-body" *ngIf="viajes !='' && sub_totales_viajes!= '' ">
                    <div class="panel-heading mt-4">
                        <h4 class="panel-title">Viajes</h4>
                    </div>
                    <div class="row mb-3 mt-3">
                        <div class="col-xl-6 col-md-12">
                            <input class="form-control" type="text" placeholder="Buscar por diverso, nombre..."
                                (keyup)="updateFilter($event, 'viajes')" />
                        </div>
                    </div>
                    <!-- begin table-responsive -->
                    <hr class="m-0" />
                    <div class="table-responsive table-sueldos">
                        <!-- <div class="scrollable"> -->
                        <table class="table">
                            <thead class="text-nowrap">
                                <tr>
                                    <th>Diverso</th>
                                    <th>Nombre Empleado</th>
                                    <th>13° Kilometraje</th>
                                    <th>Días de Viatico</th>
                                    <th>Días Hotel</th>
                                    <th>Valor Día Hotel</th>
                                    <th>Total Hotel</th>
                                    <th>Total Viatico</th>
                                    <th>Viajes Regionales</th>
                                    <th>Viajes Internacionales</th>
                                    <th>Km Mensuales</th>
                                    <th>Viajes Urbanos</th>
                                    <th>Otros Gastos de Viaje</th>
                                    <th>TAG</th>
                                    <th>Maleta</th>
                                    <th>Otros Reembolso de Viaje</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody class="text-nowrap" *ngIf="viaje.length">
                                <tr *ngFor="let vj of viaje;let indice=index">
                                    <td style="width: 100px;" class="bg-light align-middle">
                                        {{vj.diverso}}</td>
                                    <td class="bg-light align-middle">{{vj.nombre}}</td>
                                    <td class="bg-light align-middle">${{vj.kilometraje_13 |number:0}}</td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vj.dias_viatico" #dias_viaticoInput
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,dias_viaticoInput.value,"Dias de Viatico",indice,"viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,dias_viaticoInput.value,"Dias de Viatico",indice,"viajes")'>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text" [value]="vj.dias_hotel"
                                            #dias_hotelInput
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,dias_hotelInput.value,"Días en Hotel,indice","viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,dias_hotelInput.value,"Días en Hotel",indice,"viajes")'>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vj.valor_dia_hotel | thousandsPipe" #valor_dia_hotelInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,valor_dia_hotelInput.value,"Valor del día en el hotel",indice,"viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,valor_dia_hotelInput.value,"Valor del día en el hotel",indice,"viajes")'>
                                    </td>
                                    <td class="bg-light align-middle">${{vj.total_hotel |number:0}}
                                    </td>
                                    <td class="bg-light align-middle">${{vj.viatico |number:0}}</td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vj.viajes_regionales | thousandsPipe" #viajes_regionalevjnput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,viajes_regionalevjnput.value,"Viajes Regionales",indice,"viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,viajes_regionalevjnput.value,"Viajes Regionales",indice,"viajes")'>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vj.viajes_internacionales | thousandsPipe"
                                            #viajes_internacionalevjnput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,viajes_internacionalevjnput.value,"Viajes Internacionales",indice,"viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,viajes_internacionalevjnput.value,"Viajes Internacionales",indice,"viajes")'>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vj.km_mensuales | thousandsPipe" #km_mensualevjnput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,km_mensualevjnput.value,"Km Mensuales",indice,"viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,km_mensualevjnput.value,"Km Mensuales",indice,"viajes")'>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vj.viajes_urbanos | thousandsPipe" #viajes_urbanovjnput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,viajes_urbanovjnput.value,"Viajes Urbanos",indice,"viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,viajes_urbanovjnput.value,"Viajes Urbanos",indice,"viajes")'>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vj.otros_1 | thousandsPipe" #otros_1Input
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,otros_1Input.value,"Otros 1",indice,"viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,otros_1Input.value,"Otros 1",indice,"viajes")'>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vj.tag | thousandsPipe" #tagInput (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,tagInput.value,"TAG",indice,"viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,tagInput.value,"TAG",indice,"viajes")'>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vj.maleta | thousandsPipe" #maletaInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,maletaInput.value,"Maleta",indice,"viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,maletaInput.value,"Maleta",indice,"viajes")'>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vj.otros_2 | thousandsPipe" #otros_2Input
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_sueldo_dep(vj._id,otros_2Input.value,"Otros 2",indice,"viajes")'
                                            (blur)='modal_modificar_sueldo_dep(vj._id,otros_2Input.value,"Otros 2",indice,"viajes")'>
                                    </td>
                                    <td class="bg-light align-middle">${{vj.total |number:0}}</td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="viaje.length">
                                <tr>
                                    <th></th>
                                    <th>Sub Totales</th>
                                    <th>${{sub_totales_viajes.kilometraje_13 | number:0}}</th>
                                    <th>{{sub_totales_viajes.dias_viatico }}</th>
                                    <th>{{sub_totales_viajes.dias_hotel }}</th>
                                    <th>{{sub_totales_viajes.valor_dia_hotel}}</th>
                                    <th>${{sub_totales_viajes.total_hotel | number:0}}</th>
                                    <th>${{sub_totales_viajes.viatico | number:0}}</th>
                                    <th>${{sub_totales_viajes.viajes_regionales | number:0}}</th>
                                    <th>${{sub_totales_viajes.viajes_internacionales | number:0}}</th>
                                    <th>${{sub_totales_viajes.km_mensuales | number:0}}</th>
                                    <th>${{sub_totales_viajes.viajes_urbanos | number:0}}</th>
                                    <th>${{sub_totales_viajes.otros_1}}</th>
                                    <th>${{sub_totales_viajes.tag | number:0}}</th>
                                    <th>${{sub_totales_viajes.maleta | number:0}}</th>
                                    <th>${{sub_totales_viajes.otros_2 | number:0}}</th>
                                    <th>${{sub_totales_viajes.total | number:0}}</th>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="m-l-10 mb-3 mt-3" *ngIf="!viaje.length">
                            <em>No se han encontrado datos para mostrar</em>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
            <!-- Fin Pestaña Viajes-->
            <!-- Pestaña Cuenta 411-->
            <div *ngIf="tipo_pres == 'sueldos'">
                <div class="panel-body">
                    <div *ngIf="carga_tabla_411 != false">
                        <!-----------Tabla Sueldos Dependientes-------------->
                        <div class="" *ngIf="tipo_pres == 'sueldos'" id="sueldos">
                            <div class="panel-heading mt-4">
                                <h4 class="panel-title">Sueldos Dependientes</h4>
                            </div>
                            <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                                <div class="mw50per">
                                    <input class="form-control" type="text"
                                        placeholder="Buscar por N° cuenta, nombre cuenta..."
                                        (keyup)="updateFilter($event, 'sueldosdeptot')" />
                                </div>
                                <div>
                                    <button type="button" class="btn btn-success text-white m-b-10"
                                        (click)="exportar_tabla_excel('411', false)">Exportar Excel</button>
                                </div>
                            </div>
                            <!-- begin table-responsive -->
                            <hr class="m-0" />
                            <div class="table-responsive sueldos_411">
                                <table class="table table-hover" *ngIf="fila_411_dep.length">
                                    <thead class="align-middle">
                                        <tr class="text-center">
                                            <th class="text-nowrap ">N° Cuenta</th>
                                            <th class="text-nowrap ">Nombre Cuenta</th>
                                            <th class="">Diezmo</th>
                                            <th class="text-nowrap ">No diezmo</th>
                                            <th class="text-center mw-150 ver-ali"
                                                *ngFor="let c of columna_411_dep;let indice=index ">
                                                <div>
                                                    {{c.nombre_dep}}
                                                </div>
                                                <div>
                                                    ({{c.n_departamento}})
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-nowrap" *ngIf="fila_411_dep.length">
                                        <tr *ngFor="let f of fila_411_dep;let indice=index ">
                                            <td class="bg-light">
                                                {{f.n_cuenta}}
                                            </td>
                                            <td class="bg-light align-middle">
                                                {{f.nombre_cuenta}}
                                            </td>
                                            <td class="bg-light">
                                                ${{f.diezmo |number:0}}
                                            </td>
                                            <td class="bg-light">
                                                ${{f.no_diezmo |number:0}}
                                            </td>
                                            <td *ngFor="let d of f.departamentos;let ind=index ">
                                                ${{d.valor |number:0}}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot *ngIf="fila_411_dep.length">
                                        <tr>
                                            <th></th>
                                            <th>Sub Totales</th>
                                            <th>${{diezmo_411_dep | number:0}}</th>
                                            <th>${{no_diezmo_411_dep | number:0}}</th>
                                            <th *ngFor="let sub of sub_totales_411_dep;let indice=index ">
                                                ${{sub | number:0}}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div class="m-l-10 mb-3 mt-3" *ngIf="!fila_411_dep.length">
                                    <em>No se han encontrado datos para mostrar</em>
                                </div>
                            </div>
                            <!-- end table-responsive -->
                        </div>
                        <!-----------Tabla Sueldos Dependientes-------------->
                        <!-----------Tabla Sueldos Independientes-------------->
                        <div class="" *ngIf="tipo_pres == 'sueldos'" id="sueldos">
                            <div class="panel-heading mt-4">
                                <h4 class="panel-title">Sueldos Independientes</h4>
                            </div>
                            <div class="row mb-3 mt-3">
                                <div class="col-xl-6 col-md-12">
                                    <input class="form-control" type="text"
                                        placeholder="Buscar por N° cuenta, nombre cuenta..."
                                        (keyup)="updateFilter($event,'sueldosindtot')" />
                                </div>
                            </div>
                            <!-- begin table-responsive -->
                            <hr class="m-0" />
                            <div class="table-responsive sueldos_411">
                                <table class="table table-hover" *ngIf="fila_411_ind.length">
                                    <thead>
                                        <tr class="">
                                            <th class="text-nowrap ">N° Cuenta</th>
                                            <th class="text-nowrap ">Nombre Cuenta</th>
                                            <th class="">Diezmo</th>
                                            <th class="text-nowrap ">No diezmo</th>
                                            <th class="text-center mw-150 ver-ali"
                                                *ngFor="let c of columna_411_ind;let indice=index">
                                                <div>
                                                    {{c.nombre_dep}}
                                                </div>
                                                <div>
                                                    ({{c.n_departamento}})
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let f of fila_411_ind;let indice=index ">
                                            <td class="bg-light">
                                                {{f.n_cuenta}}
                                            </td>
                                            <td class="bg-light align-middle">
                                                {{f.nombre_cuenta}}
                                            </td>
                                            <td class="bg-light">
                                                ${{f.diezmo |number:0}}
                                            </td>
                                            <td class="bg-light">
                                                ${{f.no_diezmo |number:0}}
                                            </td>
                                            <td *ngFor="let d of f.departamentos;let ind=index ">
                                                ${{d.valor |number:0}}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th></th>
                                            <th>Sub Totales</th>
                                            <th>${{diezmo_411_ind | number:0}}</th>
                                            <th>${{no_diezmo_411_ind | number:0}}</th>
                                            <th *ngFor="let sub of sub_totales_411_ind;let indice=index ">
                                                ${{sub | number:0}}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div class="m-l-10 mb-3 mt-3" *ngIf="!fila_411_ind.length">
                                    <em>No se han encontrado datos para mostrar</em>
                                </div>
                            </div>
                            <!-- end table-responsive -->
                        </div>
                        <!------------Tabla Sueldos Independientes---------------->
                    </div>
                </div>
            </div>
            <!-- Fin Pestaña Cuenta 411-->
            <!-- Pestaña Cuenta 412-->
            <div *ngIf="tipo_pres == 'gastos_operativos'">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Gastos operativos</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por N° cuenta, nombre cuenta..."
                            (keyup)="updateFilter($event,'gastosop')" />
                    </div>
                    <div>
                        <a href="javascript:;" (click)='modal_agregar_registro_cuentas("Cuenta",412,null, modal_cuenta)'
                            class="btn btn-success" data-toggle="modal">Agregar
                            Cuenta</a>
                        <a href="javascript:;"
                            (click)='modal_agregar_registro_cuentas("Departamento",412,null, modal_cuenta)'
                            class="btn btn-success m-l-10" data-toggle="modal">Agregar
                            Departamento</a>
                        <button type="button" class="btn btn-success text-white m-l-10"
                            (click)="exportar_tabla_excel('412', false)">Exportar Excel</button>
                    </div>
                </div>
                <!-- begin table-responsive -->
                <hr class="m-0" />
                <div class="table-responsive cuentas">
                    <!-- <div class=""> -->
                    <table class="table" id="tabla_cuentas_412" *ngIf="fila_412.length">
                        <thead>
                            <tr>
                                <th class="text-nowrap">N° Cuenta/Div</th>
                                <th class="text-nowrap">Nombre Cuenta</th>
                                <th class="text-nowrap">Diezmo</th>
                                <th class="text-nowrap">No diezmo</th>
                                <th class="text-center mw-150 ver-ali-bot"
                                    *ngFor="let c of columna_412;let indice=index ">
                                    <div>
                                        {{c.nombre_departamento}}
                                    </div>
                                    <div>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="c.n_departamento" #c_n_departamentoInput
                                            (keyup.enter)='modal_modificar_cuentas("",c_n_departamentoInput.value,"412",indice,c.nombre_departamento,c_n_departamentoInput,"Departamento Cabecera")'
                                            (blur)='modal_modificar_cuentas("",c_n_departamentoInput.value,"412",indice,c.nombre_departamento,c_n_departamentoInput,"Departamento Cabecera")'>
                                    </div>
                                </th>
                                <th class="text-center">Saldo</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="fila_412.length">
                            <tr *ngFor="let f of fila_412;let indice=index ">
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" [value]="f.n_cuenta"
                                        #f_n_cuentaInput
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_n_cuentaInput.value,"412",indice,"N° Cuenta",f_n_cuentaInput,"N° Cuenta")'
                                        (blur)='modal_modificar_cuentas(f._id,f_n_cuentaInput.value,"412",indice,"N° Cuenta",f.n_cuenta,"N° Cuenta")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" [value]="f.nombre_cuenta"
                                        #f_nombre_cuentaInput
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_nombre_cuentaInput.value,"412",indice,"Nombre","","Nombre")'
                                        (blur)='modal_modificar_cuentas(f._id,f_nombre_cuentaInput.value,"412",indice,"Nombre","","Nombre")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text"
                                        [value]="f.diezmo | thousandsPipe" #f_diezmoInput
                                        (keyup)="separador_miles($event)"
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_diezmoInput.value,"412",indice,"Diezmo","","Diezmo")'
                                        (blur)='modal_modificar_cuentas(f._id,f_diezmoInput.value,"412",indice,"Diezmo","","Diezmo")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text"
                                        [value]="f.no_diezmo | thousandsPipe" #f_no_diezmoInput
                                        (keyup)="separador_miles($event)"
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_no_diezmoInput.value,"412",indice,"No Diezmo","","No Diezmo")'
                                        (blur)='modal_modificar_cuentas(f._id,f_no_diezmoInput.value,"412",indice,"No Diezmo","","No Diezmo")'>
                                </td>
                                <td *ngFor="let d of f.departamentos;let ind=index ">
                                    <input class="form-control form-control-sm" type="text"
                                        [value]="d.valor | thousandsPipe" #valor (keyup)="separador_miles($event)"
                                        (keyup.enter)='modal_modificar_cuentas(f._id,valor.value,"412",ind,d.nombre_departamento,d.n_departamento,"Departamento")'
                                        (blur)='modal_modificar_cuentas(f._id,valor.value,"412",ind,d.nombre_departamento,d.n_departamento,"Departamento")'>
                                </td>
                                <td class="bg-light align-middle text-nowrap">
                                    ${{f.saldo | number:0}}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="fila_412.length">
                            <tr>
                                <th></th>
                                <th>Sub Totales</th>
                                <th>${{diez_nodiez_412.diezmo | number:0}}</th>
                                <th>${{diez_nodiez_412.no_diezmo | number:0}}</th>
                                <th *ngFor="let sub of sub_totales_412;let indice=index ">
                                    ${{sub | number:0}}
                                </th>
                                <th>${{sub_saldo_412 | number:0}}</th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="m-l-10 mt-2 mb-3" *ngIf="!fila_412.length">
                        <em>No se han encontrado datos para mostrar. Por favor agregue una nueva cuenta</em>
                    </div>
                    <!-- </div> -->
                </div>
                <!-- </div> -->
            </div>
            <!-- Fin Pestaña Cuenta 412 -->
            <!-- Pestaña Cuenta 414-->
            <div *ngIf="tipo_pres == 'reuniones_eventos'" id="viajes">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Gastos reuniones y eventos</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por N° cuenta, nombre cuenta..."
                            (keyup)="updateFilter($event, 'eventos')" />
                    </div>
                    <div>
                        <a href="javascript:;" (click)='modal_agregar_registro_cuentas("Cuenta",414,0, modal_cuenta)'
                            class="btn btn-success" data-toggle="modal">Agregar
                            Cuenta</a>
                        <a href="javascript:;"
                            (click)='modal_agregar_registro_cuentas("Departamento",414,0, modal_cuenta)'
                            class="btn btn-success m-l-10" data-toggle="modal">Agregar
                            Departamento</a>
                        <button type="button" class="btn btn-success text-white m-l-10"
                            (click)="exportar_tabla_excel('414', false)">Exportar Excel</button>
                    </div>
                </div>
                <!-- begin table-responsive -->
                <hr class="m-0" />
                <div class="table-responsive cuentas">
                    <table class="table" id="tabla_cuentas_414" *ngIf="fila_414.length">
                        <thead>
                            <tr>
                                <th class="text-nowrap">N° Cuenta/Div</th>
                                <th class="text-nowrap">Nombre Cuenta</th>
                                <th class="text-nowrap">Diezmo</th>
                                <th class="text-nowrap">No diezmo</th>
                                <th class="text-center mw-150 ver-ali-bot"
                                    *ngFor="let c of columna_414;let indice=index ">
                                    <div>
                                        {{c.nombre_departamento}}
                                    </div>
                                    <div>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="c.n_departamento" #c_n_departamentoInput
                                            (keyup.enter)='modal_modificar_cuentas("",c_n_departamentoInput.value,"414",indice,c.nombre_departamento,c_n_departamentoInput,"Departamento Cabecera")'
                                            (blur)='modal_modificar_cuentas("",c_n_departamentoInput.value,"414",indice,c.nombre_departamento,c_n_departamentoInput,"Departamento Cabecera")'>
                                    </div>
                                </th>
                                <th class="text-center">
                                    Saldo
                                </th>

                            </tr>
                        </thead>
                        <tbody *ngIf="fila_414.length">
                            <tr *ngFor="let f of fila_414;let indice=index ">
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" [value]="f.n_cuenta"
                                        #f_n_cuentaInput
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_n_cuentaInput.value,"414",indice,"N° Cuenta",f_n_cuentaInput,"N° Cuenta")'
                                        (blur)='modal_modificar_cuentas(f._id,f_n_cuentaInput.value,"414",indice,"N° Cuenta",f.n_cuenta,"N° Cuenta")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" [value]="f.nombre_cuenta"
                                        #f_nombre_cuentaInput
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_nombre_cuentaInput.value,"414",indice,"Nombre","","Nombre")'
                                        (blur)='modal_modificar_cuentas(f._id,f_nombre_cuentaInput.value,"414",indice,"Nombre","","Nombre")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text"
                                        [value]="f.diezmo | thousandsPipe" #f_diezmoInput
                                        (keyup)="separador_miles($event)"
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_diezmoInput.value,"414",indice,"Diezmo","","Diezmo")'
                                        (blur)='modal_modificar_cuentas(f._id,f_diezmoInput.value,"414",indice,"Diezmo","","Diezmo")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text"
                                        [value]="f.no_diezmo | thousandsPipe" #f_no_diezmoInput
                                        (keyup)="separador_miles($event)"
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_no_diezmoInput.value,"414",indice,"No Diezmo","","No Diezmo")'
                                        (blur)='modal_modificar_cuentas(f._id,f_no_diezmoInput.value,"414",indice,"No Diezmo","","No Diezmo")'>
                                </td>
                                <td *ngFor="let d of f.departamentos;let ind=index ">
                                    <input class="form-control form-control-sm" type="text"
                                        [value]="d.valor | thousandsPipe" #valor (keyup)="separador_miles($event)"
                                        (keyup.enter)='modal_modificar_cuentas(f._id,valor.value,"414",ind,d.nombre_departamento,d.n_departamento,"Departamento")'
                                        (blur)='modal_modificar_cuentas(f._id,valor.value,"414",ind,d.nombre_departamento,d.n_departamento,"Departamento")'>
                                </td>
                                <td class="bg-light align-middle">
                                    ${{f.saldo | number:0}}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="fila_414.length">
                            <tr>
                                <th></th>
                                <th>Sub Totales</th>
                                <th>${{diez_nodiez_414.diezmo | number:0}}</th>
                                <th>${{diez_nodiez_414.no_diezmo | number:0}}</th>
                                <th *ngFor="let sub of sub_totales_414;let indice=index ">
                                    ${{sub | number:0}}
                                </th>
                                <th>${{sub_saldo_414 | number:0}}</th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="m-l-10 mt-2 mb-3" *ngIf="!fila_414.length">
                        <em>No se han encontrado datos para mostrar. Por favor agregue una nueva cuenta</em>
                    </div>
                </div>
                <!-- modal-modificar-cuenta -->
                <div class="modal fade" id="modificar-cuenta">
                    <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title text-center">
                                    {{modificacion_cuentas_departamentos.departamento}}
                                    ({{modificacion_cuentas_departamentos.n_departamento}})</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div class="modal-body">
                                <input class="form-control" type="text" #valor="ngModel" name="valor"
                                    [(ngModel)]="modificacion_cuentas_departamentos.valor" />
                                <div *ngIf="state == false" class="m-t-10" [class.hide]="state == undefined">
                                    <div class="alert alert-sm alert-danger fade show  col-md-12">
                                        <span class="close" data-dismiss="alert">×</span>

                                        <p class="m-t-10 col-md-4" style="word-wrap: break-word; width: 20px;">
                                            <i class="fa fa-exclamation-triangle"></i> {{mensaje}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <a href="javascript:;" class="btn btn-white" data-dismiss="modal">Cerrar</a>
                                <a href="javascript:;" (click)="modifacacuentas()" class="btn btn-success">Guardar</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin modal-modificar-cuenta -->
            </div>
            <!-- Fin Pestaña Cuenta 414-->
            <!-- Pestaña Cuenta 419 -->
            <div *ngIf="tipo_pres == 'otorgamientos'" id="viajes">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Otorgamientos</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por N° cuenta, nombre cuenta..."
                            (keyup)="updateFilter($event, 'otorgamientos')" />
                    </div>
                    <div>
                        <a href="javascript:;" (click)='modal_agregar_registro_cuentas("Cuenta",419,0, modal_cuenta)'
                            class="btn btn-success" data-toggle="modal">Agregar
                            Cuenta</a>
                        <a href="javascript:;"
                            (click)='modal_agregar_registro_cuentas("Departamento",419,0, modal_cuenta)'
                            class="btn btn-success m-l-10" data-toggle="modal">Agregar
                            Departamento</a>
                        <button type="button" class="btn btn-success text-white m-l-10"
                            (click)="exportar_tabla_excel('419', false)">Exportar Excel</button>
                    </div>
                </div>
                <!-- begin table-responsive -->
                <hr class="m-0" />
                <div class="table-responsive cuentas">
                    <table class="table" id="tabla_cuentas_419" *ngIf="fila_419.length">
                        <thead>
                            <tr>
                                <th class="text-nowrap">N° Cuenta/Div</th>
                                <th class="text-nowrap">Nombre Cuenta</th>
                                <th class="text-nowrap">Diezmo</th>
                                <th class="text-nowrap">No diezmo</th>
                                <th class="text-center mw-150 ver-ali-bot"
                                    *ngFor="let c of columna_419;let indice=index ">
                                    <div>
                                        {{c.nombre_departamento}}
                                    </div>
                                    <div>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="c.n_departamento" #c_n_departamentoInput
                                            (keyup.enter)='modal_modificar_cuentas("",c_n_departamentoInput.value,"419",indice,c.nombre_departamento,c_n_departamentoInput,"Departamento Cabecera")'
                                            (blur)='modal_modificar_cuentas("",c_n_departamentoInput.value,"419",indice,c.nombre_departamento,c_n_departamentoInput,"Departamento Cabecera")'>
                                    </div>
                                </th>
                                <th>
                                    Saldo
                                </th>

                            </tr>
                        </thead>
                        <tbody *ngIf="fila_419.length">
                            <tr *ngFor="let f of fila_419;let indice=index ">
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text" [value]="f.n_cuenta"
                                        #f_n_cuentaInput
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_n_cuentaInput.value,"419",indice,"N° Cuenta",f_n_cuentaInput,"N° Cuenta")'
                                        (blur)='modal_modificar_cuentas(f._id,f_n_cuentaInput.value,"419",indice,"N° Cuenta",f.n_cuenta,"N° Cuenta")'>
                                </td>
                                <td class="bg-light ">
                                    <input class="form-control form-control-sm" type="text" [value]="f.nombre_cuenta"
                                        #f_nombre_cuentaInput
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_nombre_cuentaInput.value,"419",indice,"Nombre","","Nombre")'
                                        (blur)='modal_modificar_cuentas(f._id,f_nombre_cuentaInput.value,"419",indice,"Nombre","","Nombre")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text"
                                        [value]="f.diezmo | thousandsPipe" #f_diezmoInput
                                        (keyup)="separador_miles($event)"
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_diezmoInput.value,"419",indice,"Diezmo","","Diezmo")'
                                        (blur)='modal_modificar_cuentas(f._id,f_diezmoInput.value,"419",indice,"Diezmo","","Diezmo")'>
                                </td>
                                <td class="bg-light">
                                    <input class="form-control form-control-sm" type="text"
                                        [value]="f.no_diezmo | thousandsPipe" #f_no_diezmoInput
                                        (keyup)="separador_miles($event)"
                                        (keyup.enter)='modal_modificar_cuentas(f._id,f_no_diezmoInput.value,"419",indice,"No Diezmo","","No Diezmo")'
                                        (blur)='modal_modificar_cuentas(f._id,f_no_diezmoInput.value,"419",indice,"No Diezmo","","No Diezmo")'>
                                </td>
                                <td *ngFor="let d of f.departamentos;let ind=index ">
                                    <input class="form-control form-control-sm" type="text"
                                        [value]="d.valor | thousandsPipe" #valor (keyup)="separador_miles($event)"
                                        (keyup.enter)='modal_modificar_cuentas(f._id,valor.value,"419",ind,d.nombre_departamento,d.n_departamento,"Departamento")'
                                        (blur)='modal_modificar_cuentas(f._id,valor.value,"419",ind,d.nombre_departamento,d.n_departamento,"Departamento")'>
                                </td>
                                <td class="bg-light align-middle">
                                    ${{f.saldo | number:0}}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="fila_419.length">
                            <tr>
                                <th></th>
                                <th>Sub Totales</th>
                                <th>${{diez_nodiez_419.diezmo | number:0}}</th>
                                <th>${{diez_nodiez_419.no_diezmo | number:0}}</th>
                                <th *ngFor="let sub of sub_totales_419;let indice=index">
                                    ${{sub | number:0}}
                                </th>
                                <th>${{sub_saldo_419 | number:0}}</th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="m-l-10 mt-2 mb-3" *ngIf="!fila_419.length">
                        <em>No se han encontrado datos para mostrar. Por favor agregue una nueva cuenta</em>
                    </div>
                </div>
            </div>
            <!-- Fin Pestaña Cuenta 419 -->
            <!-- Pestaña Ingresos -->
            <div *ngIf="tipo_pres == 'ingresos' " id="viajes">
                <div class="panel-heading mt-4">
                    <h4 class="panel-title">Ingresos</h4>
                </div>
                <div class="d-flex align-content-center justify-content-between mb-3 mt-3">
                    <div class="mw50per">
                        <input class="form-control" type="text" placeholder="Buscar por N° cuenta, nombre cuenta..."
                            (keyup)="updateFilter($event, 'ingresos')" />
                    </div>
                    <div>
                        <a href="javascript:;" id="abrir_modal_ingreso" (click)="open(modal_ingreso)"
                            class="btn btn-success">Agregar Ingreso</a>
                        <button type="button" class="btn btn-success text-white m-l-10"
                            (click)="exportar_tabla_excel('Ingresos', false)">Exportar Excel</button>
                    </div>
                </div>
                <!-- begin table-responsive -->
                <hr class="m-0" />
                <div class="table-responsive ingresos">
                    <table class="table" id="tabla_ingreso">
                        <thead>
                            <tr>
                                <th></th>
                                <th>N° Cuenta/Div</th>
                                <th>Nombre Cuenta</th>
                                <th>Diezmo</th>
                                <th>No diezmo</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="diezmo_ingr.length">
                            <tr *ngFor="let d of diezmo_ingr;let i=index">
                                <td *ngIf="i==0" [attr.rowspan]="count_diezmo_ingr">
                                    Diezmos - 311
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #n_cuenta_diezmo_ingr
                                        [value]="d.n_cuenta"
                                        (keyup.enter)='modificar_ingresos(d._id,n_cuenta_diezmo_ingr.value,"Diezmos","n_cuenta")'
                                        (blur)='modificar_ingresos(d._id,n_cuenta_diezmo_ingr.value,"Diezmos","n_cuenta")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #nombre_cuenta_diezmo_ingr
                                        [value]="d.nombre_cuenta"
                                        (keyup.enter)='modificar_ingresos(d._id,nombre_cuenta_diezmo_ingr.value,"Diezmos","nombre_cuenta")'
                                        (blur)='modificar_ingresos(d._id,nombre_cuenta_diezmo_ingr.value,"Diezmos","nombre_cuenta")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #diezmo_diezmo_ingr
                                        [value]="d.diezmo | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_ingresos(d._id,diezmo_diezmo_ingr.value,"Diezmos","diezmo")'
                                        (blur)='modificar_ingresos(d._id,diezmo_diezmo_ingr.value,"Diezmos","diezmo")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #no_diezmo_diezmo_ingr
                                        [value]="d.no_diezmo | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_ingresos(d._id,no_diezmo_diezmo_ingr.value,"Diezmos","no_diezmo")'
                                        (blur)='modificar_ingresos(d._id,no_diezmo_diezmo_ingr.value,"Diezmos","no_diezmo")'>
                                </td>
                            </tr>

                        </tbody>
                        <tbody *ngIf="ofrendas_ingr.length">
                            <tr *ngFor="let o of ofrendas_ingr;let i=index">
                                <td *ngIf="i==0" [attr.rowspan]="count_ofrendas_ingr">Ofrendas - 312</td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #n_cuenta_ofrendas_ingr
                                        [value]="o.n_cuenta"
                                        (keyup.enter)='modificar_ingresos(o._id,n_cuenta_ofrendas_ingr.value,"Ofrendas ","n_cuenta")'
                                        (blur)='modificar_ingresos(o._id,n_cuenta_ofrendas_ingr.value,"Ofrendas ","n_cuenta")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #nombre_cuenta_ofrendas_ingr
                                        [value]="o.nombre_cuenta"
                                        (keyup.enter)='modificar_ingresos(o._id,nombre_cuenta_ofrendas_ingr.value,"Ofrendas ","nombre_cuenta")'
                                        (blur)='modificar_ingresos(o._id,nombre_cuenta_ofrendas_ingr.value,"Ofrendas ","nombre_cuenta")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #diezmo_ofrendas_ingr
                                        [value]="o.diezmo | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_ingresos(o._id,diezmo_ofrendas_ingr.value,"Ofrendas ","diezmo")'
                                        (blur)='modificar_ingresos(o._id,diezmo_ofrendas_ingr.value,"Ofrendas ","diezmo")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #no_diezmo_ofrendas_ingr
                                        [value]="o.no_diezmo | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_ingresos(o._id,no_diezmo_ofrendas_ingr.value,"Ofrendas ","no_diezmo")'
                                        (blur)='modificar_ingresos(o._id,no_diezmo_ofrendas_ingr.value,"Ofrendas ","no_diezmo")'>
                                </td>
                            </tr>

                        </tbody>
                        <tbody *ngIf="don_rec_ingr.length">
                            <tr *ngFor="let don of don_rec_ingr;let i=index">
                                <td *ngIf="i==0" [attr.rowspan]="count_don_rec_ingr">Donaciones Recibidas -
                                    313</td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #n_cuenta_don_rec_ingr
                                        [value]="don.n_cuenta"
                                        (keyup.enter)='modificar_ingresos(don._id,n_cuenta_don_rec_ingr.value,"Donaciones Recibidas","n_cuenta")'
                                        (blur)='modificar_ingresos(don._id,n_cuenta_don_rec_ingr.value,"Donaciones Recibidas","n_cuenta")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #nombre_cuenta_don_rec_ingr
                                        [value]="don.nombre_cuenta"
                                        (keyup.enter)='modificar_ingresos(don._id,nombre_cuenta_don_rec_ingr.value,"Donaciones Recibidas","nombre_cuenta")'
                                        (blur)='modificar_ingresos(don._id,nombre_cuenta_don_rec_ingr.value,"Donaciones Recibidas","nombre_cuenta")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #diezmo_don_rec_ingr
                                        [value]="don.diezmo | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_ingresos(don._id,diezmo_don_rec_ingr.value,"Donaciones Recibidas","diezmo")'
                                        (blur)='modificar_ingresos(don._id,diezmo_don_rec_ingr.value,"Donaciones Recibidas","diezmo")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #no_diezmo_don_rec_ingr
                                        [value]="don.no_diezmo | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_ingresos(don._id,no_diezmo_don_rec_ingr.value,"Donaciones Recibidas","no_diezmo")'
                                        (blur)='modificar_ingresos(don._id,no_diezmo_don_rec_ingr.value,"Donaciones Recibidas","no_diezmo")'>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="otros_op_ingr.length">
                            <tr *ngFor="let ot of otros_op_ingr;let i=index">
                                <td *ngIf="i==0" [attr.rowspan]="count_otros_op_ingr">Otros Operativos - 318
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #n_cuenta_otros_op_ingr
                                        [value]="ot.n_cuenta"
                                        (keyup.enter)='modificar_ingresos(ot._id,n_cuenta_otros_op_ingr.value,"Otros Operativos","n_cuenta")'
                                        (blur)='modificar_ingresos(ot._id,n_cuenta_otros_op_ingr.value,"Otros Operativos","n_cuenta")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #nombre_cuenta_otros_op_ingr
                                        [value]="ot.nombre_cuenta"
                                        (keyup.enter)='modificar_ingresos(ot._id,nombre_cuenta_otros_op_ingr.value,"Otros Operativos","nombre_cuenta")'
                                        (blur)='modificar_ingresos(ot._id,nombre_cuenta_otros_op_ingr.value,"Otros Operativos","nombre_cuenta")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #diezmo_otros_op_ingr
                                        [value]="ot.diezmo | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_ingresos(ot._id,diezmo_otros_op_ingr.value,"Otros Operativos","diezmo")'
                                        (blur)='modificar_ingresos(ot._id,diezmo_otros_op_ingr.value,"Otros Operativos","diezmo")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #no_diezmo_otros_op_ingr
                                        [value]="ot.no_diezmo | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_ingresos(ot._id,no_diezmo_otros_op_ingr.value,"Otros Operativos","no_diezmo")'
                                        (blur)='modificar_ingresos(ot._id,no_diezmo_otros_op_ingr.value,"Otros Operativos","no_diezmo")'>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="suv_net_ingr.length">
                            <tr *ngFor="let suv of suv_net_ingr;let i=index">
                                <td *ngIf="i==0" [attr.rowspan]="count_suv_net_ingr">Suvenciones Recibidas -
                                    319</td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #n_cuenta_suv_net_ingr
                                        [value]="suv.n_cuenta"
                                        (keyup.enter)='modificar_ingresos(suv._id,n_cuenta_suv_net_ingr.value,"Suvenciones Recibidas","n_cuenta")'
                                        (blur)='modificar_ingresos(suv._id,n_cuenta_suv_net_ingr.value,"Suvenciones Recibidas","n_cuenta")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #nombre_cuenta_suv_net_ingr
                                        [value]="suv.nombre_cuenta"
                                        (keyup.enter)='modificar_ingresos(suv._id,nombre_cuenta_suv_net_ingr.value,"Suvenciones Recibidas","nombre_cuenta")'
                                        (blur)='modificar_ingresos(suv._id,nombre_cuenta_suv_net_ingr.value,"Suvenciones Recibidas","nombre_cuenta")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #diezmo_suv_net_ingr
                                        [value]="suv.diezmo | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_ingresos(suv._id,diezmo_suv_net_ingr.value,"Suvenciones Recibidas","diezmo")'
                                        (blur)='modificar_ingresos(suv._id,diezmo_suv_net_ingr.value,"Suvenciones Recibidas","diezmo")'>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" #no_diezmo_suv_net_ingr
                                        [value]="suv.no_diezmo | thousandsPipe" (keyup)="separador_miles($event)"
                                        (keyup.enter)='modificar_ingresos(suv._id,no_diezmo_suv_net_ingr.value,"Suvenciones Recibidas","no_diezmo")'
                                        (blur)='modificar_ingresos(suv._id,no_diezmo_suv_net_ingr.value,"Suvenciones Recibidas","no_diezmo")'>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot
                            *ngIf="suv_net_ingr.length || otros_op_ingr.length || don_rec_ingr.length || ofrendas_ingr.length || diezmo_ingr.length">
                            <tr>
                                <th colspan="3">
                                    Totales
                                </th>
                                <th>
                                    ${{sub_totales_ingr.diezmo | number:0}}
                                </th>
                                <th>
                                    ${{sub_totales_ingr.no_diezmo | number:0}}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="m-l-10 mt-2 mb-3"
                        *ngIf="!suv_net_ingr.length && !otros_op_ingr.length && !don_rec_ingr.length && !ofrendas_ingr.length && !diezmo_ingr.length">
                        <em>No se han encontrado datos para mostrar. Por favor agregue una nueva cuenta</em>
                    </div>
                </div>
            </div>
            <!-- Modal Agregar Ingresos-->
            <ng-template #modal_ingreso let-c="close" let-d="dismiss" let-modal>
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Agregar Nuevo Ingreso</h5>
                    <button type="button" class="close btn btn-sm btn-white m-r-1" (click)="d('Cross click')"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <div class="panel-body panel-form">
                        <div class="form-group row">
                            <label class="col-md-4 col-form-label">N° Cuenta</label>
                            <div class="col-md-4">
                                <input type="text" [class.is-invalid]="!cuenta.valid && cuenta.touched "
                                    #cuenta="ngModel" name="cuenta" [(ngModel)]="ingresos.cuenta" class="form-control"
                                    id="recipient-name" required />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 col-form-label">Tipo Ingreso</label>
                            <div class="col-md-8">
                                <select class="form-control" [(ngModel)]="ingresos.tipo_ingreso">
                                    <option disabled value="" selected="">Seleccione un tipo de ingreso
                                    </option>
                                    <option *ngFor="let tp of tipo_ingreso">{{tp}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
                    <button type="button" (click)='insertarIngreso(modal_ingreso)'
                        class="btn btn-primary">Guardar</button>
                </div>
            </ng-template>
            <!-- Fin Modal Agregar Ingresos -->
            <!-- Fin Pestaña Ingresos -->
            <!--Fin Tablas-->
            <!-- Modal Agregar Registro -->
            <ng-template #modal_cuenta let-c="close" let-d="dismiss" let-modal>
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <div *ngIf='cuentas.tipo == "Cuenta"'>Agregar Nueva Fila </div>
                        <div *ngIf='cuentas.tipo == "Departamento"'>Agregar Nuevo {{cuentas.tipo}}</div>
                    </h5>
                    <button type="button" class="close btn btn-sm btn-white m-r-1" (click)="d('Cross click')"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="recipient-name" class="col-form-label">N° {{cuentas.tipo}}</label>
                            <input class="form-control" type="text" #valor="ngModel" name="valor"
                                [(ngModel)]="cuentas.valor" />
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
                    <button type="button" (click)='agregar_registro_cuentas(modal_cuenta)'
                        class="btn btn-primary">Guardar</button>
                </div>
            </ng-template>
            <!-- Fin Modal Agregar Registro -->
            <div *ngIf="tipo_pres == 'ingresos' " class=" row m-t-10">
                <label class="col-md-4 ">&nbsp;&nbsp;&nbsp;</label>
                <div class="col-md-2 d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary" (click)="siguiente('cuentas')">Siguiente <i
                            class="fas fa-arrow-right fa-fw"></i></button>
                </div>
            </div>
        </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="'resumenes'">
        <a ngbNavLink (click)="tipo_presupuesto('resumenes')">Resumenes</a>
        <ng-template ngbNavContent>
            <div class="btn-group">
                <button class="btn btn-white " [class.active]="tipo_pres =='votar_b' || tipo_pestana == 'resumenes'"
                    (click)="tipo_presupuesto('votar_b')">Votar B</button>
                <button class="btn btn-white" [class.active]="tipo_pres =='votar_a'"
                    (click)="tipo_presupuesto('votar_a')">Votar A</button>
            </div>
            <!-- Inicio Pestaña Votar B -->
            <div *ngIf="tipo_pres == 'votar_b' || tipo_pestana == 'resumenes'">
                <div class="panel-body">
                    <h1 class=" m-b-20 text-center">Votar B</h1>
                    <!-- begin table-responsive -->
                    <hr class="m-0" />
                    <div class="table-responsive votar_b">
                        <table class="table" id="tabla_transferencias">
                            <thead class="text-center">
                                <tr>
                                    <th class="no-borders"></th>
                                    <th class="no-borders"></th>
                                    <th class="no-borders"></th>
                                    <th class="no-borders">Por Distribuir ---></th>
                                    <th class="no-borders">${{votar_b_pd_diezmo | number:0}}</th>
                                    <th class="no-borders">${{votar_b_pd_no_diezmo | number:0}}</th>
                                    <th class="no-borders"></th>
                                </tr>
                                <tr>
                                    <th class="no-borders"></th>
                                    <th class="no-borders"></th>
                                    <th class="no-borders"></th>
                                    <th class="no-borders">Ingresos ---></th>
                                    <th class="no-borders">${{votar_b_ingresos_totales_diezmo | number:0}}</th>
                                    <th class="no-borders">${{votar_b_ingresos_totales_no_diezmo | number:0}}</th>
                                    <th class="no-borders"></th>
                                </tr>
                                <tr>
                                    <th class="no-borders"></th>
                                    <th class="no-borders"></th>
                                    <th class="no-borders">Total Gastos ----></th>
                                    <th class="no-borders">${{totales_votar_b.gastos_totales | number:0}}</th>
                                    <th class="no-borders">-</th>
                                    <th class="no-borders">-</th>
                                    <th class="no-borders"></th>
                                </tr>
                                <tr>
                                    <th>Item</th>
                                    <th>N° Departamento</th>
                                    <th>Nombre Departamento</th>
                                    <th>Gastos Departamento</th>
                                    <th>Diezmo</th>
                                    <th>No diezmo</th>
                                    <th>Diferencia</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let vb of votar_b;let indice=index ">
                                    <td class="bg-light align-middle">
                                        {{vb.item}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{vb.n_departamento}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        {{vb.nombre_departamento}}
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{vb.gastos_dep | number:0}}
                                    </td>
                                    <td class="align-middle">
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vb.diezmo | thousandsPipe" #diezmoInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_votar_b(vb._id,diezmoInput.value,"Diezmo")'
                                            (blur)='modal_modificar_votar_b(vb._id,diezmoInput.value,"Diezmo")'>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            [value]="vb.no_diezmo | thousandsPipe" #no_diezmoInput
                                            (keyup)="separador_miles($event)"
                                            (keyup.enter)='modal_modificar_votar_b(vb._id,no_diezmoInput.value,"No Diezmo")'
                                            (blur)='modal_modificar_votar_b(vb._id,no_diezmoInput.value,"No Diezmo")'>
                                    </td>
                                    <td class="bg-light align-middle">
                                        ${{vb.diferencia | number:0}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="hide">
                            <table class="table table-bordered" id="print_section_b">
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>N° Departamento</th>
                                        <th>Nombre Departamento</th>
                                        <th>Gastos Departamento</th>
                                        <th>Diezmo</th>
                                        <th>No diezmo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let vb of votar_b;let indice=index ">
                                        <td class="bg-light align-middle">
                                            {{vb.item}}
                                        </td>
                                        <td class="bg-light align-middle">
                                            {{vb.n_departamento}}
                                        </td>
                                        <td class="bg-light align-middle">
                                            {{vb.nombre_departamento}}
                                        </td>
                                        <td class="bg-light align-middle">
                                            ${{vb.gastos_dep | number:0}}
                                        </td>
                                        <td class="bg-light align-middle">
                                            ${{vb.diezmo | number:0}}
                                        </td>
                                        <td class="bg-light align-middle">
                                            ${{vb.no_diezmo | number:0}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colspan="3">
                                            Totales
                                        </th>
                                        <th>
                                            ${{totales_votar_b.gastos_totales | number:0}}
                                        </th>
                                        <th>
                                            ${{totales_votar_b.diezmo | number:0}}
                                        </th>
                                        <th>
                                            ${{totales_votar_b.no_diezmo | number:0}}
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <!-- end table-responsive -->
                </div>
                <div class="d-flex justify-content-center mt-5">
                    <button type="button" class="btn btn-primary " (click)='generarPDF_votar_b()'>Imprimir <i
                            class="fas fa-print fa-fw"></i></button>
                </div>
            </div>
            <!-- Fin Pestaña Votar B -->
            <!-- Comienzo Pestaña Votar A -->
            <div *ngIf="tipo_pres == 'votar_a'">
                <div class="panel-body">
                    <h1 class=" m-b-20 text-center">Votar A</h1>
                    <!-- begin table-responsive -->
                    <div class="table-responsive ">
                        <div>
                            <table class="table table-bordered" id="">
                                <thead>
                                    <tr>
                                        <th class="bg-light">Grupo de cuentas</th>
                                        <th class="bg-light">Diezmos</th>
                                        <th class="bg-light">No Diezmos</th>
                                        <th class="no-borders"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle">
                                            Total Ingresos 311 Diezmos Netos
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_ingreso_311_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_ingreso_311_no_diezmo | number:0}}
                                        </td>
                                        <td class="no-borders"></td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            Total Ingresos 312 No Diezmos
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_ingreso_312_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_ingreso_312_no_diezmo | number:0}}
                                        </td>
                                        <td class="no-borders"></td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            Total Ingresos 313 Donaciones
                                        </td>
                                        <td class="align-middle">
                                            <div class="d-block" *ngIf='votar_a_ingreso_313_no_diezmo != null'>
                                                ${{votar_a_ingreso_313_diezmo | number:0}}</div>
                                            <div class="d-block" *ngIf='votar_a_ingreso_313_diezmo == null'>$0</div>
                                        </td>
                                        <td class="align-middle">
                                            <div class="d-block" *ngIf='votar_a_ingreso_313_no_diezmo != null'>
                                                ${{votar_a_ingreso_313_no_diezmo | number:0}}</div>
                                            <div class="d-block" *ngIf='votar_a_ingreso_313_no_diezmo == null'>$0
                                            </div>
                                        </td>
                                        <td class="no-borders"></td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            Total Ingresos 318 Otros Operativos
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_ingreso_318_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_ingreso_318_no_diezmo | number:0}}
                                        </td>
                                        <td class="no-borders"></td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            Total Ingresos 319 Subvenciones Líquidas
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_ingreso_319_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_ingreso_319_no_diezmo | number:0}}
                                        </td>
                                        <td class="no-borders"></td>
                                    </tr>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th class="bg-light align-middle">
                                            Totales Ingresos
                                        </th>
                                        <th class="bg-light align-middle">
                                            ${{votar_a_total_ingresos_diezmo | number:0}}
                                        </th>
                                        <th class="bg-light align-middle">
                                            ${{votar_a_total_ingreso_no_diezmo | number:0}}
                                        </th>
                                        <th class="bg-light align-middle">
                                            ${{votar_a_total_ingresos | number:0}}
                                        </th>
                                        <th class="bg-light align-middle">
                                            Factor
                                        </th>
                                        <th class="bg-light align-middle" style="width: 100px;">
                                            Factor REA
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle">
                                            411 Gastos Sueldos
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_sueldos_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_sueldos_no_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{(votar_a_sueldos_diezmo + votar_a_sueldos_no_diezmo) | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            {{votar_a_factor_411 | percent:'1.2'}}
                                        </td>
                                        <td class="align-middle">
                                            <input class="form-control form-control-sm" type="text"
                                                [value]="factor_rea.factor_411" #f_411Input
                                                (keyup.enter)='modal_modificar_factor_rea(factor_rea._id,"411",f_411Input.value)'
                                                (blur)='modal_modificar_factor_rea(factor_rea._id,"411",f_411Input.value)'>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            412 Gastos Operativos
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_gastos_operativos_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_gastos_operativos_no_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{(votar_a_gastos_operativos_diezmo +
                                            votar_a_gastos_operativos_no_diezmo) | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            {{votar_a_factor_412 | percent:'1.2'}}
                                        </td>
                                        <td class="align-middle">
                                            <input class="form-control form-control-sm" type="text"
                                                [value]="factor_rea.factor_412" #f_412Input
                                                (keyup.enter)='modal_modificar_factor_rea(factor_rea._id,"412",f_412Input.value)'
                                                (blur)='modal_modificar_factor_rea(factor_rea._id,"412",f_412Input.value)'>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            414 Gastos Reuniones y Eventos
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_gastos_reuniones_eventos_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_gastos_reuniones_eventos_no_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{(votar_a_gastos_reuniones_eventos_diezmo +
                                            votar_a_gastos_reuniones_eventos_no_diezmo) | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            {{votar_a_factor_414 | percent:'1.2'}}
                                        </td>
                                        <td class="align-middle">
                                            <input class="form-control form-control-sm" type="text"
                                                [value]="factor_rea.factor_414" #f_414Input
                                                (keyup.enter)='modal_modificar_factor_rea(factor_rea._id,"414",f_414Input.value)'
                                                (blur)='modal_modificar_factor_rea(factor_rea._id,"414",f_414Input.value)'>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            419 Gastos Otorgamientos
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_otorgamientos_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{votar_a_otorgamientos_no_diezmo | number:0}}
                                        </td>
                                        <td class="align-middle">
                                            ${{(votar_a_otorgamientos_diezmo + votar_a_otorgamientos_no_diezmo) |
                                            number:0}}
                                        </td>
                                        <td class="align-middle">
                                            {{votar_a_factor_419 | percent:'1.2'}}
                                        </td>
                                        <td class="align-middle">
                                            <input class="form-control form-control-sm" type="text"
                                                [value]="factor_rea.factor_419" #f_419Input
                                                (keyup.enter)='modal_modificar_factor_rea(factor_rea._id,"419",f_419Input.value)'
                                                (blur)='modal_modificar_factor_rea(factor_rea._id,"419",f_419Input.value)'>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th class="bg-light align-middle">
                                            Total Gastos
                                        </th>
                                        <th class="bg-light align-middle">
                                            ${{votar_a_total_gastos_diezmo | number:0}}
                                        </th>
                                        <th class="bg-light align-middle">
                                            ${{votar_a_total_gastos_no_diezmo | number:0}}
                                        </th>
                                        <th class="bg-light align-middle">
                                            ${{(votar_a_total_gastos_diezmo + votar_a_total_gastos_no_diezmo) |
                                            number:0}}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="bg-light align-middle">
                                            Diferencia (Ingresos - Gastos)
                                        </th>
                                        <th class="bg-light align-middle">
                                            ${{votar_a_diferencia_diezmo | number:0}}
                                        </th>
                                        <th class="bg-light align-middle">
                                            ${{votar_a_diferencia_no_diezmo | number:0}}
                                        </th>
                                        <th class="bg-light align-middle">
                                            ${{votar_a_total_diferencia | number:0}}
                                        </th>
                                        <th class="bg-light align-middle">
                                            {{votar_a_factor_diferencia | percent:'1.2'}}
                                        </th>
                                        <th class="bg-light align-middle">
                                            <input class="form-control form-control-sm" type="text"
                                                [value]="factor_rea.factor_diferencia" #f_diferenciaInput
                                                (keyup.enter)='modal_modificar_factor_rea(factor_rea._id,"Diferencia",f_diferenciaInput.value)'
                                                (blur)='modal_modificar_factor_rea(factor_rea._id,"Diferencia",f_diferenciaInput.value)'>
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                            <!-- Print Table -->
                            <table class="table table-bordered hide" id="print_section_a">
                                <thead>
                                    <tr>
                                        <th class="bg-light">Grupo de cuentas</th>
                                        <th class="bg-light">Diezmos</th>
                                        <th class="bg-light">No Diezmos</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Total Ingresos 311 Diezmos Netos
                                        </td>
                                        <td>
                                            ${{votar_a_ingreso_311_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{votar_a_ingreso_311_no_diezmo | number:0}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Total Ingresos 312 No Diezmos
                                        </td>
                                        <td>
                                            ${{votar_a_ingreso_312_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{votar_a_ingreso_312_no_diezmo | number:0}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Total Ingresos 313 Donaciones
                                        </td>
                                        <td>
                                            ${{votar_a_ingreso_313_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{votar_a_ingreso_313_no_diezmo | number:0}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Total Ingresos 318 Otros Operativos
                                        </td>
                                        <td>
                                            ${{votar_a_ingreso_318_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{votar_a_ingreso_318_no_diezmo | number:0}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Total Ingresos 319 Subvenciones Líquidas
                                        </td>
                                        <td>
                                            ${{votar_a_ingreso_319_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{votar_a_ingreso_319_no_diezmo | number:0}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg-light">
                                            Totales Ingresos
                                        </td>
                                        <td class="bg-light">
                                            ${{votar_a_total_ingresos_diezmo | number:0}}
                                        </td>
                                        <td class="bg-light">
                                            ${{votar_a_total_ingreso_no_diezmo | number:0}}
                                        </td>
                                        <td class="bg-light">
                                            ${{votar_a_total_ingresos | number:0}}
                                        </td>
                                        <td class="bg-light">
                                            Factor
                                        </td>
                                        <td class="bg-light" style="width: 100px;">
                                            Factor REA
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            411 Gastos Sueldos
                                        </td>
                                        <td>
                                            ${{votar_a_sueldos_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{votar_a_sueldos_no_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{(votar_a_sueldos_diezmo + votar_a_sueldos_no_diezmo) | number:0}}
                                        </td>
                                        <td>
                                            {{votar_a_factor_411 | percent:'1.2'}}
                                        </td>
                                        <td>
                                            {{(factor_rea.factor_411)/100 | percent:'1.2'}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            412 Gastos Operativos
                                        </td>
                                        <td>
                                            ${{votar_a_gastos_operativos_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{votar_a_gastos_operativos_no_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{(votar_a_gastos_operativos_diezmo +
                                            votar_a_gastos_operativos_no_diezmo) | number:0}}
                                        </td>
                                        <td>
                                            {{votar_a_factor_412 | percent:'1.2'}}
                                        </td>
                                        <td>
                                            {{(factor_rea.factor_412)/100 | percent:'1.2'}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            414 Gastos Reuniones y Eventos
                                        </td>
                                        <td>
                                            ${{votar_a_gastos_reuniones_eventos_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{votar_a_gastos_reuniones_eventos_no_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{(votar_a_gastos_reuniones_eventos_diezmo +
                                            votar_a_gastos_reuniones_eventos_no_diezmo) | number:0}}
                                        </td>
                                        <td>
                                            {{votar_a_factor_414 | percent:'1.2'}}
                                        </td>
                                        <td>
                                            {{(factor_rea.factor_414)/100 | percent:'1.2'}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            419 Gastos Otorgamientos
                                        </td>
                                        <td>
                                            ${{votar_a_otorgamientos_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{votar_a_otorgamientos_no_diezmo | number:0}}
                                        </td>
                                        <td>
                                            ${{(votar_a_otorgamientos_diezmo + votar_a_otorgamientos_no_diezmo) |
                                            number:0}}
                                        </td>
                                        <td>
                                            {{votar_a_factor_419 | percent:'1.2'}}
                                        </td>
                                        <td>
                                            {{(factor_rea.factor_419)/100 | percent:'1.2'}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th class="bg-light">
                                            Total Gastos
                                        </th>
                                        <th class="bg-light">
                                            ${{votar_a_total_gastos_diezmo | number:0}}
                                        </th>
                                        <th class="bg-light">
                                            ${{votar_a_total_gastos_no_diezmo | number:0}}
                                        </th>
                                        <th class="bg-light">
                                            ${{(votar_a_total_gastos_diezmo + votar_a_total_gastos_no_diezmo) |
                                            number:0}}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="bg-light">
                                            Diferencia (Ingresos - Gastos)
                                        </th>
                                        <th class="bg-light">
                                            ${{votar_a_diferencia_diezmo | number:0}}
                                        </th>
                                        <th class="bg-light">
                                            ${{votar_a_diferencia_no_diezmo | number:0}}
                                        </th>
                                        <th class="bg-light">
                                            ${{votar_a_total_diferencia | number:0}}
                                        </th>
                                        <th class="bg-light">
                                            {{votar_a_factor_diferencia | percent:'1.2'}}
                                        </th>
                                        <th class="bg-light">
                                            {{(factor_rea.factor_diferencia)/100 | percent:'1.2'}}
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div id="editor"></div>
                    </div>
                    <!-- end table-responsive -->
                </div>
                <div class="m-t-10">
                    <div class="d-flex justify-content-center text-center">
                        <button type="button" class="btn btn-primary " (click)='generarPDF_votar_a()'>Imprimir <i
                                class="fas fa-print fa-fw"></i></button>
                    </div>
                </div>
            </div>
            <!-- Fin Pestaña Votar A -->
            <ng-template #modal_errores let-c="close" let-d="dismiss" let-modal>
                <div class="modal-header">
                    <h4 class="modal-title">Errores Importador</h4>
                    <button type="button" class="close btn btn-sm btn-white m-r-1" (click)="d('Cross click')"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <h1 class="text-center text-danger"><i class="fa fa-exclamation-circle fa-3x"></i>
                        <div *ngIf='errores_importador.length > 1'>
                            Se encontraron {{errores_importador.length}} errores
                        </div>
                        <div *ngIf='errores_importador.length == 1'>
                            Se encontro {{errores_importador.length}} error
                        </div>
                    </h1>
                    <div *ngFor='let error of errores_importador' class="m-t-20">
                        <ul class="fa-ul">
                            <li>
                                <span class="fa-li"><i class="fa fa-exclamation-triangle"></i></span>
                                <strong> Error:</strong> {{ error.error }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-success btn-sm" (click)="archivo_importador(modal_errores, false)">Generar de Todas Formas</button>
                    <a href="javascript:;" class="btn btn-white" (click)="c('Close click')">Cerrar</a>
                </div>
            </ng-template>
            <div class="d-flex justify-content-center" *ngIf="tipo_pres == 'votar_a'">
                <button type="button" class="btn btn-primary m-t-10 m-r-10"
                    (click)="archivo_importador(modal_errores, true)">Generar
                    Importador
                </button>
                <button type="button" class="btn btn-primary text-white m-t-10 m-l-10"
                    (click)="exportar_tabla_excel('', true)">Reporte Presupuesto</button>
            </div>
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
import { Component, HostListener, Renderer2, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import pageSettings from './config/page-settings';
import * as global from './config/globals';
import { LocalService } from "./services/localService";
import { MicrosoftAuthService } from './services/authentication.service';
import { UserService } from './services/user.service';
import Swal from 'sweetalert2'
import { SharingService } from './services/sharing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  @Input() mostrar = true;
  pageSettings;
  public isLogged


  ngOnInit() {
    // page settings

    this.pageSettings = pageSettings;
  }

  // window scroll
  pageHasScroll;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 0) {
      this.pageHasScroll = true;
    } else {
      this.pageHasScroll = false;
    }
  }

  // set page minified
  onToggleSidebarMinified(val: boolean): void {
    if (this.pageSettings.pageSidebarMinified) {
      this.pageSettings.pageSidebarMinified = false;
    } else {
      this.pageSettings.pageSidebarMinified = true;
    }
  }

  // set page right collapse
  onToggleSidebarRight(val: boolean): void {
    if (this.pageSettings.pageSidebarRightCollapsed) {
      this.pageSettings.pageSidebarRightCollapsed = false;
    } else {
      this.pageSettings.pageSidebarRightCollapsed = true;
    }
  }

  // hide mobile sidebar
  onHideMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      if (this.pageSettings.pageMobileSidebarFirstClicked) {
        this.pageSettings.pageMobileSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileSidebarToggled = false;
      }
    }
  }

  // toggle mobile sidebar
  onToggleMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      this.pageSettings.pageMobileSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileSidebarToggled = true;
      this.pageSettings.pageMobileSidebarFirstClicked = true;
    }
  }


  // hide right mobile sidebar
  onHideMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
        this.pageSettings.pageMobileRightSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileRightSidebarToggled = false;
      }
    }
  }

  // toggle right mobile sidebar
  onToggleMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      this.pageSettings.pageMobileRightSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileRightSidebarToggled = true;
      this.pageSettings.pageMobileRightSidebarFirstClicked = true;
    }
  }

  constructor(private _localService: LocalService, private location: Location, private _userService: UserService, private _route: ActivatedRoute, private _sharingService: SharingService, private _authMicrosoft: MicrosoftAuthService, private titleService: Title,
    private router: Router, private renderer: Renderer2) {

    this.isLogged = this._authMicrosoft.token
    if (this.isLogged == null) {
      setTimeout(() => {
        this._route.queryParams.subscribe((params) => {
          if (params.code) this.getToken(params.code)
          else this.loginMicrosoft();
        });
      }, 500)
    } else {
      const path = location.path().includes("?code") ? "" : location.path()
      this.router.navigateByUrl(this.router.url + path);
      this.getUserData();
    }
    // this.isLogged = this._authMicrosoft.token;
    // if (this.isLogged == null) {
    //   setTimeout(() => {
    //     this._route.queryParams.subscribe((params) => {
    //       if (params.code) this.getToken(params.code)
    //       else this.loginMicrosoft();
    //     });
    //   }, 500)
    // } else {
    //   this.router.navigateByUrl(this.router.url);
    //   this.getUserData();
    // }

    router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (window.innerWidth < 768) {
          this.pageSettings.pageMobileSidebarToggled = false;
        }
      }
    });
  }

  onMostrar(val: boolean): void {
    this.mostrar = val
  }



  loginMicrosoft() {
    if (this.isLogged == null) {
      this._authMicrosoft.getTokenAPI().subscribe(res => {
        window.location.href = res.url;
      });
    }

  }
  getToken(code: string) {
    try {
      this._authMicrosoft.getAccessToken(code).subscribe(res => {
        console.log(res)
        this.router.navigateByUrl("/");
        const currentTime = new Date().getTime() / 1000
        this._localService.setJsonValue('expires_in', (currentTime + res.ext_expires_in - 900))
        this._localService.setJsonValue('ext_expires_in', (currentTime + res.ext_expires_in))

        this._authMicrosoft.token = res.access_token;
        this._authMicrosoft.refresh = res.refresh_token;
        this._sharingService.sharingTokenData = res.access_token
        this._sharingService.sharingRefreshTokenData = res.refresh_token
        this.getUserData();
      })
    } catch (error) {
      console.error('Error al obtener el token:', error);
    }
  }

  getUserData() {
    Swal.fire({
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    this._authMicrosoft.decryptToken().subscribe(res => {
      if (res.error) {
        if (res.error?.code == "InvalidAuthenticationToken") {
          this.showCautionSesion()
        } else {
          Swal.fire({
            title: "Acceso Denegado",
            text: res.error,
            icon: "error",
            confirmButtonText: "Salir",
            allowOutsideClick: false
          }).then(result => {
            this._authMicrosoft.logout().subscribe(res => {
              this._authMicrosoft.clearSesion()
              let a = document.createElement('a')
              a.href = res.url
              a.click()
            })
          })
        }
      } else {
        this._userService.privilegiosRuta().subscribe(
          (response) => {
            this._localService.setJsonValue("rutas", JSON.stringify(response));
            this._userService.processUserData(res.user.user)
            this._userService.processUserPhoto(res.photo.body.data)
            this.initializeUserData(res.user)
          },
          (error) => {
            console.log(error)
          });

        Swal.close()
        this.isLogged = this._authMicrosoft.token;
      }
    })
  }

  showCautionSesion() {
    Swal.fire({
      title: "Su Sesión ha expirado",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Renovar",
      cancelButtonText: "Cerrar Sesión",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
      allowOutsideClick: false
    }).then(result => {
      if (result.isConfirmed) {
        this._authMicrosoft.refreshTokenAPI().subscribe(res => {
          this._authMicrosoft.token = res.access_token
          this._authMicrosoft.refresh = res.refresh_token
          this._sharingService.sharingTokenData = res.access_token
          this._sharingService.sharingRefreshTokenData = res.refresh_token
          this._localService.setJsonValue("access_token", res.access_token)
          this.getUserData()
        })
      } else {
        this._authMicrosoft.logout().subscribe(res => {
          this._authMicrosoft.clearSesion()
          let a = document.createElement('a')
          a.href = res.url
          a.click()
        })
      }


    })
  }

  initializeUserData(user) {
    this._sharingService.sharingEntitiesData = user.entities
    this._sharingService.sharingIdentityData = user.identity
    this._sharingService.sharingEntityData = user.entity
    this._sharingService.sharingRoleData = user.role
    this._sharingService.sharingMenuData = user.menu
    this._sharingService.sharingTokenData = this._localService.getJsonValue('access_token')

  }

}


import { Component, OnInit, ElementRef } from '@angular/core';
import { EntidadService } from '../../../services/entidad.service';
import { UploadService } from '../../../services/upload.service';
import { Global } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { F29Service } from '../../../services/declaracion_f29.service';
import { Documentacion_f29 } from '../../../models/documentacion_f29';
import { RevisionPeriodo } from '../../../models/revision_periodo';
import { Borrador_f29 } from '../../../models/f29_borrador';
import { ReportesImpuestosService } from '../../../services/informesPDF.services';
import { detectAnyAdblocker } from 'just-detect-adblock'


import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'


declare var jQuery: any;
declare var $: any;
declare var button: any;
import Swal from 'sweetalert2';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThousandsPipe } from '../../../pipes/thousands.pipe'
import { COLOR_BLACK } from '../../../config/globals';
import { LocalService } from '../../../services/localService';

@Component({
  selector: 'app-reportes-impuestos',
  templateUrl: './reportes-impuestos.component.html',
  styleUrls: ['./reportes-impuestos.component.css'],
  providers: [EntidadService, UploadService, F29Service, UserService, ThousandsPipe, ReportesImpuestosService]
})
export class ReportesImpuestosComponent implements OnInit {

  public categoria_borrador = "Débitos y Ventas"
  public url = Global.url_reportes;
  public titulo = " Reportes e Informes ";
  public descripcion = " Declaraciones (F-29) ";
  public icono = "fa fas-box-archive fa-fw";
  public filesToUpload;
  public meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

  public active = 1
  public tipo_archivo;
  public token;
  public entidad_sel;
  public archivo_subir;
  public archivos = [];

  public nombre_boton = "SECCIÓN: DÉBITOS Y VENTAS"

  public enviar_disabled = true;
  public guardar_disabled = false;

  public alerta = false;
  public mensaje_alerta;
  public periodo_abierto;
  public periodo;
  public fecha_vencimiento;
  public mes_periodo;
  public registro_periodo = false;

  public nombre_archivo;
  public extencion_archivo;
  public extencion_valida_archivo;
  public adjuntar_disabled = false;

  public etapa_periodo;
  public ultBitacora;
  public estado_periodo;
  public archivo_pdf
  public archivo_excel
  public bitacora = [];
  public etapa_revision;
  public estados_revision;
  public privilegio = { revision_periodo: false }
  public fecha_actual = new Date();
  public vencimiento_sig_periodo = new Date()
  public grupos = []
  public tab = "Reporte Débito Venta"

  public revision_periodo;
  public closeResult: string;
  public btn_cerrar_periodo = false;
  public estadisticas = {
    porcentaje_aTiempo: "",
    dias_entrega: "",
    promedio_rechazos: "",
    activo: 0
  }
  public entidad_periodo;
  public minified;
  public borrador_f29
  public f29_grupos = []
  public tipo_borrador = "entidad"
  public cargando_pdf = false
  public grupoSel
  public reporteSel
  public reportes = []
  public anoSel = 0
  public mesSel
  public ano_actual = new Date()
  public anos = []
  public borradorF29
  public url_blob
  public pdf
  constructor(

    private _entidadService: EntidadService,
    private _uploadService: UploadService,
    private _userService: UserService,
    private _f29Service: F29Service,
    private modalService: NgbModal,
    private _reportesService: ReportesImpuestosService,
    private _localService: LocalService
  ) {
    this.minified = false;
    this.ultBitacora = { fecha: Date, estado: "", etapa: "", observaciones: "" }
    this.tipo_archivo = new Documentacion_f29("", JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON, '');
    this.borrador_f29 = new Borrador_f29("", JSON, JSON, JSON, JSON, JSON, JSON, JSON, JSON)
    this.token = this._userService.getToken();
    this.entidad_sel = this._entidadService.getEntidad()
    this.revision_periodo = new RevisionPeriodo('', '', '', '', '');
    this.mesSel = this.meses[this.ano_actual.getMonth() - 1]

  }

  ngOnInit() {
    this._f29Service.getTiposReportes().subscribe(response => {
      this.reportes = response.tipos
      this.reporteSel = this.reportes[0]
    })

    for (let a = 2020; a <= this.ano_actual.getFullYear(); a++) {
      this.anos.push(a)
    }
    this.anoSel = this.ano_actual.getFullYear()

  }

  ngDoCheck() {
    var cambioEntidad = this._entidadService.getEntidad()

    if (this.entidad_sel.codigo != cambioEntidad.codigo) {
    }
  }

  open(content) {
    let size = "xl"

    this.modalService.open(content, { windowClass: "modal-holder", size: size }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  abrir(archivo) {
    document.getElementById(archivo).click()
  }

  // Funcion para advertir cambios sin guardar,  **sin implementar
  canDeactivate() {
    return true
  }

  sidebar_minified() {

    if (this.minified == false) {
      //$('#page-container').addClass('page-sidebar-minified');
      this.minified = true;
    } else {
      //$('#page-container').removeClass('page-sidebar-minified');
      this.minified = false;
    }

  }

  generarReporte(tipo_reporte) {
    Swal.fire({
      text: "Cargando...",
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    let mes = this.meses.indexOf(this.mesSel)
    this.reporteSel = tipo_reporte ? tipo_reporte : this.reporteSel
    this._reportesService.reportePDF(this.reporteSel, this.anoSel, mes).subscribe(response => {

      this.borradorF29 = response.data
      this.pdf = response.file
      Swal.close()
    })

  }

  trae_archivo(archivo, modal) {
    Swal.fire({
      text: "Cargando...",
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    this._reportesService.createReportePDF(this.pdf, 0).subscribe(response => {
      this.open(modal)
      const link = document.createElement("a")
      link.href = URL.createObjectURL(response)
      document.getElementById("pdf").setAttribute("src", link.href)
      // document.getElementById("pdf").setAttribute("name", this.pdf.data.reporte.nombre)
      Swal.close()
    })
  }

  
}


<!-- begin breadcrumb -->
<ol class="breadcrumb pull-right">
  <li class="breadcrumb-item"><a>Inicio</a></li>
  <li class="breadcrumb-item">{{descripcion}}</li>
  <li class="breadcrumb-item active">{{ titulo }}</li>
</ol>
<!-- end breadcrumb -->

<link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.10.19/css/dataTables.bootstrap4.min.css">

<!--################################################COntenido############################################################-->


<!-- begin page-header -->
<h1 class="page-header"> <i class="fa fa-folder-open"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin row -->
<div class="row ">



  <div class="col-md-12">
    <div class="panel panel-iatuch">
      <!-- begin panel-heading -->
      <div class="panel-heading ui-sortable-handle">
        <div class="panel-heading-btn">
          <!--<a href="#modal-dialog" data-toggle="modal"  style="color: azure"><i class="fa fa-calendar-check"></i> Periodo</a>-->
          <!--#modal-dialog data-toggle="modal"-->
        </div>

      </div>
      <div class="row">
        <div class="ml-2 col-md-3 m-5">
          Mes:
          <select name="listaMeses" id="mesSel" class="form-control" [(ngModel)]="mesSel" placeholder="Seleccione un Mes">
            <option *ngFor="let m of meses; let i = index" [value]="m">{{m | uppercase}}</option>
          </select>
        </div>
        <div class="ml-2 col-md-3 m-5">
          Año:
          <select name="listaAños" id="anoSel" class="form-control" [(ngModel)]="anoSel">
            <option *ngFor="let a of anos" [value]="a">{{a}}</option>
          </select>
        </div>
        <div class="ml-2 col-md-2 m-5">
          <button class="btn btn-primary btn-sm mt-3" (click)="generarReporte(null)">Generar</button>
        </div>
        <div *ngIf="borradorF29" class="col-md-3" >
          <div class="ml-2 m-5">
            Exportar a:
            <div class="col">
              <button class="btn btn-danger btn-sm mr-3" (click)="trae_archivo(archivo_pdf, modalArchivo)"><i class="fas fa-file-pdf fa-xl"></i> PDF</button>
              <!-- <button class="btn btn-success btn-sm mr-3" (click)="trae_archivo(archivo_excel, modalArchivo)"><i class="fas fa-file-excel fa-xl"></i> Excel</button> -->
            </div>
           
          </div>
        </div>
      </div>
      <div *ngIf="borradorF29" class="mt-3">
        <ul ngbNav #nav="ngbNav" class="nav-tabs" role="tablist" [activeId]="tab">
          <li *ngFor="let r of reportes" ngbNavItem [ngbNavItem]="r.nombre" class="" (click)="generarReporte(r)">
            <a ngbNavLink>{{r.nombre}}</a>
            <ng-template ngbNavContent>
              <div class="table-responsive">
                <app-reportes-debito-ventas *ngIf="reporteSel._id == '635aae6e47c64f5466a4edbf'" [borradorF29]="borradorF29"></app-reportes-debito-ventas>
                <app-reportes-credito-compra *ngIf="reporteSel._id == '635aae6e47c64f5466a4edc0'" [borradorF29]="borradorF29"></app-reportes-credito-compra>
                <app-reportes-impuesto-renta *ngIf="reporteSel._id == '635aae6e47c64f5466a4edc1'" [borradorF29]="borradorF29"></app-reportes-impuesto-renta>
              </div>
            </ng-template>
  
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
     
    
    </div>

  </div>

</div>



<ng-template #modalArchivo let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3>{{reporteSel.nombre}}</h3>
  </div>
  <div class="modal-body">
    <iframe id="pdf"  title="testPdf" width="100%" height="690px" type="application/pdf"  frameborder="0"></iframe>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="c('Close click')">Cerrar</button>
  </div>
</ng-template>

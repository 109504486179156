import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalService } from './localService';

@Injectable()
export class BodegaService {

  tempBodega
  private bodega
  private rutaValidacion
  private cambio
  private entHasBod
  private bodegasUser
  bodegaSelect
  bodegaCambio
  entidadTieneBodega
  bodegasUsuario
  rutaVal

  constructor(
    private _localService: LocalService) {
    this.tempBodega = this._localService.getJsonValue('bodega_sel') ? this._localService.getJsonValue('bodega_sel') : ''
    this.bodega = new BehaviorSubject(this.tempBodega)
    this.cambio = new BehaviorSubject(false)
    this.entHasBod = new BehaviorSubject(!this.tempBodega._id ? false : true)
    this.bodegaSelect = this.bodega.asObservable()
    this.bodegaCambio = this.cambio.asObservable()
    this.entidadTieneBodega = this.entHasBod.asObservable()
    this.rutaValidacion = !this.tempBodega ? this.validarRuta() : ''
    this.rutaValidacion = new BehaviorSubject(this.rutaValidacion)
    this.rutaVal = this.rutaValidacion.asObservable()
    this.bodegasUser = new BehaviorSubject(!this.tempBodega._id ? false : true)
    this.bodegasUsuario = this.bodegasUser.asObservable()
  }

  bodegaSeleccionada() {
    let nuevaBodega = this._localService.getJsonValue('bodega_sel')
    let bodegaTemp
    this.bodega.subscribe((data) => {
      bodegaTemp = data;
    });
    if (nuevaBodega.codigo !== bodegaTemp.codigo) {
      this.bodega.next(nuevaBodega)
      this.cambio.next(true)
    } else {
      this.cambio.next(false)
    }
  }

  validarBodegas(c) {
    this.entHasBod.next(c)
    this.bodega.next(this._localService.getJsonValue('bodega_sel'))
    this.cambio.next(true)
    this.bodegasUser.next(c)
    this.validarRuta()
  }

  actualizarBodegaSelect(select, bodegas) {
    if (select != false) {
      this._localService.setJsonValue('bodega_sel', select)
      this._localService.setJsonValue('bodegas', bodegas)
      this.bodega.next(this._localService.getJsonValue('bodega_sel'))
      this.bodegasUser.next(true)
      // this.bodega.next(this._localService.getJsonValue('bodega_sel'))
    }
  }

  validarRuta() {
    let identity = JSON.parse(this._localService.getJsonValue('identity')) ? JSON.parse(this._localService.getJsonValue('identity')) : ''
    let level = identity ? identity[0].level[0] : ''
    let ruta
    let crearBodegas = ['5c9b8617bda5d7a498aa3b90', '5c9b8617bda5d7a498aa3b92', '5d8bd2c79b43103393c6174a', '5d371cf2c2629158d2434451']
    ruta = crearBodegas.includes(level._id) ? '/inventario/mantenedor/bodegas' : '/inicio'
    let cambio
    this.entidadTieneBodega.subscribe((data) => {
      cambio = data
    })
    return { ruta, cambio }
    // return ruta
  }




}